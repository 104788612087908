import { TableGlobalConfigurationProvider } from '@clinintell/components/table_v2';
import WindowSizeContextProvider from '@clinintell/modules/windowSizeContext';
import AuthenticatedRoutes from '@clinintell/routes/authentication/AuthenticatedRoutes';
import UnauthenticatedRoutes from '@clinintell/routes/authentication/UnauthenticatedRoutes';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import { ConfidentialityAgreementProvider } from '../authentication/ConfidentialityAgreementContext';
import { Tooltip } from '@clinintell/components/index';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useMediaQuery } from '@mui/material';

type InitialRouteProps = {
  isAuthenticated: boolean;
};

const InitialRoute: React.FC<InitialRouteProps> = ({ isAuthenticated }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <WindowSizeContextProvider>
      <TableGlobalConfigurationProvider
        renderTooltipComponent={text => (
          <Tooltip content={text}>
            <Box component="span" ml={1} mt={0.5}>
              <HelpOutlineIcon component="svg" style={{ width: '18px', height: '18px' }} />
            </Box>
          </Tooltip>
        )}
        isMobileView={isMobileView}
      >
        {isAuthenticated ? (
          <AuthenticatedRoutes />
        ) : (
          <ConfidentialityAgreementProvider>
            <UnauthenticatedRoutes />
          </ConfidentialityAgreementProvider>
        )}
      </TableGlobalConfigurationProvider>
    </WindowSizeContextProvider>
  );
};

export default InitialRoute;
