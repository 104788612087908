import { ColumnName, getNodeTypeLabelForNode } from '../typings/tableSchemas';
import { NodeType } from '../typings/metricTypes';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import { getColumnMetadata } from '../typings/columnMetadata';
import { RowMenu } from '@clinintell/containers/metricsTimeSeries/typings/metrics';

const getDateBasedColumnHeader = (startDate: Date, endDate: Date) =>
  `${parseDateIntoPeriod(startDate)}-${parseDateIntoPeriod(endDate)}`;

type GetColumnHeaderArgs = {
  column: ColumnName;
  periodStart: string;
  periodEnd: string;
  comparisonPeriodStart?: string;
  comparisonPeriodEnd?: string;
  nodeType: NodeType;
  metric: keyof typeof Metrics;
};

export const getColumnHeader = ({
  column,
  periodEnd,
  periodStart,
  comparisonPeriodEnd,
  comparisonPeriodStart,
  nodeType,
  metric
}: GetColumnHeaderArgs): string => {
  switch (column) {
    case 'name':
      return getNodeTypeLabelForNode(nodeType);
    case 'current':
    case 'otoE':
      return getDateBasedColumnHeader(new Date(periodStart), new Date(periodEnd));
    case 'historical':
    case 'historicalOtoE':
      return comparisonPeriodStart && comparisonPeriodEnd
        ? getDateBasedColumnHeader(new Date(comparisonPeriodStart), new Date(comparisonPeriodEnd))
        : '';
    default:
      const columnMetadata = getColumnMetadata(column, metric);
      return columnMetadata.title;
  }
};

type BuildActiomMenuArgs = {
  onGraphClick: (entity: number) => void;
  onComparisonTableClick: (entity: number) => void;
  onConditionCodeClick: (entity: number) => void;
  metric: keyof typeof Metrics;
};

export const buildActionMenu = ({
  onGraphClick,
  onComparisonTableClick,
  onConditionCodeClick,
  metric
}: BuildActiomMenuArgs): RowMenu[] => {
  const rowMenu: RowMenu[] = [];
  rowMenu.push({ title: 'Graph', onClick: onGraphClick });

  if (metric === 'allConditions' || metric === 'targetedConditions') {
    rowMenu.push({
      title: 'Comparison Table',
      onClick: onComparisonTableClick
    });

    rowMenu.push({
      title: 'Condition Codes',
      onClick: onConditionCodeClick
    });
  }

  return rowMenu;
};
