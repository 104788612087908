import { DefaultDatesDTO } from '@clinintell/modules/defaultDates';
import { OrgTreeTypes } from '@clinintell/modules/orgTree';
import { useDefaultDates, useDefaultDatesDispatch } from '@clinintell/modules/store';
import { useEffect, useMemo } from 'react';

const useMyDefaultDates = (
  id: number | undefined,
  orgTreeType: OrgTreeTypes = 'client'
): DefaultDatesDTO | undefined => {
  const defaultDatesDispatch = useDefaultDatesDispatch();

  const { byId, bySAFId } = useDefaultDates();
  const defaultDates = orgTreeType === 'client' ? byId[id?.toString() || ''] : bySAFId[id?.toString() || ''];

  const byIdCallback = useMemo(() => (orgTreeType === 'client' ? byId : bySAFId), [byId, bySAFId, orgTreeType]);

  useEffect(() => {
    if (id === undefined || byIdCallback[id.toString()]) {
      return;
    }

    defaultDatesDispatch({
      type: orgTreeType === 'client' ? 'DEFAULT_DATES_FETCH_REQUESTED' : 'DEFAULT_SAF_DATES_FETCH_REQUESTED',
      payload: id.toString()
    });
  }, [byIdCallback, defaultDatesDispatch, id, orgTreeType]);

  return defaultDates;
};

export default useMyDefaultDates;
