import React, { ElementType } from 'react';
import { Link } from 'react-router-dom';
import { Typography, SvgIconProps, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

interface Props {
  label: string;
  link: string;
  iconComponent: ElementType<SvgIconProps>;
  iconSize?: SvgIconProps['fontSize'];
  disabled?: boolean;
}

const useStyles = makeStyles(theme => ({
  clickableContainer: {
    '&:hover': {
      cursor: 'pointer',
      textShadow: '0.75px 0 0 currentColor'
    }
  },
  buttonIcon: {
    fill: 'inherit',
    marginRight: theme.spacing(1),
    transition: 'fill 0.1s linear'
  },
  hyperlink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    transition: 'text-shadow 0.1s linear'
  }
}));

const TextLink: React.FC<Props> = ({
  label,
  link,
  iconComponent,
  disabled,
  iconSize = 'default' as SvgIconProps['fontSize']
}) => {
  const { hyperlink, buttonIcon, clickableContainer } = useStyles();
  const IconComponent = iconComponent;

  return (
    <Box
      display="flex"
      alignItems="center"
      className={clsx({
        [clickableContainer]: !disabled
      })}
    >
      <Typography variant="p2" component="div">
        {!disabled ? (
          <Link className={hyperlink} to={link}>
            <IconComponent fontSize={iconSize} className={buttonIcon} />
            {label}
          </Link>
        ) : (
          <Box display="flex" alignItems="center">
            <IconComponent fontSize={iconSize} className={buttonIcon} />
            {label}
          </Box>
        )}
      </Typography>
    </Box>
  );
};

export default TextLink;
