import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const StrategyIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '21px',
      height: '21px',
      ...props.sx
    }}
  >
    <circle cx="10.4164" cy="10.6201" r="9.1" fill="white" stroke="inherit" strokeWidth="1.8" />
    <circle cx="10.4423" cy="10.5923" r="5.1" fill="white" stroke="inherit" strokeWidth="1.8" />
    <circle cx="10.5037" cy="10.5176" r="1.25" fill="#666666" stroke="inherit" strokeWidth="1.5" />
    <line x1="12.0899" y1="8.70501" x2="17.3031" y2="2.48337" stroke="white" strokeWidth="3.8" />
    <path d="M10.7465 10.2471L18.4463 1.05679" stroke="inherit" strokeWidth="1.8" />
  </SvgIcon>
);
StrategyIcon.muiName = SvgIcon.muiName;

export default StrategyIcon;
