import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignIn from '@clinintell/containers/authentication/SignIn';
import SignUp from '@clinintell/containers/authentication/SignUp';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import { useAuthenticationStatusDispatch } from '@clinintell/modules/store';
import AuthenticationTemplate from '@clinintell/containers/authentication/AuthenticationTemplate';
import Support from '@clinintell/containers/support/Support';
import ConfidentialityAgreement from '@clinintell/containers/authentication/ConfidentialityAgreement';

type wrapperProps = {
  element: () => JSX.Element | null;
};
const RedirectAuthenticatedWrapper: React.FC<wrapperProps> = (props: wrapperProps): JSX.Element | null => {
  const authDispatch = useAuthenticationStatusDispatch();
  authDispatch({ type: 'SET_AUTHENTICATED' });
  return props.element();
};

const UnauthenticatedRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path="/signin"
        element={
          <AuthenticationTemplate>
            <ConfidentialityAgreement>
              <SignIn />
            </ConfidentialityAgreement>
          </AuthenticationTemplate>
        }
      />
      <Route
        path="/callback"
        element={<RedirectAuthenticatedWrapper element={(): JSX.Element => <Navigate to="/" />} />}
      />
      <Route
        path="/signup"
        element={
          <AuthenticationTemplate>
            <ConfidentialityAgreement>
              <SignUp />
            </ConfidentialityAgreement>
          </AuthenticationTemplate>
        }
      />
      <Route path="/support" element={<Support />} />
      <Route path="/" element={<Navigate to="/signin" />} />
      <Route path="/*" element={<RouteErrorPage />} />
    </Routes>
  );
};

export default UnauthenticatedRoutes;
