import { ClinIntellChart, ClinIntellChartDataSets } from '@clinintell/components/Chart/types';
import { ChartOptions, Easing } from 'chart.js';

export const shouldNotRender = (alreadyRendered: boolean | undefined, easingValue: number): boolean =>
  alreadyRendered || easingValue === 1;

const clinintellErrorBandPlugin = {
  id: 'clinintellErrorBandPlugin',
  rendered: false,
  options: {
    color: 'green',
    lineWidth: 2.5,
    horizontalWidth: 16
  },
  beforeDatasetsDraw(chart: ClinIntellChart, easingValue: Easing, options: ChartOptions): void {
    // This method is called many times, wait until it's been rendered and only run this method
    // one time
    chart.renderedOnce = shouldNotRender(chart.renderedOnce, Number(easingValue));
    if (!chart.renderedOnce) {
      return;
    }

    this.rendered = true;
    this.options = {
      ...this.options,
      ...options
    };

    const { datasets } = chart.data;
    if (datasets === undefined) {
      return;
    }

    // Loop through each dataset, only process sets that have error bars defined in the meta data
    datasets.forEach((dataset: ClinIntellChartDataSets, index) => {
      if (chart.scales === undefined) {
        return;
      }

      const metaData = chart.getDatasetMeta(index).data;
      const horizontalScales = chart.scales['y-axis-0'];
      const verticalScales = chart.scales['x-axis-0'];
      const { ctx } = chart;

      if (ctx === null) {
        return;
      }

      ctx.save();

      // Loop through each point of the dataset, only process points that have number values. Points may have blank values
      // if used as a buffer of sorts
      metaData.forEach((point, jIndex) => {
        if (dataset.data === undefined || dataset.errorBars === undefined) {
          return;
        }

        const value = horizontalScales.getRightValue(dataset.data[jIndex] as Chart.ChartPoint);
        if (Number.isNaN(value)) {
          return;
        }

        const xAxisValue = verticalScales.ticks[jIndex];
        const errorMargin = dataset.errorBars[xAxisValue];
        if (!errorMargin) {
          return;
        }

        // Get precision of the value, so that it can be rounded to that same precision
        // after adding the error margins. JS sometimes does funky stuff with math.
        // Figure out a better way to determine rounding of error margin values
        const plus = Number(value + errorMargin.plus).toFixed(4);
        let minus = Number(value + errorMargin.minus).toFixed(4);
        // Chop off the error margin if 0 or less
        if (Number(minus) < 0) {
          minus = '0';
        }

        const plusPixels = horizontalScales.getPixelForValue(Number(plus));
        const minusPixels = horizontalScales.getPixelForValue(Number(minus));

        ctx.save();
        ctx.lineWidth = this.options.lineWidth;
        ctx.strokeStyle = this.options.color;
        ctx.beginPath();

        // Draw bottom horizontal line, then the vertical error band line, and finally
        // the top horizontal line
        // -- disabling linter rule due to working with 3rd party API
        const model = point._model; // eslint-disable-line no-underscore-dangle
        const width = this.options.horizontalWidth;

        if (Number(minus) > 0) {
          ctx.moveTo(model.x, minusPixels);
          ctx.lineTo(model.x + width / 2, minusPixels);
          ctx.moveTo(model.x, minusPixels);
          ctx.lineTo(model.x - width / 2, minusPixels);
        }

        ctx.moveTo(model.x, minusPixels);
        ctx.lineTo(model.x, plusPixels);

        ctx.moveTo(model.x, plusPixels);
        ctx.lineTo(model.x + width / 2, plusPixels);
        ctx.moveTo(model.x, plusPixels);
        ctx.lineTo(model.x - width / 2, plusPixels);

        ctx.stroke();
        ctx.restore();
      });
    });
  }
};

export default clinintellErrorBandPlugin;
