import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { NodeTypeIds } from '@clinintell/modules/orgTree';
import { useDashboard2 } from '@clinintell/modules/store';
import { DocScoreGoalItems } from '@clinintell/modules/dashboard2';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { ColumnDef, DateCell, IntegerCell, Table } from '@clinintell/components/table_v2';

const SmartGoalsWidgetTable: React.FC = props => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { entity, groupId, groupName, smartGoalsWidget } = useDashboard2();
  const { isModalLoading } = smartGoalsWidget;
  const tableDataArray = smartGoalsWidget.modalTableData as DocScoreGoalItems[];

  const entityLevel =
    groupId === undefined || groupId === -1 || groupName === 'All' ? entity?.nodeTypeId : NodeTypeIds.SpecialtyGroup;

  const formatStatusText = (text: string): string => {
    const words = text.split(' ');
    words.forEach((word, ix) => {
      words[ix] = word[0].toUpperCase() + word.substring(1);
    });
    return words.join(' ');
  };

  const getStatusColor = (text: string): string => {
    if (text.toLocaleLowerCase() === 'meeting goal') return theme.palette.teal.light4;
    if (text.toLocaleLowerCase() === 'below goal') return theme.palette.red[500];
    return theme.palette.grey[500];
  };

  return isModalLoading ? (
    <ClinIntellSkeleton width="100%" height="200px" variant={'rectangular'} />
  ) : (
    <Box mt={2}>
      <Table<DocScoreGoalItems>
        rowData={tableDataArray || []}
        renderColumns={({ renderColumn }) => {
          const entityDetails: Array<ColumnDef<
            DocScoreGoalItems,
            'hospitalName' | 'physicianGroupName' | 'providerName'
          >> = [];
          switch (entityLevel) {
            case NodeTypeIds.Entity:
              entityDetails.push(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                renderColumn('hospitalName', {
                  cellRenderer: props => props.value || '',
                  headerName: 'Hospital',
                  headerAlignment: 'left',
                  sortable: true,
                  minWidth: 300
                })
              );

              if (!isMobileView) {
                entityDetails.push(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  renderColumn('physicianGroupName', {
                    cellRenderer: props => props.value || '',
                    headerName: 'Physician Group',
                    headerAlignment: 'left',
                    sortable: true,
                    minWidth: 300
                  })
                );
              }
              break;
            case NodeTypeIds.Hospital:
              entityDetails.push(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                renderColumn('physicianGroupName', {
                  cellRenderer: props => props.value || '',
                  headerName: 'Physician Group',
                  headerAlignment: 'left',
                  sortable: true,
                  minWidth: 300
                })
              );
              break;
            case NodeTypeIds.SpecialtyGroup:
              entityDetails.push(
                renderColumn('providerName', {
                  cellRenderer: props => props.value || '',
                  headerName: 'Provider Name',
                  headerAlignment: 'left',
                  sortable: true,
                  minWidth: 300
                })
              );
              break;
          }

          return [
            ...entityDetails,
            !isMobileView
              ? renderColumn('implementationDate', {
                  cellRenderer: props => (props.value ? <DateCell date={props.value} /> : '-'),
                  headerName: 'Implementation Date',
                  sortable: true,
                  cellStyle: {
                    justifyContent: 'center'
                  }
                })
              : null,
            !isMobileView &&
              renderColumn('docScore', {
                cellRenderer: props => <IntegerCell postfix="%" value={props.value || 0} />,
                headerName: 'Doc Score - Implementation',
                sortable: true,
                cellStyle: {
                  justifyContent: 'center'
                }
              }),
            !isMobileView &&
              renderColumn('docScoreGoal', {
                cellRenderer: props => <IntegerCell postfix="%" value={props.value || 0} />,
                headerName: 'Doc Score - Goal',
                sortable: true,
                cellStyle: {
                  justifyContent: 'center'
                }
              }),
            renderColumn('status', {
              cellRenderer: props => (
                <Box
                  component="span"
                  sx={{
                    width: '100%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: getStatusColor(props.value || '')
                  }}
                >
                  {formatStatusText(props.value || '')}
                </Box>
              ),
              headerTooltip: (
                <Box>
                  <p>Met: The Doc Score – Implementation is at or above Doc Score – Goal</p>
                  <p>Near: The Doc Score – Implementation is within the margin of error of Doc Score – Goal</p>
                  <p>Below: The Doc Score – Implementation is significantly lower than Doc Score – Goal</p>
                </Box>
              ),
              sortable: true,
              headerName: 'Status'
            })
          ];
        }}
      />
    </Box>
  );
};

export default SmartGoalsWidgetTable;
