import React, { ReactNode } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TrainingStartIcon from '@clinintell/components/icons/TrainingStart';
import TrainingResumeIcon from '@clinintell/components/icons/TrainingResume';
import TrainingRetakeIcon from '@clinintell/components/icons/TrainingRetake';
import { useTraining } from '@clinintell/modules/store';
import ClinIntellSkeleton from './ClinIntellSkeleton';
import { TrainingProgressStatus } from '@clinintell/containers/trainingTest/types/trainingProgressTypes';

type TrainingButtonProps = {
  onClick: (status: TrainingProgressStatus) => void;
};

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    cursor: 'pointer',
    transform: 'translateY(0) scale(1)',
    transition: 'transform 100ms linear',
    '&:hover': {
      transform: 'translateY(-0.5rem) scale(1.1)'
    },
    '&:focus': {
      outline: 'none'
    }
  }
}));

const ButtonContainer = ({
  children,
  onClick
}: {
  onClick: () => void;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const theme = useTheme();
  const { buttonContainer } = useStyles();

  return (
    <Box
      component="button"
      onClick={onClick}
      className={buttonContainer}
      boxShadow={2}
      borderTop="none"
      borderLeft="none"
      borderRight="none"
      borderBottom={`12px solid ${theme.palette.blue.main}`}
      padding={`${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(2)}`}
      display="inline-flex"
      flexDirection="column"
      alignItems="center"
      bgcolor="white"
      borderRadius="4px"
    >
      {children}
    </Box>
  );
};

const TRAINING_SVG_PROPS = {
  fontSize: '1.5rem'
};

const TrainingButtonLink: React.FC<TrainingButtonProps> = ({ onClick }) => {
  const theme = useTheme();
  const { status, isLoading, errorMessage } = useTraining();

  let action = '';
  let actionIcon: ReactNode = <Box />;
  let statusMessage = '';

  // 404 on the service is valid if no training is found for the user, in this case return nothing
  if (errorMessage && errorMessage === '404 error: Not Found') {
    return null;
  }

  if (status) {
    switch (status.trainingStatus) {
      case 'New':
        action = 'Start Training';
        actionIcon = (
          <Box paddingX={1} marginBottom={1} borderBottom={`6px solid rgba(56, 94, 171, 0.5)`}>
            <TrainingStartIcon style={TRAINING_SVG_PROPS} />
          </Box>
        );
        break;
      case 'InProgress':
        action = 'Resume Training';
        actionIcon = <TrainingResumeIcon style={TRAINING_SVG_PROPS} />;
        statusMessage = `${status.percentComplete}% Complete`;
        break;
      case 'Completed':
        action = 'Retake Training';
        actionIcon = <TrainingRetakeIcon style={TRAINING_SVG_PROPS} />;
        statusMessage = `Score: ${status.percentScore}%`;
        break;
      default:
        action = 'NA';
        break;
    }
  }

  return isLoading ? (
    <ClinIntellSkeleton height="100px" width="130px" variant="rectangular" />
  ) : (
    <ButtonContainer onClick={(): void => onClick(status ? status.trainingStatus : 'New')}>
      <Box marginBottom={1} color={theme.palette.blue.main}>
        {actionIcon}
      </Box>
      <Typography color="primary" variant="button">
        <b>{action}</b>
      </Typography>
      <Box fontSize={theme.typography.caption.fontSize}>{statusMessage}</Box>
    </ButtonContainer>
  );
};

export default TrainingButtonLink;
