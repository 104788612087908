import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDashboard2 } from '@clinintell/modules/store';
import { HospitalsWithSystem } from '@clinintell/types/common';
import { MetricRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import { Timeline } from '@mui/icons-material';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { CMIDocScoreType } from './SeverityOpportunity';
import Footnotes from '@clinintell/components/Footnotes';
import { IntegerCell, IsStatSignificantCell, Table } from '@clinintell/components/table_v2';

type Props = {
  onGraphClick: (orgId: number, name: string) => void;
};

const SeverityOpportunityModalTable: React.FC<Props> = ({ onGraphClick }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { severityOpportunityWidget, entity, groupName, dashboardDefaultDates } = useDashboard2();
  const { modalTableData } = severityOpportunityWidget;
  const { nodeTypeId } = entity as HospitalsWithSystem;

  const { severityOpportunityData, docScoreData } = modalTableData as CMIDocScoreType;

  const footnote = [
    `Period: ${parseDateIntoPeriod(
      new Date(dashboardDefaultDates ? dashboardDefaultDates.currentStart : '')
    )}-${parseDateIntoPeriod(new Date(dashboardDefaultDates ? dashboardDefaultDates.currentEnd : ''))}`
  ];

  return (
    <Box>
      <Typography variant={'h5'} style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
        Performance Table
      </Typography>
      <Box mt={2}>
        <Table<MetricRecordJSON>
          rowData={severityOpportunityData}
          renderColumns={({ renderColumn, renderCustomColumn }) => {
            return [
              renderColumn('name', {
                headerName: nodeTypeId === 1 ? 'Hospital' : groupName === 'All' ? 'Physician Group' : 'Physician Name',
                cellRenderer: props => props.value || '',
                headerAlignment: 'left',
                sortable: true,
                width: 300
              }),
              renderCustomColumn({
                cellRenderer: props => (
                  <Box
                    onClick={(): void => {
                      if (!props.data) {
                        return;
                      }

                      onGraphClick(props.data.orgId, props.data.name);
                    }}
                    onTouchEnd={(): void => {
                      if (!props.data) {
                        return;
                      }

                      onGraphClick(props.data.orgId, props.data.name);
                    }}
                    sx={{ marginTop: 1, cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '100%' }}
                  >
                    <Tooltip content="View Graph" position="right">
                      <Timeline />
                    </Tooltip>
                  </Box>
                ),
                headerName: 'Action',
                cellStyle: {
                  justifyContent: 'center'
                },
                width: 100
              }),
              !isMobileView &&
                renderCustomColumn({
                  cellRenderer: props => {
                    const currentDocScoreRecord = docScoreData.find(
                      rec => rec.orgId === props.data?.orgId
                    ) as MetricRecordJSON;
                    return <IntegerCell value={currentDocScoreRecord.current} postfix="%" />;
                  },
                  headerName: 'CMI Doc Score',
                  cellStyle: {
                    justifyContent: 'center'
                  },
                  sortable: true
                }),
              !isMobileView &&
                renderColumn('gapVsCe', {
                  cellRenderer: props => (
                    <IsStatSignificantCell
                      value={props.value || 0}
                      errorMargin={props.data?.gapVsCeInErrorRange || 0}
                      isPercentageBased={false}
                      captureOverdocumentation
                    />
                  ),
                  headerName: 'Gap',
                  cellStyle: {
                    justifyContent: 'center'
                  },
                  sortable: true,
                  headerTooltip: (
                    <Box>
                      <p>
                        Gap is the unreported MS-DRG severity relative to full available MS-DRG severity in the unique
                        patient mix.
                      </p>
                    </Box>
                  )
                }),
              !isMobileView &&
                renderColumn('cases', {
                  cellRenderer: props => <IntegerCell value={props.value || 0} />,
                  headerName: 'Cases',
                  cellStyle: {
                    justifyContent: 'center'
                  },
                  sortable: true
                }),
              renderColumn('opportunityRW', {
                cellRenderer: props => <IntegerCell value={props.value || 0} />,
                headerName: 'Opportunity',
                cellStyle: {
                  justifyContent: 'center'
                },
                sortable: true,
                sort: 'desc',
                headerTooltip: (
                  <Box>
                    <p>Opportunity in Relative Weight points is calculated by multiplying the Gap and Cases.</p>
                    <p>
                      Please note that the calculation uses the full precision Gap and not the displayed Gap which is
                      rounded.
                    </p>
                    <p>For example, if the full precision Gap is 0.04935, then 0.05 would be displayed.</p>
                  </Box>
                )
              })
            ];
          }}
        />
      </Box>
      <Box mt={1} ml={-2}>
        <Footnotes footnotes={footnote} />
      </Box>
    </Box>
  );
};

export default SeverityOpportunityModalTable;
