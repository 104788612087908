import Breadcrumbs from '@clinintell/components/breadcrumbs/Breadcrumbs';
import { useBreadcrumbNavigationState } from '@clinintell/modules/store';
import useCurrentBreakpointOnScreen from '@clinintell/utils/useCurrentBreakpointOnScreen';
import React from 'react';

const BreadcrumbNavigation = () => {
  const { breadcrumbs, navigationAction } = useBreadcrumbNavigationState();

  const currentBreakpoint = useCurrentBreakpointOnScreen();

  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <Breadcrumbs
      onBreadcrumbClick={navigationAction}
      breadcrumbs={breadcrumbs}
      maxBreadcrumbsBeforeCollapse={currentBreakpoint !== 'xl' ? 2 : 4}
    />
  );
};

export default BreadcrumbNavigation;
