import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import HideTreeButton from '@clinintell/containers/treeSelector/sections/HideTreeButton';
import TreeSelector, { TreeSelectorWithContextProps } from '@clinintell/containers/treeSelector/TreeSelector';
import { NodeTypeIds, OrgTreeTypes } from '@clinintell/modules/orgTree';
import { dfsFindNodeInTree, dfsFindNodeInTreeWithSpecificParentId } from '@clinintell/utils/treeTraversal';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from 'react';
import CheckableTreeSelector from '../treeSelector/CheckableTreeSelector';
import { extractDetailsFromNode, NodeDetails } from '../treeSelector/logic/TreeContext';
import CollapsiblePanel from '../treeSelector/sections/CollapsiblePanel';
import EntityNavigationSearch from './EntityNavigationSearch';
import useOrgTreeByType from './logic/useOrgTreeByType';

const useStyles = makeStyles(theme => ({
  lefty: {
    float: 'left'
    // marginRight: theme.spacing(2)
  },
  treecontainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    height: '100%',
    backgroundColor: 'white',
    boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.25)`,
    marginRight: theme.spacing(1)
  }
}));

export type EntityNavigationProps = {
  onEntitySearchComplete: (nodeId: number, nodeDetails: NodeDetails) => void;
  entity: number;
  maxnodetype: keyof typeof NodeTypeIds;
  withSearch: boolean;
  minCharactersToSearch: number;
  maxCharactersToSearch: number;
  hideButtonColor: 'primary' | 'secondary';
  isTreeVisible: boolean;
  onToggleTreeVisibility?: () => void;
  id: string;
  cannotHideTree?: boolean;
  height?: number;
  checkedEntities?: number[];
  onCheckedChange?: (entity: number, isChecked: boolean) => void;
  nodeTypeWithCheckboxes?: keyof typeof NodeTypeIds;
  type: OrgTreeTypes;
  defaultExpandedNodes: string[];
  onEntitySelected: (nodeId: number, nodeDetails: NodeDetails) => void;
  withBorderOnTree?: boolean;
  searchStyles?: React.CSSProperties;
  treeStyles?: React.CSSProperties;
  showStartIcon?: boolean;
  sx?: SxProps<Theme>;
};

const EntityNavigation: React.FC<EntityNavigationProps> = ({
  onEntitySearchComplete,
  entity,
  maxnodetype,
  withSearch,
  minCharactersToSearch,
  maxCharactersToSearch,
  hideButtonColor,
  isTreeVisible,
  onToggleTreeVisibility = (): void => {
    /* empty object */
  },
  id,
  cannotHideTree,
  height,
  checkedEntities,
  onCheckedChange,
  nodeTypeWithCheckboxes,
  type,
  defaultExpandedNodes,
  onEntitySelected,
  withBorderOnTree,
  searchStyles,
  treeStyles,
  showStartIcon = false,
  sx
}) => {
  const { treeNode, nodeIdBeingFetched, fetchNode } = useOrgTreeByType({ type });

  const { lefty, treecontainer } = useStyles();
  const theme = useTheme();

  const handleEntitySearchResultSelection = useCallback(
    async (id: number, _name: string, parentId: number): Promise<void> => {
      if (!treeNode) {
        return;
      }

      const node = dfsFindNodeInTreeWithSpecificParentId(treeNode, id, parentId);
      let nodeDetails: NodeDetails;
      if (!node || (!node.isLeaf && node.children.length === 0)) {
        nodeDetails = await fetchNode({ nodeId: id, parentId });
      } else {
        nodeDetails = extractDetailsFromNode(node);
      }

      onEntitySearchComplete(id, nodeDetails);
    },
    [fetchNode, onEntitySearchComplete, treeNode]
  );

  const handleEntitySelection = async (nodeId: number, parentId?: number): Promise<void> => {
    if (!treeNode) {
      return;
    }

    const node =
      parentId !== undefined
        ? dfsFindNodeInTreeWithSpecificParentId(treeNode, nodeId, parentId)
        : dfsFindNodeInTree(treeNode, nodeId);

    let nodeDetails: NodeDetails;
    if (!node || (!node.isLeaf && node.children.length === 0 && node.nodeTypeId < NodeTypeIds[maxnodetype])) {
      nodeDetails = await fetchNode({ nodeId, parentId });
    } else {
      nodeDetails = extractDetailsFromNode(node);
    }

    onEntitySelected(nodeId, nodeDetails);
  };

  let treeSelector;
  if (!treeNode) {
    treeSelector = <ClinIntellSkeleton variant="rectangular" height="17rem" width="100%" />;
  } else {
    const treeSelectorProps: TreeSelectorWithContextProps = {
      selectednode: entity,
      maxnodetype,
      domid: id,
      nodeidloading: nodeIdBeingFetched,
      rootNode: treeNode,
      onNodeSelected: handleEntitySelection,
      onNodeToggled: fetchNode,
      defaultExpandedNodes
    };

    treeSelector =
      onCheckedChange && checkedEntities && nodeTypeWithCheckboxes ? (
        <CheckableTreeSelector
          nodeTypeWithCheckboxes={nodeTypeWithCheckboxes}
          onCheckedChange={onCheckedChange}
          checkedItems={checkedEntities}
          {...treeSelectorProps}
        />
      ) : (
        <TreeSelector {...treeSelectorProps} domid={id} treeStyles={{ marginLeft: 16, boxShadow: 'none' }} sx={sx} />
      );
  }

  if (withSearch) {
    return (
      <Box>
        <EntityNavigationSearch
          minCharactersToSearch={minCharactersToSearch}
          maxCharactersToSearch={maxCharactersToSearch}
          orgTreeSearchType={type}
          onSearchResultSelection={handleEntitySearchResultSelection}
          searchFieldInputStyles={{
            width: '100%',
            marginLeft: cannotHideTree ? 0 : theme.spacing(1),
            ...searchStyles
          }}
          showStartIcon={showStartIcon}
        >
          {!cannotHideTree ? (
            <HideTreeButton
              color={hideButtonColor}
              isTreeVisible={isTreeVisible}
              toggleTreeVisibility={onToggleTreeVisibility}
            />
          ) : null}
        </EntityNavigationSearch>
        <Box ml={cannotHideTree ? 0 : 6}>
          <CollapsiblePanel height={height} isCollapsed={isTreeVisible} withBorder={withBorderOnTree}>
            <Box display="flex" flexGrow="1" className={treecontainer} style={treeStyles}>
              {treeSelector}
            </Box>
          </CollapsiblePanel>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box className={lefty}>
        {!cannotHideTree ? (
          <HideTreeButton
            color={hideButtonColor}
            isTreeVisible={isTreeVisible}
            toggleTreeVisibility={onToggleTreeVisibility}
          />
        ) : null}
      </Box>
      <Box sx={{ flex: 1 }}>
        <CollapsiblePanel height={height} isCollapsed={isTreeVisible} withBorder={withBorderOnTree}>
          <Box display="flex" flexGrow="1" className={treecontainer}>
            {treeSelector}
          </Box>
        </CollapsiblePanel>
      </Box>
      {isTreeVisible ? null : <Box mb={7} />}
    </Box>
  );
};

export default EntityNavigation;
