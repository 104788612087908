import { useMemo } from 'react';
import {
  initializeMetricsNavigation,
  dataTrendLabels,
  setOpportunityPreferences
} from '@clinintell/modules/metricsNavigation';
import { useMetricsNavigation, useMetricsNavigationDispatch, useUser } from '@clinintell/modules/store';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { useEffect } from 'react';
import { ConditionShareRatios } from '../Metrics';
import { DefaultDatesJSON } from '../typings/metricTypes';

const useInitializeMetrics = (): boolean => {
  const metricsNavDispatch = useMetricsNavigationDispatch();
  const { rootId, features } = useUser();

  const { isInitialized, entity, metric } = useMetricsNavigation();

  // Default dates API call should only be made when entity changes - there could possibly be new dates and/or intervention period
  const { output: defaultDates } = useGetAPICAll<DefaultDatesJSON>({
    endpoint: `DefaultDates/${rootId}`,
    // User redux state should already be set prior to this component rendering
    isWaiting: false
  });

  if (defaultDates && defaultDates.error) {
    throw new Error(defaultDates.error);
  }

  // This needs to be called on initial setup to grab the shares
  const fetchMetricShares = useMemo(
    () => async (entityId: number) => {
      let output: AsyncOutput<ConditionShareRatios> = {};
      try {
        output = await ApplicationAPI.get({ endpoint: `metrics/conditions/shares/${entityId}` });
      } catch (error) {
        throw new Error(output.error);
      } finally {
        if (output.hasOwnProperty('error')) return;
        const shares = output.data as ConditionShareRatios;
        metricsNavDispatch(
          setOpportunityPreferences({
            hccWeight: shares.hcc,
            exmWeight: shares.elixhauserMortality,
            exrWeight: shares.elixhauserReadmission,
            psiWeight: shares.psi,
            psi02Weight: shares.psi02,
            psi04Weight: shares.psi04,
            psi07Weight: shares.psi07,
            psi90Weight: shares.psi90
          })
        );
      }
    },
    [metricsNavDispatch]
  );

  useEffect(() => {
    // Once default dates and targeted condition shares (if needed) have been fetched, we can initialize the metrics navigation
    // If already initialized, this doesn't have to happen.
    if (!defaultDates || isInitialized) {
      return;
    }

    const {
      historicalMinMonth,
      historicalMaxMonth,
      currentMinMonth,
      currentMaxMonth,
      absoluteMinMonth,
      interventionMonth
    } = defaultDates.data as DefaultDatesJSON;

    fetchMetricShares(rootId);

    metricsNavDispatch(
      initializeMetricsNavigation({
        metric: features.some(v => v.featureName === 'metricsAll') ? 'cmi' : 'docScore',
        entity: rootId,
        defaultPeriodStart: currentMinMonth,
        defaultPeriodEnd: currentMaxMonth,
        defaultComparisonPeriodStart: historicalMinMonth,
        defaultComparisonPeriodEnd: historicalMaxMonth,
        absoluteMinPeriod: absoluteMinMonth,
        interventionPeriod: interventionMonth,
        dataTrend: dataTrendLabels.codedrate
      })
    );
  }, [defaultDates, metricsNavDispatch, rootId, isInitialized, features, fetchMetricShares]);

  useEffect(() => {
    if (entity < 0 || metric !== 'allConditions') return;

    fetchMetricShares(entity);
  }, [entity, metric, metricsNavDispatch, fetchMetricShares]);

  return isInitialized;
};

export default useInitializeMetrics;
