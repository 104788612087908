import { DefaultDatesDTO } from '@clinintell/modules/defaultDates';
import { useDefaultDates, useDefaultDatesDispatch, useUser } from '@clinintell/modules/store';
import { useEffect } from 'react';

const useRootDefaultDates = (): DefaultDatesDTO => {
  const { rootId } = useUser();
  const defaultDatesDispatch = useDefaultDatesDispatch();

  const { byId } = useDefaultDates();
  const defaultDates = byId[rootId];

  useEffect(() => {
    if (byId[rootId]) {
      return;
    }

    defaultDatesDispatch({
      type: 'DEFAULT_DATES_FETCH_REQUESTED',
      payload: rootId.toString()
    });
  }, [byId, defaultDatesDispatch, rootId]);

  return defaultDates;
};

export default useRootDefaultDates;
