import Button from '@clinintell/components/button/Button';
import { TrainingProgressRouteProps } from '@clinintell/containers/reports/types/routeProps';
import { useReportDownloadStatus } from '@clinintell/containers/trainingStatusReport/context/ReportDownloadContext';
import useReportDownload from '@clinintell/containers/trainingStatusReport/logic/useReportDownload';
import { getReportType } from '@clinintell/containers/trainingStatusReport/TrainingStatusReport';
import { containerBorderRadius } from '@clinintell/theme/theme';
import { Box } from '@mui/system';
import React, { useCallback } from 'react';
import TrainingProgressFilter from '../TrainingProgressFilter';
import { useTableApiActions } from '@clinintell/components/table_v2';

type TrainingProgressFilterRouteProps = {
  reportFilterState: TrainingProgressRouteProps;
  setReportFilterState: React.Dispatch<React.SetStateAction<TrainingProgressRouteProps>>;
};

const TrainingProgressFilterRoute: React.FC<TrainingProgressFilterRouteProps> = ({
  reportFilterState,
  setReportFilterState
}) => {
  const { handleDownload, isDownloading } = useReportDownload();
  const { downloadIsEnabled } = useReportDownloadStatus();

  const { getSortedColumn } = useTableApiActions();

  const onFilterChanged = useCallback(
    (filters: TrainingProgressRouteProps) => {
      setReportFilterState({ ...filters });
    },
    [setReportFilterState]
  );

  const handleReportDownload = () => {
    const sortedColumn = getSortedColumn();
    let sortColumn;
    let sortIsDescending;

    if (sortedColumn) {
      sortColumn = sortedColumn[0];
      sortIsDescending = sortedColumn[1] === 'desc';
    }

    handleDownload({
      sortColumn,
      sortIsDescending,
      reportType: getReportType({
        hospital: reportFilterState.hospital,
        group: reportFilterState.group,
        physician: reportFilterState.physician
      }),
      hospital: reportFilterState.hospital,
      physician: reportFilterState.physician,
      group: reportFilterState.group
    });
  };

  return (
    <Box
      sx={{
        mt: 2,
        p: 2,
        backgroundColor: 'shade.white',
        borderRadius: `${containerBorderRadius}px`,
        border: '1px solid',
        borderColor: 'grey.300',
        width: '36rem'
      }}
    >
      <TrainingProgressFilter
        onFilterChanged={onFilterChanged}
        defaultHospital={reportFilterState.hospital}
        defaultGroup={reportFilterState.group}
        defaultPhysician={reportFilterState.physician}
      />
      <Box display="flex" flexDirection="row">
        <Button
          onClick={handleReportDownload}
          sx={{ width: '8rem' }}
          label="Download"
          isBusy={isDownloading}
          disabled={!downloadIsEnabled}
        />
      </Box>
    </Box>
  );
};

export default React.memo(TrainingProgressFilterRoute);
