import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApplicationAPI, AsyncOutput, HttpResponseException } from '@clinintell/utils/api';
import WidgetJSON, {
  QueryParams,
  WidgetSize,
  TableInfo,
  GraphInfo
} from '@clinintell/containers/dashboard/widgetTypes';
import { Metrics as MetricTypes } from '@clinintell/modules/metricsNavigation';
import { ChartDataSetType } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { TreeJSON } from './orgTree';

interface Dataset {
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
}

export type TreeEntity = {
  id: number;
  name: string;
  parentId: number | null;
  parents: number[] | [];
  children: number[] | [];
  childrenCount: number;
  nodeTypeId: number;
};

export type TreeEntities = {
  [key: string]: TreeEntity;
};

export type WidgetType = 'graph' | 'table' | 'progress';

export interface WidgetDefinition {
  widgetType: WidgetType;
  metric: MetricTypes;
  entity: number | null;
  entityName: string | null;
  condition: number | null;
  conditionName: string | null;
}

export interface ExtendedWidgetDefinition extends WidgetDefinition {
  name: string;
  title?: string;
  endpoint: string;
  queryString?: QueryParams;
  size: WidgetSize;
  tableInfo?: TableInfo;
  graphInfo?: GraphInfo;
  customSettings?: QueryParams;
}

export interface DashboardDataset extends Dataset {
  widgetList: WidgetJSON[];
  newWidget: WidgetDefinition;
  originId: number;
  originTypeId: number;
  originChildren: TreeJSON[];
  treeFragment: TreeJSON;
  treeEntities: TreeEntities;
  isInitialized: boolean;
}

// Placeholder until tree obtained from api
export const initTree: TreeJSON = {
  id: -1,
  name: '',
  children: [],
  childrenCount: 0,
  nodeTypeId: -1,
  fundamentalSpecialties: []
};

export enum DashboardActions {
  INITIALIZE_DASHBOARD = 'INITIALIZE_DASHBOARD',
  INITIALIZE_DASHBOARD_BEGIN = 'INITIALIZE_DASHBOARD_BEGIN',
  INITIALIZE_DASHBOARD_FAILED = 'INITIALIZE_DASHBOARD_FAILED',
  INITIALIZE_DASHBOARD_SUCCESS = 'INITIALIZE_DASHBOARD_SUCCESS',
  GET_DASHBOARD_WIDGET_LIST = 'GET_DASHBOARD_WIDGET_LIST',
  GET_DASHBOARD_WIDGET_LIST_BEGIN = 'GET_DASHBOARD_WIDGET_LIST_BEGIN',
  GET_DASHBOARD_WIDGET_LIST_FAILED = 'GET_DASHBOARD_WIDGET_LIST_FAILED',
  GET_DASHBOARD_WIDGET_LIST_SUCCESS = 'GET_DASHBOARD_WIDGET_LIST_SUCCESS',
  UPDATE_DASHBOARD_LIST = 'UPDATE_DASHBOARD_LIST',
  UPDATE_DASHBOARD_LIST_BEGIN = 'UPDATE_DASHBOARD_LIST_BEGIN',
  UPDATE_DASHBOARD_LIST_FAILED = 'UPDATE_DASHBOARD_LIST_FAILED',
  UPDATE_DASHBOARD_LIST_SUCCESS = 'UPDATE_DASHBOARD_LIST_SUCCESS',
  SET_DASHBOARD_LIST = 'SET_DASHBOARD_LIST',
  SET_WIDGET_TYPE = 'SET_WIDGET_TYPE',
  SET_WIDGET_METRIC = 'SET_WIDGET_METRIC',
  SET_WIDGET_CONDITION = 'SET_WIDGET_CONDITION',
  SET_WIDGET_ENTITY = 'SET_WIDGET_ENTITY',
  SET_DASHBOARD_ENTITY = 'SET_DASHBOARD_ENTITY',
  CLEAR_WIDGET_DEFINITION = 'CLEAR_WIDGET_DEFINITION',
  RESET_DASHBOARD = 'RESET_DASHBOARD'
}

export type DashboardAction<T> = {
  type: keyof typeof DashboardActions;
  payload?: T;
};

export type DashboardPayload = {
  userId: number;
  widgetList: WidgetJSON[];
};

export type WidgetSelectOptionPayload = {
  value: number;
  text: string;
};

const initialWidgetDefinition: WidgetDefinition = {
  widgetType: 'graph',
  metric: MetricTypes.cmi,
  entity: null,
  entityName: '',
  condition: null,
  conditionName: ''
};

export const initialDashboardState: DashboardDataset = {
  widgetList: [],
  newWidget: initialWidgetDefinition,
  originId: 1,
  originTypeId: 1,
  originChildren: [],
  treeFragment: initTree,
  treeEntities: {},
  isLoading: false,
  hasError: false,
  isInitialized: false
};

type InitPayload = {
  id: number;
  rootId: number;
};

export const initializeDashboard = ({ id, rootId }: InitPayload): DashboardAction<InitPayload> => ({
  type: DashboardActions.INITIALIZE_DASHBOARD,
  payload: { id, rootId }
});

export const fetchDashboardList = (id: number): DashboardAction<number> => ({
  type: DashboardActions.GET_DASHBOARD_WIDGET_LIST,
  payload: id
});

export const clearWidgetDefinition = (): DashboardAction<void> => ({ type: DashboardActions.CLEAR_WIDGET_DEFINITION });

export const resetUserDashboard = (id: number): DashboardAction<number> => ({
  type: DashboardActions.RESET_DASHBOARD,
  payload: id
});

export const setDashboardEntity = (treeEntity: TreeEntity): DashboardAction<TreeEntity> => ({
  type: 'SET_DASHBOARD_ENTITY',
  payload: treeEntity
});

export const setDashboardList = (widgetList: WidgetJSON[]): DashboardAction<WidgetJSON[]> => ({
  type: 'SET_DASHBOARD_LIST',
  payload: widgetList
});

const dashboardReducer = (
  state: DashboardDataset = initialDashboardState,
  action: DashboardAction<
    | DashboardDataset
    | WidgetDefinition
    | ExtendedWidgetDefinition
    | WidgetSelectOptionPayload
    | MetricTypes
    | string
    | string[]
    | ChartDataSetType[]
    | number
    | WidgetJSON[]
    | TreeJSON
    | TreeEntity
    | TreeEntities
  >
): DashboardDataset => {
  switch (action.type) {
    case DashboardActions.INITIALIZE_DASHBOARD_BEGIN: {
      return {
        widgetList: [],
        newWidget: initialWidgetDefinition,
        originId: 1,
        originTypeId: 1,
        originChildren: [],
        treeFragment: initTree,
        treeEntities: {},
        isLoading: true,
        hasError: false,
        isInitialized: false
      };
    }
    case DashboardActions.INITIALIZE_DASHBOARD_FAILED: {
      return {
        widgetList: [],
        newWidget: initialWidgetDefinition,
        originId: 1,
        originTypeId: 1,
        originChildren: [],
        treeFragment: initTree,
        treeEntities: {},
        isLoading: false,
        hasError: true,
        errorMessage: action.payload as string,
        isInitialized: false
      };
    }
    case DashboardActions.INITIALIZE_DASHBOARD_SUCCESS: {
      const { widgetList, originId, originTypeId, originChildren } = action.payload as DashboardDataset;
      return {
        widgetList: widgetList,
        newWidget: {
          ...initialWidgetDefinition,
          entity: originId
        },
        originId: originId,
        originTypeId: originTypeId,
        originChildren: originChildren,
        treeFragment: initTree,
        treeEntities: {},
        isLoading: false,
        hasError: false,
        isInitialized: true
      };
    }
    case DashboardActions.GET_DASHBOARD_WIDGET_LIST_BEGIN: {
      return {
        ...state,
        widgetList: [],
        newWidget: initialWidgetDefinition,
        isLoading: true,
        hasError: false,
        isInitialized: false
      };
    }
    case DashboardActions.GET_DASHBOARD_WIDGET_LIST_FAILED: {
      return {
        ...state,
        widgetList: [],
        newWidget: initialWidgetDefinition,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload as string,
        isInitialized: false
      };
    }
    case DashboardActions.GET_DASHBOARD_WIDGET_LIST_SUCCESS: {
      return {
        ...state,
        widgetList: action.payload as WidgetJSON[],
        newWidget: initialWidgetDefinition,
        isLoading: false,
        hasError: false,
        isInitialized: true
      };
    }
    case DashboardActions.UPDATE_DASHBOARD_LIST_BEGIN: {
      return {
        ...state,
        widgetList: [],
        newWidget: initialWidgetDefinition,
        isLoading: true,
        hasError: false,
        isInitialized: false
      };
    }
    case DashboardActions.UPDATE_DASHBOARD_LIST_FAILED: {
      return {
        ...state,
        widgetList: [],
        newWidget: initialWidgetDefinition,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload as string,
        isInitialized: false
      };
    }
    case DashboardActions.UPDATE_DASHBOARD_LIST_SUCCESS: {
      return {
        ...state,
        widgetList: action.payload as WidgetJSON[],
        newWidget: initialWidgetDefinition,
        isLoading: true,
        hasError: false,
        isInitialized: true
      };
    }
    case DashboardActions.SET_DASHBOARD_LIST: {
      return {
        ...state,
        widgetList: action.payload as WidgetJSON[]
      };
    }
    case DashboardActions.SET_WIDGET_TYPE: {
      return {
        ...state,
        newWidget: {
          ...state.newWidget,
          widgetType: action.payload as 'graph' | 'table'
        }
      };
    }
    case DashboardActions.SET_WIDGET_METRIC: {
      return {
        ...state,
        newWidget: {
          ...state.newWidget,
          metric: action.payload as MetricTypes
        }
      };
    }
    case DashboardActions.SET_WIDGET_CONDITION: {
      const { value, text } = action.payload as WidgetSelectOptionPayload;
      return {
        ...state,
        newWidget: {
          ...state.newWidget,
          condition: value,
          conditionName: text
        }
      };
    }
    case DashboardActions.SET_WIDGET_ENTITY: {
      const { value, text } = action.payload as WidgetSelectOptionPayload;
      return {
        ...state,
        newWidget: {
          ...state.newWidget,
          entity: value,
          entityName: text
        }
      };
    }
    case DashboardActions.SET_DASHBOARD_ENTITY: {
      const ids: string = (action.payload as TreeEntity).id
        ? (action.payload as TreeEntity).id.toString()
        : 'typescript wants this string';
      return {
        ...state,
        treeEntities: Object.assign({}, state, {
          [ids]: {
            ...(action.payload as TreeEntity)
          }
        })
      };
    }
    case DashboardActions.CLEAR_WIDGET_DEFINITION: {
      return {
        ...state,
        newWidget: initialWidgetDefinition,
        treeEntities: {}
      };
    }
    default: {
      return { ...state };
    }
  }
};

export function* initializeDashboardSaga({ payload }: DashboardAction<InitPayload>): SagaIterator {
  if (!payload) throw new Error('id and rootId must be provided for initialization');

  yield put({ type: DashboardActions.INITIALIZE_DASHBOARD_BEGIN });

  const { id, rootId } = payload;

  const [dashboardOutput, originOutput]: [AsyncOutput<WidgetJSON[]>, AsyncOutput<TreeJSON>] = yield all([
    call(ApplicationAPI.get, {
      endpoint: `dashboard/${id}`
    }),
    call(ApplicationAPI.get, {
      endpoint: `org/${rootId}`
    })
  ]);

  if (dashboardOutput.error || originOutput.error) {
    const errorArray: string[] = [];
    if (dashboardOutput.error) {
      errorArray.push(dashboardOutput.error);
    }
    if (originOutput.error) {
      errorArray.push(originOutput.error);
    }

    yield put({
      type: DashboardActions.INITIALIZE_DASHBOARD_FAILED,
      payload: errorArray.join(' / ')
    });
  } else {
    const dashboardData = dashboardOutput.data as WidgetJSON[];
    const originData = originOutput.data as TreeJSON;
    yield put({
      type: DashboardActions.INITIALIZE_DASHBOARD_SUCCESS,
      payload: {
        widgetList: dashboardData,
        originId: originData.id,
        originTypeId: originData.nodeTypeId,
        originChildren: originData.children
      }
    });
  }
}

export function* getDashboardListSaga({ payload }: DashboardAction<number>): SagaIterator {
  yield put({ type: DashboardActions.GET_DASHBOARD_WIDGET_LIST_BEGIN });

  const dashboardOutput: AsyncOutput<WidgetJSON[]> = yield call(ApplicationAPI.get, {
    endpoint: `dashboard/${payload}`
  });

  if (dashboardOutput.error) {
    yield put({
      type: DashboardActions.GET_DASHBOARD_WIDGET_LIST_FAILED,
      payload: dashboardOutput.error
    });
  } else {
    yield put({
      type: DashboardActions.GET_DASHBOARD_WIDGET_LIST_SUCCESS,
      payload: dashboardOutput.data
    });
  }
}

export function* updateDashboardListSaga({ payload }: DashboardAction<DashboardPayload>): SagaIterator {
  if (!payload) {
    throw new Error('User ID and widget list must be sent with the UPDATE_DASHBOARD_WIDGET_LIST action');
  }

  yield put({ type: DashboardActions.UPDATE_DASHBOARD_LIST_BEGIN });

  const { userId, widgetList } = payload;

  try {
    let widgetOutput: WidgetJSON[] = [];
    let errorMsg = '';

    ApplicationAPI.put({
      endpoint: `dashboard/${userId}`,
      data: JSON.stringify(widgetList)
    })
      .then(res => {
        widgetOutput = res.data as WidgetJSON[];
        put({ type: DashboardActions.UPDATE_DASHBOARD_LIST_SUCCESS, payload: widgetOutput });
      })
      .catch(err => {
        errorMsg = err;
        put({ type: DashboardActions.UPDATE_DASHBOARD_LIST_FAILED, payload: errorMsg });
      });
  } catch (exception) {
    throw new Error((exception as HttpResponseException).message);
  }
}

export function* resetUserDashboardSaga({ payload }: DashboardAction<number>): SagaIterator {
  if (!payload) {
    throw new Error('id is required in the RESET_DASHBOARD action');
  }

  yield put({ type: DashboardActions.UPDATE_DASHBOARD_LIST_BEGIN });

  yield call(ApplicationAPI.delete, {
    endpoint: `dashboard/${payload}`
  });

  fetchDashboardList(payload);
}

export function* dashboardDataSaga(): SagaIterator {
  yield takeLatest(DashboardActions.INITIALIZE_DASHBOARD, initializeDashboardSaga);
  yield takeLatest(DashboardActions.GET_DASHBOARD_WIDGET_LIST, getDashboardListSaga);
  yield takeLatest(DashboardActions.UPDATE_DASHBOARD_LIST, updateDashboardListSaga);
  yield takeLatest(DashboardActions.RESET_DASHBOARD, resetUserDashboardSaga);
}

export default dashboardReducer;
