import { DRGMixTableRow } from '@clinintell/containers/cmiComparison/typings/cmiComparisonDtos';
import { Box, useTheme } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ClinIntellSkeleton from '../ClinIntellSkeleton';
import Panel from '../Panel';
import DRGImpactTable from './DRGImpactTable';

type ImpactTableWithPanelProps = {
  panelIsToggled: boolean;
  onPanelToggle: () => void;
  isLoading: boolean;
  tableRows: DRGMixTableRow[];
  panelTitle: string;
  referenceLabel: string;
  comparerLabel: string;
  sortDirection: 'asc' | 'desc';
  changeLabel: string;
  noImpactMessage: string;
};

const ImpactTableWithPanel: React.FC<ImpactTableWithPanelProps> = ({
  panelIsToggled,
  isLoading,
  tableRows,
  panelTitle,
  onPanelToggle,
  referenceLabel,
  comparerLabel,
  sortDirection,
  changeLabel,
  noImpactMessage
}) => {
  const theme = useTheme();

  let panelChildComponent;
  if (isLoading) {
    panelChildComponent = (
      <ClinIntellSkeleton variant="rectangular" width="100%" height={panelIsToggled ? '38rem' : '3rem'} />
    );
  } else if (tableRows.length > 0) {
    panelChildComponent = (
      <Box width="100%">
        <DRGImpactTable
          referenceLabel={referenceLabel}
          comparerLabel={comparerLabel}
          defaultSortDirection={sortDirection}
          tableRows={tableRows}
          changeLabel={changeLabel}
        />
      </Box>
    );
  } else {
    panelChildComponent = (
      <Box m={2}>
        <Box component="span" color={theme.palette.red[500]}>
          {noImpactMessage}
        </Box>
      </Box>
    );
  }

  return (
    <Box marginTop={2}>
      <Panel
        isDraggable={false}
        summaryTitle={panelTitle}
        toggleExpanded={onPanelToggle}
        expanded={panelIsToggled}
        detailStyles={panelDetailStyles}
        withBorder
      >
        {panelChildComponent}
      </Panel>
    </Box>
  );
};

const MemoizedImpactTableWithPanel = memo(ImpactTableWithPanel);

const panelDetailStyles = {
  overflow: 'auto',
  padding: 0,
  width: '100%',
  display: 'block'
};

type DRGImpactTablesProps = {
  tableRows: DRGMixTableRow[];
  hasPositiveDelta: boolean;
  isLoading: boolean;
  referenceLabel: string;
  comparerLabel: string;
  changeLabel: string;
};

const DRGImpactTables: React.FC<DRGImpactTablesProps> = ({
  tableRows,
  hasPositiveDelta,
  isLoading,
  referenceLabel,
  comparerLabel,
  changeLabel
}) => {
  const [positivePanelToggled, setPositivePanelToggled] = useState(hasPositiveDelta);
  const [negativePanelToggled, setNegativePanelToggled] = useState(!hasPositiveDelta);

  useEffect(() => {
    if (hasPositiveDelta) {
      setPositivePanelToggled(true);
      setNegativePanelToggled(false);
    } else {
      setPositivePanelToggled(false);
      setNegativePanelToggled(true);
    }
  }, [hasPositiveDelta]);

  const rowsWithPositiveImpact = useMemo(() => tableRows.filter(row => row.cmiImpact >= 0), [tableRows]);
  const rowsWithNegativeImpact = useMemo(() => tableRows.filter(row => row.cmiImpact < 0), [tableRows]);

  const togglePositivePanel = useCallback(() => setPositivePanelToggled(!positivePanelToggled), [positivePanelToggled]);
  const toggleNegativePanel = useCallback(() => setNegativePanelToggled(!negativePanelToggled), [negativePanelToggled]);

  const positiveImpactTable = (
    <MemoizedImpactTableWithPanel
      panelTitle="DRGs with Positive Impacts on CMI"
      isLoading={isLoading}
      panelIsToggled={positivePanelToggled}
      onPanelToggle={togglePositivePanel}
      tableRows={rowsWithPositiveImpact}
      referenceLabel={referenceLabel}
      comparerLabel={comparerLabel}
      sortDirection="desc"
      changeLabel={changeLabel}
      noImpactMessage="There are no DRGs with Positive Impacts on CMI to display"
    />
  );

  const negativeImpactTable = (
    <MemoizedImpactTableWithPanel
      panelTitle="DRGs with Negative Impacts on CMI"
      isLoading={isLoading}
      panelIsToggled={negativePanelToggled}
      onPanelToggle={toggleNegativePanel}
      tableRows={rowsWithNegativeImpact}
      referenceLabel={referenceLabel}
      comparerLabel={comparerLabel}
      sortDirection="asc"
      changeLabel={changeLabel}
      noImpactMessage="There are no DRGs with Negative Impacts on CMI to display"
    />
  );

  return (
    <Box>
      {hasPositiveDelta ? (
        <>
          {positiveImpactTable}
          {negativeImpactTable}
        </>
      ) : (
        <>
          {negativeImpactTable}
          {positiveImpactTable}
        </>
      )}
    </Box>
  );
};

export default DRGImpactTables;
