import ActionPanel from '@clinintell/components/ActionPanel';
import { EntityDetailsState } from '@clinintell/components/analysisSharedComponents/useEntityDetails';
import { Box } from '@mui/material';
import React from 'react';
import useComparisonEntities from '../logic/useComparisonEntities';
import ComparisonSelectors from './ComparisonSelectors';
import { Button } from '@clinintell/components/index';

type EntitySelectionPanelProps = {
  isOpen: boolean;
  toggleIsOpen: () => void;
  myCurrentSelectedEntity: EntityDetailsState;
  peerCurrentSelectedEntity: EntityDetailsState;
};

const EntitySelectionPanel: React.FC<EntitySelectionPanelProps> = ({
  isOpen,
  toggleIsOpen,
  myCurrentSelectedEntity,
  peerCurrentSelectedEntity
}) => {
  const {
    myEntity,
    peerEntity,
    myEntityName,
    peerEntityName,
    updateMyEntity,
    updatePeerEntity,
    onComparisonSelected
  } = useComparisonEntities({ myCurrentSelectedEntity, peerCurrentSelectedEntity });

  const handleSelectorSave = (): void => {
    onComparisonSelected();
    toggleIsOpen();
  };

  return (
    <ActionPanel
      closeFn={toggleIsOpen}
      open={isOpen}
      title={
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>Change Comparison</Box>
          <Button
            onClick={handleSelectorSave}
            label="Compare"
            disabled={myEntity === undefined && peerEntity === undefined}
          />
        </Box>
      }
      direction="down"
    >
      <Box p={1}>
        <ComparisonSelectors
          myEntity={myEntity}
          myEntityName={myEntityName}
          onMyEntitySelected={updateMyEntity}
          peerEntity={peerEntity}
          peerEntityName={peerEntityName}
          onPeerEntitySelected={updatePeerEntity}
          withBorderSeparator={false}
          treeHeight={450}
        />
      </Box>
    </ActionPanel>
  );
};

export default EntitySelectionPanel;
