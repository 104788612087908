import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DeleteWidgetIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{ width: 24, height: 24, fill: 'none', ...props.sx }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.12 10.4702L12 12.5902L9.87 10.4702L8.46 11.8802L10.59 14.0002L8.47 16.1202L9.88 17.5302L12 15.4102L14.12 17.5302L15.53 16.1202L13.41 14.0002L15.53 11.8802L14.12 10.4702ZM15.5 4.00024L14.5 3.00024H9.5L8.5 4.00024H5V6.00024H19V4.00024H15.5ZM6 19.0002C6 20.1002 6.9 21.0002 8 21.0002H16C17.1 21.0002 18 20.1002 18 19.0002V7.00024H6V19.0002ZM8 9.00024H16V19.0002H8V9.00024Z"
      fill="#64748B"
    />
  </SvgIcon>
);
DeleteWidgetIcon.muiName = SvgIcon.muiName;

export default DeleteWidgetIcon;
