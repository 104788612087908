import React, { ReactElement } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, useTheme } from '@mui/material';
import ExpandPanelIcon from '@clinintell/components/icons/ExpandPanelIcon';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { containerBorderRadius } from '@clinintell/theme/theme';

type PanelProps = {
  expanded: boolean;
  toggleExpanded: () => void;
  summaryTitle: string | ReactElement;
  children?: React.Component | JSX.Element | HTMLElement | null;
  subPanel?: React.Component | JSX.Element | HTMLElement | null;
  isDraggable: boolean;
  withBorder?: boolean;
  draggableHandleProps?: DraggableProvidedDragHandleProps;
  detailStyles?: React.CSSProperties;
  useAutoWidth?: boolean;
};

const Panel: React.FC<PanelProps> = props => {
  const theme = useTheme();

  return (
    <Box flexGrow={1}>
      <Accordion
        sx={{
          border: props.withBorder ? '1px solid' : 'none',
          // Default styles for the Material UI panel have a weird setup, so using important for simplicity sake
          borderRadius: `${containerBorderRadius}px !important`,
          borderColor: 'grey.300',
          boxShadow: 'none',
          '&:before': {
            display: 'none'
          }
        }}
        expanded={props.expanded}
      >
        <AccordionSummary
          sx={{
            margin: -1,
            minHeight: '64px',
            padding: '1 0 1 1',
            '& > div': {
              display: 'flex',
              alignItems: 'center',
              margin: '0',
              cursor: props.isDraggable ? 'grab' : 'default'
            },
            '& > .Mui-expanded': {
              minHeight: '0 !important',
              margin: '0 !important'
            }
          }}
          {...props.draggableHandleProps}
        >
          <IconButton
            onClick={props.toggleExpanded}
            style={{
              color: theme.palette.blue.main,
              padding: theme.spacing(1)
            }}
            size="large"
          >
            <ExpandPanelIcon
              sx={{
                transform: props.expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 100ms ease-in-out'
              }}
            />
          </IconButton>
          <Box
            role="button"
            onClick={props.toggleExpanded}
            sx={{
              typography: 'h6',
              fontWeight: 400,
              color: 'blue.main',
              cursor: props.isDraggable ? 'grab' : 'pointer',
              width: '100%'
            }}
          >
            {props.summaryTitle}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            overflow: 'auto',
            '& .MuiPaper-root': {
              boxShadow: 'none',
              '&.subPanel': {
                top: '-15px',
                border: 0,
                '& .MuiTableContainer-root': {
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                  marginLeft: 3
                }
              }
            }
          }}
          style={props.detailStyles}
        >
          <Box flexWrap="wrap">
            {props.children}
            {props.expanded ? props.subPanel : null}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Panel;
