import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ClinIntellSkeleton } from '@clinintell/components/index';
import { MetricRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import { useDashboard2, useDashboard2Dispatch } from '@clinintell/modules/store';
import {
  ChartDataSetAverageTypes,
  ChartDataSetJSON
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import ViewPerformanceGraph from '@clinintell/containers/autogenStrategy/components/ViewPerformanceGraph';
import { getSeverityOpportunityGraphData, DashboardDefaultDates } from '@clinintell/modules/dashboard2';
import SeverityOpportunityModalTable from './SeverityOpportunityModalTable';
import { containerBorderRadius } from '@clinintell/theme/theme';
import useInitializeMetrics from '@clinintell/containers/metrics/logic/useInitializeMetrics';
import { sub } from 'date-fns';

type Props = {
  data: MetricRecordJSON;
};

const SeverityOpportunityModal: React.FC<Props> = ({ data }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { orgId, nodeTypeId } = data;
  const dashboardDispatch = useDashboard2Dispatch();
  const { severityOpportunityWidget, dashboardDefaultDates } = useDashboard2();
  const { isModalLoading, isModalGraphLoading, modalGraphData } = severityOpportunityWidget;
  const { historicStart, historicEnd, currentEnd } = dashboardDefaultDates as DashboardDefaultDates;

  const [selectedEntity, setSelectedEntity] = useState(orgId);
  const [selectedEntityName, setSelectedEntityName] = useState(data.name);

  const handleViewGraphPerformance = (entityId: number, entityName: string): void => {
    if (entityId === selectedEntity) return;
    setSelectedEntity(entityId);
    setSelectedEntityName(entityName);
  };

  useEffect(() => {
    if (isModalLoading) return;
    dashboardDispatch(
      getSeverityOpportunityGraphData({
        orgId: selectedEntity,
        nodeTypeId,
        historicalStart: historicStart,
        historicalEnd: historicEnd,
        currentStart: sub(new Date(currentEnd), { months: isMobileView ? 11 : 23 }).toDateString(),
        currentEnd
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity]);

  const graphHasData = modalGraphData && (modalGraphData as ChartDataSetJSON).data;
  const graphTargetDocScoreFiltered = (): boolean => {
    let hasData = true;
    if (graphHasData) {
      hasData =
        (modalGraphData as ChartDataSetJSON).data
          .filter(rec => rec.dataSetAverageType === ChartDataSetAverageTypes.ThreeMonth)
          .filter(rec => rec.dataSetType === 'TargetDocScore')
          .filter(rec => rec.dataSet.find(rec => rec.value !== null)).length > 0;
    }
    return hasData;
  };

  useInitializeMetrics();

  return (
    <Box p={isMobileView ? 0 : 4} pt={0}>
      {isModalLoading ? (
        <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
      ) : (
        <>
          <Typography variant="h5" style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
            {selectedEntityName} - CMI Documentation Score Graph
          </Typography>
          <Box
            sx={{
              border: `1px solid grey[200]`,
              borderRadius: `${containerBorderRadius}px`,
              padding: theme.spacing(2),
              display: 'flex',
              marginTop: theme.spacing(1.5),
              marginBottom: theme.spacing(4),
              overflow: 'scroll',
              [theme.breakpoints.down(1350)]: {
                overflowX: 'scroll',
                justifyContent: 'flex-start'
              }
            }}
          >
            {isModalGraphLoading || !graphHasData ? (
              <ClinIntellSkeleton variant="rectangular" width="100%" height="400px" />
            ) : (
              <ViewPerformanceGraph
                graphData={modalGraphData as ChartDataSetJSON}
                isCondition={false}
                viewOption={ChartDataSetAverageTypes.TwoMonth}
                filteredChart={
                  new Set(
                    graphTargetDocScoreFiltered()
                      ? ['Actual', 'ClinicallyExpected', 'TargetDocScore']
                      : ['Actual', 'ClinicallyExpected']
                  )
                }
              />
            )}
          </Box>
          <Box>
            <SeverityOpportunityModalTable onGraphClick={handleViewGraphPerformance} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default SeverityOpportunityModal;
