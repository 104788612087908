import React, { useRef } from 'react';
import { Box } from '@mui/material';
import Select, { SelectInstance } from 'react-select';
import useDropdownPositioning from './logic/useDropdownPositioning';
import useDropdownStyles from './dropdownStyles';
import Option from './Option';

export type SelectOptionType = {
  value: string | number | undefined;
  label: string;
  withLineDivider?: boolean;
  useLabelForTestId?: boolean;
};

export type DropDownProps = {
  /**
   * Unique name for the dropdown.
   */
  id?: string;
  /**
   * List of data options using the SelectOptionType: { value: string | number | undefined; label: string; withLineDivider?: boolean; }.
   */
  options: SelectOptionType[];
  /**
   * Id/Value of the default option. Component will find the value in the list of options.
   */
  value?: number | string | null | SelectOptionType | SelectOptionType[];
  /**
   * Change event that passes the value and label.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (id: number | string | SelectOptionType[] | SelectOptionType, label: string, actionMeta?: any) => void;
  /**
   * Controls use of the dropdown
   */
  isDisabled?: boolean;
  /**
   * Width of the dropdown component.
   */
  width?: number | string;
  /**
   * Width of the menu list. Used if menu option labels wrap.
   */
  listWidth?: number;
  /**
   * Indicates if the dropdown should be searchable.
   */
  isSearchable?: boolean;
  /**
   * Determines if multiple options can be selected
   */
  isMultiSelect?: boolean;
  /**
   * Indicates if the options are loading
   */
  isLoading?: boolean;
  /**
   * Placeholder text of dropdown
   */
  placeholder?: string;
  /*
   * Test ID used with React-Testing-Library
   */
  testId?: string;
};

/**
 * Uses React-Select as the driving component. All API calls to retrieve data should be done outside of the component.
 */
const DropDown: React.FC<DropDownProps> = ({
  id = 'selectDropDown',
  options,
  value,
  onChange,
  isDisabled = false,
  width = '100%',
  listWidth,
  isSearchable = false,
  isMultiSelect = false,
  isLoading = false,
  placeholder = 'Select',
  testId
}) => {
  const defaultOption =
    value === undefined || value === -1 || value === null
      ? null // React-Select requires a null to clear out the default option
      : options.find(option => {
          if (typeof value === 'string' || typeof value === 'number' || Array.isArray(value)) {
            return option.value === value;
          }

          return option.value === value.value;
        });

  const ref = useRef<HTMLDivElement>();
  const selectRef = useRef<SelectInstance>(null);
  const { placement, setPlacementHandler, maxHeight } = useDropdownPositioning(ref);

  const dropdownStyles = useDropdownStyles({ width, listWidth, maxHeight });

  const handleMenuOpen = () => {
    setPlacementHandler();
  };

  const openMenu = () => {
    selectRef.current?.openMenu('first');
  };

  return (
    <Box ref={ref} minWidth={150} width={width}>
      <Select
        id={id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2322
        ref={selectRef}
        options={options}
        value={defaultOption}
        closeMenuOnSelect={!isMultiSelect}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isMulti={isMultiSelect}
        isLoading={isLoading}
        maxMenuHeight={maxHeight}
        placeholder={placeholder}
        onMenuOpen={handleMenuOpen}
        onChange={(value, actionMeta): void => {
          const option = (value as SelectOptionType).value as number;
          if (isMultiSelect) {
            onChange(value as SelectOptionType[], '', actionMeta);
          } else {
            onChange(option, (value as SelectOptionType).label, actionMeta);
          }
        }}
        components={{
          IndicatorSeparator: null,
          Option: Option
        }}
        menuPortalTarget={document.body}
        menuPlacement={placement}
        styles={{ ...dropdownStyles }}
        testId={testId}
        menuPosition="fixed"
      />
      <button data-test-id={`${testId}-activator`} style={{ display: 'none' }} onClick={openMenu} />
    </Box>
  );
};

export default DropDown;
