import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { SelectGroup, SelectHospital } from '@clinintell/containers/common';
import { useAppConfig, useDefaultDates, useDefaultDatesDispatch } from '@clinintell/modules/store';
import { ReportType } from '@clinintell/containers/reports/types/actionTypes';
import BDSelectSystem from '../../components/BDSelectSystem';
import BDSelectHospital from '../../components/BDSelectHospital';

type HospitalAndGroupFilterProps = {
  groupId?: number;
  hospitalId?: number;
  systemId?: number;
  reportType?: string;
  onPhysicianGroupChange: (groupId: number | null) => void;
  onHospitalChange: (hospitalId: number | null) => void;
  onSystemChange?: (systemId: number | null) => void;
  width?: number | string;
};

const HospitalAndGroupFilter: React.FC<HospitalAndGroupFilterProps> = ({
  groupId,
  hospitalId,
  systemId,
  reportType,
  onPhysicianGroupChange,
  onHospitalChange,
  onSystemChange,
  width = '100%'
}) => {
  const defaultDatesDispatch = useDefaultDatesDispatch();
  const { isBDTenant } = useAppConfig();
  const defaultDates = useDefaultDates();

  // Fetch default dates if needed for group
  useEffect(() => {
    if (groupId === undefined || groupId === -1 || defaultDates.byId[groupId]) {
      return;
    }
    defaultDatesDispatch({
      type: 'DEFAULT_DATES_FETCH_REQUESTED',
      payload: groupId
    });
  }, [defaultDates.byId, defaultDatesDispatch, groupId, reportType]);

  return (
    <>
      {reportType === ReportType.System ? (
        ''
      ) : (
        <>
          {isBDTenant && onSystemChange ? (
            <>
              <Box p={1}>
                <BDSelectSystem width={width} onSystemChangeFn={onSystemChange} />
              </Box>
              <Box p={1}>
                <BDSelectHospital
                  systemId={systemId}
                  defaultValue={hospitalId}
                  width={width}
                  onHospitalChangeFn={onHospitalChange}
                />
              </Box>
            </>
          ) : (
            <Box p={1}>
              <SelectHospital defaultValue={hospitalId} width={width} onHospitalChangeFn={onHospitalChange} />
            </Box>
          )}
          <Box p={1}>
            <SelectGroup
              hospitalId={hospitalId}
              onGroupChangeFn={onPhysicianGroupChange}
              includeAllGroups={false}
              excludeOtherGroups
              excludeOtherMDs
              excludeHiddenProviders
              excludeHiddenSpecialtyGroups
              defaultValue={groupId}
              width={width}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default React.memo(HospitalAndGroupFilter);
