import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

const useCurrentBreakpointOnScreen = (): Breakpoint => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));

  if (isSmall) {
    return 'sm';
  }

  if (isMedium) {
    return 'md';
  }

  if (isLarge) {
    return 'lg';
  }

  return 'xl';
};

export default useCurrentBreakpointOnScreen;
