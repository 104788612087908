import React from 'react';
import { Box } from '@mui/material';

type TextInputValidationErrorProps = {
  message: string;
};

const TextInputValidationError: React.FC<TextInputValidationErrorProps> = ({ message }) => {
  return (
    <Box component="span" sx={{ typography: 'p2', color: 'red.500', mt: 1 }}>
      {message}
    </Box>
  );
};

export default TextInputValidationError;
