import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ClinIntellLogoAlt = (props: SvgIconProps) => (
  <SvgIcon
    sx={{
      width: '74px',
      height: '15px',
      fill: 'none',
      ...props.sx
    }}
    viewBox="0 0 74 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5877_2335)">
      <path
        d="M0.00585938 7.76246C0.00585938 7.00423 0.134174 6.2635 0.390804 5.54027C0.6591 4.80537 1.04405 4.14047 1.54564 3.54555C2.04724 2.93897 2.66548 2.4607 3.40038 2.11075C4.13528 1.74914 4.96933 1.56833 5.90253 1.56833C7.01071 1.56833 7.95557 1.82496 8.73713 2.33822C9.53035 2.83982 10.1136 3.48722 10.4869 4.28045L9.52452 4.87536C9.25622 4.32711 8.91794 3.8955 8.50966 3.58055C8.10139 3.25393 7.66395 3.02062 7.19735 2.88064C6.74241 2.74066 6.28747 2.67067 5.83254 2.67067C5.08598 2.67067 4.4269 2.82232 3.85532 3.12561C3.28373 3.41724 2.79963 3.81385 2.40302 4.31544C2.01808 4.80537 1.72645 5.35946 1.52815 5.97771C1.32984 6.58429 1.23069 7.20254 1.23069 7.83245C1.23069 8.52068 1.34734 9.17976 1.58064 9.80967C1.81394 10.4396 2.14056 10.9995 2.5605 11.4894C2.98044 11.9677 3.47037 12.3526 4.03029 12.6443C4.60188 12.9242 5.22013 13.0642 5.88503 13.0642C6.35163 13.0642 6.8299 12.9826 7.31983 12.8192C7.80976 12.6559 8.2647 12.4051 8.68464 12.0669C9.11624 11.7286 9.46036 11.2911 9.71699 10.7545L10.7318 11.2795C10.4752 11.8977 10.0786 12.4226 9.54202 12.8542C9.00542 13.2858 8.40468 13.6125 7.73977 13.8341C7.08653 14.0557 6.44495 14.1666 5.81504 14.1666C4.96349 14.1666 4.18194 13.9857 3.47037 13.6241C2.7588 13.2509 2.14056 12.7609 1.61563 12.1543C1.10237 11.5361 0.70576 10.8479 0.4258 10.0896C0.145839 9.31974 0.00585938 8.54401 0.00585938 7.76246Z"
        fill="#385EAB"
      />
      <path
        d="M24.8348 3.96841C25.978 3.96841 26.9112 4.3242 27.6344 5.03576C28.3577 5.73567 28.7193 6.75635 28.7193 8.09783V13.697H27.512V8.23781C27.512 7.19963 27.2495 6.4064 26.7246 5.85815C26.2113 5.30989 25.5056 5.03576 24.6074 5.03576C23.6858 5.03576 22.9509 5.32739 22.4027 5.91064C21.8544 6.49389 21.5803 7.35127 21.5803 8.48278V13.697H20.3555V4.14339H21.5803V5.77066C21.8836 5.18741 22.321 4.74414 22.8926 4.44085C23.4642 4.12589 24.1116 3.96841 24.8348 3.96841Z"
        fill="#385EAB"
      />
      <path
        d="M17.3225 2.34113C17.0776 2.34113 16.8676 2.25364 16.6926 2.07866C16.5177 1.90369 16.4302 1.68788 16.4302 1.43125C16.4302 1.17462 16.5177 0.964653 16.6926 0.801343C16.8676 0.626368 17.0776 0.538879 17.3225 0.538879C17.5675 0.538879 17.7775 0.626368 17.9525 0.801343C18.1274 0.964653 18.2149 1.17462 18.2149 1.43125C18.2149 1.68788 18.1274 1.90369 17.9525 2.07866C17.7775 2.25364 17.5675 2.34113 17.3225 2.34113ZM17.935 4.14337V13.697H16.7101V4.14337H17.935Z"
        fill="#385EAB"
      />
      <path d="M14.2895 0.748871V13.697H13.0647V0.748871H14.2895Z" fill="#385EAB" />
      <path d="M69.6374 0.748871V13.697H67.1877V0.748871H69.6374Z" fill="#385EAB" />
      <path d="M74.0117 0.748871V13.697H71.562V0.748871H74.0117Z" fill="#385EAB" />
      <path
        d="M65.263 8.64024C65.263 8.99019 65.2396 9.30515 65.193 9.58511H58.1065C58.1648 10.285 58.4098 10.8333 58.8414 11.2299C59.273 11.6265 59.8037 11.8248 60.4336 11.8248C61.3435 11.8248 61.9909 11.434 62.3759 10.6525H65.018C64.738 11.5857 64.2015 12.3556 63.4082 12.9621C62.615 13.557 61.641 13.8545 60.4861 13.8545C59.5529 13.8545 58.7131 13.6504 57.9665 13.2421C57.2316 12.8222 56.6542 12.2331 56.2342 11.4748C55.826 10.7166 55.6218 9.84174 55.6218 8.85021C55.6218 7.84702 55.826 6.96631 56.2342 6.20808C56.6425 5.44986 57.2141 4.86661 57.949 4.45833C58.6839 4.05005 59.5296 3.84592 60.4861 3.84592C61.4077 3.84592 62.2301 4.04422 62.9533 4.44083C63.6882 4.83744 64.2539 5.4032 64.6506 6.13809C65.0588 6.86133 65.263 7.69537 65.263 8.64024ZM62.7258 7.94034C62.7142 7.31043 62.4867 6.80883 62.0434 6.43555C61.6002 6.05061 61.0577 5.85813 60.4161 5.85813C59.8096 5.85813 59.2963 6.04477 58.8764 6.41805C58.4681 6.77967 58.2173 7.2871 58.124 7.94034H62.7258Z"
        fill="#385EAB"
      />
      <path
        d="M51.8132 6.01561V10.705C51.8132 11.0316 51.889 11.2707 52.0406 11.4223C52.2039 11.5623 52.4722 11.6323 52.8455 11.6323H53.9828V13.697H52.4431C50.3784 13.697 49.346 12.6938 49.346 10.6875V6.01561H48.1912V4.00339H49.346V1.60623H51.8132V4.00339H53.9828V6.01561H51.8132Z"
        fill="#385EAB"
      />
      <path
        d="M42.6676 3.86343C43.8225 3.86343 44.7557 4.23088 45.4672 4.96578C46.1788 5.68901 46.5346 6.70387 46.5346 8.01035V13.6971H44.0849V8.3428C44.0849 7.57291 43.8925 6.98383 43.5075 6.57555C43.1226 6.15561 42.5976 5.94564 41.9327 5.94564C41.2562 5.94564 40.7196 6.15561 40.323 6.57555C39.938 6.98383 39.7456 7.57291 39.7456 8.3428V13.6971H37.2959V4.00342H39.7456V5.21075C40.0722 4.7908 40.4863 4.46418 40.9879 4.23088C41.5011 3.98592 42.0611 3.86343 42.6676 3.86343Z"
        fill="#385EAB"
      />
      <path d="M35.5418 11.8744H30.6206V13.6971H35.5418V11.8744Z" fill="#385EAB" />
      <path d="M35.5418 1.30295H30.6206V3.12561H35.5418V1.30295Z" fill="#385EAB" />
      <path d="M34.2778 1.48376V13.697H31.8281V1.48376H34.2778Z" fill="#385EAB" />
      <path
        d="M24.8348 3.96841C25.978 3.96841 26.9112 4.3242 27.6344 5.03576C28.3577 5.73567 28.7193 6.75635 28.7193 8.09783V13.697H27.512V8.23781C27.512 7.19963 27.2495 6.4064 26.7246 5.85815C26.2113 5.30989 25.5056 5.03576 24.6074 5.03576C23.6858 5.03576 22.9509 5.32739 22.4027 5.91064C21.8544 6.49389 21.5803 7.35127 21.5803 8.48278V13.697H20.3555V4.14339H21.5803V5.77066C21.8836 5.18741 22.321 4.74414 22.8926 4.44085C23.4642 4.12589 24.1116 3.96841 24.8348 3.96841Z"
        fill="#385EAB"
      />
      <path
        d="M17.3225 2.34113C17.0776 2.34113 16.8676 2.25364 16.6926 2.07866C16.5177 1.90369 16.4302 1.68788 16.4302 1.43125C16.4302 1.17462 16.5177 0.964653 16.6926 0.801343C16.8676 0.626368 17.0776 0.538879 17.3225 0.538879C17.5675 0.538879 17.7775 0.626368 17.9525 0.801343C18.1274 0.964653 18.2149 1.17462 18.2149 1.43125C18.2149 1.68788 18.1274 1.90369 17.9525 2.07866C17.7775 2.25364 17.5675 2.34113 17.3225 2.34113ZM17.935 4.14337V13.697H16.7101V4.14337H17.935Z"
        fill="#385EAB"
      />
      <path d="M14.2895 0.748871V13.697H13.0647V0.748871H14.2895Z" fill="#385EAB" />
      <path d="M69.6374 0.748871V13.697H67.1877V0.748871H69.6374Z" fill="#385EAB" />
      <path d="M74.0117 0.748871V13.697H71.562V0.748871H74.0117Z" fill="#385EAB" />
      <path
        d="M65.263 8.64024C65.263 8.99019 65.2396 9.30515 65.193 9.58511H58.1065C58.1648 10.285 58.4098 10.8333 58.8414 11.2299C59.273 11.6265 59.8037 11.8248 60.4336 11.8248C61.3435 11.8248 61.9909 11.434 62.3759 10.6525H65.018C64.738 11.5857 64.2015 12.3556 63.4082 12.9621C62.615 13.557 61.641 13.8545 60.4861 13.8545C59.5529 13.8545 58.7131 13.6504 57.9665 13.2421C57.2316 12.8222 56.6542 12.2331 56.2342 11.4748C55.826 10.7166 55.6218 9.84174 55.6218 8.85021C55.6218 7.84702 55.826 6.96631 56.2342 6.20808C56.6425 5.44986 57.2141 4.86661 57.949 4.45833C58.6839 4.05005 59.5296 3.84592 60.4861 3.84592C61.4077 3.84592 62.2301 4.04422 62.9533 4.44083C63.6882 4.83744 64.2539 5.4032 64.6506 6.13809C65.0588 6.86133 65.263 7.69537 65.263 8.64024ZM62.7258 7.94034C62.7142 7.31043 62.4867 6.80883 62.0434 6.43555C61.6002 6.05061 61.0577 5.85813 60.4161 5.85813C59.8096 5.85813 59.2963 6.04477 58.8764 6.41805C58.4681 6.77967 58.2173 7.2871 58.124 7.94034H62.7258Z"
        fill="#385EAB"
      />
      <path
        d="M51.8132 6.01561V10.705C51.8132 11.0316 51.889 11.2707 52.0406 11.4223C52.2039 11.5623 52.4722 11.6323 52.8455 11.6323H53.9828V13.697H52.4431C50.3784 13.697 49.346 12.6938 49.346 10.6875V6.01561H48.1912V4.00339H49.346V1.60623H51.8132V4.00339H53.9828V6.01561H51.8132Z"
        fill="#385EAB"
      />
      <path
        d="M42.6676 3.86343C43.8225 3.86343 44.7557 4.23088 45.4672 4.96578C46.1788 5.68901 46.5346 6.70387 46.5346 8.01035V13.6971H44.0849V8.3428C44.0849 7.57291 43.8925 6.98383 43.5075 6.57555C43.1226 6.15561 42.5976 5.94564 41.9327 5.94564C41.2562 5.94564 40.7196 6.15561 40.323 6.57555C39.938 6.98383 39.7456 7.57291 39.7456 8.3428V13.6971H37.2959V4.00342H39.7456V5.21075C40.0722 4.7908 40.4863 4.46418 40.9879 4.23088C41.5011 3.98592 42.0611 3.86343 42.6676 3.86343Z"
        fill="#385EAB"
      />
      <path d="M35.5418 11.8744H30.6206V13.6971H35.5418V11.8744Z" fill="#385EAB" />
      <path d="M35.5418 1.30295H30.6206V3.12561H35.5418V1.30295Z" fill="#385EAB" />
      <path d="M34.2778 1.48376V13.697H31.8281V1.48376H34.2778Z" fill="#385EAB" />
    </g>
    <defs>
      <clipPath id="clip0_5877_2335">
        <rect width="74" height="13.8522" fill="white" transform="translate(0 0.573883)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ClinIntellLogoAlt;
