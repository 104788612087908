import { ColDef, ColumnState, ValueGetterParams } from 'ag-grid-community';
import { mergePersistedColumnState } from './persistence';
import { ColumnDef } from '../types/table';

type ColDefMapperProps<T> = {
  columns: Array<ColumnDef<T, keyof T>>;
  persistedColumnState?: ColumnState[];
};

export const colDefMapper = <T>({ columns, persistedColumnState }: ColDefMapperProps<T>): Array<ColDef<T>> => {
  const newColDefs = columns
    .filter(column => Boolean(column))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore -- Using unknown as the parameter just for mapping purposes
    .map<ColDef<T, keyof T>>((column: ColumnDef<T, keyof T> | ColumnDefNoValue<T>) => {
      return {
        ...column,
        menuTabs: ['filterMenuTab'],
        filter: true,
        filterParams: {
          applyMiniFilterWhileTyping: true,
          valueGetter: (params: ValueGetterParams<T>): NonNullable<string> | NonNullable<number> => {
            if (!params.data) {
              return '';
            }

            if (Object.hasOwn(column, 'filterValuesRenderer')) {
              // I'm literally checking for this property above
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return column.filterValuesRenderer(params.data);
            }

            return params.data[column.field as keyof T] as NonNullable<string | number>;
          }
        },
        cellStyle:
          typeof column.cellStyle === 'function'
            ? params => ({
                display: 'flex',
                alignItems: 'center',
                ...column.cellStyle(params)
              })
            : {
                display: 'flex',
                alignItems: 'center',
                ...column.cellStyle
              },
        ...(persistedColumnState &&
          mergePersistedColumnState(
            persistedColumnState.find(state => state.colId === column.field),
            column as Partial<ColDef<T>>
          ))
      };
    });

  return newColDefs;
};
