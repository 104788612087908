import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { ChartTooltipItem, ChartOptions } from 'chart.js';
import { formatNumberWithCommas } from '@clinintell/utils/formatting';
import { ChartDataWithFullLabels } from '@clinintell/types/common';

export type ChartLegendType = {
  label: string;
  color?: string;
  tooltip?: string;
};

type Props = {
  data: ChartDataWithFullLabels;
  legendItems?: ChartLegendType[];
  axisLabel?: string;
  isHorizontal?: boolean;
  customHeight?: number;
  tooltips?: string[];
  handleOnChartBarClick?: (_?: globalThis.MouseEvent, elements?: unknown[]) => void;
  testId: string;
};

const DashboardBarChart: React.FC<Props> = ({
  data,
  legendItems,
  axisLabel,
  isHorizontal = false,
  customHeight = 180,
  tooltips,
  handleOnChartBarClick,
  testId
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: handleOnChartBarClick,
    legend: {
      display: false
    },
    tooltips: {
      yPadding: 8,
      xPadding: 8,
      callbacks: {
        label(tooltipItem: ChartTooltipItem): string {
          if (!tooltipItem.value) return '';
          return tooltipItem.value ? `Opportunity (RW): ${formatNumberWithCommas(tooltipItem.value)}` : '';
        },
        title(item: ChartTooltipItem[], _: ChartDataWithFullLabels): string {
          if (!item[0].label) return '';
          if (tooltips && tooltips.length) return tooltips[Number(item[0].index)];
          return item[0].label.split(',').join(' ');
        }
      }
    },
    onHover: function(this, event: MouseEvent, activeElements: unknown[]) {
      (event.target as HTMLCanvasElement).style.cursor = activeElements[0] ? 'pointer' : 'default';
    }
  } as ChartOptions;

  return (
    <>
      <Box sx={{ height: isMobileView ? 120 : customHeight, width: '100%' }}>
        {legendItems && (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', mb: 1 }}>
            {legendItems.map(legend => (
              <React.Fragment key={legend.label}>
                <Box sx={{ height: 8, width: 20, backgroundColor: legend.color, mr: 1 }} />
                <Typography variant="body1" sx={{ fontWeight: 600, color: theme.palette.grey[500] }}>
                  {legend.label}
                </Typography>
              </React.Fragment>
            ))}
          </Box>
        )}

        {isHorizontal ? (
          <HorizontalBar
            data={data as ChartDataWithFullLabels}
            options={{
              ...chartOptions,
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      display: false
                    }
                  }
                ],
                xAxes: [
                  {
                    position: 'top',
                    gridLines: {
                      display: true,
                      drawBorder: true,
                      drawOnChartArea: false,
                      tickMarkLength: 5
                    },
                    scaleLabel: {
                      display: !!axisLabel,
                      labelString: axisLabel
                    },
                    ticks: {
                      autoSkip: true,
                      maxTicksLimit: 5,
                      callback: value => formatNumberWithCommas(value)
                    }
                  }
                ]
              }
            }}
          />
        ) : (
          <Bar
            data={data as ChartDataWithFullLabels}
            options={{
              ...chartOptions,
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: !!axisLabel,
                      labelString: axisLabel
                    },
                    ticks: {
                      min: 0,
                      autoSkip: true,
                      maxTicksLimit: 5
                    }
                  }
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      fontSize: 10,
                      lineHeight: 10,
                      padding: { top: 0 }
                    }
                  }
                ]
              }
            }}
          />
        )}
      </Box>
      <Box data-test-id={`${testId}-graphDescription`} display="none">
        {data && data.datasets && data.fullTextLabels && data.datasets[0] && data.datasets[0].data ? (
          <>
            <Box>{JSON.stringify(data.datasets[0].data)}</Box>
            {data.datasets[0].data.map((d, ix) => {
              const optionTestId =
                data.fullTextLabels && data.fullTextLabels[ix] ? data.fullTextLabels[ix].replaceAll(' ', '') : d;
              return (
                <button
                  data-test-id={`${testId}-${optionTestId}-activator`}
                  data-test-value={d}
                  key={ix}
                  onClick={() =>
                    handleOnChartBarClick ? handleOnChartBarClick(undefined, [{ _index: ix }]) : undefined
                  }
                />
              );
            })}
          </>
        ) : null}
      </Box>
    </>
  );
};

export default DashboardBarChart;
