import ThreeLinesIcon from '@clinintell/components/icons/ThreeLinesIcon';
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

const useStyles = makeStyles(theme => ({
  iconButtonPrimary: {
    padding: '0px',
    backgroundColor: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: theme.palette.blue.light5
    }
  },
  iconButtonSecondary: {
    padding: '0px',
    backgroundColor: theme.palette.teal.main,
    '&:hover': {
      backgroundColor: theme.palette.teal.light2
    }
  },
  muiiconrootPrimary: {
    fill: theme.palette.blue.main,
    backgroundColor: theme.palette.blue.light5,
    '&:hover': {
      fill: theme.palette.blue.light5,
      backgroundColor: theme.palette.blue.main,
      cursor: 'pointer'
    },
    borderRadius: '27px'
  },
  muiiconrootSecondary: {
    fill: theme.palette.teal.main,
    backgroundColor: theme.palette.teal.light2,
    '&:hover': {
      fill: theme.palette.teal.light2,
      backgroundColor: theme.palette.teal.main,
      cursor: 'pointer'
    },
    borderRadius: '27px'
  }
}));

type HideTreeButtonProps = {
  isTreeVisible: boolean;
  toggleTreeVisibility: () => void;
  color: 'primary' | 'secondary';
};

const HideTreeButton: React.FC<HideTreeButtonProps> = ({ isTreeVisible, toggleTreeVisibility, color }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton
        size="medium"
        aria-label="show or hide search tree"
        onClick={toggleTreeVisibility}
        className={color === 'primary' ? classes.iconButtonPrimary : classes.iconButtonSecondary}
        data-cy="showHideTreeButton"
      >
        {isTreeVisible ? (
          <CancelIcon
            style={{ width: 38, height: 38 }}
            classes={{ root: color === 'primary' ? classes.muiiconrootPrimary : classes.muiiconrootSecondary }}
          />
        ) : (
          <ThreeLinesIcon
            viewBox="0 0 32 32"
            className={color === 'primary' ? classes.iconButtonPrimary : classes.iconButtonSecondary}
          ></ThreeLinesIcon>
        )}
      </IconButton>
    </Box>
  );
};

export default HideTreeButton;
