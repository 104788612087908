import { extractDetailsFromNode, NodeDetails } from '@clinintell/containers/treeSelector/logic/TreeContext';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { dfsFindNodeInTree, dfsFindNodeInTreeWithSpecificParentId } from '@clinintell/utils/treeTraversal';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { CombinedState } from './store';

export enum NodeTypeIds {
  'Entity' = 1,
  'SystemView' = 2,
  'Hospital' = 3,
  'SpecialtyGroup' = 4,
  'Provider' = 5
}

export type FundamentalSpecialty = {
  id: number;
  fundamentalSpecialtyName: string;
};

export type TreeJSON = {
  id: number;
  name: string;
  childrenCount: number;
  children: TreeJSON[] | null;
  parentId?: number;
  lineage?: number[] | [];
  nodeTypeId: number;
  fundamentalSpecialties: FundamentalSpecialty[];
};

export type TreeNode = {
  id: number;
  parentId: number | null;
  children: TreeNode[];
  isLeaf: boolean;
  name: string;
  nodeTypeId: number;
};

export type OrgTree = {
  node: TreeNode | null;
  error?: string;
  isFetchingNodeId: number | null;
};

export type OrgTreeTypes = 'client' | 'saf';
export type OrgTreeState = Record<OrgTreeTypes, OrgTree>;

type FetchBeginProps = {
  nodeId: number;
  orgTreeType: OrgTreeTypes;
  onFetchComplete?: (value: NodeDetails | PromiseLike<NodeDetails>) => void;
  parentId?: number;
};

type FetchSuccessfulPayload = {
  node: TreeNode;
  orgTreeType: OrgTreeTypes;
};

type FetchFailedPayload = {
  orgTreeType: OrgTreeTypes;
  error: string;
};

/* fetch path to root from node actions */
const FETCH_PATH_TO_ROOT_FROM_NODE = 'FETCH_PATH_TO_ROOT_FROM_NODE';
const FETCH_PATH_TO_ROOT_FROM_NODE_BEGIN = 'FETCH_PATH_TO_ROOT_FROM_NODE_BEGIN';
const FETCH_PATH_TO_ROOT_FROM_NODE_SUCCESSFUL = 'FETCH_PATH_TO_ROOT_FROM_NODE_SUCCESSFUL';
const FETCH_PATH_TO_ROOT_FROM_NODE_FAILED = 'FETCH_PATH_TO_ROOT_FROM_NODE_FAILED';

interface FetchPathToRootFromNodeAction {
  type: typeof FETCH_PATH_TO_ROOT_FROM_NODE;
  payload: FetchBeginProps;
}

interface FetchPathToRootFromNodeBeginAction {
  type: typeof FETCH_PATH_TO_ROOT_FROM_NODE_BEGIN;
  payload: FetchBeginProps;
}

interface FetchPathToRootFromNodeSuccessfulAction {
  type: typeof FETCH_PATH_TO_ROOT_FROM_NODE_SUCCESSFUL;
  payload: FetchSuccessfulPayload;
}

interface FetchPathToRootFromNodeFailedAction {
  type: typeof FETCH_PATH_TO_ROOT_FROM_NODE_FAILED;
  payload: FetchFailedPayload;
}

export type OrgTreeActionTypes =
  | FetchPathToRootFromNodeAction
  | FetchPathToRootFromNodeBeginAction
  | FetchPathToRootFromNodeSuccessfulAction
  | FetchPathToRootFromNodeFailedAction;

export const fetchPathToRootFromClientNode = ({
  nodeId,
  onFetchComplete,
  parentId
}: Omit<FetchBeginProps, 'orgTreeType'>): OrgTreeActionTypes => ({
  type: 'FETCH_PATH_TO_ROOT_FROM_NODE',
  payload: {
    nodeId,
    orgTreeType: 'client',
    onFetchComplete,
    parentId
  }
});

export const fetchPathToRootFromSAFNode = ({
  nodeId,
  onFetchComplete,
  parentId
}: Omit<FetchBeginProps, 'orgTreeType'>): OrgTreeActionTypes => ({
  type: 'FETCH_PATH_TO_ROOT_FROM_NODE',
  payload: {
    nodeId,
    orgTreeType: 'saf',
    onFetchComplete,
    parentId
  }
});

export const initialState: OrgTreeState = {
  client: {
    isFetchingNodeId: null,
    node: null
  },
  saf: {
    isFetchingNodeId: null,
    node: null
  }
};

// Reducer
const reducer = (state: OrgTreeState = initialState, action: OrgTreeActionTypes): OrgTreeState => {
  switch (action.type) {
    case 'FETCH_PATH_TO_ROOT_FROM_NODE_BEGIN': {
      const { error, ...errorlessState } = state[action.payload.orgTreeType];
      errorlessState.isFetchingNodeId = action.payload.nodeId;

      return {
        ...state,
        [action.payload.orgTreeType]: errorlessState
      };
    }
    case 'FETCH_PATH_TO_ROOT_FROM_NODE_SUCCESSFUL': {
      const { error, ...treeTypeState } = state[action.payload.orgTreeType];
      treeTypeState.isFetchingNodeId = null;
      treeTypeState.node = action.payload.node;

      return {
        ...state,
        [action.payload.orgTreeType]: { ...treeTypeState }
      };
    }
    case 'FETCH_PATH_TO_ROOT_FROM_NODE_FAILED': {
      const treeTypeState = state[action.payload.orgTreeType];
      treeTypeState.isFetchingNodeId = null;
      treeTypeState.error = action.payload.error;

      return {
        ...state,
        [action.payload.orgTreeType]: {
          ...treeTypeState
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

const getParentIdFromLineage = (nodeId: number, lineage: number[]): number | null => {
  let parentId: number | null = null;

  lineage.forEach((id, index) => {
    if (nodeId === id && index > 0) {
      parentId = lineage[index - 1];
    }
  });

  return parentId;
};

export const fetchNode = async (id: number, orgTreeType: OrgTreeTypes, withLineage?: boolean) => {
  const childrenResult: AsyncOutput<TreeJSON> = await ApplicationAPI.get({
    endpoint: `org/${id}${withLineage === true ? '?includeLineage=true' : ''}`,
    useSAF: orgTreeType === 'saf'
  });

  return childrenResult;
};

const nodeIsSpecialGroupEntity = (name: string): boolean => {
  return name.includes('(Physician Groups)');
};

const mapChildrenToNode = (children: TreeJSON[], nodeId: number, nodeName: string): TreeNode[] => {
  return children.map(
    (child: TreeJSON): TreeNode => ({
      id: child.id,
      name: child.name,
      parentId: nodeId,
      isLeaf: child.childrenCount === 0,
      nodeTypeId: nodeIsSpecialGroupEntity(nodeName) ? (child.childrenCount > 0 ? 4 : 5) : child.nodeTypeId,
      children: []
    })
  );
};

const processChildrenForNode = (existingNode: TreeNode, childNode: TreeJSON): TreeNode => {
  const processedNode = { ...existingNode };

  processedNode.children = mapChildrenToNode(childNode.children || [], existingNode.id, existingNode.name);

  return processedNode;
};

const replaceChildNode = (node: TreeNode, childNode: TreeNode): TreeNode => {
  const selectedChildIndex = node.children.findIndex(child => child.id === childNode.id);
  if (selectedChildIndex > -1) {
    node.children.splice(selectedChildIndex, 1, childNode);
  }

  return { ...node };
};

// Sagas
// From a given node, will traverse up the tree and add the relevant nodes until it finds an existing parent. It will go all the way up to the root if needed.
export const orgTreeSelector = (state: CombinedState): OrgTreeState => state.orgTree;
export function* fetchPathToRootFromNodeMethod({ payload }: FetchPathToRootFromNodeAction): SagaIterator {
  const { nodeId, orgTreeType, onFetchComplete } = payload;

  yield put({
    type: FETCH_PATH_TO_ROOT_FROM_NODE_BEGIN,
    payload: {
      nodeId,
      orgTreeType
    }
  });

  let isRoot = false;
  // Query org hierarchy on the parent node. Keep doing this until you find an existing parent in the tree or go all the way up to the root.
  let currentNode: TreeNode | undefined;
  let idToQuery = payload.nodeId;
  let findParentId = payload.parentId;
  let nodeDetails: NodeDetails = {
    name: '',
    type: -1,
    isNew: true
  };

  const currentState = yield select(orgTreeSelector);
  const node = currentState[orgTreeType].node;

  while (isRoot === false) {
    // First check if this node is in the current tree in state
    let existingNode =
      findParentId !== undefined
        ? dfsFindNodeInTreeWithSpecificParentId(node, idToQuery, findParentId)
        : dfsFindNodeInTree(node, idToQuery);

    // Process node if it already exists in the tree
    if (existingNode) {
      if (existingNode.id === payload.nodeId) {
        nodeDetails = extractDetailsFromNode(existingNode);
      }

      // Fetch children for this node if they have unloaded children
      if (existingNode.children.length === 0 && !existingNode.isLeaf) {
        const childrenResult: AsyncOutput<TreeJSON> = yield call(fetchNode, existingNode.id, orgTreeType);

        if (childrenResult.data && childrenResult.data.children) {
          existingNode = processChildrenForNode(existingNode, childrenResult.data);
        } else if (childrenResult.error) {
          yield put({
            type: FETCH_PATH_TO_ROOT_FROM_NODE_FAILED,
            payload: `Error fetching org tree - ${childrenResult.error}`
          });

          return;
        }
      }

      // Replace child node with the previous processed node ... could be different with loaded/unloaded children
      const childNode = currentNode;
      if (childNode) {
        existingNode = replaceChildNode(existingNode, childNode);
      }

      currentNode = existingNode;

      if (existingNode.parentId) {
        const parentNode = dfsFindNodeInTree(node, existingNode.parentId);
        if (!parentNode) {
          yield put({
            type: FETCH_PATH_TO_ROOT_FROM_NODE_FAILED,
            payload: `Cannot find parent in tree for ${existingNode.name}`
          });

          return;
        }

        idToQuery = parentNode.id;
        // We only care about finding the specific parent ID of the given target node
        findParentId = undefined;
      } else {
        isRoot = true;
      }

      continue;
    }

    // Node doesn't exist in the tree yet, so fetch the entity plus lineage, so that we can backtrack all the way to the root if needed.
    // This happens during a tree search
    const result: AsyncOutput<TreeJSON> = yield call(fetchNode, idToQuery, orgTreeType, true);

    const childNode = currentNode;

    let parentId: number | null = null;

    if (result.data) {
      const { id, lineage, name, nodeTypeId, childrenCount } = result.data;
      // Use the lineage to figure out the parent.
      if (lineage) {
        parentId = getParentIdFromLineage(id, lineage);
      }

      currentNode = {
        id,
        name,
        parentId,
        isLeaf: childrenCount === 0,
        nodeTypeId: nodeTypeId,
        children: []
      };

      currentNode = processChildrenForNode(currentNode, result.data);

      if (result.data.id === payload.nodeId) {
        nodeDetails = extractDetailsFromNode(currentNode);
      }

      // Replace child from output data with the childNode that has been processed.
      if (childNode && currentNode.id !== childNode.id) {
        currentNode = replaceChildNode(currentNode, childNode);
      }

      if (currentNode.parentId === null) {
        isRoot = true;
      } else {
        idToQuery = currentNode.parentId;
        findParentId = undefined;
      }
    } else if (result.error) {
      yield put({
        type: FETCH_PATH_TO_ROOT_FROM_NODE_FAILED,
        payload: `Error fetching data for Node ID ${idToQuery} - ${result.error}`
      });

      return;
    } else if (result.status && result.status === 204) {
      yield put({
        type: FETCH_PATH_TO_ROOT_FROM_NODE_FAILED,
        payload: `Error fetching data for Node ID ${idToQuery} - Node ID does not exist`
      });

      return;
    }
  }

  yield put({
    type: FETCH_PATH_TO_ROOT_FROM_NODE_SUCCESSFUL,
    payload: {
      orgTreeType,
      node: currentNode
    }
  });

  if (onFetchComplete) {
    onFetchComplete(nodeDetails);
  }
}

export function* fetchPathToRootFromNodeSaga(): SagaIterator {
  yield takeLatest('FETCH_PATH_TO_ROOT_FROM_NODE', fetchPathToRootFromNodeMethod);
}
