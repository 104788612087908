import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EditIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6056_14845)">
      <path
        d="M0.5 16.0404V19.9982H4.45778L16.1306 8.32535L12.1728 4.36756L0.5 16.0404ZM19.1913 5.26466C19.6029 4.85305 19.6029 4.18814 19.1913 3.77653L16.7216 1.30688C16.31 0.895267 15.6451 0.895267 15.2335 1.30688L13.3021 3.23827L17.2599 7.19606L19.1913 5.26466Z"
        fill="#64748B"
      />
    </g>
    <defs>
      <clipPath id="clip0_6056_14845">
        <rect width="20" height="20" fill="white" transform="translate(0 0.500244)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
EditIcon.muiName = SvgIcon.muiName;

export default EditIcon;
