import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Widget from '../../components/widget/Widget';
import DashboardDoughnutChart from '@clinintell/containers/dashboard/charts/DashboardDoughnutChart';
import { DashboardWidget, getTargetedConditionsModalData, WidgetSchema } from '@clinintell/modules/dashboard2';
import {
  useDashboard2,
  useDashboard2Dispatch,
  useMenuNavigationDispatch,
  useMetricsNavigationDispatch
} from '@clinintell/modules/store';
import { setActiveMenuItem } from '@clinintell/modules/menuNavigation';
import { setEntity, setMetric, Metrics, updateTreeAfterNavigation } from '@clinintell/modules/metricsNavigation';
import TargetedConditionsModal from './TargetedConditionsModal';
import WidgetError from '../../components/widget/WidgetError';
import WidgetModal from '../../components/modal/WidgetModal';

const TargetedConditionsWidget: React.FC = () => {
  const dashboard2Dispatch = useDashboard2Dispatch();
  const { targetedConditionsWidget: widget, entity, groupName: group, groupId } = useDashboard2();
  const { hasError, data, isLoading } = widget as DashboardWidget;

  const navigate = useNavigate();
  const menuNavigationDispatch = useMenuNavigationDispatch();
  const metricsDispatch = useMetricsNavigationDispatch();

  const [valueBelow, setValueBelow] = useState(0);
  const [valueMeeting, setValueMeeting] = useState(0);
  const [valueNearing, setValueNearing] = useState(0);
  const [totalStrategies, settotalStrategies] = useState(0);
  const [graphMessage, setGraphMessage] = useState('');
  const [graphStatus, setGraphStatus] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const getOrgTitle = useMemo(() => {
    if (group && group !== 'All') return group;
    if (entity) return entity.name;
    return '';
  }, [entity, group]);
  const getOrgId = useMemo(() => {
    if (groupId && groupId !== entity?.id) return groupId;
    if (entity) return entity.id;
    return -1;
  }, [entity, groupId]);
  const widgetTooltip = (
    <Box width={300} my={-1}>
      <p>This widget monitors the performance of the Targeted Conditions across {getOrgTitle}.</p>
      {graphStatus === 'Active' && <p>Click on the donut chart to see more details.</p>}
    </Box>
  );

  const handleChartClick = (): void => {
    dashboard2Dispatch(getTargetedConditionsModalData(getOrgId));
    setOpenModal(true);
  };

  const WidgetModalMemo = useMemo(
    () => (
      <WidgetModal
        modalVisible={openModal}
        toggleVisible={() => setOpenModal(false)}
        tooltipMsg={`Navigate to Targeted Conditions for ${getOrgTitle}`}
        redirectUserHandler={(): void => {
          const orgId = groupId && groupId !== entity?.id ? groupId : entity ? entity.id : -1;
          navigate('/metrics');
          metricsDispatch(setEntity(orgId));
          metricsDispatch(setMetric(Metrics.targetedConditions));
          metricsDispatch(updateTreeAfterNavigation());
          menuNavigationDispatch(setActiveMenuItem({ name: 'metrics' }));
        }}
        headerTitle={`${getOrgTitle} Targeted Condition Performance`}
        isModalLoading={isLoading}
      >
        <TargetedConditionsModal />
      </WidgetModal>
    ),
    [entity, getOrgTitle, groupId, isLoading, menuNavigationDispatch, metricsDispatch, navigate, openModal]
  );

  useEffect(() => {
    if (isLoading || !data) return;
    const {
      numBelow,
      numMeeting,
      numNearing,
      percentBelow,
      percentMeeting,
      percentNearing,
      message,
      status
    } = data as WidgetSchema;
    setValueBelow(percentBelow);
    setValueMeeting(percentMeeting);
    setValueNearing(percentNearing);
    settotalStrategies(numBelow + numMeeting + numNearing);
    setGraphMessage(totalStrategies ? `${numMeeting}/${totalStrategies}\nConditions Improving` : (message as string));
    setGraphStatus(status);
  }, [data, isLoading, totalStrategies, valueMeeting]);

  return (
    <>
      <Widget
        isLoading={isLoading}
        hasError={hasError}
        headerTitle="Targeted Condition Performance"
        toolTip={widgetTooltip}
        displayTooltip={graphStatus === 'Active' ? true : false}
      >
        {graphStatus !== 'Active' ? (
          <WidgetError graphStatus={graphStatus} graphMessage={graphMessage} />
        ) : (
          <DashboardDoughnutChart
            chartData={[valueMeeting, valueNearing, valueBelow]}
            chartLabels={['Improving', 'No Change', 'Declining']}
            labelMsg={graphMessage}
            onClick={handleChartClick}
            testId="targetedConditionsWidget"
          />
        )}
      </Widget>
      {WidgetModalMemo}
    </>
  );
};

export default TargetedConditionsWidget;
