import { ProviderTrainingConditionType } from '@clinintell/containers/providerTrainingAssignment/types/providerTrainingConditionType';
import { ProviderTrainingAssignmentState } from '@clinintell/modules/trainingAssignment';

export const providerHasAssignedTraining = ({
  trainingStatus,
  assignedDate
}: ProviderTrainingAssignmentState | ProviderTrainingConditionType): boolean => {
  return (
    (trainingStatus === 'New' ||
      trainingStatus === 'Completed' ||
      trainingStatus === 'InProgress' ||
      trainingStatus === 'Removed') &&
    assignedDate !== null
  );
};

export const providerHasCompletedTraining = ({
  trainingStatus,
  completedDate
}: ProviderTrainingAssignmentState | ProviderTrainingConditionType): boolean => {
  return (trainingStatus === 'Completed' || trainingStatus === 'Removed') && completedDate !== null;
};
