import { Box } from '@mui/material';
import { VariantType } from 'notistack';
import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';

export type AlertProps = {
  message: string;
  variant: VariantType;
  onClose: () => void;
};

const Alert = React.forwardRef<HTMLElement, AlertProps>(({ message, variant, onClose }, ref) => {
  let backgroundColor = '';
  let icon;

  switch (variant) {
    case 'success':
    default:
      backgroundColor = 'green.500';
      icon = <CheckCircleOutlineIcon />;
      break;
    case 'error':
      backgroundColor = 'red.500';
      icon = <ErrorOutlineIcon />;
      break;
    case 'warning':
      backgroundColor = 'yellow.500';
      icon = <WarningAmberIcon />;
      break;
    case 'info':
      backgroundColor = 'blue.main';
      icon = <ErrorOutlineIcon />;
      break;
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'shade.white',
        typography: 'p2',
        fontWeight: 600,
        backgroundColor,
        borderRadius: '5px',
        py: 2,
        pl: 3,
        pr: 4,
        minWidth: '20rem'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ mr: 1 }}>{icon}</Box>
        {message}
      </Box>
      <CloseIcon onClick={onClose} role="button" sx={{ '&:hover': { cursor: 'pointer' } }} />
    </Box>
  );
});

export default Alert;
