import ResultSummaryTable from '@clinintell/components/analysisSharedComponents/ResultSummaryTable';
import { AnalysisRecord } from '@clinintell/modules/cmiComparison';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';
import {
  netImpactMessage,
  significantNegativeImpactMessage,
  significantPositiveImpactMessage
} from '../../../components/analysisSharedComponents/tooltipMessages';
import { DRGMixComparisonDTO } from '../typings/cmiComparisonDtos';

type DRGMixTableProps = {
  drgMixSummary: AnalysisRecord;
  drgAnalysis: DRGMixComparisonDTO;
  baseLabel: string;
  comparisonLabel: string;
};

const DRGMixTable: React.FC<DRGMixTableProps> = ({ drgMixSummary, drgAnalysis, baseLabel, comparisonLabel }) => {
  const totalDifference =
    Number(formatToDecimalPlaces(drgAnalysis.totalPositiveCmiImpact, 2)) +
    Number(formatToDecimalPlaces(drgAnalysis.totalNegativeCmiImpact, 2));

  const hcupCmiDifference =
    drgMixSummary.base !== null && drgMixSummary.compare !== null
      ? `${formatToDecimalPlaces(
          Number(formatToDecimalPlaces(drgMixSummary.base, 2)) -
            Number(formatToDecimalPlaces(drgMixSummary.compare, 2)),
          2
        )}`
      : '-';

  const impactPercentage =
    hcupCmiDifference !== '-' && Number(hcupCmiDifference) !== 0 ? totalDifference / Number(hcupCmiDifference) : null;

  return (
    <ResultSummaryTable
      rows={[
        {
          columns: [
            {
              value: '',
              width: 2,
              align: 'center',
              bold: true,
              key: 'header1'
            },
            {
              value: baseLabel,
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerHistorical'
            },
            {
              value: comparisonLabel,
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerCurrent'
            },
            {
              value: 'Difference',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerChange'
            }
          ],
          key: 'headerRow'
        },
        {
          divider: 'solid',
          key: 'headerDivider'
        },
        {
          columns: [
            {
              value: 'DRG Mix Value',
              width: 2,
              align: 'left',
              bold: true,
              key: 'drgMix'
            },
            {
              value: drgMixSummary.base ? formatToDecimalPlaces(drgMixSummary.base, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'drgMixHistorical'
            },
            {
              value: drgMixSummary.compare ? formatToDecimalPlaces(drgMixSummary.compare, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'drgMixCurrent'
            },
            {
              value: hcupCmiDifference,
              width: 2,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'drgMixDifference'
            }
          ],
          key: 'drgMixRow'
        },
        {
          divider: 'solid',
          key: 'drgDividerRow'
        },
        {
          columns: [
            {
              value: 'Significant Positive Impact',
              width: 10,
              align: 'right',
              bold: true,
              key: 'positiveImpactLabel',
              rowLabelNoEllipsis: true,
              tooltip: significantPositiveImpactMessage
            },
            {
              value: formatToDecimalPlaces(drgAnalysis.totalPositiveCmiImpact, 2),
              width: 2,
              type: 'number',
              offsetCenter: true,
              align: 'left',
              key: 'positiveImpactValue'
            }
          ],
          key: 'positiveImpactRow'
        },
        {
          columns: [
            {
              value: 'Significant Negative Impact',
              width: 10,
              align: 'right',
              bold: true,
              key: 'negativeImpactLabel',
              rowLabelNoEllipsis: true,
              tooltip: significantNegativeImpactMessage
            },
            {
              value: formatToDecimalPlaces(drgAnalysis.totalNegativeCmiImpact, 2),
              width: 2,
              type: 'number',
              offsetCenter: true,
              align: 'left',
              key: 'negativeImpactValue',
              underline: true
            }
          ],
          key: 'negativeImpactRow'
        },
        {
          columns: [
            {
              value: 'Net Impact',
              width: 10,
              align: 'right',
              bold: true,
              key: 'totalLabel',
              rowLabelNoEllipsis: true,
              tooltip: netImpactMessage
            },
            {
              value: `${formatToDecimalPlaces(totalDifference, 2)} ${
                impactPercentage !== null ? `(${formatToDecimalPlaces(Math.min(impactPercentage * 100, 100), 0)}%)` : ''
              }`,
              width: 2,
              type: 'number',
              offsetCenter: true,
              align: 'left',
              key: 'totalValue'
            }
          ],
          key: 'totalRow'
        }
      ]}
    />
  );
};

export default DRGMixTable;
