import { Box } from '@mui/material';
import React from 'react';
import GroupGeneratedReport from './sections/GroupGeneratedReport';
import HospitalGeneratedReport from './sections/HospitalGeneratedReport';
import ProviderGeneratedReport from './sections/ProviderGeneratedReport';
import SystemGeneratedReport from './sections/SystemGeneratedReport';
import { TrainingProgressEntity } from '../reports/types/routeProps';
import { useUser } from '@clinintell/modules/store';

export type ReportTypes = 'System' | 'Hospital' | 'Group' | 'Provider' | 'Unknown';

export type OrgTableProps = {
  orgId: number;
};

type ReportTypeProps = {
  hospital?: TrainingProgressEntity;
  physician?: TrainingProgressEntity;
  group?: TrainingProgressEntity;
};

// Helper functions
export const getReportType = ({ hospital, physician, group }: ReportTypeProps): ReportTypes => {
  if (physician && physician.name !== 'All' && physician.name !== '') {
    return 'Provider';
  } else if (group && group.name !== 'All' && group.name !== '') {
    return 'Group';
  } else if (hospital && hospital.name !== 'All Hospitals' && hospital.name !== '') {
    return 'Hospital';
  } else if (hospital) {
    return 'System';
  } else {
    return 'Unknown';
  }
};

type TrainingStatusReportProps = {
  hospital?: TrainingProgressEntity;
  group?: TrainingProgressEntity;
  physician?: TrainingProgressEntity;
};

const TrainingStatusReport: React.FC<TrainingStatusReportProps> = ({ hospital, group, physician }) => {
  const { rootId } = useUser();

  let report;
  switch (getReportType({ hospital, group, physician })) {
    case 'Provider':
      report = <ProviderGeneratedReport orgId={Number(physician?.id)} />;
      break;
    case 'Group':
      report = <GroupGeneratedReport orgId={Number(group?.id)} />;
      break;
    case 'Hospital':
      report = <HospitalGeneratedReport orgId={Number(hospital?.id)} />;
      break;
    case 'System':
      if (hospital && hospital.id) {
        report = <SystemGeneratedReport orgId={hospital && hospital.id !== -1 ? hospital.id : rootId} />;
      } else {
        report = null;
      }
      break;
    case 'Unknown':
    default:
      report = null;
      break;
  }

  return <Box marginTop={4}>{report}</Box>;
};

export default TrainingStatusReport;
