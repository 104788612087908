import { Auth0Client } from '@auth0/auth0-spa-js';

export interface AuthenticationStatus {
  isAuthenticated: boolean | null;
  auth0Client: Auth0Client | null;
}

// Actions
enum AuthStatusActions {
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
}

export interface AuthStatusAction {
  type?: keyof typeof AuthStatusActions;
  payload?: Auth0Client;
}

export const defaultAuthState: AuthenticationStatus = {
  isAuthenticated: null,
  auth0Client: null
};

// Reducer
const reducer = (state: AuthenticationStatus = defaultAuthState, action: AuthStatusAction): AuthenticationStatus => {
  switch (action.type) {
    case 'SET_AUTHENTICATED': {
      return {
        ...state,
        isAuthenticated: true,
        auth0Client: action.payload as Auth0Client
      };
    }
    case 'SET_UNAUTHENTICATED': {
      return {
        ...state,
        isAuthenticated: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
