import React, { createContext, useContext, useState } from 'react';

type ReportDownloadContextArgs = {
  downloadIsEnabled: boolean;
  enableDownload: () => void;
  disableDownload: () => void;
};

const ReportDownloadContext = createContext<ReportDownloadContextArgs>({
  downloadIsEnabled: true,
  enableDownload: () => {
    /* empty function */
  },
  disableDownload: () => {
    /* empty function */
  }
});

export const ReportDownloadProvider: React.FC = ({ children }) => {
  const [downloadIsEnabled, setDownloadIsEnabled] = useState(true);

  const enableDownload = () => {
    setDownloadIsEnabled(true);
  };

  const disableDownload = () => {
    setDownloadIsEnabled(false);
  };

  return (
    <ReportDownloadContext.Provider
      value={{
        downloadIsEnabled,
        enableDownload,
        disableDownload
      }}
    >
      {children}
    </ReportDownloadContext.Provider>
  );
};

export const useReportDownloadStatus = () => {
  const context = useContext(ReportDownloadContext);
  if (!context) {
    throw new Error('useReportDownloadStatus must be used within a ReportDownloadProvider');
  }

  return context;
};

export default ReportDownloadProvider;
