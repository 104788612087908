import Button from '@clinintell/components/button/Button';
import { Box } from '@mui/material';
import React from 'react';
import { CMIAnalysisTypes, EntitySelection } from '@clinintell/modules/cmiAnalysisSelections';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { OrgTreeTypes } from '@clinintell/modules/orgTree';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export type EntitySelectionActionsProps = {
  onGoBack: () => void;
  onAnalyze: (analysisType: CMIAnalysisTypes, entity?: EntitySelection, peerEntity?: EntitySelection) => void;
  orgTreeType: OrgTreeTypes;
};

type EntitySelectionDetailsProps = {
  analyzeIsDisabled: boolean;
  type: CMIAnalysisTypes;
  entity?: EntitySelection;
  peerEntity?: EntitySelection;
  selectionDescription: string;
  actionButtonLabel: string;
};

const EntitySelectionActions: React.FC<EntitySelectionActionsProps & EntitySelectionDetailsProps> = ({
  onGoBack,
  onAnalyze,
  children,
  analyzeIsDisabled,
  type,
  entity,
  peerEntity,
  selectionDescription,
  actionButtonLabel
}) => {
  const handleAnalyzeClick = () => {
    onAnalyze(type, entity, peerEntity);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button
          label="Back to CMI Analysis"
          leftAdornment={<ChevronLeftIcon sx={{ mt: '4px' }} />}
          variant="text"
          onClick={onGoBack}
          // Lines up icon with text below
          sx={{ ml: '-10px' }}
        />
        <Button label={actionButtonLabel} disabled={analyzeIsDisabled} onClick={handleAnalyzeClick} />
      </Box>
      <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row' }}>
        <Box component="span" sx={{ color: 'blue.main', typography: 'h5', fontWeight: 500 }}>
          Make a selection
        </Box>
        <Tooltip content={selectionDescription}>
          <HelpOutlineIcon sx={{ color: 'grey.500', ml: 1 }} />
        </Tooltip>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default EntitySelectionActions;
