import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  ColumnResizedEvent,
  ColumnState,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  IColumnLimit,
  RowDataUpdatedEvent,
  RowHeightParams,
  RowSelectedEvent,
  SortChangedEvent
} from 'ag-grid-community';
import ColumnHeader from './ColumnHeader';
import { ColumnDef, ColumnDefNoValue } from '../types/table';
import { debounce } from '@clinintell/utils/debounce';
import { useTableGlobalConfiguration } from '../context/TableGlobalConfigurationContext';

// Height of table row
const ROW_HEIGHT = 38;
const HEADER_HEIGHT = 47.44;

// When autosizing of columns occur (when adjusting browser width),
// this defines the minimum width of generic columns.
// Otherwise they would shrink far too much when adjusting the width
const COLUMN_AUTOSIZE_WIDTH = 150;

type RenderColumns<T> = {
  renderColumn: <Field extends keyof T>(field: Field, args: Omit<ColumnDef<T, Field>, 'field'>) => ColumnDef<T, Field>;
  renderCustomColumn: (args: Omit<ColumnDefNoValue<T>, 'field'>) => ColumnDefNoValue<T>;
};

export type TableProps<T> = {
  /* 
    rowData: array of typed data that makes up the rows of the table/
  */
  rowData: T[];
  /*
    footerData: single row of typed data that displays as the footer of the table. This is done
    by setting it as a bottom pinned column within AG Grid. 
  */
  footerData?: T;
  /*
    renderColumns: methods exposed to the given columns to render them to work with AG Grid.
      - renderColumn: method that is typed to a field with the table's given DTO
      - renderActionColumn: method that isn't typed to a field, thus needs its own Typescript definition
  */
  renderColumns: (renderers: RenderColumns<T>) => Array<unknown>;
  /*
    onGridReady: Event that fires when the grid APIs have been initialized. This is the first event of a table
    render. 
  */
  onGridReady?: (gridApi: GridApi, columnApi: ColumnApi) => void;
  /*
    onSortingChanged: Event that fires when manual column sorting occurs.
  */
  onSortingChanged?: (params: SortChangedEvent) => void;
  /*
    onColumnResized: Event that fires when manual column resizing occurs.
  */
  onColumnResized?: (params: ColumnResizedEvent) => void;
  /*
    onRowDragMove: Event that fires when manually dragging a row.
  */
  onRowDragMove?: () => void;
  /*
    onFirstDataRendered: Event that fires the first time data has been rendered on the table.
    This occurs after onGridReady.
  */
  onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
  onFilterChanged?: (event: FilterChangedEvent) => void;
  onRowDataUpdated?: (event: RowDataUpdatedEvent) => void;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
  /*
    persistedColumnState: Column state that gets merged into the initial default columnDefs generated from the children.
    Basically used when persisting column state for a table.
  */
  persistedColumnState?: ColumnState[];
  /*
    tableHeight: Used when calculating the DOM layout of the table. If not provided, the window height will be 
    used as the "container".
  */
  tableHeight?: number;
  /*
    rowHeight: Table has a set default row height that is static for every row. Use this to override that value.
    NOTE - this is separate from the header and footer rows.
  */
  rowHeight?: number;
  /*
    PRIVATE TABLE PROPS - Injected in from wrapper Table components
  */
  /*
    AG Grid ColDefs, which define the column definitions for the table. These are derived from 
    the wrapper table components.
 */
  colDefs: ColDef<T, keyof T>[];
  /*
   
  */
  rowSelection?: 'single' | 'multiple';
  onSelectedRows?: (event: RowSelectedEvent) => void;
  doNotDebounceRowSelection?: boolean;
};

export const Table = <T,>(props: TableProps<T>) => {
  const gridApi = useRef<GridApi>();
  const columnApi = useRef<ColumnApi>();
  const [tableHeight, setTableHeight] = useState(0);

  const { isMobileView } = useTableGlobalConfiguration();

  // The RowSelect event fires once for EVERY row selected. This means it will
  // fire n number of times if you toggle all on the table. To prevent the browser from
  // melting, debounce the event loop.
  const debouncedRowSelect = useRef(
    debounce<RowSelectedEvent<T>[], void>(async event => {
      if (!props.onSelectedRows) {
        return;
      }

      props.onSelectedRows(event);
    }, 300)
  );

  const updateTableHeight = useCallback(
    (gridApi: GridApi, footerIsHidden?: boolean) => {
      const extraRows = HEADER_HEIGHT * (props.footerData && !footerIsHidden ? 2 : 1);
      let rowCount = 0;
      gridApi.forEachNode(node => {
        if (node.displayed) {
          rowCount += 1;
        }
      });

      // Calculate the height of the table as the minimum of the sum of rows (+ header and possibly footer) and the entire height of the
      // window. This is used to generate a sticky header at the top of the window. autoHeight does not do this.
      const tableHeight = rowCount * ROW_HEIGHT + extraRows;
      const windowHeight = props.tableHeight || window.innerHeight - 25;

      setTableHeight(Math.min(tableHeight, windowHeight));
      // If table is smaller than the window height, let AG Grid auto set the height. Otherwise
      // set height manually so that sticky headers work
      gridApi.setDomLayout(tableHeight < windowHeight ? 'autoHeight' : 'normal');
    },
    [props.footerData, props.tableHeight]
  );

  const autosizeColumnsToFit = useCallback(
    (gridApi: GridApi) => {
      gridApi.sizeColumnsToFit({
        defaultMinWidth: COLUMN_AUTOSIZE_WIDTH,
        // If any given columns have a set width less than the column autosized width, limit their width to what is given.
        // Otherwise autosizing will adjust them to be wider than they should be.
        columnLimits: props.colDefs
          .filter(colDef => colDef.width !== undefined && colDef.width < COLUMN_AUTOSIZE_WIDTH)
          .map<IColumnLimit>(colDef => {
            return { key: colDef.field || '', minWidth: colDef.width, maxWidth: colDef.width };
          })
      });
    },
    [props.colDefs]
  );

  // Resizes columns to fill entire width of screen when the window/browser width changes
  useLayoutEffect(() => {
    if (!gridApi.current) {
      return;
    }

    const autosizeColumns = () => {
      autosizeColumnsToFit(gridApi.current as GridApi);
    };

    window.addEventListener('resize', autosizeColumns);

    return (): void => {
      window.removeEventListener('resize', autosizeColumns);
    };
  }, [autosizeColumnsToFit]);

  useEffect(() => {
    if (!gridApi.current) {
      return;
    }

    autosizeColumnsToFit(gridApi.current);
  }, [autosizeColumnsToFit, props.colDefs]);

  const handleFooterRendering = (gridApi: GridApi): boolean => {
    // Hide footer if any rows are filtered
    const displayRowCount = gridApi.getDisplayedRowCount();
    const hasFilteredRows = displayRowCount < props.rowData.length;

    if (props.footerData) {
      if (hasFilteredRows) {
        gridApi.setPinnedBottomRowData(undefined);
      } else {
        gridApi.setPinnedBottomRowData([props.footerData]);
      }
    }

    return hasFilteredRows;
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;

    if (props.onGridReady) {
      props.onGridReady(gridApi.current, columnApi.current);
    }
  };

  const onSortChanged = (params: SortChangedEvent) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;

    if (props.onSortingChanged) {
      props.onSortingChanged(params);
    }
  };

  const onColumnResized = (params: ColumnResizedEvent) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;

    if (props.onColumnResized) {
      props.onColumnResized(params);
    }
  };

  const onRowDragMove = () => {
    // Clear out any sorting, otherwise row dragging will not work out of the box.
    const columns = columnApi.current?.getColumnState();
    columnApi.current?.applyColumnState({
      state: columns?.map(col => ({
        ...col,
        sort: null
      }))
    });

    if (props.onRowDragMove) {
      props.onRowDragMove();
    }
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    const hasFilteredRows = handleFooterRendering(event.api);

    updateTableHeight(event.api, hasFilteredRows);
    autosizeColumnsToFit(event.api);

    gridApi.current = event.api;
    columnApi.current = event.columnApi;

    if (props.onFirstDataRendered) {
      props.onFirstDataRendered(event);
    }
  };

  const onFilterChange = (event: FilterChangedEvent) => {
    const hasFilteredRows = handleFooterRendering(event.api);

    updateTableHeight(event.api, hasFilteredRows);

    gridApi.current = event.api;
    columnApi.current = event.columnApi;

    if (props.onFilterChanged) {
      props.onFilterChanged(event);
    }
  };

  const onRowDataUpdated = (event: RowDataUpdatedEvent) => {
    updateTableHeight(event.api);
    gridApi.current = event.api;
    columnApi.current = event.columnApi;

    if (props.onRowDataUpdated) {
      props.onRowDataUpdated(event);
    }
  };

  const onCellValueChanged = (event: CellValueChangedEvent) => {
    if (props.onCellValueChanged) {
      props.onCellValueChanged(event);
    }

    gridApi.current = event.api;
    columnApi.current = event.columnApi;
  };

  const onRowSelection = (event: RowSelectedEvent<T>) => {
    if (props.onSelectedRows) {
      props.onSelectedRows(event);
    }
  };

  const getRowHeight = (params: RowHeightParams<T>) => {
    const rowHeight = props.rowHeight ?? ROW_HEIGHT;

    // Sets footer (bottom pinned row) height to match column header
    return params.node.rowPinned === 'bottom' ? HEADER_HEIGHT : rowHeight;
  };

  return (
    <div data-test-id="table" style={{ height: tableHeight }} className="ag-theme-alpine ag-grid-custom">
      <AgGridReact
        defaultColDef={{
          resizable: true,
          suppressMovable: false,
          suppressMenu: true,
          sortingOrder: ['asc', 'desc'],
          sortable: false
        }}
        columnDefs={props.colDefs}
        components={{
          agColumnHeader: ColumnHeader
        }}
        rowSelection={props.rowSelection}
        rowData={props.rowData}
        animateRows
        enableCellChangeFlash
        rowDragManaged={!isMobileView}
        rowDragEntireRow={!isMobileView}
        onCellValueChanged={onCellValueChanged}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onRowDragMove={onRowDragMove}
        onSortChanged={onSortChanged}
        onColumnResized={onColumnResized}
        onFilterChanged={onFilterChange}
        onRowSelected={props.doNotDebounceRowSelection ? onRowSelection : debouncedRowSelect.current}
        getRowHeight={getRowHeight}
        suppressContextMenu
        suppressCellFocus
        suppressRowClickSelection
        suppressPropertyNamesCheck
        onRowDataUpdated={onRowDataUpdated}
        // Defines parent of the filter menu popup
        popupParent={document.querySelector('body')}
      />
    </div>
  );
};
