import React, { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import Popper from '@mui/material/Popper';
import { LiveHelpIcon } from '@clinintell/components/index';
import useIsZendeskTenant from '@clinintell/utils/useIsZendeskTenant';
import { useMenuNavigationState, useUser } from '@clinintell/modules/store';

const ResourceMenuButton: React.FC = () => {
  const { menuIsExpanded, menuItems } = useMenuNavigationState();
  const { hasUniversityAccess } = useUser();
  const resourcesMenuData = menuItems.find(menuItem => menuItem.name === 'resources');
  const isZendeskTenant = useIsZendeskTenant();
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);

  const handleResourcesClick = () => {
    window.open(HELP_CENTER_URL, '_blank');
  };

  const handleContactSupportClick = () => {
    window.open('https://help.clinintell.com/hc/en-us/requests/new', '_blank');
  };

  const handleLearnUponClick = () => {
    window.open('https://clinintell.learnupon.com/', '_blank');
  };

  const handlePopupMenuClick = () => setMenuOpen(val => !val);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    } else if (event.key === 'Escape') {
      setMenuOpen(false);
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setMenuOpen(false);
  };
  const prevOpen = React.useRef(menuOpen);
  useEffect(() => {
    if (prevOpen.current === true && menuOpen === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  return (
    <>
      {resourcesMenuData && isZendeskTenant ? (
        <span>
          <Box
            ref={anchorRef}
            component="div"
            onClick={handlePopupMenuClick}
            aria-controls={menuOpen ? 'composition-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            sx={{
              py: 1.5,
              px: menuIsExpanded ? 2 : 3,
              ml: menuIsExpanded ? 0 : -0.75,
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '20px',
              gap: 1,
              backgroundColor: menuOpen ? 'grey.200' : 'grey.100',
              color: 'grey.500',
              textDecoration: 'none',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'grey.200'
              }
            }}
          >
            <LiveHelpIcon
              sx={{
                height: 21,
                width: 18,
                color: 'grey.500'
              }}
            />
            {menuIsExpanded && (
              <Typography variant={'body1'} sx={{ fontWeight: 'bold' }}>
                Resources
              </Typography>
            )}
          </Box>
        </span>
      ) : null}
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                  {hasUniversityAccess && (
                    <MenuItem onClick={handleLearnUponClick} sx={{ '&:hover': { backgroundColor: 'grey.50' } }}>
                      ClinIntell University
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleResourcesClick} sx={{ '&:hover': { backgroundColor: 'grey.50' } }}>
                    Help Center
                  </MenuItem>
                  <MenuItem onClick={handleContactSupportClick} sx={{ '&:hover': { backgroundColor: 'grey.50' } }}>
                    Contact Support
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ResourceMenuButton;
