import { Box, Button } from '@mui/material';
import React from 'react';
import ThreeDotLoadingAnimation from '@clinintell/components/ThreeDotLoadingAnimation';
import { ChevronRight } from '@mui/icons-material';
import { TreeNode } from '@clinintell/modules/orgTree';

type NodeLabelProps = {
  item: TreeNode;
  isLoading: boolean;
  onClick: (id: number, parentId?: number) => void;
  onLoadClick: (id: number, parentId?: number) => void;
  isActive: boolean;
  overrideNoIndentation: boolean;
  overrideNoChildren: boolean;
};

const NodeLabel: React.FC<NodeLabelProps> = ({
  item,
  isLoading,
  onClick,
  onLoadClick,
  isActive,
  overrideNoIndentation,
  overrideNoChildren
}): JSX.Element => {
  const isUnloadedChildren = item.children.length === 0 && !item.isLeaf;

  // if element is a leaf, it needs some whitespace to indent
  const spacer: JSX.Element = overrideNoIndentation ? <Box component="span" ml={2} mr={1} /> : <Box component="span" />;

  return (
    <Box display="flex">
      {isUnloadedChildren && !overrideNoChildren ? (
        <Box
          role="button"
          onClick={(): void => onLoadClick(item.id, item.parentId || undefined)}
          sx={{
            padding: 0,
            width: '1.7rem',
            backgroundColor: 'shade.white',
            border: 'none',
            outline: 'none',
            '&:focus': {
              outline: 'none',
              boxShadow: 'none',
              border: 'none',
              backgroundColor: '#d7eaf922'
            },
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        >
          {isLoading ? (
            <ThreeDotLoadingAnimation sx={{ mt: 0.5 }} />
          ) : (
            <ChevronRight
              sx={{
                mt: 1,
                ml: '2px',
                fontSize: '18px',
                stroke: theme => theme.palette.grey[500],
                strokeWidth: '0.25px'
              }}
            />
          )}
        </Box>
      ) : (
        spacer
      )}
      <Button
        onClick={(event): void => {
          onClick(item.id, item.parentId || undefined);
          event.stopPropagation();
          event.preventDefault();
        }}
        sx={{
          justifyContent: 'flex-start',
          textTransform: 'inherit',
          marginLeft: isUnloadedChildren ? '2px' : '0',
          textAlign: 'left',
          color: isActive ? 'teal.main' : 'dark.50',
          '&:hover': {
            color: 'teal.main'
          }
        }}
      >
        <Box
          sx={{
            typography: 'p2',
            fontWeight: 400
          }}
        >
          {item.name}
        </Box>
      </Button>
    </Box>
  );
};

export default NodeLabel;
