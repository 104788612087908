import { SummaryRecord } from '@clinintell/modules/cmiAnalysis';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';
import ResultSummaryTable from '../../../components/analysisSharedComponents/ResultSummaryTable';
import { SeverityCMIAnalysisJSON } from '@clinintell/containers/cmiAnalysis/types/SevCMIGroup';

type SeverityCMIResultSummaryProps = {
  severityCMISummary: SummaryRecord;
  severityCMIAnalysis: SeverityCMIAnalysisJSON;
};

const SeverityCMIResultSummary: React.FC<SeverityCMIResultSummaryProps> = ({
  severityCMISummary,
  severityCMIAnalysis
}) => {
  const roundedTotalPositiveCmiImpact = Number(formatToDecimalPlaces(severityCMIAnalysis.totalPositiveCmiImpact, 2));
  const roundedTotalNegativeCmiImpact = Number(formatToDecimalPlaces(severityCMIAnalysis.totalNegativeCmiImpact, 2));

  const roundedChange =
    severityCMISummary.current && severityCMISummary.historical
      ? `${formatToDecimalPlaces(
          Number(formatToDecimalPlaces(severityCMISummary.current, 2)) -
            Number(formatToDecimalPlaces(severityCMISummary.historical, 2)),
          2
        )}`
      : '-';

  const totalDifference = roundedTotalPositiveCmiImpact + roundedTotalNegativeCmiImpact;
  const roundedTotalImpact = roundedChange !== '-' ? totalDifference / Number(roundedChange) : null;
  const roundedTotalImpactLabel =
    roundedTotalImpact !== null ? `(${formatToDecimalPlaces(Math.min(roundedTotalImpact * 100, 100), 0)}%)` : '';

  return (
    <ResultSummaryTable
      rows={[
        {
          columns: [
            {
              value: '',
              width: 4,
              align: 'center',
              bold: true,
              key: 'header1'
            },
            {
              value: 'Historical',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerHistorical'
            },
            {
              value: 'Current',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerCurrent'
            },
            {
              value: 'Change',
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerChange'
            }
          ],
          key: 'headerRow'
        },
        {
          divider: 'solid',
          key: 'headerDivider'
        },
        {
          columns: [
            {
              value: 'Severity Value',
              width: 4,
              align: 'left',
              bold: true,
              key: 'sevCmiLabel'
            },
            {
              value: severityCMISummary.historical ? formatToDecimalPlaces(severityCMISummary.historical, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'sevCmiHist'
            },
            {
              value: severityCMISummary.current ? formatToDecimalPlaces(severityCMISummary.current, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'sevCmiCurr'
            },
            {
              value:
                severityCMISummary.current && severityCMISummary.historical
                  ? `${formatToDecimalPlaces(
                      Number(formatToDecimalPlaces(severityCMISummary.current, 2)) -
                        Number(formatToDecimalPlaces(severityCMISummary.historical, 2)),
                      2
                    )}`
                  : '-',
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'sevCmiDiff'
            }
          ],
          key: 'sevCmiRow'
        },
        {
          divider: 'solid',
          key: 'sevCMIDivider'
        },
        {
          columns: [
            {
              value: 'Significant Positive Impact',
              width: 8,
              align: 'right',
              key: 'positiveImpactTitle',
              tooltip:
                'The sum of all DRG conditions that had a 0.002 or more positive impact on the severity component of CMI.',
              bold: true,
              rowLabelNoEllipsis: true
            },
            {
              value: formatToDecimalPlaces(severityCMIAnalysis.totalPositiveCmiImpact, 2),
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'positiveImpactValue'
            }
          ],
          key: 'positiveImpactRow'
        },
        {
          columns: [
            {
              value: 'Significant Negative Impact',
              width: 8,
              align: 'right',
              key: 'negativeImpactTitle',
              tooltip:
                'The sum of all DRG conditions that had a -0.002 or more negative impact on the severity component of CMI.',
              bold: true,
              rowLabelNoEllipsis: true
            },
            {
              value: formatToDecimalPlaces(severityCMIAnalysis.totalNegativeCmiImpact, 2),
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'negativeImpactValue',
              underline: true
            }
          ],
          key: 'negativeImpactRow'
        },
        {
          columns: [
            {
              value: 'Net Impact',
              width: 8,
              align: 'right',
              key: 'totalLabel',
              tooltip: 'The sum of all DRG conditions that had a significant impact on the severity component of CMI.',
              bold: true,
              rowLabelNoEllipsis: true
            },
            {
              value: `${formatToDecimalPlaces(totalDifference, 2)} ${roundedTotalImpactLabel}`,
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'totalValue'
            }
          ],
          key: 'totalRow'
        }
      ]}
    />
  );
};

export default SeverityCMIResultSummary;
