import {
  eachMonthOfInterval,
  isBefore,
  parseISO,
  formatISO,
  addYears,
  subYears,
  subMonths,
  startOfMonth,
  addDays,
  addMonths
} from 'date-fns';
import { parseDateIntoPeriod } from './formatting';
import { format } from 'date-fns';
import usLocale from 'date-fns/locale/en-US';
import { SelectOptionType } from '@clinintell/types/common';

// export type DateOption = {
//   display: string; // like July20 - utils/formatting/parseDateintoPeriod
//   value: string; // like 2020-07-01T00:00:00-05:00 (ISO8601) - date-fns/formatISO
// };

export const makeMonthYearList = (start: string, end: string): SelectOptionType[] => {
  try {
    const startDate = parseISO(start);
    const endDate = parseISO(end);
    return eachMonthOfInterval({ start: startDate, end: endDate })
      .sort((a, b) => {
        return isBefore(a, b) ? 0 : -1;
      })
      .map(v => {
        return {
          label: parseDateIntoPeriod(v),
          value: formatISO(v),
          useLabelForTestId: true
        };
      });
  } catch (e) {
    console.log('error', start, end, 'did not make date range');
    return [{ label: 'dates error', value: 'error' }];
  }
};

export const subMonthsFromDate = (date: Date, numberOfMonths: number): Date => {
  return startOfMonth(subMonths(date, numberOfMonths));
};

export const addMonthsToDate = (date: Date, numberOfMonths: number): Date => {
  return addMonths(date, numberOfMonths);
};

export const formatDate = (date: Date): string => {
  return format(date, 'MM/dd/yyyy hh:mm:ss', { locale: usLocale });
};

export const formatDateSlash = (date: Date): string => {
  return format(date, 'MM/dd/yyyy', { locale: usLocale }).replace(/\b0/g, '');
};

export const getNewDate = (): string => {
  return format(new Date(), 'MM/dd/yyyy hh:mm:ss', { locale: usLocale });
};

export const getCurrentDateInUTC = (): string => {
  return format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
};

export const addYearsToDate = (date: Date, numberOfYears: number): string => {
  const result = addYears(date, numberOfYears);
  return format(result, 'MM/dd/yyyy hh:mm:ss', { locale: usLocale });
};

export const subractYearsToDate = (date: Date, numberOfYears: number): string => {
  const result = addDays(subYears(date, numberOfYears), 1);
  return format(result, 'MM/dd/yyyy hh:mm:ss', { locale: usLocale });
};

export const subtractMonthsToDate = (date: Date, numberOfMonths: number): string => {
  const result = addDays(subMonths(date, numberOfMonths), 1);
  return format(result, 'MM/dd/yyyy hh:mm:ss', { locale: usLocale });
};

export const checkDateRange = (earliestDate: string, latestDate: string, date: string): boolean => {
  const dateSelected = new Date(date);
  const earliestDateAllowed = new Date(earliestDate);
  const latestDateAllowed = new Date(latestDate);

  return latestDateAllowed > dateSelected && earliestDateAllowed <= dateSelected ? true : false;
};

export const isDateInRange = (date: Date, startDate: Date, endDate: Date): boolean => {
  const oneYearRange = new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate());

  return date >= startDate && date <= endDate && date < oneYearRange;
};
