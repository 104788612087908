import { createBlob } from '@clinintell/containers/metrics/sections/Download';
import { OrgTreeTypes } from '@clinintell/modules/orgTree';
import { ApplicationAPI } from '@clinintell/utils/api';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { useState } from 'react';

type UsePDFDownloadProps = {
  entityId: number;
  compareToEntityId?: number;
  chartMinDate: Date;
  chartMaxDate: Date;
  historicalMinDate?: Date;
  historicalMaxDate?: Date;
  currentMinDate: Date;
  currentMaxDate: Date;
  analysisType: 'analysis' | 'comparison';
  filename: string;
  orgTreeType?: OrgTreeTypes;
};

type UsePDFDownloadOutput = {
  isDownloading: boolean;
  downloadPDF: (args: UsePDFDownloadProps) => Promise<void>;
};

const usePDFDownload = (): UsePDFDownloadOutput => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadPDF = async ({
    entityId,
    historicalMinDate,
    historicalMaxDate,
    currentMaxDate,
    currentMinDate,
    analysisType,
    compareToEntityId,
    filename,
    chartMinDate,
    chartMaxDate,
    orgTreeType = 'client'
  }: UsePDFDownloadProps): Promise<void> => {
    setIsDownloading(true);

    const metricEndpoint = analysisType === 'analysis' ? 'patientmixanalysisreport' : 'cmicomparisonreport';
    let dateQuery = `periodStart=${formatDateForAPI(chartMinDate)}&periodEnd=${formatDateForAPI(chartMaxDate)}`;
    dateQuery +=
      historicalMinDate && historicalMaxDate
        ? `&historicalStart=${formatDateForAPI(historicalMinDate)}
        &historicalEnd=${formatDateForAPI(historicalMaxDate)}&currentStart=${formatDateForAPI(currentMinDate)}
        &currentEnd=${formatDateForAPI(currentMaxDate)}`
        : `&rangeStart=${formatDateForAPI(currentMinDate)}&rangeEnd=${formatDateForAPI(currentMaxDate)}`;

    const endpoint = `${orgTreeType === 'saf' ? 'saf/' : ''}download/metrics/${metricEndpoint}/${entityId}${
      compareToEntityId !== undefined ? `/${compareToEntityId}` : ''
    }?${dateQuery}`;

    try {
      const result = await ApplicationAPI.download(endpoint, 'pdf');
      createBlob(result.data, `${filename}.pdf`);
    } catch (error) {
      throw new Error(`Error download to PDF - ${error}`);
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    isDownloading,
    downloadPDF
  };
};

export default usePDFDownload;
