import { useEffect, useState } from 'react';
import { useAuth0Config } from '@clinintell/modules/store';

export const useClinIntellAuthWidget = (
  options: Auth0LockConstructorOptions,
  shouldImport: boolean
): Auth0LockStatic | null => {
  const [authWidget, setAuthWidget] = useState<Auth0LockStatic | null>(null);
  const { clientId, endpointUrl, tenant } = useAuth0Config();

  useEffect(() => {
    if (!clientId || !shouldImport) {
      return;
    }

    const dynamicImport = async (): Promise<void> => {
      // TODO -- how do you type imports?
      // eslint-disable-next-line
      // @ts-ignore
      const { Auth0Lock } = await import(/* webpackChunkName: "auth0-lock", webpackPrefetch: true */ 'auth0-lock');

      const lockOptions = {
        ...options,
        overrides: {
          __tenant: tenant,
          // Have to conform to Auth0 config options naming conventions
          __token_issuer: endpointUrl
        },
        configurationBaseUrl: 'https://cdn.us.auth0.com'
      };

      setAuthWidget(new Auth0Lock(clientId, endpointUrl, lockOptions));
    };

    dynamicImport();
  }, [clientId, endpointUrl, options, shouldImport, tenant]);

  return authWidget;
};
