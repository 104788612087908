import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SideMenuExpand = (props: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{
        width: '36px',
        height: '24px',
        ...props.sx
      }}
      viewBox="0 0 36 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15 18H33" stroke="inherit" fill="none" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 12H33" stroke="inherit" fill="none" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 6H33" stroke="inherit" fill="none" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 9L7.5 12L4.5 15" stroke="inherit" fill="none" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default SideMenuExpand;
