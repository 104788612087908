import React, { useCallback, useEffect, useState, useRef } from 'react';
import { IHeaderParams } from 'ag-grid-community/dist/lib/main';
import { useTableGlobalConfiguration } from '../context/TableGlobalConfigurationContext';
import { ColumnDef } from '../types/table';

const ColumnHeader: React.FC<IHeaderParams> = ({
  showColumnMenu,
  column,
  setSort,
  enableMenu,
  enableSorting,
  displayName
}): JSX.Element => {
  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');

  const refButton = useRef<HTMLDivElement>(null);
  const [filterActive, setFilterActive] = useState(false);

  const { renderTooltipComponent } = useTableGlobalConfiguration();

  const onMenuClicked = (): void => {
    showColumnMenu(refButton.current as HTMLDivElement);
  };

  const onSortChanged = useCallback(() => {
    setAscSort(column.isSortAscending() ? 'active' : 'inactive');
    setDescSort(column.isSortDescending() ? 'active' : 'inactive');
  }, [setAscSort, setDescSort, column]);

  const onFilterActiveChanged = useCallback(() => {
    setFilterActive(column.isFilterActive());
  }, [column]);

  const onSortRequested = (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent): void => {
    const propSort = column.getSort();
    setSort(propSort === 'asc' ? 'desc' : 'asc', event.shiftKey);
  };

  useEffect(() => {
    column.addEventListener('filterActiveChanged', onFilterActiveChanged);
    column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();

    return (): void => {
      column.removeEventListener('sortChanged', onSortChanged);
      column.removeEventListener('filterActiveChanged', onFilterActiveChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let menu = null;
  if (enableMenu) {
    menu = (
      <div
        ref={refButton}
        role="button"
        tabIndex={-1}
        className="ag-header-icon ag-header-cell-menu-button ag-header-menu-always-show"
        onClick={onMenuClicked}
        onKeyPress={onMenuClicked}
        onTouchEnd={onMenuClicked}
      >
        <span className="ag-icon ag-icon-menu" role="presentation" />
      </div>
    );
  }

  let sort = null;
  if (enableSorting) {
    sort = (
      <>
        <span
          style={{ marginRight: '8px', display: ascSort === 'inactive' ? 'none' : 'inline-block' }}
          onClick={onSortRequested}
          onTouchEnd={onSortRequested}
          className={`ag-header-icon ag-header-label-icon ag-sort-ascending-icon`}
          role="presentation"
        >
          <span className="ag-icon ag-icon-asc" role="presentation" />
        </span>
        <span
          style={{ marginRight: '8px', display: descSort === 'inactive' ? 'none' : 'inline-block' }}
          onClick={onSortRequested}
          onTouchEnd={onSortRequested}
          className={`ag-header-icon ag-header-label-icon ag-sort-descending-icon`}
          role="presentation"
        >
          <span className="ag-icon ag-icon-desc" role="presentation" />
        </span>
        <span className="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden" aria-hidden="true">
          <span className="ag-icon ag-icon-none" unselectable="on" role="presentation" />
        </span>
      </>
    );
  }

  const userColDef = column.getUserProvidedColDef() as ColumnDef<unknown, never>;

  return (
    <div className="custom-header-container">
      <div className="custom-header-subcontainer">
        <div
          style={{ display: 'flex', justifyContent: userColDef?.headerAlignment === 'left' ? 'flex-start' : 'center' }}
          onClick={(event): void => {
            if (!enableSorting) {
              return;
            }

            onSortRequested(event);
          }}
          onTouchEnd={(event): void => {
            if (!enableSorting) {
              return;
            }

            onSortRequested(event);
          }}
          className="ag-header-cell-label"
          role="presentation"
        >
          <>
            <span className="ag-header-cell-text" style={{ ...userColDef?.headerStyles }} unselectable="on">
              {displayName}
            </span>
            {userColDef?.headerTooltip && renderTooltipComponent(userColDef.headerTooltip)}
          </>
          {filterActive && (
            <span style={{ marginLeft: '16px' }} className="ag-header-icon ag-header-label-icon ag-filter-icon">
              <span className="ag-icon ag-icon-filter" unselectable="on" role="presentation" />
            </span>
          )}
          {sort}
        </div>
      </div>
      {menu}
    </div>
  );
};

export default ColumnHeader;
