import { Box } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import useSubFilters from './logic/useSubFilters';
import useHospitalFilters from './logic/useHospitalFilters';
import StatusReportFilter from './sections/StatusReportFilter';
import { TrainingProgressEntity, TrainingProgressRouteProps } from '../../types/routeProps';
import { SelectOptionType } from '@clinintell/types/common';
import { EntityReturnType } from '@clinintell/containers/common/index';
import { TreeJSON } from '@clinintell/modules/orgTree';

type TrainingProgressFilterProps = {
  onFilterChanged: (filters: TrainingProgressRouteProps) => void;
  defaultHospital?: TrainingProgressEntity;
  defaultGroup?: TrainingProgressEntity;
  defaultPhysician?: TrainingProgressEntity;
};

const TrainingProgressFilter: React.FC<TrainingProgressFilterProps> = ({
  onFilterChanged,
  defaultHospital,
  defaultGroup,
  defaultPhysician
}) => {
  const { options: hospitalOptions, isLoading } = useHospitalFilters();

  const [filters, setFilters] = useState<TrainingProgressRouteProps>(
    defaultHospital !== undefined
      ? ({ hospital: defaultHospital, group: defaultGroup, physician: defaultPhysician } as TrainingProgressRouteProps)
      : { hospital: { name: '' } }
  );

  const selectedGroup = filters.group ? filters.group.name : '';
  const selectedPhysician = filters.physician ? filters.physician.name : '';
  const { isLoading: groupsLoading, fetchSubFilters: fetchGroups, options: groupOptions } = useSubFilters();
  const { isLoading: physiciansLoading, fetchSubFilters: fetchPhysicians, options: physicianOptions } = useSubFilters();

  const memoizedFilterChange = useCallback((filters: TrainingProgressRouteProps) => onFilterChanged(filters), [
    onFilterChanged
  ]);

  const handleHospitalChange = async (option: SelectOptionType): Promise<void> => {
    if (!option) return;

    if (option.label === 'All Hospitals') {
      setFilters(filters => ({
        ...filters,
        hospital: { name: option.label, id: Number(option.value) },
        group: undefined,
        physician: undefined
      }));

      return;
    }

    // Fetch groups
    const fetchGroupsResult = (await fetchGroups(Number(option.value))) as EntityReturnType;
    // Extract the Groups from the object
    const groups = fetchGroupsResult?.physicianGroups.filter(group => group.trainingUsers);
    if (!groups) {
      return;
    }

    // More than 1 group found, so set value to All and nothing needed regarding physicians. If 0 found, just do the same thing this shouldn't happen
    if (groups.length > 1) {
      if (defaultGroup) {
        // use the group option since the groupId will be different for each hospital
        const groupFound = groups.find(g => g.physicianGroupName === defaultGroup.name);
        const groupOption = groupFound
          ? { name: groupFound.physicianGroupName, id: Number(groupFound.id) }
          : { name: 'All' };
        setFilters({
          hospital: { name: option.label, id: Number(option.value) },
          group: groupOption,
          physician: undefined
        });
      } else {
        setFilters({
          hospital: { name: option.label, id: Number(option.value) },
          group: { name: 'All' },
          physician: undefined
        });
      }

      return;
    }

    const groupFilterState: TrainingProgressEntity = { id: groups[0].id, name: groups[0].physicianGroupName };
    // Only 1 group found. Set that value as the group filter state and fetch physicians
    const fetchPhysiciansResult = (await fetchPhysicians(groups[0].id, 'physician')) as TreeJSON;

    const physicians = fetchPhysiciansResult?.children;
    if (!physicians) {
      return;
    }

    const physicianFilterState: TrainingProgressEntity =
      physicians.length === 1 ? { id: physicians[0].id, name: physicians[0].name } : { name: 'All' };

    setFilters(filters => ({
      ...filters,
      hospital: { name: option.label, id: Number(option.value) },
      group: groupFilterState,
      physician: physicianFilterState
    }));
  };

  const handleGroupChange = async (option: SelectOptionType): Promise<void> => {
    if (option.value === -1) {
      setFilters(filters => ({
        ...filters,
        group: { name: option.label },
        physician: undefined
      }));

      return;
    }

    // Fetch physicians
    const fetchPhysiciansResult = (await fetchPhysicians(Number(option.value), 'physician')) as TreeJSON;
    const physicians = fetchPhysiciansResult?.children;
    if (!physicians) {
      return;
    }

    const physicianFilterState: TrainingProgressEntity =
      physicians.length === 1 ? { name: physicians[0].name, id: physicians[0].id } : { name: 'All' };

    setFilters(filters => ({
      ...filters,
      group: { name: option.label, id: Number(option.value) },
      physician: physicianFilterState
    }));
  };

  const handlePhysicianChange = (option: SelectOptionType) => {
    if (option.value === -1) {
      setFilters(filters => ({
        ...filters,
        physician: { name: option.label }
      }));

      return;
    }

    setFilters(filters => ({
      ...filters,
      physician: { name: option.label, id: Number(option.value) }
    }));
  };

  const loadDefaultFilters = useCallback(async () => {
    // We have a list of more than 1 hospital and no default hospital passed in. For now we can end the chain as fetching groups
    // won't occur until
    if (hospitalOptions.length > 1 && !defaultHospital) {
      const hospitalAllOption = hospitalOptions.find(option => option.label === 'All Hospitals');
      if (!hospitalAllOption) {
        throw new Error(
          'Cannot find hospital option with label of "All Hospitals", check and make sure this was not changed'
        );
      }

      // Fetch parent system ID, as that will be the report's default ID in this case - we will show reports at the system level
      setFilters(filtersState => ({
        ...filtersState,
        hospital: { name: hospitalAllOption?.label, id: Number(hospitalAllOption.value) }
      }));
      return;
    }

    // If 1 hospital it is by default the selected hospital, otherwise we can use the passed in optional default hospital.
    // In either case, fetch groups next
    const defaultHospitalState: TrainingProgressEntity =
      hospitalOptions.length === 1
        ? { name: hospitalOptions[0].label, id: Number(hospitalOptions[0].value) }
        : (defaultHospital as TrainingProgressEntity);

    if (defaultHospitalState.id === undefined) {
      return;
    }

    if (defaultHospitalState.id === -1) return;

    const fetchGroupsResult = (await fetchGroups(defaultHospitalState.id)) as EntityReturnType;
    // If no groups found, just end the chain here and set the hospital filter state
    const groups = fetchGroupsResult?.physicianGroups.filter(group => group.trainingUsers);
    if (!groups) {
      setFilters(filtersState => ({
        ...filtersState,
        hospital: defaultHospitalState
      }));

      return;
    }

    // We have a list of more than 1 group and no default group passed in. For now we can end the chain as fetching physicians
    // won't occur until
    if (groups.length > 1 && !defaultGroup) {
      setFilters(filtersState => ({
        ...filtersState,
        hospital: defaultHospitalState,
        group: { name: 'All' }
      }));

      return;
    }

    // If 1 group it is by default the selected group, otherwise we can use the passed in optional group
    // In either case, fetch physicians next
    const defaultGroupState: TrainingProgressEntity =
      groups.length === 1
        ? { name: groups[0].physicianGroupName, id: groups[0].id }
        : (defaultGroup as TrainingProgressEntity);

    if (defaultGroupState === undefined || defaultGroupState.id === undefined) {
      return;
    }

    const fetchPhysiciansResult = (await fetchPhysicians(defaultGroupState.id, 'physician')) as TreeJSON;

    // If no physicians found, just end the chain here and set the hospital and group filter state
    const physicians = fetchPhysiciansResult?.children;
    if (!physicians) {
      setFilters(filtersState => ({
        ...filtersState,
        hospital: defaultHospitalState,
        group: defaultGroupState
      }));

      return;
    }

    // We have a list of more than 1 physician and no default physician passed in. Set physician value to All
    if (physicians.length > 1 && !defaultPhysician) {
      setFilters(filtersState => ({
        ...filtersState,
        hospital: defaultHospitalState,
        group: defaultGroupState,
        physician: { name: 'All' }
      }));

      return;
    }

    // If 1 physician it is by default the selected physician, otherwise we can use the passed in optional default physician
    const defaultPhysicianState: TrainingProgressEntity | undefined =
      physicians.length === 1 ? { name: physicians[0].name, id: physicians[0].id } : defaultPhysician;

    setFilters(filtersState => ({
      ...filtersState,
      hospital: defaultHospitalState,
      group: defaultGroupState,
      physician: defaultPhysicianState
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchGroups, fetchPhysicians, hospitalOptions]);

  // Once hospitals are loaded up in state, set off an onChange event to set a default value for the hospitals dropdown
  useEffect(() => {
    if (hospitalOptions.length === 0) {
      return;
    }

    loadDefaultFilters();
  }, [hospitalOptions.length, loadDefaultFilters]);

  useEffect(() => {
    memoizedFilterChange(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <Box
        marginBottom={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box component="label">View Training Progress By</Box>
        <StatusReportFilter
          onChange={handleHospitalChange}
          value={filters.hospital.name}
          filterOptions={hospitalOptions}
          loading={isLoading}
          disabled={hospitalOptions.length <= 1}
        />
      </Box>
      <Box
        marginBottom={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box component="label">Physician Group</Box>
        <StatusReportFilter
          value={selectedGroup}
          filterOptions={groupOptions}
          onChange={handleGroupChange}
          disabled={!filters.hospital.name || filters.hospital.name === 'All Hospitals' || groupOptions.length === 1}
          loading={groupsLoading}
        />
      </Box>
      <Box
        marginBottom={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box component="label">Provider</Box>
        <StatusReportFilter
          onChange={handlePhysicianChange}
          value={selectedPhysician}
          filterOptions={physicianOptions}
          disabled={!selectedGroup || selectedGroup === 'All' || physicianOptions.length === 1}
          loading={physiciansLoading}
        />
      </Box>
    </>
  );
};

export default React.memo(TrainingProgressFilter);
