import { XAxisOptions } from '@clinintell/components/Chart/types';

const generateXAxes = (tickStart: number, tickEnd: number): XAxisOptions[] => [
  {
    scaleLabel: {
      display: true,
      labelString: '',
      fontSize: 20
    },
    gridLines: {
      display: true,
      drawOnChartArea: false,
      drawBorder: true,
      fontSize: 14,
      color: 'black'
    },
    ticks: {
      display: true,
      autoSkip: false,
      drawTicks: true,
      fontSize: 14,
      fontColor: 'black',
      padding: 10,
      callback(value: number, index: number): string | null {
        return index >= tickStart && index <= tickEnd ? `${value}` : null;
      }
    }
  }
];

export default generateXAxes;
