// This only works with the Material UI Tree View as it references specific classes
const findVerticalPositionOfNodeInTree = (node: HTMLElement): number => {
  let offsetTop = 0;
  let treeNode: HTMLElement | null = node;
  while (treeNode && !treeNode.classList.contains('MuiTreeView-root')) {
    if (treeNode.tagName === 'DIV' && treeNode.hasAttribute('id')) {
      offsetTop += treeNode.offsetTop;
    }

    treeNode = treeNode.parentElement;
  }

  return offsetTop;
};

export default findVerticalPositionOfNodeInTree;
