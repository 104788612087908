import { useErrorBoundary } from 'react-error-boundary';

import { showErrorMessage, captureErrorSilently } from './utils';

export interface IUseErrorHandlingOutput {
  /**
   * NOTE: should be used for non-recoverable errors (something the user cant fix)
   *
   * Rethrows the error to be caught and handled at the Error Boundary
   * - will cause the app to display the 'Something Went Wrong' screen
   * - will report the error to Sentry
   * @param error
   * @returns
   *
   * @example
   * // simple
   * doSomethingAsync().catch(throwToErrorBoundary)
   *
   * // with custom error (see errors/customErrors)
   * try {
   *  doSomethingAsync();
   * } catch(error) {
   *   throwToErrorBoundary(new CustomError(error))
   * }
   */
  throwToErrorBoundary: (error: Error | unknown) => void;

  showErrorMessage: typeof showErrorMessage;

  captureErrorSilently: typeof captureErrorSilently;
}

/**
 * Utilities for handling errors in the application
 * - see docs on each callback for details and usage
 * @returns callbacks for handling errors
 */
export const useErrorHandling = (): IUseErrorHandlingOutput => {
  const { showBoundary } = useErrorBoundary();

  return {
    showErrorMessage,
    captureErrorSilently,
    throwToErrorBoundary: showBoundary
  };
};

export default useErrorHandling;
