import { Auth0Client } from '@auth0/auth0-spa-js';
import { useAuthenticationStatusDispatch } from '@clinintell/modules/store';
import { useEffect } from 'react';

const useSetAuthenticationStatus = (auth0Client: Auth0Client | undefined): void => {
  const authDispatch = useAuthenticationStatusDispatch();

  useEffect(() => {
    if (!auth0Client) {
      return;
    }

    const silentlyAuthenticate = async (): Promise<void> => {
      try {
        await auth0Client.getTokenSilently();

        authDispatch({
          type: 'SET_AUTHENTICATED',
          payload: auth0Client
        });
        // getTokenSilently method hides all errors except for login required, so we can assume if an exception, the user has to login
      } catch (exception) {
        authDispatch({
          type: 'SET_UNAUTHENTICATED'
        });
      }
    };

    silentlyAuthenticate();
  }, [auth0Client, authDispatch]);
};

export default useSetAuthenticationStatus;
