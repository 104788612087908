import { buildSentryTagsFromUser, shapeSentryUser } from './sentryScopeUtils';

import { ReportToSentryContext } from './utils';
import { AuthorizedUserDto } from '@clinintell/api/apiSchemas';
import { ErrorWrapper, IRequestErrorDetails } from '@clinintell/api/apiFetcher';

export interface ICustomError {
  name: string;
  details: string;
  originalError: unknown;
  displayTimeMs: number;
  sentryContext: ReportToSentryContext;
}

export class CustomError extends Error implements ICustomError {
  name = 'UnknownError';

  message = 'An unexpected error has occurred';

  details = 'Details have been forwarded to our team for resolution';

  displayTimeMs = 8000;

  originalError: unknown = null;

  sentryContext: ReportToSentryContext = { extra: {}, tags: {}, user: {} };

  constructor(originalError?: unknown) {
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    if (originalError) {
      this.originalError = originalError;
      this.sentryContext.extra = { ...this.sentryContext.extra, originalError };

      if (originalError instanceof ApiRequestError) {
        // strip out the nested context if the ApiRequestError was wrapped by another CustomError
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore 2771
        delete this.originalError.sentryContext;
      }
    }
  }

  addSentryContext(additionalContext: ReportToSentryContext) {
    // this ensures all information is preserved and merged consistently
    this.sentryContext = {
      extra: {
        ...(this.sentryContext.extra || {}),
        ...(additionalContext.extra || {})
      },
      tags: {
        ...(this.sentryContext.tags || {}),
        ...(additionalContext.tags || {})
      },
      user: {
        ...(this.sentryContext.user || {}),
        ...(additionalContext.user || {})
      }
    };
  }
}

export const isCustomError = (error: Error | unknown) => error instanceof CustomError;

export class ProviderOrgHierarchyError extends CustomError {
  name = 'ProviderOrgHierarchyError';

  message = 'Provider identity not found';

  details = 'Contact ClinIntell to fix your user account';
}

export class AuthenticationError extends CustomError {
  name = 'AuthenticationError';

  message = 'Error authenticating with identity provider';

  details = 'Contact ClinIntell to fix your user account';
}

export class UnsupportedRoleError extends CustomError {
  name = 'UnsupportedRoleError';

  message = 'User has a role that is not supported by the application';

  constructor(userContext: AuthorizedUserDto) {
    super();

    // set the role name as the message for improving grouping on Sentry
    this.message = userContext.role?.roleName || 'Unknown role';

    const user = shapeSentryUser(userContext);
    const tags = buildSentryTagsFromUser(user);

    this.addSentryContext({ user, tags });
  }
}

export class ApiRequestError extends CustomError {
  name = 'ApiRequestError';

  message = 'Network request failed';

  endpoint: string;

  statusCode?: number;

  constructor(originalError: ErrorWrapper, apiRequestDetails: IRequestErrorDetails) {
    super(originalError);

    this.endpoint = `${apiRequestDetails.method} ${apiRequestDetails.path}`;

    this.statusCode = originalError?.status;

    this.addSentryContext({
      tags: {
        apiStatusCode: this.statusCode
      },
      extra: {
        apiRequestDetails
      }
    });
  }
}

export class AbortedApiRequestError extends ApiRequestError {
  name = 'AbortedApiRequestError';

  message = 'API request aborted';
}

export class InvalidSessionError extends CustomError {
  name = 'InvalidSessionError';

  message = 'Your user session has ended and will need you to sign in again';

  details = '';

  constructor(details: string) {
    super();

    this.details = details;
  }
}
