export interface QueryStringParam {
  key: string;
  value: string;
}

// Parses input string using URLSearchParams web API, and returns any querystring parameters as an array of key/values. Returns empty array if no params exist.
export const parseQueryString = (input: string, indicator = '?'): QueryStringParam[] => {
  if (!input) {
    return [];
  }

  // URLSearchParams constructor doesn't snip off anything before the ? character
  const [, querystring] = input.split(indicator);
  if (!querystring) {
    return [];
  }

  const searchParams = new URLSearchParams(querystring);
  const outputParams: QueryStringParam[] = [];
  searchParams.forEach((value, key) => {
    outputParams.push({
      key,
      value
    });
  });

  return outputParams;
};

// Creates string using URLSearchParams web API using the given input string and array of key/values. Each key/value is a querystring parameter appended to the input string.
export const createUrlWithQueryString = (input: string, parameters: QueryStringParam[] = []): string => {
  if (!input) {
    return '';
  }

  let baseString = input;
  if (parameters.length > 0) {
    const urlParams = new URLSearchParams();
    parameters.forEach(param => urlParams.append(param.key, param.value));

    baseString += `?${urlParams.toString()}`;
  }

  return baseString;
};
