import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const CheckMarkIcon = (props: SvgIconPropType): JSX.Element => {
  const colorOverride = props.sx.color ?? 'inherit';
  return (
    <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5220_14756)">
        <path
          d="M10 18.3336C14.6024 18.3336 18.3334 14.6026 18.3334 10.0003C18.3334 5.39789 14.6024 1.66693 10 1.66693C5.39765 1.66693 1.66669 5.39789 1.66669 10.0003C1.66669 14.6026 5.39765 18.3336 10 18.3336Z"
          stroke={colorOverride}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M14.1666 7.75024L9.12498 12.7919L6.83331 10.5002"
        stroke={colorOverride}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_5220_14756">
          <rect width="20" height="20" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
CheckMarkIcon.muiName = SvgIcon.muiName;

export default CheckMarkIcon;
