import React, { useCallback, useMemo } from 'react';
import { Table, TableProps } from './Table';
import { ColumnDef, ColumnDefNoValue } from '../types/table';
import { colDefMapper } from '../utils/colDefMapper';

export const BaseTable = <T,>(props: Omit<TableProps<T>, 'colDefs'>) => {
  const renderColumn = useCallback(
    <Field extends keyof T>(field: Field, args: Omit<ColumnDef<T, Field>, 'field'>): ColumnDef<T, Field> => {
      return {
        ...args,
        field
      };
    },
    []
  );

  const renderCustomColumn = useCallback((args: Omit<ColumnDefNoValue<T>, 'field'>): ColumnDefNoValue<T> => {
    return {
      ...args,
      field: 'actions'
    };
  }, []);

  const colDefs = useMemo(() => {
    const columns = props.renderColumns({ renderColumn, renderCustomColumn });

    const castedColumns = columns as ColumnDef<T, keyof T>[];

    const newColDefs = colDefMapper({
      columns: castedColumns,
      persistedColumnState: props.persistedColumnState
    });

    return newColDefs;
  }, [props, renderCustomColumn, renderColumn]);

  return <Table {...props} colDefs={colDefs} />;
};
