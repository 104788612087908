import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import HospitalAndGroupFilter from '@clinintell/containers/reports/components/hospitalAndGroupFilter/HospitalAndGroupFilter';
import ReportEmailTextBox from '@clinintell/containers/reports/components/reportEmailTextBox/ReportEmailTextBox';
import { ReportType, ScheduleReportRolesType } from '@clinintell/containers/reports/types/actionTypes';
import SelectFrequency from '@clinintell/containers/common/SelectFrequency';
import ReportNameInput from '@clinintell/containers/reports/components/reportNameInput/ReportNameInput';
import ReportSidePanel from '@clinintell/containers/reports/components/reportSidePanel/ReportSidePanel';
import useReportSchedulerEntitySelector from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerEntitySelector';
import useReportSchedulerEmails from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerEmails';
import useReportSchedulerValidation from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerValidation';
import useReportSchedulerSave from '@clinintell/containers/reports/sections/context/hooks/useReportSchedulerSave';
import { ScheduleFrequencyDto } from '@clinintell/api/apiSchemas';

const QuarterlySummaryReport: React.FC = () => {
  const { hospitalData, groupData, hospitalFilterHandler, groupFilterHandler } = useReportSchedulerEntitySelector();
  const { adminEmails, resetEmailLists, setReportEmailList } = useReportSchedulerEmails();
  const [selectedFrequency, setSelectedFrequency] = useState<ScheduleFrequencyDto>({ frequencyName: '', id: 0 });
  const { scheduleQuarterlyReportClickHandler } = useReportSchedulerSave();
  const {
    submitEnabled,
    setReportScheduleType,
    checkGroupIdRequirement,
    checkReportNameRequirement,
    checkReportEmailRequirement
  } = useReportSchedulerValidation();
  const [reportName, setReportName] = useState<string>('');

  useEffect(() => {
    if (hospitalData.id === -1) setReportScheduleType(ReportType.Quarterly);
    checkGroupIdRequirement(groupData.id);
    if (groupData.name === '' || groupData.id === -1) {
      setReportName('');
      return;
    }
    setReportName(`Quarterly Report - ${hospitalData.name} ${groupData.name}`);
    checkReportNameRequirement(reportName);
  }, [hospitalData, groupData, reportName, checkReportNameRequirement, checkGroupIdRequirement, setReportScheduleType]);

  useEffect(() => {
    checkReportEmailRequirement(ReportType.Quarterly, adminEmails);
  }, [adminEmails, checkReportEmailRequirement]);

  return (
    <>
      <ReportSidePanel
        title="Schedule Quarterly Summary Report"
        submitEnabled={submitEnabled}
        onCloseHandler={() => {
          setReportScheduleType(null);
          hospitalFilterHandler(-1);
          resetEmailLists();
        }}
        onSubmitHandler={scheduleQuarterlyReportClickHandler}
        reportSubmissionData={{
          schedulingReportType: ReportType.Quarterly,
          groupData,
          reportName,
          adminEmails,
          selectedFrequency
        }}
      >
        <>
          <HospitalAndGroupFilter
            hospitalId={hospitalData.id}
            groupId={groupData.id}
            onHospitalChange={hospitalFilterHandler}
            onPhysicianGroupChange={groupFilterHandler}
          ></HospitalAndGroupFilter>

          <Box padding="8px">
            <ReportNameInput
              reportName={reportName}
              showError={groupData.id > 0 && reportName.length === 0}
              showRequired={reportName.length === 0 && hospitalData.id >= 0 && groupData.id >= 0}
            />
          </Box>

          <Box padding="8px">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <SelectFrequency
                width={'100%'}
                defaultValue={5}
                disableDropdown={true}
                onFrequencyChangeFn={(value: number, display: string) => {
                  setSelectedFrequency({ frequencyName: display, id: value });
                }}
              />
            </Box>
          </Box>
          <ReportEmailTextBox
            textBoxHeader={'Recipients'}
            validEmailList={adminEmails.emails}
            invalidEmailList={adminEmails.invalidEmails}
            emailRoleType={ScheduleReportRolesType.admin}
            entryRequired={reportName.length > 0}
            setValidEmailsFunc={setReportEmailList}
          />
        </>
      </ReportSidePanel>
    </>
  );
};

export default QuarterlySummaryReport;
