import { DRGGroupShare } from '@clinintell/containers/cmiAnalysis/types/DRGGroup';
import { SevCMIGroupShare } from '@clinintell/containers/cmiAnalysis/types/SevCMIGroup';
import { calculateImpactOnCMI } from '@clinintell/containers/cmiAnalysis/logic/calculateImpactOnCMI';
import { useDashboard2 } from '@clinintell/modules/store';
import { CMIAnalysisWidgetData } from '@clinintell/modules/dashboard2';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';

export type CMIAnalysisWidgetSummaryOutput = {
  hasError: boolean;
  isLoading: boolean;
  historicalDates: string;
  currentDates: string;
  historicalCmi: number;
  currentCmi: number;
  cmiDelta: number;
  historicalDrg: number;
  currentDrg: number;
  drgDelta: number;
  drgPercentage: number;
  historicalSeverity: number;
  currentSeverity: number;
  severityDelta: number;
  severityPercentage: number;
  drgMixTables: DRGGroupShare[];
  severityTables: SevCMIGroupShare[];
  errorMessage?: string;
};

const DefaultOutput: CMIAnalysisWidgetSummaryOutput = {
  hasError: false,
  isLoading: true,
  historicalDates: '',
  currentDates: '',
  historicalCmi: 0,
  currentCmi: 0,
  cmiDelta: 0,
  historicalDrg: 0,
  currentDrg: 0,
  drgDelta: 0,
  drgPercentage: 0,
  historicalSeverity: 0,
  currentSeverity: 0,
  severityDelta: 0,
  severityPercentage: 0,
  drgMixTables: [],
  severityTables: []
};

const useCmiAnalysis = (): CMIAnalysisWidgetSummaryOutput => {
  const { cmiAnalysisWidget } = useDashboard2();

  if (cmiAnalysisWidget.hasError) {
    return {
      ...DefaultOutput,
      hasError: true,
      isLoading: false,
      errorMessage: 'There is no CMI Analysis data for this entity'
    };
  }

  const hasDates = (cmiAnalysisWidget.data as CMIAnalysisWidgetData).bestFitDates;
  if (!hasDates) return DefaultOutput;

  const {
    bestFitDates,
    cmiAnalysis,
    drgMix,
    severityCMI,
    severityValue
  } = cmiAnalysisWidget.data as CMIAnalysisWidgetData;

  const { bestFitHistoricalStart, bestFitHistoricalEnd, bestFitCurrentStart, bestFitCurrentEnd } = bestFitDates;
  const { modalRecordId, isLoading: isWidgetLoading } = cmiAnalysisWidget;

  const historicalDates = `${parseDateIntoPeriod(new Date(bestFitHistoricalStart))}-${parseDateIntoPeriod(
    new Date(bestFitHistoricalEnd)
  )}`;
  const currentDates = `${parseDateIntoPeriod(new Date(bestFitCurrentStart))}-${parseDateIntoPeriod(
    new Date(bestFitCurrentEnd)
  )}`;

  const cmiGroup = cmiAnalysis.find(record => record.orgId === modalRecordId);
  const severityGroup = severityCMI.find(record => record.orgId === modalRecordId);

  const cmiDelta = cmiGroup
    ? Number(formatToDecimalPlaces(cmiGroup.current, 2)) - Number(formatToDecimalPlaces(cmiGroup.historical, 2))
    : 0;
  const drgDelta =
    Number(formatToDecimalPlaces(drgMix.currentHcupCmi, 2)) -
    Number(formatToDecimalPlaces(drgMix.historicalHcupCmi, 2));
  const severityDelta = severityGroup
    ? Number(formatToDecimalPlaces(severityGroup.current, 2)) -
      Number(formatToDecimalPlaces(severityGroup.historical, 2))
    : 0;
  const drgPercentage = calculateImpactOnCMI(cmiDelta, drgDelta, severityDelta) * 100;
  const severityPercentage = calculateImpactOnCMI(cmiDelta, severityDelta, drgDelta) * 100;

  const historicalCmi = cmiGroup ? cmiGroup.historical : 0;
  const currentCmi = cmiGroup ? cmiGroup.current : 0;
  const historicalDrg = drgMix.historicalHcupCmi;
  const currentDrg = drgMix.currentHcupCmi;
  const historicalSeverity = severityGroup ? severityGroup.historical : 0;
  const currentSeverity = severityGroup ? severityGroup.current : 0;

  return {
    hasError: cmiAnalysisWidget.hasError,
    isLoading: isWidgetLoading,
    historicalDates,
    currentDates,
    historicalCmi,
    currentCmi,
    cmiDelta,
    historicalDrg,
    currentDrg,
    drgDelta,
    drgPercentage,
    historicalSeverity,
    currentSeverity,
    severityDelta,
    severityPercentage,
    drgMixTables: drgMix.table,
    severityTables: (severityValue && severityValue.table) ?? []
  };
};

export default useCmiAnalysis;
