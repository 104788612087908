import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Analytics = (props: SvgIconProps) => (
  <SvgIcon
    sx={{
      fill: 'none',
      ...props.sx
    }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.675 13.2417C17.1448 14.4954 16.3156 15.6002 15.2599 16.4594C14.2041 17.3187 12.954 17.9062 11.6187 18.1707C10.2834 18.4351 8.90368 18.3685 7.60011 17.9765C6.29655 17.5845 5.10884 16.8792 4.14084 15.9222C3.17283 14.9652 2.454 13.7856 2.04719 12.4866C1.64037 11.1876 1.55796 9.80874 1.80715 8.47053C2.05635 7.13232 2.62957 5.87552 3.4767 4.81002C4.32382 3.74453 5.41906 2.90276 6.66666 2.35833"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 10C18.3333 8.90565 18.1178 7.82202 17.699 6.81097C17.2802 5.79993 16.6664 4.88127 15.8926 4.10744C15.1187 3.33362 14.2001 2.71979 13.189 2.30101C12.178 1.88222 11.0943 1.66667 10 1.66667V10H18.3333Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
Analytics.muiName = SvgIcon.muiName;

export default Analytics;
