import useEntityDetails, { EntityDetailsState } from '@clinintell/components/analysisSharedComponents/useEntityDetails';
import useMyDefaultDates from '@clinintell/components/analysisSharedComponents/useMyDefaultDates';
import { NodeDetails } from '@clinintell/containers/treeSelector/logic/TreeContext';
import { setCMIComparisonSettings, setComparisonRecords } from '@clinintell/modules/cmiComparison';
import { useCMIComparisonDispatch, useCMIComparisonState } from '@clinintell/modules/store';
import { useCallback, useEffect } from 'react';

type UseComparisonEntitiesProps = {
  myCurrentSelectedEntity?: EntityDetailsState;
  peerCurrentSelectedEntity?: EntityDetailsState;
};

type UseComparisonEntitiesOutput = {
  myEntity: number | undefined;
  myEntityName: string;
  updateMyEntity: (nodeId: number, nodeDetails: NodeDetails) => void;
  peerEntity: number | undefined;
  peerEntityName: string;
  updatePeerEntity: (nodeId: number, nodeDetails: NodeDetails) => void;
  onComparisonSelected: () => void;
};

const useComparisonEntities = (props?: UseComparisonEntitiesProps): UseComparisonEntitiesOutput => {
  const myCurrentSelectedEntity = props ? props.myCurrentSelectedEntity : undefined;
  const peerCurrentSelectedEntity = props ? props.peerCurrentSelectedEntity : undefined;

  const { entityId: myEntity, entityName: myEntityName, updateEntity: updateMyEntity } = useEntityDetails(
    myCurrentSelectedEntity
  );
  const { entityId: peerEntity, entityName: peerEntityName, updateEntity: updatePeerEntity } = useEntityDetails(
    peerCurrentSelectedEntity
  );

  const handleUpdateMyEntity = useCallback(
    (nodeId: number, nodeDetails: NodeDetails) => {
      updateMyEntity({ id: nodeId, ...nodeDetails });
    },
    [updateMyEntity]
  );

  const handleUpdatePeerEntity = useCallback(
    (nodeId: number, nodeDetails: NodeDetails) => {
      updatePeerEntity({ id: nodeId, ...nodeDetails });
    },
    [updatePeerEntity]
  );

  const { orgTreeType, orgId, orgName, comparisonOrgId, comparisonOrgName } = useCMIComparisonState();
  const cmiComparisonDispatch = useCMIComparisonDispatch();
  const defaultDates = useMyDefaultDates(myEntity, orgTreeType);

  // Update entity state when toggling between national medicare comparison and entity comparison
  useEffect(() => {
    if (orgId === undefined || comparisonOrgId === undefined) {
      return;
    }

    updateMyEntity({ id: orgId, name: orgName } as EntityDetailsState);
    updatePeerEntity({ id: comparisonOrgId, name: comparisonOrgName } as EntityDetailsState);
  }, [comparisonOrgId, comparisonOrgName, orgId, orgName, orgTreeType, updateMyEntity, updatePeerEntity]);

  const onComparisonSelected = (): void => {
    if (
      myEntity === undefined ||
      peerEntity === undefined ||
      !defaultDates ||
      !defaultDates.currentMinMonth ||
      !defaultDates.currentMaxMonth
    ) {
      return;
    }

    // Save entities in store and fetch comparison records
    cmiComparisonDispatch(
      setCMIComparisonSettings({
        orgId: myEntity,
        orgName: myEntityName,
        comparisonOrgId: peerEntity,
        comparisonOrgName: peerEntityName,
        comparisonMinDate: defaultDates.currentMinMonth,
        comparisonMaxDate: defaultDates.currentMaxMonth,
        chartMinDate: defaultDates.historicalMinMonth || undefined,
        chartMaxDate: defaultDates.currentMaxMonth,
        orgTreeType
      })
    );

    cmiComparisonDispatch(
      setComparisonRecords({
        orgId: myEntity,
        comparisonOrgId: peerEntity,
        comparisonMin: defaultDates.currentMinMonth,
        comparisonMax: defaultDates.currentMaxMonth,
        orgTreeType
      })
    );
  };

  return {
    myEntity,
    peerEntity,
    myEntityName,
    peerEntityName,
    updateMyEntity: handleUpdateMyEntity,
    updatePeerEntity: handleUpdatePeerEntity,
    onComparisonSelected
  };
};

export default useComparisonEntities;
