import { TreeNode } from '@clinintell/modules/orgTree';

export const dfsFindNodeInTreeWithSpecificParentId = (
  tree: TreeNode,
  targetId: number,
  parentId: number
): TreeNode | null => {
  const stack = [tree];

  const discoveredIds = new Set<string>();
  while (stack.length > 0) {
    const node = stack.shift();
    if (!node || discoveredIds.has(`${node.id}-${node.parentId}`)) {
      continue;
    }

    if (node.id === targetId && node.parentId === parentId) {
      return node;
    }

    discoveredIds.add(`${node.id}-${node.parentId}`);

    if (node.children.length > 0) {
      node.children.forEach(child => {
        stack.push(child);
      });
    }
  }

  return null;
};

export const dfsFindNodeInTree = (tree: TreeNode, targetId: number): TreeNode | null => {
  const stack = [tree];

  const discoveredIds = new Set<number>();
  while (stack.length > 0) {
    const node = stack.shift();

    if (!node || discoveredIds.has(node.id)) {
      continue;
    }

    if (node.id === targetId) {
      return node;
    }

    discoveredIds.add(node.id);

    if (node.children.length > 0) {
      node.children.forEach(child => {
        stack.push(child);
      });
    }
  }

  return null;
};

type ParentChildMap = {
  [index: number]: number | null;
};

export const dfsFindLineageInTree = (tree: TreeNode, targetId: number): number[] | null => {
  const stack = [tree];

  const discoveredIds = new Set<number>();
  const parentChildMap: ParentChildMap = {};

  let targetNode: TreeNode | null = null;
  while (stack.length > 0 && !targetNode) {
    const node = stack.shift();

    if (!node || discoveredIds.has(node.id)) {
      continue;
    }

    parentChildMap[node.id] = node.parentId;
    discoveredIds.add(node.id);

    if (node.id === targetId) {
      targetNode = node;
    }

    if (node.children.length > 0) {
      node.children.forEach(child => {
        stack.push(child);
      });
    }
  }

  if (targetNode) {
    const path: number[] = [];
    let id = targetId;
    let parentId = parentChildMap[id];

    path.unshift(id);
    while (parentId !== null) {
      id = parentId;
      path.unshift(id);
      parentId = parentChildMap[id];
    }

    return path;
  }

  return null;
};

export const dfsFindLineageInTreeWithSpecificParent = (
  tree: TreeNode,
  targetId: number,
  parentId: number
): number[] | null => {
  const stack = [tree];

  const discoveredIds = new Set<string>();
  const parentChildMap: ParentChildMap = {};

  let targetNode: TreeNode | null = null;
  while (stack.length > 0 && !targetNode) {
    const node = stack.shift();

    if (!node || discoveredIds.has(`${node.id}-${node.parentId}`)) {
      continue;
    }

    discoveredIds.add(`${node.id}-${node.parentId}`);
    parentChildMap[node.id] = node.parentId;

    if (node.id === targetId && node.parentId === parentId) {
      targetNode = node;
    }

    if (node.children.length > 0) {
      node.children.forEach(child => {
        stack.push(child);
      });
    }
  }

  if (targetNode) {
    const path: number[] = [];
    let id = targetId;
    let parentId = parentChildMap[id];

    path.unshift(id);
    while (parentId !== null) {
      id = parentId;
      path.unshift(id);
      parentId = parentChildMap[id];
    }

    return path;
  }

  return null;
};
