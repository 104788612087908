import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DashboardIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      width: 20,
      height: 20,
      fill: 'none',
      ...props.sx
    }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 11.6667H11.6667V17.5001H17.5V11.6667Z"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33333 11.6667H2.5V17.5001H8.33333V11.6667Z"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 2.5H11.6667V8.33333H17.5V2.5Z"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
DashboardIcon.muiName = SvgIcon.muiName;

export default DashboardIcon;
