/*
    This method takes a given change value and error margin.
    If the change value is positive and outside the error margin, return 1
    If the change value is negative and outside the error margin, return -1
    If the change value is positive OR negative and inside the error margin, return 0
*/
export const calculateErrorBoundary = (change: number, errorMargin: number): number => {
  const absChange: number = Math.abs(change);

  if (absChange <= Number(errorMargin)) {
    return 0;
  }

  return change > 0 ? 1 : -1;
};

// This method returns the number of decimal places of the given number
export const precision = (value: number): number => {
  if (!Number.isFinite(value)) {
    return 0;
  }

  let multiplier = 1;
  let count = 0;
  while (Math.round(value * multiplier) / multiplier !== value) {
    multiplier *= 10;
    count += 1;
  }

  return count;
};

export const round = (value: number): number => Math.round((value + 0.00001) * 100) / 100;

export const calculateCeiling = (value: number, increment: number): number => {
  const correctionValue: number = (value * 10) / (increment * 10);
  const valueWithCeiling: number = Math.ceil(correctionValue) * increment;
  const valuePrecision = precision(increment);

  return Number(valueWithCeiling.toFixed(valuePrecision));
};

export const calculateFloor = (value: number, increment: number): number => {
  const correctionValue: number = (value * 10) / (increment * 10);
  const valueWithFloor: number = Math.floor(correctionValue) * increment;
  const valuePrecision = precision(increment);

  return valueWithFloor > 0 ? Number(valueWithFloor.toFixed(valuePrecision)) : 0;
};
