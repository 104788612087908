import React from 'react';
import { Box } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { ChartTooltipItem } from 'chart.js';

interface DoughnutChartProps {
  title?: string;
  chartData: number[];
  chartBackGroundColor: string[];
  labels?: string[];
  enableTooltip?: boolean;
  styles?: React.CSSProperties;
  innerDoughnutPercentage?: string;
  innerDoughnutStats?: string;
  onClick?: () => void;
  testId: string;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  title = 'doughnutChart',
  chartData,
  chartBackGroundColor,
  labels = [],
  enableTooltip = false,
  styles,
  innerDoughnutPercentage,
  innerDoughnutStats,
  onClick,
  testId
}) => {
  return (
    <>
      <Box
        id={title}
        role="img"
        aria-label="DoughnutChart"
        aria-describedby={`${title}-graphDescription`}
        style={styles}
      >
        <Doughnut
          id="DoughnutChartCanvasId"
          data={{
            datasets: [
              {
                data: chartData,
                backgroundColor: chartBackGroundColor,
                borderWidth: 4
              }
            ],
            labels: labels
          }}
          options={{
            cutoutPercentage: 68,
            responsive: true,
            maintainAspectRatio: false,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            plugin_options: {
              percentageText: innerDoughnutPercentage,
              lowerBodyText: innerDoughnutStats,
              maxFontSize: '18px'
            },
            legend: {
              display: false
            },
            tooltips: {
              enabled: enableTooltip,
              callbacks: {
                label(tooltipItem: ChartTooltipItem, data: Chart.ChartData): string {
                  if (
                    (!tooltipItem.index && !Array.isArray(data.datasets)) ||
                    !data.datasets?.length ||
                    !Array.isArray(data.datasets[0].data) ||
                    !data.datasets[0].data?.length
                  )
                    return '';
                  return `${labels[tooltipItem.index as number]}: ${
                    data.datasets[0].data[tooltipItem.index as number]
                  }%`;
                }
              }
            },
            plugins: ['innerDoughtnutTextPlugin']
          }}
          onElementsClick={() => onClick && onClick()}
        />
      </Box>
      <Box id={`${title}-graphDescription`} display="none">
        {chartData ? (
          <>
            <Box>{JSON.stringify(chartData)}</Box>
            {chartData.map((data, ix) => {
              const optionTestId = labels[ix] ? labels[ix].replaceAll(' ', '') : data;
              return (
                <button
                  key={ix}
                  data-test-id={`${testId}-${optionTestId}-activator`}
                  data-test-value={data}
                  onClick={onClick}
                />
              );
            })}
          </>
        ) : null}
      </Box>
    </>
  );
};

export default DoughnutChart;
