import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { useCMIAnalysisState } from '@clinintell/modules/store';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';
import useTimeSeriesData from '../logic/useTimeSeriesData';
import AnalysisChart from './AnalysisChart';

const CMIAnalysisDetails: React.FC = () => {
  const { chartMinDate, chartMaxDate, cmiSummary, entityId } = useCMIAnalysisState();

  const { data: chartData, isLoading } = useTimeSeriesData({
    minChartPeriod: chartMinDate,
    maxChartPeriod: chartMaxDate,
    orgId: entityId,
    metric: 'cmi'
  });

  // Filter out discharges and gap columns from chart datasets so we can re-use existing hooks to generate the chart
  let filteredChartData;
  if (chartData) {
    filteredChartData = chartData.data.filter(set => set.dataSetType === 'Actual');
  }

  const difference =
    cmiSummary && cmiSummary.current && cmiSummary.historical
      ? Number(formatToDecimalPlaces(cmiSummary.current, 2)) - Number(formatToDecimalPlaces(cmiSummary.historical, 2))
      : 0;

  return chartData && filteredChartData && !isLoading ? (
    <AnalysisChart
      metric="cmi"
      dataset={chartData}
      chartDataSets={filteredChartData}
      chartPeriodType="Month"
      currentDateIsBetterTimes={!cmiSummary || difference > 0}
      chartTitle="CMI"
    />
  ) : (
    <ClinIntellSkeleton variant="rectangular" height="26rem" width="100%" />
  );
};

export default CMIAnalysisDetails;
