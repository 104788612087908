import { Box, BoxProps, Divider, Grid, GridSize, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties } from '@mui/styles';
import React from 'react';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '../tooltip/Tooltip';

const useStyles = makeStyles(theme => ({
  textEllipsis: {
    display: 'block',
    width: '175%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  valueWithTooltip: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& > *:first-child': {
      marginLeft: theme.spacing(1)
    }
  }
}));

type ResultSummaryTableColumn = {
  value: string;
  width: GridSize;
  align: 'center' | 'left' | 'right';
  offsetCenter?: boolean;
  bold?: boolean;
  type?: 'string' | 'number';
  key: string;
  rowLabelNoEllipsis?: boolean;
  tooltip?: string;
  underline?: boolean;
};

type ResultSummaryTableRow = {
  columns?: ResultSummaryTableColumn[];
  divider?: 'dashed' | 'solid';
  marginBottom?: CSSProperties['marginBottom'];
  key: string;
};

type ResultSummaryTableProps = {
  rows: ResultSummaryTableRow[];
};

const ResultSummaryTable: React.FC<ResultSummaryTableProps> = ({ rows }) => {
  const theme = useTheme();
  const { textEllipsis, valueWithTooltip } = useStyles();

  return (
    <Grid container spacing={1}>
      {rows.map((row, rowIndex) => {
        return (
          <React.Fragment key={row.key}>
            {row.columns ? (
              row.columns.map((column, colIndex) => {
                let marginLeftValue: string | number = 0;
                if (column.offsetCenter && column.value.length > 1) {
                  marginLeftValue = column.value[0] === '-' ? 'calc(40% - 5px)' : '40%';
                }

                const restProps: BoxProps = {};
                if (rowIndex === 0) {
                  restProps.display = 'flex';
                  restProps.justifyContent = 'center';
                  restProps.alignItems = 'flex-end';
                  restProps.height = '100%';
                }

                if (column.underline) {
                  restProps.borderBottom = `1px solid ${theme.palette.grey[300]}`;
                  restProps.paddingBottom = theme.spacing(1);
                }

                let whiteSpaceProperty: React.CSSProperties['whiteSpace'] = 'pre';
                if (rowIndex === 0) {
                  whiteSpaceProperty = 'normal';
                } else if (colIndex === 0 && !column.rowLabelNoEllipsis) {
                  whiteSpaceProperty = 'nowrap';
                }

                return (
                  <Grid item key={column.key} xs={column.width} style={{ marginBottom: row.marginBottom || 0 }}>
                    <Box
                      fontWeight={column.bold ? 500 : 'fontWeightRegular'}
                      textAlign={
                        column.align === 'left' && column.offsetCenter && column.value.length === 1
                          ? 'center'
                          : column.align
                      }
                      {...restProps}
                    >
                      <Box
                        fontFamily={
                          column.type === 'number' ? theme.typography.fontFamilyMonospaced : theme.typography.fontFamily
                        }
                        marginLeft={marginLeftValue}
                        component="span"
                        className={colIndex === 0 && !column.rowLabelNoEllipsis ? textEllipsis : ''}
                        whiteSpace={whiteSpaceProperty}
                      >
                        {column.tooltip ? (
                          <Box className={valueWithTooltip}>
                            {column.value}
                            <Tooltip content={column.tooltip}>
                              <HelpIcon style={{ color: 'grey' }} />
                            </Tooltip>
                          </Box>
                        ) : (
                          column.value
                        )}
                      </Box>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Divider sx={{ borderColor: 'grey.300' }} />
              </Grid>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default ResultSummaryTable;
