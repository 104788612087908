import React from 'react';
import { NumberCell } from './NumberCell';
import { formatNumberWithCommas, roundDecimal } from '@clinintell/utils/formatting';

type IsStatSignificantCellProps = {
  value: number;
  errorMargin: number;
  isPercentageBased: boolean;
  captureOverdocumentation: boolean;
};

export const IsStatSignificantCell: React.FC<IsStatSignificantCellProps> = ({
  value,
  errorMargin,
  isPercentageBased,
  captureOverdocumentation
}) => {
  let bubbleClass = '';
  if (errorMargin !== 0) {
    if (captureOverdocumentation) {
      bubbleClass = errorMargin === 1 ? 'bubble-yellow' : 'bubble-red';
    } else {
      bubbleClass = errorMargin === 1 ? 'bubble-green' : 'bubble-red';
    }
  }

  return (
    <div className={`ag-grid-custom bubble ${bubbleClass}`}>
      {isPercentageBased ? (
        <div>{`${formatNumberWithCommas(roundDecimal(value, 0))}%`}</div>
      ) : (
        <NumberCell value={value} />
      )}
    </div>
  );
};
