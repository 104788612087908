import { ChildMenuItem } from '@clinintell/modules/menuNavigation';
import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import useIsMobileView from '@clinintell/utils/useIsMobileView';

type ChildMenuNavigationLinkProps = {
  childMenuItem: ChildMenuItem;
  onClick: (childMenuItem: ChildMenuItem) => void;
};

const ChildMenuNavigationLink = React.forwardRef<HTMLElement, ChildMenuNavigationLinkProps>(
  ({ childMenuItem, onClick }, ref) => {
    const isMobileView = useIsMobileView();

    const handleChildMenuLinkClick = () => {
      onClick(childMenuItem);
    };

    return (
      <Link
        onClick={handleChildMenuLinkClick}
        to={{
          pathname: Boolean(childMenuItem.isExternalLink)
            ? `${childMenuItem.link}`
            : `${childMenuItem.parent.link}${childMenuItem.link}`
        }}
        style={{ textDecoration: 'none', display: 'block' }}
        target={Boolean(childMenuItem.isExternalLink) ? '_blank' : '_self'}
      >
        <Box
          ref={ref}
          sx={{
            pl: '3.25rem',
            py: 2,
            color: childMenuItem.isActive ? 'teal.main' : 'grey.500',
            backgroundColor: childMenuItem.isActive && !isMobileView ? 'teal.vyond7' : 'transparent',
            ...(childMenuItem.isActive ? { fontWeight: 600 } : null),
            '&:hover': {
              cursor: 'pointer',
              color: 'teal.main',
              backgroundColor: 'teal.vyond7'
            }
          }}
        >
          {childMenuItem.label}
        </Box>
      </Link>
    );
  }
);

export default ChildMenuNavigationLink;
