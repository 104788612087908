import React from 'react';
import { Box } from '@mui/material';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import {
  ChartDataSet,
  ChartDataSetJSON,
  ChartDataSetType
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import CheckForData from '@clinintell/containers/metricsTimeSeries/sections/CheckForData';
import useGraphContainerProps from '@clinintell/containers/metricsTimeSeries/logic/useGraphContainerProps';
import { Legend } from '@clinintell/components/Chart/Legend';
import Chart from '@clinintell/components/Chart/Chart';
import { Bar, Line } from 'react-chartjs-2';
import { ChartDataSetAverageTypes } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';

const ViewPerformanceGraph: React.FC<{
  graphData: ChartDataSetJSON;
  isCondition?: boolean;
  viewOption?: ChartDataSetAverageTypes;
  filteredChart?: Set<ChartDataSetType>;
  forOCE?: boolean;
  useErrorBands?: boolean;
}> = ({
  graphData,
  isCondition = false,
  viewOption = ChartDataSetAverageTypes.ThreeMonth,
  filteredChart,
  forOCE,
  useErrorBands = true
}) => {
  const filteredChartDatasets =
    filteredChart ??
    new Set<ChartDataSetType>(isCondition ? ['Secondary', 'CESecondary'] : ['Actual', 'ClinicallyExpected']);

  const checkArrayHistoryCurrent = (
    dataSets: ChartDataSet[],
    strgComp: string,
    primaryIndex: number,
    secondaryIndex: number
  ) => {
    const filteredData = dataSets
      .filter(
        element =>
          (element.dataSetType === 'CurrentAverageOtoCE' &&
            element.dataSetAverageType === ChartDataSetAverageTypes.TwoMonth) ||
          (element.dataSetType === 'HistoricalAverageOtoCE' &&
            element.dataSetAverageType === ChartDataSetAverageTypes.TwoMonth)
      )
      .map(elem => {
        const historicCurrentTag = elem.dataSetType;
        const historicCurrentData = elem.dataSet.find(elemValue => elemValue.value !== null)?.value as number;
        return { historicCurrentTag, historicCurrentData };
      });
    if (
      filteredData[primaryIndex].historicCurrentTag === strgComp &&
      filteredData[primaryIndex].historicCurrentData > filteredData[secondaryIndex].historicCurrentData
    )
      return true;
  };

  let historicalAvgHigher = false;
  if (forOCE && filteredChart !== undefined && filteredChart.has('CurrentAverageOtoCE')) {
    historicalAvgHigher = checkArrayHistoryCurrent(graphData.data, 'HistoricalAverageOtoCE', 0, 1)
      ? true
      : checkArrayHistoryCurrent(graphData.data, 'HistoricalAverageOtoCE', 1, 0)
      ? true
      : false;
  }
  const containerProps = useGraphContainerProps({
    metric: isCondition ? Metrics.condition : Metrics.docScore,
    chartPeriodType: viewOption,
    dataset: graphData as ChartDataSetJSON,
    chartDataSets: (graphData as ChartDataSetJSON).data.filter(set => filteredChartDatasets.has(set.dataSetType)),
    forOCE: forOCE,
    historicalAvgHigher: historicalAvgHigher,
    disableErrorBands: !useErrorBands
  });

  const { chartDatasets, legendItems, xAxis, yAxes } = containerProps;

  let chartComponent;
  if (yAxes.length > 1) {
    chartComponent = Bar;
  } else {
    chartComponent = chartDatasets.length > 0 && chartDatasets[0].type === 'bar' ? Bar : Line;
  }

  if (isCondition) {
    return (
      <Box display="flex" flexDirection="column">
        <Legend styles={{ marginLeft: '3rem', marginBottom: '2.5rem' }} items={legendItems} />
        <Chart
          title="viewPerformanceGraph"
          datasets={chartDatasets}
          yAxes={yAxes}
          xAxis={xAxis}
          useErrorBands={useErrorBands}
        >
          {chartComponent}
        </Chart>
      </Box>
    );
  }

  return (
    <CheckForData
      timeSeriesDataset={{
        id: Metrics.docScore,
        dataset: graphData
      }}
      timeSeriesAverageType={'Month'}
    >
      <Box display="flex" flexDirection="column">
        <Legend styles={{ marginLeft: '3rem', marginBottom: '2.5rem' }} items={legendItems} />
        <Chart
          title="viewPerformanceGraph"
          datasets={chartDatasets}
          yAxes={yAxes}
          xAxis={xAxis}
          useErrorBands={useErrorBands}
        >
          {chartComponent}
        </Chart>
      </Box>
    </CheckForData>
  );
};

export default ViewPerformanceGraph;
