import { useHospitalsDispatch, useUserDispatch } from '@clinintell/modules/store';
import { HOSPITAL_SELECT_LIMIT } from '@clinintell/utils/resources';
import { useEffect } from 'react';

const useFetchInitialData = (isAuthenticated: boolean): void => {
  const userDispatch = useUserDispatch();
  const hospitalsDispatch = useHospitalsDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      userDispatch({ type: 'LOAD_USER' });
      // Preload hospitals and use count to determine if user needs search or select for the reports hospital inputs
      hospitalsDispatch({
        type: 'HOSPITAL_FETCH_ALL_REQUESTED',
        payload: { itemCount: HOSPITAL_SELECT_LIMIT }
      });
    }
  }, [hospitalsDispatch, isAuthenticated, userDispatch]);
};

export default useFetchInitialData;
