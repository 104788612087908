import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExpandPanelIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{ width: 20, height: 20, fill: 'none', ...props.sx }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 15L12.5 10L7.5 5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ExpandPanelIcon;
