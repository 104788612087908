import React, { useEffect } from 'react';
import { Button } from '@clinintell/components/index';
import { isMobile } from 'react-device-detect';
import useErrorHandling from '@clinintell/errors/useErrorHandling';
import CIReportsModal from '@clinintell/containers/reports/components/reportModal/CIReportsModal';
import { ReportType } from '../../types';
import { useScheduleGetReportsMonthlyGroupReportDownloadByPhysicianGroupOrgId } from '@clinintell/api/apiComponents';
import GroupReportFilter from '@clinintell/containers/reports/components/hospitalAndGroupFilter/GroupReportFilter';
import { downloadFile } from '@clinintell/utils/downloads';

type SaveReponseType = { originalError: { payload: string; status: number } };
type GroupReportDownloadProps = {
  groupId: number;
  hospitalId: number;
  onHospitalChange: (hospitalId: number | null) => void;
  onGroupChange: (groupId: number | null) => void;
};

const GroupReportDownload: React.FC<GroupReportDownloadProps> = ({
  groupId,
  hospitalId,
  onHospitalChange,
  onGroupChange
}) => {
  const { showErrorMessage } = useErrorHandling();

  const {
    data: groupReportData,
    isFetching: isFetchingMonthlyReports,
    refetch: fetchMonthlyGroupReport
  } = useScheduleGetReportsMonthlyGroupReportDownloadByPhysicianGroupOrgId(
    {
      onError: (err: unknown | SaveReponseType) => {
        showErrorMessage(err, 'This Group does not have data on the selected times.');
      },
      pathParams: {
        physicianGroupOrgId: groupId
      }
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (!groupReportData) {
      return;
    }

    // I believe the underlying API spec is wrong. This isn't a blob, it is an array buffer
    downloadFile((groupReportData as unknown) as ArrayBuffer, 'application/zip', 'Reports');
  }, [groupReportData]);

  const handleDownloadClick = async () => {
    fetchMonthlyGroupReport();
  };

  return (
    <CIReportsModal
      modalButtonOverride={<Button label="Download Report" type={'primary'} variant={'text'} />}
      modalBtnLabel="Download Report"
      reportLabel="Download Report"
      reportModalTitle="Group Report"
      reportType={ReportType.Group}
      modalActionButtonOverride={
        <Button
          isBusy={isFetchingMonthlyReports}
          label="Download Report"
          disabled={groupId === -1 || hospitalId === -1}
          onClick={handleDownloadClick}
        />
      }
    >
      <GroupReportFilter
        hospitalId={hospitalId}
        groupId={groupId}
        onHospitalChange={onHospitalChange}
        onPhysicianGroupChange={onGroupChange}
        width={isMobile ? 325 : '100%'}
      />
    </CIReportsModal>
  );
};

export default GroupReportDownload;
