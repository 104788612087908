import useTimeSeriesData from '@clinintell/containers/cmiAnalysis/logic/useTimeSeriesData';
import { ChartDataSetJSON } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import { useCMIComparisonState } from '@clinintell/modules/store';
import { useEffect, useState } from 'react';

type UseChartDatasetsProps = {
  metric: keyof typeof Metrics;
};

type UseChartDatasetsOutput = {
  orgChartDataset: ChartDataSetJSON | undefined;
  comparisonOrgChartDataset: ChartDataSetJSON | undefined;
  isLoading: boolean;
};

const useChartDatasets = ({ metric }: UseChartDatasetsProps): UseChartDatasetsOutput => {
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();

  const { chartMinDate, chartMaxDate, orgId, comparisonOrgId, orgTreeType } = useCMIComparisonState();

  useEffect(() => {
    if (!chartMinDate || !chartMaxDate) {
      return;
    }

    setMinDate(new Date(chartMinDate));
    setMaxDate(new Date(chartMaxDate));
  }, [chartMaxDate, chartMinDate]);

  const orgChartDataset = useTimeSeriesData({
    metric,
    orgId,
    minChartPeriod: minDate,
    maxChartPeriod: maxDate,
    orgTreeType
  });

  const comparisonOrgChartDataset = useTimeSeriesData({
    metric,
    orgId: comparisonOrgId,
    minChartPeriod: minDate,
    maxChartPeriod: maxDate,
    orgTreeType
  });

  return {
    orgChartDataset: orgChartDataset.data,
    comparisonOrgChartDataset: comparisonOrgChartDataset.data,
    isLoading: orgChartDataset.isLoading || comparisonOrgChartDataset.isLoading
  };
};

export default useChartDatasets;
