import { BreadcrumbItem } from '@clinintell/modules/breadcrumbNavigation';
import { Box } from '@mui/material';
import React from 'react';

type CollapsedBreadcrumbsMenuProps = {
  breadcrumbs: BreadcrumbItem[];
  onClick: (value: number | string) => void;
};

const CollapsedBreadcrumbsMenu: React.FC<CollapsedBreadcrumbsMenuProps> = ({ breadcrumbs, onClick }) => {
  const handleCollapsedBreadcrumbClick = (breadcrumb: BreadcrumbItem, isCurrent: boolean) => {
    if (isCurrent || breadcrumb.isDisabled) {
      return;
    }

    onClick(breadcrumb.value);
  };

  return (
    <Box
      component="ul"
      sx={{
        listStyleType: 'none',
        paddingLeft: 0,
        marginY: 0,
        '& > li': { paddingX: 2, paddingY: 1.5 }
      }}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        const isCurrent = index === breadcrumbs.length - 1;

        return (
          <Box
            onClick={() => handleCollapsedBreadcrumbClick(breadcrumb, isCurrent)}
            key={breadcrumb.value}
            component="li"
            sx={{
              color: index === breadcrumbs.length - 1 ? 'grey.600' : 'blue.main',
              '&:hover': {
                cursor: isCurrent ? 'auto' : 'pointer',
                backgroundColor: isCurrent ? 'shade.white' : 'grey.50'
              }
            }}
          >
            {breadcrumb.label}
          </Box>
        );
      })}
    </Box>
  );
};

export default CollapsedBreadcrumbsMenu;
