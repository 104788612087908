import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Select, SelectOptionType } from '../../components/index';
import { UserAccountDto } from '@clinintell/api/apiSchemas';
import { useUsersGetClinicalLeaders } from '@clinintell/api/apiComponents';
import useErrorHandling from '@clinintell/errors/useErrorHandling';

export type ClinicalLeaderType = {
  id: number;
  name: string;
};

type Props = {
  labelPosition?: 'top' | 'side';
  title?: string;
  defaultValue?: number | string;
  onClinicalLeaderChangeFn?: (value: number, display: string) => void;
  width?: number | string;
  labelWidth?: number | string;
  disableDropdown?: boolean;
};

const SelectClinicalLeader: React.FC<Props> = ({
  labelPosition = 'top',
  title,
  defaultValue,
  onClinicalLeaderChangeFn,
  width = 400,
  labelWidth,
  disableDropdown = false
}) => {
  const [clinicalLeaders, setClinicalLeaders] = useState<SelectOptionType[]>([]);
  const { showErrorMessage } = useErrorHandling();

  const { data: clinicalLeadsReponse, isLoading: isFetchingClincalLeads } = useUsersGetClinicalLeaders({
    onError: showErrorMessage
  });

  useEffect(() => {
    if (isFetchingClincalLeads || !clinicalLeadsReponse) return;

    if (clinicalLeadsReponse) {
      const dropDownList: SelectOptionType[] | [] = (clinicalLeadsReponse as UserAccountDto[]).map(element => {
        return {
          value: element.id === -1 ? 0 : element.id,
          label: element.name,
          useLabelForTestId: true
        };
      });

      setClinicalLeaders(dropDownList);
    }
  }, [onClinicalLeaderChangeFn, isFetchingClincalLeads, clinicalLeadsReponse, defaultValue]);

  return (
    <Box
      display="flex"
      flexDirection={labelPosition === 'top' ? 'column' : 'row'}
      alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
      width={width}
    >
      <Typography variant="p2" width={labelWidth !== undefined || labelWidth !== null ? labelWidth : 'auto'}>
        {title}
      </Typography>
      <Box width="100%" style={labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}>
        <Select
          isDisabled={clinicalLeaders === undefined || isFetchingClincalLeads || disableDropdown}
          isSearchable={true}
          options={clinicalLeaders}
          value={Number(defaultValue)}
          onChange={(value, label): void => {
            if (onClinicalLeaderChangeFn) onClinicalLeaderChangeFn(Number(value), label);
          }}
          testId="clinicalLeaderSelector"
        />
      </Box>
    </Box>
  );
};

export default React.memo(SelectClinicalLeader);
