const waitUntilInDOM = (nodeId: string): Promise<HTMLElement> => {
  return new Promise(resolve => {
    const node = document.getElementById(nodeId);
    if (node) {
      return resolve(node);
    }

    const observer = new MutationObserver(() => {
      const node = document.getElementById(nodeId);
      if (node) {
        resolve(node);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
};

export default waitUntilInDOM;
