import React from 'react';
import { GeneratedHospitalReportRecord } from '../types/generatedReportDataTypes';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { OrgTableProps } from '../TrainingStatusReport';
import { useReportDownloadStatus } from '../context/ReportDownloadContext';
import { Box } from '@mui/material';
import { Table, IntegerCell, useTableApiActions } from '@clinintell/components/table_v2';

const SystemGeneratedReport: React.FC<OrgTableProps> = ({ orgId }) => {
  const { isLoading, output } = useGetAPICAll<GeneratedHospitalReportRecord[]>({
    endpoint: `reports/training/system/${orgId}`,
    isWaiting: false
  });

  const { enableDownload, disableDownload } = useReportDownloadStatus();
  const { initializeTableApis } = useTableApiActions();

  if (isLoading) {
    return <ClinIntellSkeleton variant="rectangular" height="25rem" width="100%" />;
  }

  if (output && output.data && output.data.length === 0) {
    disableDownload();
  } else {
    enableDownload();
  }

  return (
    <Table<GeneratedHospitalReportRecord>
      footerData={output?.data ? output.data.find(row => row.isFooter) : undefined}
      rowData={output?.data ? output.data.filter(row => !row.isFooter) : []}
      onGridReady={initializeTableApis}
      renderColumns={({ renderColumn }) => {
        return [
          renderColumn('name', {
            suppressMenu: false,
            cellRenderer: props => {
              return <Box>{props.value}</Box>;
            },
            headerName: 'Hospital',
            headerAlignment: 'left',
            sortable: true,
            width: 350,
            suppressSizeToFit: true
          }),
          renderColumn('totalInvitationSent', {
            cellRenderer: props =>
              props.value !== null && props.value !== undefined ? <IntegerCell value={props.value} /> : <Box>0</Box>,
            headerName: 'Provider Count (Assigned)',
            sortable: true,
            cellStyle: {
              justifyContent: 'center'
            }
          }),
          renderColumn('totalCompletedTraining', {
            cellRenderer: props =>
              props.value !== null && props.value !== undefined ? <IntegerCell value={props.value} /> : <Box>0</Box>,
            headerName: 'Provider Count (Completed)',
            sortable: true,
            cellStyle: {
              justifyContent: 'center'
            }
          }),
          renderColumn('trainingProgressPercent', {
            cellRenderer: props => <IntegerCell value={props.value || 0} postfix="%" />,
            headerName: 'Training Progress',
            sortable: true,
            cellStyle: {
              justifyContent: 'center'
            },
            sort: 'desc'
          })
        ];
      }}
    />
  );
};

export default SystemGeneratedReport;
