import React from 'react';
import { OptionProps } from 'react-select';
import { SelectOptionType } from './DropDown';
import { Box } from '@mui/material';

const Option: React.FC<OptionProps<SelectOptionType>> = ({ children, innerProps, selectProps, data, isSelected }) => {
  const { withLineDivider, value, label, useLabelForTestId } = data;

  let testIdValue = value;
  // Need to check for a new option property that indicates if the label should be used for the testId
  if (useLabelForTestId) {
    testIdValue = label.replaceAll(' ', '');
  }

  const extraProps = selectProps.testId
    ? {
        'data-test-id': `${selectProps.testId}-${testIdValue}`,
        'data-test-value': value
      }
    : {};

  return (
    // DISCUSSION - typescript is erorring here basically saying the given inner
    // props isn't compatible with the Material UI box.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Box
      sx={{
        px: 1,
        py: 1,
        color: 'grey.900',
        backgroundColor: 'transparent',
        fontWeight: isSelected ? 600 : 'normal',
        borderBottom: `${withLineDivider ? '1px' : 0} solid`,
        borderColor: 'grey.200',
        '&:hover': {
          backgroundColor: 'grey.50'
        }
      }}
      {...innerProps}
      {...extraProps}
    >
      {children}
    </Box>
  );
};

export default Option;
