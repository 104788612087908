export const findMaterialUITreeViewParentId = (id: number): number | undefined => {
  const treeElement = document.getElementById(id.toString());

  const closestParentTreeItemRoot = treeElement?.closest('.MuiTreeItem-root');
  if (closestParentTreeItemRoot) {
    const parentElement = closestParentTreeItemRoot.parentElement;
    if (parentElement) {
      const id = parentElement.getAttribute('id');
      if (id !== null) {
        return Number(id);
      }
    }
  }

  return undefined;
};
