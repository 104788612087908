import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import ClinIntellSkeleton from '../ClinIntellSkeleton';
import Panel from '../Panel';
import { SevCMIGroupShare } from '@clinintell/containers/cmiAnalysis/types/SevCMIGroup';
import SeverityImpactTable from './SeverityImpactTable';

type ImpactTableWithPanelProps = {
  panelIsToggled: boolean;
  onPanelToggle: () => void;
  isLoading: boolean;
  tableRows: SevCMIGroupShare[];
  panelTitle: string;
  noImpactMessage: string;
  sortDirection: 'asc' | 'desc';
};

const ImpactTableWithPanel: React.FC<ImpactTableWithPanelProps> = props => {
  const theme = useTheme();
  const { panelIsToggled, onPanelToggle, isLoading, panelTitle, tableRows, noImpactMessage } = props;

  let panelChildComponent;
  if (isLoading) {
    panelChildComponent = (
      <ClinIntellSkeleton variant="rectangular" width="100%" height={panelIsToggled ? '38rem' : '3rem'} />
    );
  } else if (tableRows.length > 0) {
    panelChildComponent = (
      <Box width={'100%'}>
        <SeverityImpactTable {...props} />
      </Box>
    );
  } else {
    panelChildComponent = (
      <Box m={2}>
        <Box component="span" color={theme.palette.red[500]}>
          {noImpactMessage}
        </Box>
      </Box>
    );
  }

  return (
    <Box marginTop={2}>
      <Panel
        isDraggable={false}
        summaryTitle={panelTitle}
        toggleExpanded={onPanelToggle}
        expanded={panelIsToggled}
        detailStyles={panelDetailStyles}
        withBorder
      >
        {panelChildComponent}
      </Panel>
    </Box>
  );
};

const MemoizedImpactTableWithPanel = memo(ImpactTableWithPanel);

const panelDetailStyles = {
  overflow: 'auto',
  padding: 0,
  width: '100%',
  display: 'block'
};

type SeverityImpactTablesProp = {
  tableRows: SevCMIGroupShare[];
  hasPositiveDelta: boolean;
  isLoading: boolean;
};

const SeverityImpactTables: React.FC<SeverityImpactTablesProp> = props => {
  const { hasPositiveDelta, tableRows } = props;
  const [positivePanelToggled, setPositivePanelToggled] = useState(hasPositiveDelta);
  const [negativePanelToggled, setNegativePanelToggled] = useState(!hasPositiveDelta);

  useEffect(() => {
    if (hasPositiveDelta) {
      setPositivePanelToggled(true);
      setNegativePanelToggled(false);
    } else {
      setPositivePanelToggled(false);
      setNegativePanelToggled(true);
    }
  }, [hasPositiveDelta]);

  const rowsWithPositiveImpact = useMemo(() => tableRows.filter(row => row.cmiImpact >= 0), [tableRows]);
  const rowsWithNegativeImpact = useMemo(() => tableRows.filter(row => row.cmiImpact < 0), [tableRows]);

  const togglePositivePanel = useCallback(() => setPositivePanelToggled(!positivePanelToggled), [positivePanelToggled]);
  const toggleNegativePanel = useCallback(() => setNegativePanelToggled(!negativePanelToggled), [negativePanelToggled]);

  const positiveImpactTable = (
    <MemoizedImpactTableWithPanel
      {...props}
      panelTitle={'DRG Secondary Conditions with Positive Impacts on CMI'}
      panelIsToggled={positivePanelToggled}
      onPanelToggle={togglePositivePanel}
      tableRows={rowsWithPositiveImpact}
      noImpactMessage="There are no DRG Secondary Conditions with Positive Impacts on CMI to display"
      sortDirection="desc"
    />
  );

  const negativeImpactTable = (
    <MemoizedImpactTableWithPanel
      {...props}
      panelTitle={'DRG Secondary Conditions with Negative Impacts on CMI'}
      panelIsToggled={negativePanelToggled}
      onPanelToggle={toggleNegativePanel}
      tableRows={rowsWithNegativeImpact}
      noImpactMessage="There are no DRG Secondary Conditions with Negative Impacts on CMI to display"
      sortDirection="asc"
    />
  );

  return (
    <Box>
      {hasPositiveDelta ? (
        <>
          {positiveImpactTable}
          {negativeImpactTable}
        </>
      ) : (
        <>
          {negativeImpactTable}
          {positiveImpactTable}
        </>
      )}
    </Box>
  );
};

export default SeverityImpactTables;
