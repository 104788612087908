/* This hook returns presentational information for chart dataset types including
    1. Chart type (line or graph)
    2. Color (hex color)
    3. Line type
    4. If it is part of the chart display, data table display, or both
    5. If bolded (used in data table)
    6. If underlined (used in data table)
    7. Classes for table cells
    8. Presentation label
*/
import { ChartDataSetType } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChartTypes, LineTypes } from '@clinintell/components/Chart/types';
import { userCan } from '@clinintell/containers/authentication/rules';
import { useUser } from '@clinintell/modules/store';
import { Metrics } from '@clinintell/modules/metricsNavigation';

export interface ChartStyles {
  id: ChartDataSetType;
  chartType: keyof typeof ChartTypes;
  color: string;
  lineType: keyof typeof LineTypes;
  tableClassName: string;
  label: string;
  pointStyle?: 'circle' | 'dash' | 'line';
  tooltip?: string;
}

type Output = {
  [key in ChartDataSetType]: ChartStyles;
};

const useDataTableStyles = makeStyles(theme => ({
  bolded: {
    fontWeight: 700
  },
  underlined: {
    borderTop: `1px solid ${theme.palette.chart.black.main}`
  },
  notDisplayed: {
    display: 'none',
    height: '0px !important'
  }
}));

const useChartStyles = (metric: keyof typeof Metrics, historicalAvgHigher?: boolean): Output => {
  const { bolded, underlined, notDisplayed } = useDataTableStyles();
  const theme = useTheme();
  const user = useUser();

  return {
    Actual: {
      id: 'Actual',
      chartType: 'Line',
      color: theme.palette.chart.black.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: metric === 'cmi' || metric === 'severityCmi' || metric === 'los' ? 'Observed' : 'O/CE'
    },
    ComparisonEntity: {
      id: 'ComparisonEntity',
      chartType: 'Line',
      color: theme.palette.blue.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: 'Comparison Entity'
    },
    ClinicallyExpected: {
      id: 'ClinicallyExpected',
      chartType: 'Line',
      color: theme.palette.chart.green.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: 'Clinically Expected'
    },
    Discharges: {
      id: 'Discharges',
      chartType: 'Line',
      color: theme.palette.chart.black.main,
      lineType: 'Solid',
      tableClassName: '',
      label: 'Cases'
    },
    Gap: {
      id: 'Gap',
      chartType: 'Bar',
      color: theme.palette.chart.blue.light,
      lineType: 'Bar',
      tableClassName: `${bolded} ${underlined}`,
      label: metric === 'los' ? 'LOS Severity Gap' : 'Gap'
    },
    HCUPAvg: {
      id: 'HCUPAvg',
      chartType: 'Line',
      color: theme.palette.chart.grey.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: 'HCUP Avg'
    },
    CEHCUPAvg: {
      id: 'CEHCUPAvg',
      chartType: 'Line',
      color: theme.palette.chart.green.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: 'CE HCUP Avg'
    },
    Secondary: {
      id: 'Secondary',
      chartType: 'Line',
      color: theme.palette.chart.black.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: 'Secondary'
    },
    Total: {
      id: 'Total',
      chartType: 'Line',
      color: theme.palette.chart.black.main,
      lineType: 'Dashed',
      tableClassName: '',
      label: 'Total'
    },
    CESecondary: {
      id: 'CESecondary',
      chartType: 'Line',
      color: theme.palette.chart.green.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: 'CE Secondary'
    },
    // we don't have specific feature for this.  Needs to be clinintell internal only like usersEdit
    SecondarySingle: {
      id: 'SecondarySingle',
      chartType: 'Line',
      color: theme.palette.chart.blue.main,
      lineType: 'Solid',
      tableClassName: userCan(user, ['conditionSingle']) ? '' : notDisplayed,
      label: 'Secondary (Single)'
    },
    AvgImpactSingle: {
      id: 'AvgImpactSingle',
      chartType: 'Bar',
      color: theme.palette.chart.blue.light,
      lineType: 'Bar',
      tableClassName: userCan(user, ['conditionSingle']) ? '' : notDisplayed,
      label: 'Avg Impact (Single)'
    },
    SecondaryDual: {
      id: 'SecondaryDual',
      chartType: 'Line',
      color: theme.palette.chart.brown.main,
      lineType: 'Solid',
      tableClassName: userCan(user, ['conditionSingle']) ? '' : notDisplayed,
      label: 'Secondary (Dual)'
    },
    AvgImpactDual: {
      id: 'AvgImpactDual',
      chartType: 'Bar',
      color: theme.palette.chart.brown.light,
      lineType: 'Bar',
      tableClassName: userCan(user, ['conditionSingle']) ? '' : notDisplayed,
      label: 'Avg Impact (Dual)'
    },
    TargetDocScore: {
      id: 'TargetDocScore',
      chartType: 'Line',
      color: theme.palette.chart.blue.main,
      lineType: 'Dashed',
      tableClassName: bolded,
      label: 'Target',
      pointStyle: 'dash'
    },
    CurrentAverageOtoCE: {
      id: 'CurrentAverageOtoCE',
      chartType: 'Line',
      lineType: 'Dashed',
      color: historicalAvgHigher ? theme.palette.error.light : theme.palette.success.light,
      tableClassName: bolded,
      label: 'Current Avg',
      pointStyle: 'dash'
    },
    HistoricalAverageOtoCE: {
      id: 'HistoricalAverageOtoCE',
      chartType: 'Line',
      lineType: 'Dashed',
      color: historicalAvgHigher ? theme.palette.success.light : theme.palette.error.light,
      tableClassName: bolded,
      label: 'Historical Avg',
      pointStyle: 'dash'
    },
    OtoERatio: {
      id: 'OtoERatio',
      chartType: 'Line',
      color: theme.palette.chart.black.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: 'O/CE'
    },
    OtoECE: {
      id: 'OtoECE',
      chartType: 'Line',
      color: theme.palette.chart.green.main,
      lineType: 'Solid',
      tableClassName: bolded,
      label: 'Clinically Expected'
    },
    COVID: {
      id: 'COVID',
      chartType: 'Bar',
      color: 'rgba(181, 219, 255, 0.5)',
      lineType: 'Bar',
      tableClassName: '',
      label: 'COVID',
      tooltip: 'Click to display total coded rate of COVID'
    }
  };
};

export default useChartStyles;
