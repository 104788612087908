import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Tooltip } from '@clinintell/components/index';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import {
  useDashboard2,
  useMenuNavigationDispatch,
  useCMIAnalysisDispatch,
  useCMIAnalysisSelectionsDispatch
} from '@clinintell/modules/store';
import { setActiveMenuItem } from '@clinintell/modules/menuNavigation';
import { setAnalysisType } from '@clinintell/modules/cmiAnalysisSelections';
import { setCMIAnalysisView, resetCMIAnalysis } from '@clinintell/modules/cmiAnalysis';
import { NodeTypeEnum } from '@clinintell/containers/metrics/typings/metricTypes';
import Widget from '../../components/widget/Widget';
import WidgetModal from '../../components/modal/WidgetModal';
import DashboardPieChart, { ChartLegendType } from '../../charts/DashboardPieChart';
import CmiAnalysisWidgetTable from './CmiAnalysisWidgetTable';
import DRGAnalysisModal from './DRGAnalysisModal';
import SeverityValueModal from './SeverityValueModal';
import useCmiAnalysis from '../../hooks/useCmiAnalysis';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';

const useStyles = makeStyles(theme => ({
  CmiAnalysisContainer: {
    display: 'grid',
    minWidth: '301px',
    gridTemplateRows: '.5fr 1fr .5fr'
  },
  CmiAnalysisChartLegend: {
    display: 'flex',
    height: 'fit-content',
    gap: 1,
    justifyContent: 'center',
    marginTop: '30px'
  }
}));

const CmiAnalysisWidget: React.FC = () => {
  const theme = useTheme();
  const { CmiAnalysisContainer, CmiAnalysisChartLegend } = useStyles();
  const navigate = useNavigate();
  const menuNavigationDispatch = useMenuNavigationDispatch();
  const cmiAnalysisDispatch = useCMIAnalysisDispatch();
  const cmiAnalysisSelectionDispatch = useCMIAnalysisSelectionsDispatch();
  const { entity, groupName: group, groupId } = useDashboard2();
  const cmiAnalysisData = useCmiAnalysis();
  const [severityPercentage, setSeverityPercentage] = useState(0);
  const [drgPercentage, setDrgPercentage] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalAnalysisType, setModalAnalysisType] = useState<'drgmix' | 'severity'>('drgmix');

  useEffect(() => {
    setHasError(cmiAnalysisData.hasError);
    setIsLoading(cmiAnalysisData.isLoading);
    setSeverityPercentage(Number(cmiAnalysisData.severityPercentage));
    setDrgPercentage(Number(cmiAnalysisData.drgPercentage));
  }, [cmiAnalysisData]);

  const pieChartData = {
    labels: ['Severity Value', 'DRG Mix Value'],
    datasets: [
      {
        data: [
          severityPercentage > 0 ? Number(formatToDecimalPlaces(severityPercentage, 0)) : 0,
          drgPercentage > 0 ? Number(formatToDecimalPlaces(drgPercentage, 0)) : 0
        ],
        backgroundColor: [theme.palette.teal.light3, theme.palette.red[300]],
        hoverOffset: 8
      }
    ]
  };
  const pieChartLegends: ChartLegendType[] = [
    {
      label: 'DRG Mix Value',
      color: theme.palette.red[500],
      tooltip: 'The component of CMI that is driven by the DRG mix, which fluctuates from month-to-month.'
    },
    {
      label: 'Severity Value',
      color: theme.palette.teal.light5,
      tooltip: 'The component of CMI that is driven by accurate documentation and coding of comorbid conditions.'
    }
  ];

  const getFullOrgTitle = useMemo(() => {
    if (group && group !== 'All') return `${entity?.name} - ${group}`;
    if (entity) return entity.name;
    return '';
  }, [entity, group]);
  const widgetTooltipEntity = useMemo(() => {
    if (entity?.nodeTypeId === NodeTypeEnum.Entity) return `${entity.name} system `;
    return `${entity?.name} - ${group} group's `;
  }, [entity, group]);

  const handleOnChartSliceClick = (_?: globalThis.MouseEvent, elements?: unknown[]): void => {
    if (!elements || !Object.entries(elements).length) return;
    const { _index: index } = elements[0] as { _index: number; _datasetIndex: number };
    const isDrg = pieChartData.labels[index].toLowerCase().includes('drg');

    setModalTitle(`${getFullOrgTitle} ${pieChartData.labels[index]}`);
    setModalAnalysisType(isDrg ? 'drgmix' : 'severity');
    setOpenModal(true);
  };

  const WidgetModalMemo = useMemo(
    () => (
      <WidgetModal
        modalVisible={openModal}
        toggleVisible={() => setOpenModal(!openModal)}
        redirectUserHandler={(): void => {
          const orgId = groupId && groupId !== entity?.id ? groupId : entity ? entity.id : -1;
          const orgTitle = group && group !== 'All' ? `${entity?.name} - ${group}` : entity ? entity.name : '';
          navigate('/analytics/cmianalysis');
          cmiAnalysisDispatch(resetCMIAnalysis());
          cmiAnalysisSelectionDispatch(
            setAnalysisType({ analysisType: 'single', entity: { id: orgId, name: orgTitle } })
          );
          cmiAnalysisDispatch(setCMIAnalysisView({ analysisView: modalAnalysisType }));
          menuNavigationDispatch(setActiveMenuItem({ name: 'cmiAnalysis' }));
        }}
        tooltipMsg="Navigate to CMI Analysis"
        headerTitle={modalTitle}
      >
        {modalAnalysisType === 'drgmix' ? <DRGAnalysisModal /> : <SeverityValueModal />}
      </WidgetModal>
    ),
    [
      cmiAnalysisDispatch,
      cmiAnalysisSelectionDispatch,
      entity,
      group,
      groupId,
      menuNavigationDispatch,
      modalAnalysisType,
      modalTitle,
      navigate,
      openModal
    ]
  );

  return (
    <>
      <Widget
        isLoading={isLoading}
        hasError={hasError}
        graphMessage={hasError ? cmiAnalysisData.errorMessage : undefined}
        textOnly={hasError && cmiAnalysisData.errorMessage ? true : undefined}
        headerTitle="CMI Analysis"
        toolTip={
          `This widget displays the change in ` +
          widgetTooltipEntity +
          `CMI and explains how much each component of CMI ` +
          `(severity value or patient mix value) contributes to the change.`
        }
        displayTooltip={true}
      >
        {!isLoading && (
          <Box className={CmiAnalysisContainer}>
            <CmiAnalysisWidgetTable />
            <DashboardPieChart
              data={pieChartData}
              handleOnChartSliceClick={handleOnChartSliceClick}
              testId="cmiAnalysisWidget"
            />
            {pieChartLegends && (
              <Box className={CmiAnalysisChartLegend}>
                {pieChartLegends.map((legend: ChartLegendType, ix) => {
                  return (
                    <Box key={`${legend.label}-${ix}`} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {ix > 0 && <Box> | </Box>}
                      <Typography variant="p2" sx={{ fontWeight: 'bold', color: legend.color ?? 'grey.500' }}>
                        {legend.label}
                      </Typography>
                      {legend.tooltip && (
                        <Tooltip content={legend.tooltip}>
                          <HelpOutlineIcon sx={{ height: 18, width: 18, color: 'grey.500', ml: -0.5 }} />
                        </Tooltip>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        )}
      </Widget>
      {WidgetModalMemo}
    </>
  );
};

export default CmiAnalysisWidget;
