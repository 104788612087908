import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { ColumnName } from '../typings/tableSchemas';
import { useMetricsNavigation } from '@clinintell/modules/store';

type UseMetricTooltipsType = {
  getTooltip: (column: string, metric: string, view: string) => JSX.Element | undefined;
};

const useMetricTooltips = (): UseMetricTooltipsType => {
  const { dataTrend } = useMetricsNavigation();

  const getTooltip = useCallback(
    (col: string, metric: string, view: string): JSX.Element | undefined => {
      const column = col as ColumnName;
      if (column === 'otoEChange' || column === 'change') {
        if (
          metric === 'cmi' &&
          (view === 'improvement' || view === 'improvementRange' || view === 'improvementAndOpportunity')
        ) {
          return (
            <Box>
              <p>Change is the difference between your current and historical performance.</p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Green – A statistically significant increase</li>
                  <li>Red – A statistically significant decrease</li>
                  <li>No bubble – Not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (
          (metric === 'docScore' ||
            metric === 'elixhauserMortality' ||
            metric === 'elixhauserReadmission' ||
            metric === 'psi' ||
            metric === 'raf' ||
            metric === 'allConditions' ||
            metric === 'condition' ||
            metric === 'targetedConditions') &&
          (view === 'change' || view === 'changeAndGap')
        ) {
          return (
            <Box>
              <p>Change is the difference between your current and historical performance.</p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Green – A statistically significant increase</li>
                  <li>Red – A statistically significant decrease</li>
                  <li>No bubble – Not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (metric === 'severityCmi' && (view === 'improvement' || view === 'improvementRange')) {
          return (
            <Box>
              <p>Change is the difference between your current and historical performance.</p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Green – A statistically significant increase</li>
                  <li>Red – A statistically significant decrease</li>
                  <li>No bubble – Not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (metric === 'los' && (view === 'improvement' || view === 'improvementAndOpportunity')) {
          return (
            <Box>
              <p>Change is the difference between your current and historical performance.</p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Green – A statistically significant increase</li>
                  <li>Red – A statistically significant decrease</li>
                  <li>No bubble – Not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
      }

      if (column === 'otoEGapVsCe' || column === 'gapVsCe') {
        if (metric === 'docScore' && (view === 'changeAndGap' || view === 'gap')) {
          return (
            <Box>
              <p>
                Gap is the percentage of unreported MS-DRG severity relative to the total available MS-DRG severity
                (100%) in the unique patient mix.
              </p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (
          metric === 'cmi' &&
          (view === 'opportunity' || view === 'opportunityRange' || view === 'improvementAndOpportunity')
        ) {
          return (
            <Box>
              <p>
                Gap is the unreported MS-DRG severity relative to full available MS-DRG severity in the unique patient
                mix.
              </p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (metric === 'los' && (view === 'opportunity' || view === 'improvementAndOpportunity')) {
          return (
            <Box>
              <p>Gap is the number of days of Expected LOS improvement that the CMI Gap represents.</p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (metric === 'elixhauserMortality' && (view === 'changeAndGap' || view === 'gap')) {
          return (
            <Box>
              <p>
                Gap is the percentage of unreported AHRQ Mortality risk adjustment value relative to total available
                value (100%) in the unique inpatient mix.
              </p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (metric === 'elixhauserReadmission' && (view === 'changeAndGap' || view === 'gap')) {
          return (
            <Box>
              <p>
                Gap is the percentage of unreported AHRQ Readmission risk adjustment value relative to total available
                value (100%) in the unique inpatient mix.
              </p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (metric === 'psi' && (view === 'changeAndGap' || view === 'gap')) {
          return (
            <Box>
              <p>
                Gap is the percentage of unreported AHRQ PSI risk adjustment value relative to total available value
                (100%) in the unique inpatient mix.
              </p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (metric === 'raf' && (view === 'changeAndGap' || view === 'gap')) {
          return (
            <Box>
              <p>
                Gap is the percentage of unreported IP RAF value relative to total available value (100%) in the unique
                inpatient mix.
              </p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (
          (metric === 'allConditions' || metric === 'targetedConditions' || metric === 'condition') &&
          (view === 'changeAndGap' || view === 'opportunityRW' || view === 'gap') &&
          dataTrend === 'codedrate'
        ) {
          return (
            <Box>
              <p>
                Gap is the percent of the patient population for which a clinical condition was underreported as a
                secondary diagnosis.
              </p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
        if (
          (metric === 'allConditions' || metric === 'targetedConditions' || metric === 'condition') &&
          (view === 'changeAndGap' || view === 'gap') &&
          dataTrend === 'otoce'
        ) {
          return (
            <Box>
              <p>
                Gap is the percent of the patient population for which a clinical condition was underreported as a
                secondary diagnosis relative to expected (100%).
              </p>
              <div>
                The bubble color indicates:
                <ul style={{ marginLeft: -16 }}>
                  <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                  <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                  <li>No bubble – Gap is not statistically significant</li>
                </ul>
              </div>
            </Box>
          );
        }
      }

      if (column.toLowerCase().includes('opportunity')) {
        if (
          (metric === 'cmi' || metric === 'los') &&
          (view === 'opportunity' || view === 'improvementAndOpportunity')
        ) {
          if (metric === 'los') {
            return (
              <Box>
                <p>
                  The increase in Expected LOS days if a CMI gap is fully closed (i.e., CMI Doc. Score increased to
                  100%).
                </p>
              </Box>
            );
          }
          return (
            <Box>
              <p>
                Opportunity in Relative Weight points is calculated by multiplying the Gap and Cases. Please note that
                the calculation uses the full precision Gap and not the displayed Gap which is rounded. For example, if
                the full precision Gap is 0.04935, then 0.05 would be displayed.
              </p>
              <p>
                Opportunity in Amount ($) is calculated by multiplying the Gap, Cases and the facility’s blended rate.
                Contact us at support@clinintell.com to find out what we’re using as your facility’s blended rate.
              </p>
            </Box>
          );
        }
        if (
          (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions') &&
          view === 'opportunityRW'
        ) {
          return (
            <Box>
              <p>
                Estimate of the RW value of the clinical condition gap based on all the claims for which it was the only
                CC or MCC for the particular provider, physician group or hospital.
              </p>
            </Box>
          );
        }
      }

      if (
        column.toLowerCase().includes('improvement') &&
        (metric === 'cmi' || metric === 'severityCmi') &&
        (view === 'improvement' || view === 'improvementAndOpportunity')
      ) {
        return (
          <Box>
            <p>
              Improvement in Relative Weight points is calculated by multiplying the Change and Cases. Please note that
              the calculation uses the full precision Change and not the displayed change which is rounded. For example,
              if the full precision Change is 0.04935, then 0.05 would be displayed.
            </p>
            <p>
              Improvement in Amount ($) is calculated by multiplying the Change, Cases and the facility’s blended rate.
              Contact us at support@clinintell.com to find out what we’re using as your facility’s blended rate.
            </p>
          </Box>
        );
      }

      return undefined;
    },
    [dataTrend]
  );

  return {
    getTooltip
  };
};

export default useMetricTooltips;
