import { AutoCompleteChoiceType, ReasonType } from '@clinintell/components/AutoComplete';
import useAutoCompleteInputHandler from '@clinintell/components/AutoComplete/useAutoCompleteInputHandler';
import { AutocompleteRenderOptionState } from '@mui/material';
import { HTMLAttributes, SyntheticEvent, useState } from 'react';
import useFetchOrgsBySearch from './useFetchOrgsBySearch';

export type UseControlledEntitySearchProps = {
  minCharactersToSearch: number;
  maxCharactersToSearch: number;
  useSAF?: boolean;
  defaultValue?: AutoCompleteChoiceType | Partial<AutoCompleteChoiceType>;
};

export type UseControlledEntitySearchOutput = {
  searchValue: string;
  debouncedSearchValue: string;
  handleTextChange: (e: SyntheticEvent<Element, Event> | null, value: string, reason: ReasonType) => void;
  handleInputChange: (
    e: SyntheticEvent<Element, Event>,
    value: AutoCompleteChoiceType | null | AutoCompleteChoiceType[]
  ) => void;
  isSearching: boolean;
  selectedEntity: AutoCompleteChoiceType | undefined;
  selectOptions: AutoCompleteChoiceType[];
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    opt: AutoCompleteChoiceType,
    state: AutocompleteRenderOptionState
  ) => JSX.Element;
  getOptionLabel: (opt: AutoCompleteChoiceType) => string;
};

const useControlledEntitySearch = ({
  minCharactersToSearch,
  maxCharactersToSearch,
  useSAF,
  defaultValue
}: UseControlledEntitySearchProps): UseControlledEntitySearchOutput => {
  const [entitySearchTerm, setEntitySearchTerm] = useState(defaultValue?.childName || '');
  const [selectedEntity, setSelectedEntity] = useState<
    AutoCompleteChoiceType | Partial<AutoCompleteChoiceType> | undefined
  >(defaultValue);

  const {
    debouncedBaseSearchTerm,
    handleTextChange,
    handleInputChange,
    renderOption,
    getOptionLabel
  } = useAutoCompleteInputHandler({
    baseSearchTerm: entitySearchTerm,
    onTextClear: () => setEntitySearchTerm(''),
    onTextInput: (value: string) => setEntitySearchTerm(value),
    onSelected: (selection: AutoCompleteChoiceType) => setSelectedEntity(selection),
    minCharactersToSearch,
    maxCharactersToSearch
  });

  const { orgs, isLoading } = useFetchOrgsBySearch({
    searchValue: debouncedBaseSearchTerm,
    useSAF: useSAF === undefined ? false : useSAF
  });

  return {
    searchValue: entitySearchTerm,
    handleTextChange,
    handleInputChange,
    selectOptions: orgs,
    debouncedSearchValue: debouncedBaseSearchTerm,
    isSearching: isLoading,
    selectedEntity: selectedEntity as AutoCompleteChoiceType,
    renderOption,
    getOptionLabel
  };
};

export default useControlledEntitySearch;
