import { useRef, useEffect, MutableRefObject } from 'react';

const useStateValueForEventListener = <T>(value: T): MutableRefObject<T> => {
  const stateRef = useRef(value);

  useEffect(() => {
    stateRef.current = value;
  }, [value]);

  return stateRef;
};

export default useStateValueForEventListener;
