import { MenuNames, setActiveMenuItem } from '@clinintell/modules/menuNavigation';
import { useMenuNavigationDispatch, useMenuNavigationState, useAppConfig } from '@clinintell/modules/store';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const routeShouldPerformExactMatch = (route: MenuNames): boolean => {
  return route === 'dashboard';
};

const findExactMatch = (baseString: string, comparisonString: string): boolean => {
  return baseString === comparisonString;
};

const findInclusionMatch = (baseString: string, comparisonString: string): boolean => {
  return baseString.includes(comparisonString);
};

const useDefaultActiveMenuItemFromRoute = () => {
  const [defaultRoute, setDefaultRoute] = useState('');

  const { pathname } = useLocation();
  const { menuItems } = useMenuNavigationState();
  const menuNavigationDispatch = useMenuNavigationDispatch();

  const { isBDTenant } = useAppConfig();

  useEffect(() => {
    if (defaultRoute) {
      return;
    }

    const activeMenuItem = menuItems
      .filter(menuItem => {
        if (isBDTenant) return menuItem.name !== 'dashboard';
        return true;
      })
      .find(menuItem =>
        routeShouldPerformExactMatch(menuItem.name)
          ? findExactMatch(pathname, menuItem.link)
          : findInclusionMatch(pathname, menuItem.link)
      );
    if (!activeMenuItem) {
      return;
    }

    let activeMenuItemName = activeMenuItem.name;
    if (activeMenuItem.children.length > 0) {
      const activeChildMenuItem = activeMenuItem.children.find(childMenuItem =>
        routeShouldPerformExactMatch(childMenuItem.name)
          ? findExactMatch(pathname, childMenuItem.link)
          : findInclusionMatch(pathname, childMenuItem.link)
      );
      if (activeChildMenuItem) {
        activeMenuItemName = activeChildMenuItem.name;
      }
    }

    setDefaultRoute(activeMenuItemName);
    menuNavigationDispatch(setActiveMenuItem({ name: activeMenuItemName }));
  }, [defaultRoute, menuItems, menuNavigationDispatch, pathname, isBDTenant]);
};

export default useDefaultActiveMenuItemFromRoute;
