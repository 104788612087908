import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import Widget from '../../components/widget/Widget';
import { useDashboard2 } from '@clinintell/modules/store';
import { DashboardWidget } from '@clinintell/modules/dashboard2';
import { ChartDataSet } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { parsePeriodIntoDate } from '@clinintell/utils/formatting';
import { parseDateIntoPeriod, formatToDecimalPlaces } from '@clinintell/utils/formatting';
import DashboardLineChart from '@clinintell/containers/dashboard/charts/DashboardLineChart';
import { CMIMetricRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';

const AHRQMortalityRAWidget: React.FC = () => {
  // Redux Data
  const { ahrqMortalityRAWidget: widget, entity, groupName: group } = useDashboard2();
  const { hasError, modalGraphData, modalTableData, isLoading } = widget as DashboardWidget;

  // Component States
  const [ahrqMortalityData, setAhrqMortalityData] = useState<
    {
      date: Date | string | null;
      value: number;
    }[]
  >([]);
  const [lowerBoundDate, setLowerBoundDate] = useState('');
  const [upperBoundDate, setUpperBoundDate] = useState('');
  const [startingBoundAverage, setStartingBoundAverage] = useState(0);
  const [endingBoundAverage, setEndingBoundAverage] = useState(0);

  const getOrgTitle = useMemo(() => {
    if (group && group !== 'All') return group;
    if (entity) return entity.name;
    return '';
  }, [entity, group]);

  const widgetTooltip = (
    <Box width={300} my={-1}>
      <p>{`This widget displays the AHRQ Mortality RA for ${getOrgTitle} for ${lowerBoundDate} compared to ${upperBoundDate}.`}</p>
    </Box>
  );

  useEffect(() => {
    if (isLoading || !modalGraphData || !modalTableData) return;
    const { dataSet } = modalGraphData as ChartDataSet;
    if (dataSet === undefined || dataSet.length === 0) return;
    const sortedDocData = dataSet
      .map(elem => ({
        date: elem.name !== null ? parsePeriodIntoDate(elem.name) : '',
        value: elem.value !== null ? elem.value : 0
      }))
      .sort((a, b) => {
        if (a.date !== null && b.date !== null) {
          if (a.date < b.date) return -1;
          else if (a.date > b.date) return 1;
          else return 0;
        } else return 0;
      });
    const metricFooter = (modalTableData as CMIMetricRecordJSON[]).filter(record => record.isFooter)[0];
    setStartingBoundAverage(metricFooter.historical);
    setEndingBoundAverage(metricFooter.current);
    setAhrqMortalityData(sortedDocData);
  }, [modalGraphData, modalTableData, isLoading]);

  useEffect(() => {
    if (
      (ahrqMortalityData !== undefined && ahrqMortalityData.length > 0) ||
      (ahrqMortalityData !== null && ahrqMortalityData.length > 0)
    ) {
      setLowerBoundDate(
        `${parseDateIntoPeriod(ahrqMortalityData[0].date as Date)}-${parseDateIntoPeriod(
          ahrqMortalityData[11].date as Date
        )} (${formatToDecimalPlaces(startingBoundAverage, 0)}%)`
      );
      setUpperBoundDate(
        `${parseDateIntoPeriod(ahrqMortalityData[12].date as Date)}-${parseDateIntoPeriod(
          ahrqMortalityData[ahrqMortalityData.length - 1].date as Date
        )} (${formatToDecimalPlaces(endingBoundAverage, 0)}%)`
      );
    }
  }, [ahrqMortalityData, endingBoundAverage, startingBoundAverage]);

  const chartNumData = ahrqMortalityData.map(elem => elem.value as number);

  return (
    <Widget
      isLoading={isLoading}
      hasError={hasError}
      headerTitle="AHRQ Mortality RA"
      toolTip={!hasError ? widgetTooltip : undefined}
      displayTooltip={true}
      smallWidget={true}
      textOnly={true}
    >
      <DashboardLineChart
        averageHistoric={startingBoundAverage}
        averageCurrent={endingBoundAverage}
        chartData={chartNumData}
      />
    </Widget>
  );
};

export default AHRQMortalityRAWidget;
