export interface Auth0Config {
  endpointUrl: string;
  clientId: string;
  audienceId: string;
  tenant: string;
  realm: string;
  useConfidentialityStatement: boolean;
  confidentialityStatement: string;
}

// Actions
enum Auth0ConfigActions {
  SET_AUTH0_CONFIG = 'SET_AUTH0_CONFIG'
}

export interface Auth0ConfigAction {
  type?: keyof typeof Auth0ConfigActions;
}

export interface Auth0ConfigActionWithPayload extends Auth0ConfigAction {
  payload?: Auth0Config;
}

export const defaultAuth0State: Auth0Config = {
  endpointUrl: '',
  clientId: '',
  audienceId: '',
  tenant: '',
  realm: '',
  useConfidentialityStatement: false,
  confidentialityStatement: ''
};

// Reducer
const reducer = (state: Auth0Config = defaultAuth0State, action: Auth0ConfigActionWithPayload): Auth0Config => {
  switch (action.type) {
    case 'SET_AUTH0_CONFIG': {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
