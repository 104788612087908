import { Legend, LegendItemProps } from '@clinintell/components/Chart/Legend';
import { SliderOptions, SliderRange } from '@clinintell/components/Chart/plugins/sliderPlugin';
import useGraphContainerProps, {
  UseGraphContainerProps
} from '@clinintell/containers/metricsTimeSeries/logic/useGraphContainerProps';
import { parseDateIntoPeriod, parsePeriodIntoDate } from '@clinintell/utils/formatting';
import { Box, useTheme } from '@mui/material';
import Chart from '@clinintell/components/Chart/Chart';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { setCMIComparisonSettings, setComparisonRecords } from '@clinintell/modules/cmiComparison';
import useStateValueForEventListener from '@clinintell/utils/useStateValueForEventListener';
import { containerBorderRadius } from '@clinintell/theme/theme';
import { useCMIComparisonDispatch, useCMIComparisonState } from '@clinintell/modules/store';

type AnalysisChartProps = {
  chartTitle: string;
  yAxisLabelOverride?: string;
};

const COLOR = '#369EA8';

const ComparisonChart: React.FC<AnalysisChartProps & UseGraphContainerProps> = ({
  metric,
  dataset,
  chartPeriodType,
  chartDataSets,
  chartTitle,
  yAxisLabelOverride
}) => {
  const theme = useTheme();

  const {
    comparisonMaxDate,
    comparisonMinDate,
    orgName,
    comparisonOrgName,
    orgId,
    comparisonOrgId,
    orgTreeType
  } = useCMIComparisonState();

  const cmiComparisonDispatch = useCMIComparisonDispatch();
  const comparisonMinDateRef = useStateValueForEventListener(comparisonMinDate);
  const comparisonMaxDateRef = useStateValueForEventListener(comparisonMaxDate);

  const handleStart = (_val: string, value: string): void => {
    const newComparisonMinDate = parsePeriodIntoDate(value)?.toString();
    const updatedComparisonMaxDate = comparisonMaxDateRef.current.toString();

    if (!newComparisonMinDate) {
      return;
    }

    cmiComparisonDispatch(
      setCMIComparisonSettings({
        comparisonMinDate: newComparisonMinDate,
        comparisonMaxDate: updatedComparisonMaxDate,
        orgTreeType
      })
    );

    if (orgId === undefined || comparisonOrgId === undefined) {
      return;
    }

    cmiComparisonDispatch(
      setComparisonRecords({
        orgId,
        comparisonOrgId,
        comparisonMin: newComparisonMinDate,
        comparisonMax: updatedComparisonMaxDate,
        orgTreeType
      })
    );
  };

  const handleEnd = (_val: string, value: string): void => {
    const newComparisonMaxDate = parsePeriodIntoDate(value)?.toString();
    const updatedComparisonMinDate = comparisonMinDateRef.current.toString();
    if (!newComparisonMaxDate) {
      return;
    }

    cmiComparisonDispatch(
      setCMIComparisonSettings({
        comparisonMaxDate: newComparisonMaxDate,
        comparisonMinDate: updatedComparisonMinDate,
        orgTreeType
      })
    );

    if (orgId === undefined || comparisonOrgId === undefined) {
      return;
    }

    cmiComparisonDispatch(
      setComparisonRecords({
        orgId,
        comparisonOrgId,
        comparisonMin: updatedComparisonMinDate,
        comparisonMax: newComparisonMaxDate,
        orgTreeType
      })
    );
  };

  const { yAxes, xAxis, legendItems, chartDatasets } = useGraphContainerProps({
    metric,
    dataset,
    chartPeriodType,
    chartDataSets
  });

  let yAxesSets = [...yAxes];
  if (yAxisLabelOverride) {
    yAxesSets = yAxes.map(yAxis => ({
      ...yAxis,
      title: yAxisLabelOverride
    }));
  }

  const sliders: SliderRange[] = [];
  if (comparisonMaxDate && comparisonMinDate) {
    const currentStartPeriod = parseDateIntoPeriod(new Date(comparisonMinDate));
    const currentEndPeirod = parseDateIntoPeriod(new Date(comparisonMaxDate));
    const currentStartIndex = xAxis.labels.findIndex(label => label === currentStartPeriod);
    const currentEndIndex = xAxis.labels.findIndex(label => label === currentEndPeirod);

    sliders.push({
      id: 'current',
      startIndex: currentStartIndex,
      span: currentEndIndex - currentStartIndex + 1,
      color: COLOR
    });
  }

  const sliderPluginOptions: SliderOptions = {
    sliderEnabled: true,
    canvasId: metric,
    onSliderStartChange: handleStart,
    onSliderEndChange: handleEnd,
    sliders
  };

  const legendWithSliderPeriods: LegendItemProps[] = [
    ...legendItems.map((item, index) => ({
      ...item,
      label: index === 0 ? orgName : comparisonOrgName
    })),
    {
      color: COLOR,
      label: 'Comparison Period',
      type: 'Slider'
    }
  ];

  return (
    <Box
      paddingTop={2}
      overflow="auto"
      bgcolor="white"
      border={1}
      borderColor={theme.palette.grey[300]}
      borderRadius={`${containerBorderRadius}px`}
    >
      <Box marginBottom={2}>
        <Box
          marginLeft={5}
          component="span"
          color={theme.palette.blue.main}
          fontSize={theme.typography.subheading.fontSize}
        >
          {chartTitle}
        </Box>
      </Box>
      <Legend styles={{ marginLeft: '3rem', marginBottom: '2.5rem' }} items={legendWithSliderPeriods} />
      <Chart
        customPlugins={{
          sliderPlugin: {
            ...sliderPluginOptions
          }
        }}
        title={metric}
        datasets={chartDatasets}
        yAxes={yAxesSets}
        xAxis={xAxis}
      >
        {Line}
      </Chart>
    </Box>
  );
};

export default ComparisonChart;
