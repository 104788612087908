import React, { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: `linear-gradient(67.13deg, #DFE9F3 0%, #FFFF 100%);`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      backgroundImage: '#DFE9F3',
      justifyContent: 'flex-start',
      minHeight: '100vh'
    },
    [theme.breakpoints.up('md')]: {
      height: '100vh'
    }
  },
  logo: {
    height: '5.6rem',
    width: '5.6rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4)
    }
  }
}));

interface Props {
  children: ReactElement;
}

const AuthenticationTemplate: FC<Props> = ({ children }) => {
  const { container } = useStyles();

  return (
    <Box className={container}>
      <Box position="relative">{children}</Box>
    </Box>
  );
};

export default AuthenticationTemplate;
