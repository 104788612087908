import { containerBorderRadius } from '@clinintell/theme/theme';
import { Box } from '@mui/material';
import useIsMobileView from '@clinintell/utils/useIsMobileView';
import React from 'react';

type CardSelectorProps = {
  onClick?: () => void;
};

type CardSelectorComposition = {
  Details: React.FC<CardSelectorDetailsProps>;
  Actions: React.FC;
};

export const CARD_WIDTH = '25.15rem';

export const CardSelectorLayout: React.FC = ({ children }) => {
  const isMobileView = useIsMobileView();

  return isMobileView ? (
    <>{children}</>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '25px' }}>{children}</Box>
  );
};

const CardSelector: React.FC<CardSelectorProps> & CardSelectorComposition = ({ onClick, children }) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        backgroundColor: 'shade.white',
        p: '25px',
        borderColor: 'grey.200',
        borderRadius: `${containerBorderRadius}px`,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
        minHeight: '9.55rem',
        justifyContent: 'space-between'
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

type CardSelectorDetailsProps = {
  title?: string | undefined;
  explanation?: string;
};

const Details: React.FC<CardSelectorDetailsProps> = ({ title, explanation }) => {
  return (
    <Box>
      <Box sx={{ typography: 'h6', color: 'grey.800', mb: 1 }}>{title}</Box>
      <Box sx={{ fontSize: '12px', color: 'grey.700' }}>{explanation}</Box>
    </Box>
  );
};

const Actions: React.FC = ({ children }) => {
  if (!children) {
    return null;
  }

  const childElement = Array.isArray(children)
    ? children.map((child, index) => {
        return <Box key={index}>{child}</Box>;
      })
    : children;

  return (
    <Box mt={3} display="flex" flexDirection="row">
      {childElement}
    </Box>
  );
};

CardSelector.Details = Details;
CardSelector.Actions = Actions;

export default CardSelector;
