import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
const NPITooltipIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon sx={{ ...props.sx }} viewBox="0 0 14, 14" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1161_40355)">
        <path
          d="M6.99984 12.8337C10.2215 12.8337 12.8332 10.222 12.8332 7.00033C12.8332 3.77866 10.2215 1.16699 6.99984 1.16699C3.77818 1.16699 1.1665 3.77866 1.1665 7.00033C1.1665 10.222 3.77818 12.8337 6.99984 12.8337Z"
          stroke="#64748B"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7 9.33333V7" stroke="#64748B" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 4.66699H7.00583" stroke="#64748B" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1161_40355">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default NPITooltipIcon;
