import React, { createContext, useCallback, useContext } from 'react';

export type CheckboxStatus = 'checked' | 'unchecked' | 'partialChecked';

type TableGlobalConfguration = {
  renderTooltipComponent: (text: string | JSX.Element) => JSX.Element;
  isMobileView: boolean;
};

const TableGlobalConfgurationContext = createContext<TableGlobalConfguration>({
  renderTooltipComponent: () => <></>,
  isMobileView: false
});

export const TableGlobalConfigurationProvider: React.FC<TableGlobalConfguration> = ({
  children,
  renderTooltipComponent,
  isMobileView
}) => {
  const cachedRenderTooltipComponent = useCallback((text: string | JSX.Element) => renderTooltipComponent(text), [
    renderTooltipComponent
  ]);

  return (
    <TableGlobalConfgurationContext.Provider
      value={{
        renderTooltipComponent: cachedRenderTooltipComponent,
        isMobileView
      }}
    >
      {children}
    </TableGlobalConfgurationContext.Provider>
  );
};

export const useTableGlobalConfiguration = () => {
  const context = useContext(TableGlobalConfgurationContext);
  if (context === undefined) {
    throw new Error('useTableGlobalConfiguration hook must be used within a TableGlobalConfigurationProvider');
  }

  return context;
};
