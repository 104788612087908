import React, { useState, createContext, FC } from 'react';

interface IDialogContext {
  modalVisible: boolean;
  toggleVisible: () => void;
}

const defaultState = {
  modalVisible: false,
  toggleVisible: () => void 0
};

export const ModalContext = createContext<IDialogContext>(defaultState);

export const ModalContextProvider: FC = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(defaultState.modalVisible);
  const toggleVisible = () => {
    setModalVisible(!modalVisible);
  };

  return <ModalContext.Provider value={{ modalVisible, toggleVisible }}>{children}</ModalContext.Provider>;
};
