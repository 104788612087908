import { SummaryRecord } from '@clinintell/modules/cmiAnalysis';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';
import { PatientMixAnalysisJSON } from '../types/DRGGroup';
import ResultSummaryTable from '../../../components/analysisSharedComponents/ResultSummaryTable';
import {
  netImpactMessage,
  significantNegativeImpactMessage,
  significantPositiveImpactMessage
} from '@clinintell/components/analysisSharedComponents/tooltipMessages';

type DRGResultSummaryProps = {
  drgMixSummary: SummaryRecord;
  drgAnalysis: PatientMixAnalysisJSON;
};

const DRGResultSummary: React.FC<DRGResultSummaryProps> = ({ drgMixSummary, drgAnalysis }) => {
  const roundedTotalPositiveCmiImpact = Number(formatToDecimalPlaces(drgAnalysis.totalPositiveCmiImpact, 2));
  const roundedTotalNegativeCmiImpact = Number(formatToDecimalPlaces(drgAnalysis.totalNegativeCmiImpact, 2));

  const roundedChange =
    drgMixSummary.current && drgMixSummary.historical
      ? `${formatToDecimalPlaces(
          Number(formatToDecimalPlaces(drgMixSummary.current, 2)) -
            Number(formatToDecimalPlaces(drgMixSummary.historical, 2)),
          2
        )}`
      : '-';

  const totalDifference = roundedTotalPositiveCmiImpact + roundedTotalNegativeCmiImpact;
  const roundedTotalImpact = roundedChange !== '-' ? totalDifference / Number(roundedChange) : null;
  const roundedTotalImpactLabel =
    roundedTotalImpact !== null ? `(${formatToDecimalPlaces(Math.min(roundedTotalImpact * 100, 100), 0)}%)` : '';

  return (
    <ResultSummaryTable
      rows={[
        {
          columns: [
            {
              value: '',
              width: 4,
              align: 'center',
              bold: true,
              key: 'header1'
            },
            {
              value: 'Historical',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerHistorical'
            },
            {
              value: 'Current',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerCurrent'
            },
            {
              value: 'Change',
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerChange'
            }
          ],
          key: 'headerRow'
        },
        {
          divider: 'solid',
          key: 'headerDivider'
        },
        {
          columns: [
            {
              value: 'DRG Mix Value',
              width: 4,
              align: 'left',
              bold: true,
              key: 'drgMix'
            },
            {
              value: drgMixSummary.historical ? formatToDecimalPlaces(drgMixSummary.historical, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'drgMixHistorical'
            },
            {
              value: drgMixSummary.current ? formatToDecimalPlaces(drgMixSummary.current, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'drgMixCurrent'
            },
            {
              value:
                drgMixSummary.current && drgMixSummary.historical
                  ? `${formatToDecimalPlaces(
                      Number(formatToDecimalPlaces(drgMixSummary.current, 2)) -
                        Number(formatToDecimalPlaces(drgMixSummary.historical, 2)),
                      2
                    )}`
                  : '-',
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'drgMixDifference'
            }
          ],
          key: 'drgMixRow'
        },
        {
          divider: 'solid',
          key: 'drgDividerRow'
        },
        {
          columns: [
            {
              value: 'Significant Positive Impact',
              width: 8,
              align: 'right',
              bold: true,
              key: 'positiveImpactLabel',
              rowLabelNoEllipsis: true,
              tooltip: significantPositiveImpactMessage
            },
            {
              value: formatToDecimalPlaces(drgAnalysis.totalPositiveCmiImpact, 2),
              width: 4,
              type: 'number',
              offsetCenter: true,
              align: 'left',
              key: 'positiveImpactValue'
            }
          ],
          key: 'positiveImpactRow'
        },
        {
          columns: [
            {
              value: 'Significant Negative Impact',
              width: 8,
              align: 'right',
              bold: true,
              key: 'negativeImpactLabel',
              rowLabelNoEllipsis: true,
              tooltip: significantNegativeImpactMessage
            },
            {
              value: formatToDecimalPlaces(drgAnalysis.totalNegativeCmiImpact, 2),
              width: 4,
              type: 'number',
              offsetCenter: true,
              align: 'left',
              key: 'negativeImpactValue',
              underline: true
            }
          ],
          key: 'negativeImpactRow'
        },
        {
          columns: [
            {
              value: 'Net Impact',
              width: 8,
              align: 'right',
              bold: true,
              key: 'totalLabel',
              rowLabelNoEllipsis: true,
              tooltip: netImpactMessage
            },
            {
              value: `${formatToDecimalPlaces(totalDifference, 2)} ${roundedTotalImpactLabel}`,
              width: 4,
              type: 'number',
              offsetCenter: true,
              align: 'left',
              key: 'totalValue'
            }
          ],
          key: 'totalRow'
        }
      ]}
    />
  );
};

export default DRGResultSummary;
