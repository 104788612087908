import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Report = (props: SvgIconProps) => (
  <SvgIcon
    sx={{
      width: '20px',
      height: '20px',
      fill: 'none',
      ...props.sx
    }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8333 1.66667H5.00001C4.55798 1.66667 4.13406 1.84226 3.8215 2.15482C3.50894 2.46738 3.33334 2.89131 3.33334 3.33333V16.6667C3.33334 17.1087 3.50894 17.5326 3.8215 17.8452C4.13406 18.1577 4.55798 18.3333 5.00001 18.3333H15C15.442 18.3333 15.866 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V7.5L10.8333 1.66667Z"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8333 1.66667V7.5H16.6667"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.66666 15V14.1667" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 15V11.6667" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3333 15V10" stroke="inherit" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
Report.muiName = SvgIcon.muiName;

export default Report;
