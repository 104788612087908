import React from 'react';
import AutoComplete from '@mui/material/Autocomplete';
import { Box, CircularProgress, TextField, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DropDownIcon from '@clinintell/components/icons/DropDownIcon';
import { SelectOptionType } from '@clinintell/types/common';
import { isMobile } from 'react-device-detect';

type StatusReportFilterProps = {
  value: SelectOptionType | string | undefined;
  filterOptions: SelectOptionType[];
  onChange: (value: SelectOptionType) => void;
  disabled?: boolean;
  loading: boolean;
};

const useStyles = makeStyles(theme => ({
  inputLabelRoot: {
    top: '-2px'
  }
}));

const StatusReportFilter: React.FC<StatusReportFilterProps> = ({
  filterOptions,
  value,
  onChange,
  disabled,
  loading
}) => {
  const { inputLabelRoot } = useStyles();
  const theme = useTheme();

  const classes = makeStyles(theme => ({
    root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      color: theme.palette.grey[900],
      borderRadius: 10,
      backgroundColor: 'white',
      width: isMobile ? 325 : '100%',
      marginTop: theme.spacing(1),
      '&:hover': {
        borderColor: theme.palette.grey[200]
      },
      '& .MuiFormControl-root': {
        borderRadius: 10,
        '& .MuiInputBase-formControl': {
          borderRadius: 10
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px ${theme.palette.grey[300]} solid !important`
      }
    },
    inputRoot: {
      width: isMobile ? 325 : '100%'
    },
    listbox: {
      borderRadius: 10
    },
    option: {
      color: theme.palette.grey[900],
      '&:hover': {
        backgroundColor: `${theme.palette.grey[50]} !important`
      },
      '&[aria-selected="true"]': {
        backgroundColor: `transparent !important`
      }
    },
    popper: {
      borderRadius: 10,
      border: `1px ${theme.palette.grey[300]} solid !important`,
      overflow: 'hidden',
      boxShadow: `0px 10px 15px -3px rgba(0, 0, 0, 0.05)`
    },
    clearIndicator: {
      display: 'none'
    }
  }))(theme);

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, value: string | SelectOptionType | null) => {
    onChange(value as SelectOptionType);
  };

  const selectedOptionByValue =
    value === ''
      ? null
      : value === typeof String
      ? filterOptions.find(option => option.label === value)
      : (value as SelectOptionType);

  return (
    <AutoComplete
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot,
        listbox: classes.listbox,
        option: classes.option,
        popper: classes.popper,
        clearIndicator: classes.clearIndicator
      }}
      onChange={handleChange}
      renderOption={(props, option): JSX.Element => {
        const optionLabel = typeof option === 'string' ? option : option.label;

        return (
          <Box component="li" {...props} display="flex" flexWrap="wrap" alignItems="center">
            <Box display="flex" alignContent="center">
              <Typography variant="p2" color="textPrimary" sx={{ fontWeight: optionLabel === value ? 600 : 'normal' }}>
                {optionLabel}
              </Typography>
            </Box>
          </Box>
        );
      }}
      popupIcon={<DropDownIcon />}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          InputLabelProps={{
            classes: {
              root: inputLabelRoot
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      value={selectedOptionByValue}
      options={filterOptions}
      loading
      disabled={disabled}
      // isOptionEqualToValue is required to fix the value prop,
      // but if this component is used with other AutoCompletes, this still throws a runtime warning.
      isOptionEqualToValue={option => (option as SelectOptionType).label === value}
    />
  );
};

export default StatusReportFilter;
