import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  button: {
    height: '70px',
    boxShadow: 'none',
    '&:hover': {
      background: theme.palette.blue.main,
      boxShadow: 'none'
    },
    '& > span': {
      fontSize: '1rem',
      transition: 'font-size 0.2s ease-in-out',
      height: '100%',
      letterSpacing: '1px',
      fontWeight: 700,
      '&:hover': {
        fontSize: '1.1rem'
      }
    }
  }
}));

type Auth0ButtonProps = {
  text: string;
  onClick: () => void;
};

const Auth0Button: React.FC<Auth0ButtonProps> = ({ text, onClick }) => {
  const { button } = useStyles();

  return (
    <Button
      classes={{
        contained: button
      }}
      color="primary"
      variant="contained"
      onClick={onClick}
      fullWidth
      disableRipple
    >
      {text}
    </Button>
  );
};

export default Auth0Button;
