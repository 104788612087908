import { call, put, takeEvery } from 'redux-saga/effects';
import { ApplicationAPI } from '@clinintell/utils/api';
import { SagaIterator } from 'redux-saga';

export type DefaultDatesDTO = {
  absoluteMinMonth: null | string; // hard lower limit
  historicalMinMonth: null | string;
  historicalMaxMonth: null | string;
  currentMinMonth: null | string;
  currentMaxMonth: null | string; // hard upper limit
  interventionMonth: null | string;
};

export type DefaultDatesState = {
  byId: {
    [key: string]: DefaultDatesDTO;
  };
  bySAFId: {
    [key: string]: DefaultDatesDTO;
  };
  isLoading: boolean;
  error: string;
};

export enum DefaultDatesActions {
  DEFAULT_DATES_FETCH_REQUESTED = 'DEFAULT_DATES_FETCH_REQUESTED',
  DEFAULT_DATES_FETCH_SUCCESSFUL = 'DEFAULT_DATES_FETCH_SUCCESSFUL',
  DEFAULT_DATES_FETCH_FAILED = 'DEFAULT_DATES_FETCH_FAILED',
  ADD_DEFAULT_DATES = 'ADD_DEFAULT_DATES',
  DEFAULT_SAF_DATES_FETCH_REQUESTED = 'DEFAULT_SAF_DATES_FETCH_REQUESTED',
  DEFAULT_SAF_DATES_FETCH_SUCCESSFUL = 'DEFAULT_SAF_DATES_FETCH_SUCCESSFUL',
  DEFAULT_SAF_DATES_FETCH_FAILED = 'DEFAULT_SAF_DATES_FETCH_FAILED',
  ADD_DEFAULT_SAF_DATES = 'ADD_DEFAULT_SAF_DATES'
}

export type DefaultDatesAction<T> = {
  type: keyof typeof DefaultDatesActions;
  payload: T;
};

export const initialState = {
  byId: {},
  bySAFId: {},
  isLoading: false,
  error: ''
};

export type DefaultDatesActionPayload = {
  id: string;
  defaultDates: DefaultDatesDTO;
};
function reducer(
  state: DefaultDatesState = initialState,
  action: DefaultDatesAction<DefaultDatesActionPayload | DefaultDatesState>
): DefaultDatesState {
  switch (action.type) {
    case 'ADD_DEFAULT_DATES': {
      const payload = action.payload as DefaultDatesActionPayload;
      return { ...state, byId: { ...state.byId, [payload.id]: { ...payload.defaultDates } } };
    }
    case 'DEFAULT_DATES_FETCH_FAILED': {
      const payload = action.payload as DefaultDatesState;
      return { ...state, isLoading: false, error: payload.error };
    }
    case 'DEFAULT_DATES_FETCH_REQUESTED': {
      return { ...state, isLoading: true };
    }
    case 'DEFAULT_DATES_FETCH_SUCCESSFUL': {
      return { ...state, error: '', isLoading: false };
    }
    case 'ADD_DEFAULT_SAF_DATES': {
      const payload = action.payload as DefaultDatesActionPayload;
      return { ...state, bySAFId: { ...state.bySAFId, [payload.id]: { ...payload.defaultDates } } };
    }
    case 'DEFAULT_SAF_DATES_FETCH_FAILED': {
      const payload = action.payload as DefaultDatesState;
      return { ...state, isLoading: false, error: payload.error };
    }
    case 'DEFAULT_SAF_DATES_FETCH_REQUESTED': {
      return { ...state, isLoading: true };
    }
    case 'DEFAULT_SAF_DATES_FETCH_SUCCESSFUL': {
      return { ...state, error: '', isLoading: false };
    }
    default: {
      return { ...state };
    }
  }
}

export default reducer;

export type DefaultDatesSaga = {
  type: keyof typeof DefaultDatesActions;
  payload: string;
};

export function* fetchDefaultDates(action: DefaultDatesSaga): SagaIterator {
  try {
    const response = yield call(ApplicationAPI.get, {
      endpoint: `DefaultDates/${action.payload}`
    });
    if (response.hasOwnProperty('error')) {
      throw response.error;
    }
    const ddates: DefaultDatesDTO = response.data;
    yield put({ type: 'ADD_DEFAULT_DATES', payload: { id: action.payload, defaultDates: ddates } });
    yield put({ type: 'DEFAULT_DATES_FETCH_SUCCESSFUL' });
  } catch (e) {}
}

export function* fetchDefaultSAFDates(action: DefaultDatesSaga): SagaIterator {
  try {
    const response = yield call(ApplicationAPI.get, {
      endpoint: `saf/org/DefaultDates/${action.payload}`
    });

    if (response.hasOwnProperty('error')) {
      yield put({ type: 'DEFAULT_SAF_DATES_FETCH_FAILED', payload: response.error });
      return;
    }

    const ddates: DefaultDatesDTO = response.data;
    yield put({ type: 'ADD_DEFAULT_SAF_DATES', payload: { id: action.payload, defaultDates: ddates } });
    yield put({ type: 'DEFAULT_SAF_DATES_FETCH_SUCCESSFUL' });
  } catch (e) {}
}

export function* defaultDatesSaga(): SagaIterator {
  yield takeEvery('DEFAULT_DATES_FETCH_REQUESTED', fetchDefaultDates);
}

export function* defaultSAFDatesSaga(): SagaIterator {
  yield takeEvery('DEFAULT_SAF_DATES_FETCH_REQUESTED', fetchDefaultSAFDates);
}
