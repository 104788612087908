import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@clinintell/components/index';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/* TODO : 
  Build a useStyles and create classes as it will be better to adapt the title as
  smaller screens, tablets and mobile join in
*/

type PageTitleProps = {
  title: string | JSX.Element;
  tooltip?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  mainHeaderCustomMargin?: string;
  sx?: string;
};

const useStyles = makeStyles(theme => ({
  mainHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  mainHeader: {
    width: 'auto',
    float: 'left',
    color: theme.palette.grey[700],
    fontWeight: 600
  },
  mainHeaderTooltip: {
    height: '20px',
    width: '20px',
    color: theme.palette.grey[500],
    marginLeft: '5px',
    verticalAlign: 'middle'
  },
  mainHeaderSubText: {
    color: theme.palette.grey[700],
    marginTop: '15px'
  }
}));

const PageTitle: React.FC<PageTitleProps> = ({ title, tooltip, subTitle, mainHeaderCustomMargin, sx, children }) => {
  const theme = useTheme();
  const { mainHeaderWrapper, mainHeader, mainHeaderTooltip, mainHeaderSubText } = useStyles(theme);

  return (
    <Box className={sx} marginBottom={mainHeaderCustomMargin ?? '50px'}>
      <Box className={mainHeaderWrapper} sx={{ marginBottom: subTitle === undefined ? 0 : '15px' }}>
        <Typography variant="h4" className={mainHeader}>
          {title}
          {tooltip !== undefined ? (
            typeof tooltip === 'string' ? (
              <Tooltip content={tooltip}>
                <HelpOutlineIcon className={mainHeaderTooltip} />
              </Tooltip>
            ) : (
              { tooltip }
            )
          ) : (
            <></>
          )}
        </Typography>
        {children}
      </Box>

      <Typography variant="p1" className={mainHeaderSubText}>
        {subTitle}
      </Typography>
    </Box>
  );
};

export default PageTitle;
