import { call, put, takeLatest } from 'redux-saga/effects';
import { ApplicationAPI, HttpResponseException } from '@clinintell/utils/api';
import { SagaIterator } from 'redux-saga';

export type Hospital = {
  id: number;
  hospitalName: string;
  trainingUsers: boolean;
  physicianGroups: [] | string[];
};

type HospitalDTO = {
  id: number;
  hospitalName: string;
};

//GET /api/hospitals?itemCount&pageNumber
export type HospitalsDTO = {
  itemCount: number;
  pageNumber: number;
  totalCount: number;
  hospitals: HospitalDTO[];
};

type HospitalStateData = { data: { [key: string]: Hospital } };

export type HospitalsState = {
  data: { [key: string]: Hospital };
  totalCount: number;
  error: string;
  isLoading: boolean;
  selectedHospital: string | null; // a hospital id
};

// GET /api/hospitals?search

export enum HospitalActions {
  SET_SELECTED_HOSPITAL = 'SET_SELECTED_HOSPITAL',
  SET_HOSPITALS = 'SET_HOSPITALS',
  SET_HOSPITAL_COUNT = 'SET_HOSPITAL_COUNT',
  ADD_HOSPITALS = 'ADD_HOSPITALS',
  HOSPITAL_FETCH_FAILED = 'HOSPITAL_FETCH_FAILED',
  HOSPITAL_FETCH_SUCCESSFUL = 'HOSPITAL_FETCH_SUCCESSFUL',
  HOSPITAL_FETCH_REQUESTED = 'HOSPITAL_FETCH_REQUESTED',
  HOSPITAL_FETCH_ALL_REQUESTED = 'HOSPITAL_FETCH_ALL_REQUESTED',
  HOSPITAL_FETCH_ALL_INIT = 'HOSPITAL_FETCH_ALL_INIT'
}

export type HospitalAction<T> = {
  type: keyof typeof HospitalActions;
  payload: T;
};

// Normalize State
const convertDTOtoById = (dto: HospitalsDTO): HospitalStateData | unknown => {
  return dto.hospitals.reduce((acc, val: HospitalDTO) => {
    const id = val.id.toString();
    return { ...acc, [id]: { ...val } };
  }, {});
};

// Initial State

export const initialHospitals: HospitalsState = {
  data: {},
  totalCount: 0,
  isLoading: false,
  error: '',
  selectedHospital: ''
};

// reducer

function reducer(state: HospitalsState = initialHospitals, action: HospitalAction<HospitalsState>): HospitalsState {
  switch (action.type) {
    case 'SET_SELECTED_HOSPITAL': {
      return { ...state, selectedHospital: action.payload.selectedHospital };
    }
    // most users will set all at once TODO sort out when to use a search
    case 'SET_HOSPITALS': {
      return { ...state, data: { ...action.payload.data } };
    }
    case 'SET_HOSPITAL_COUNT': {
      return { ...state, totalCount: action.payload.totalCount };
    }
    // users with access to large numbers of hospitals may need to increment
    case 'ADD_HOSPITALS': {
      return { ...state, data: { ...state.data, ...action.payload.data } };
    }
    case 'HOSPITAL_FETCH_FAILED': {
      return { ...state, error: action.payload.error, isLoading: false };
    }
    case 'HOSPITAL_FETCH_SUCCESSFUL': {
      return { ...state, error: '', isLoading: false };
    }
    case 'HOSPITAL_FETCH_REQUESTED': {
      return { data: state.data, totalCount: state.totalCount, isLoading: true, error: '', selectedHospital: '' };
    }
    case 'HOSPITAL_FETCH_ALL_INIT': {
      return {
        ...state,
        isLoading: true
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default reducer;

// Sagas

export type FetchHospitalSagaOptions = {
  search?: string; // yes
  itemCount?: number; // number
  pageNumber?: number;
};

export type HospitalActionSaga = {
  type: keyof typeof HospitalActions;
  payload: FetchHospitalSagaOptions;
};

// TODO: Rewrite how this HOSPITAL_FETCH_REQUESTED works... it has dependencies
// For now, assuming endpoint reads user info from headers, so no params for all hospitals
export function* fetchHospitalSaga(action: HospitalActionSaga): SagaIterator {
  try {
    let params = '';
    if (action.payload) {
      params = new URLSearchParams(action.payload as Record<string, string>).toString();
    }
    const response = yield call(ApplicationAPI.get, {
      endpoint: `hospitals?${params}`
    });
    const hospitalsById = convertDTOtoById(response.data) as HospitalStateData;

    yield put({ type: 'ADD_HOSPITALS', payload: { data: { ...hospitalsById } } });
    yield put({ type: 'HOSPITAL_FETCH_SUCCESSFUL' });
  } catch (exception) {
    yield put({ type: 'HOSPITAL_FETCH_FAILED', payload: { error: (exception as HttpResponseException).message } });
  }
}

// watches for HOSPITAL_FETCH_REQUESTED and kicks off fetchHospitalSaga
export function* hospitalSaga(): SagaIterator {
  yield takeLatest('HOSPITAL_FETCH_REQUESTED', fetchHospitalSaga);
}

// For most users, can preload all the hospitals.  Expect internal users
// to have too many hospitals to load all at once.
// We set a count of all available hospitals to determine whether
// user needs a search or a select option on the reports pages
export function* fetchAllHospitalSaga(action: HospitalActionSaga): SagaIterator {
  try {
    // DO NOT use search as a param.  totalCount will only count the matches.  Need total records.
    // USE hospitalSaga if there is a search
    let params = '';
    if (action.payload) {
      params = new URLSearchParams(action.payload as Record<string, string>).toString();
    }
    yield put({ type: 'HOSPITAL_FETCH_ALL_INIT' }); // update the loading state
    const response = yield call(ApplicationAPI.get, {
      endpoint: `hospitals?${params}`
    });

    if (response.error) {
      yield put({ type: 'HOSPITAL_FETCH_FAILED', payload: { error: response.error } });
    }

    const hospitalsById = convertDTOtoById(response.data) as HospitalStateData;
    yield put({ type: 'SET_HOSPITALS', payload: { data: { ...hospitalsById } } });
    yield put({ type: 'SET_HOSPITAL_COUNT', payload: { totalCount: response.data.totalCount } });
    yield put({ type: 'HOSPITAL_FETCH_SUCCESSFUL' });
  } catch (exception) {
    yield put({ type: 'HOSPITAL_FETCH_FAILED', payload: { error: (exception as HttpResponseException).message } });
  }
}

export function* allHospitalSaga(): SagaIterator {
  yield takeLatest('HOSPITAL_FETCH_ALL_REQUESTED', fetchAllHospitalSaga);
}
