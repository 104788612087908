import { Box } from '@mui/material';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import React, { useCallback } from 'react';
import Alert from '../Alert';

type PushAlertArgs = {
  message: string;
  variant: VariantType;
};

type UseAlertOutput = {
  pushAlert: (args: PushAlertArgs) => void;
};

const useAlert = (): UseAlertOutput => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseSnackbar = useCallback(
    (key: SnackbarKey) => {
      closeSnackbar(key);
    },
    [closeSnackbar]
  );

  const pushAlert = useCallback(
    ({ message, variant }: PushAlertArgs) => {
      enqueueSnackbar(message, {
        variant,
        content: key => {
          return (
            <Box id={`${key}`}>
              <Alert message={message} variant={variant} onClose={() => handleCloseSnackbar(key)} />
            </Box>
          );
        }
      });
    },
    [enqueueSnackbar, handleCloseSnackbar]
  );

  return { pushAlert };
};

export default useAlert;
