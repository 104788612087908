import React from 'react';
import { NodeTypeIds, OrgTreeTypes, TreeNode } from '@clinintell/modules/orgTree';
import Tree, { TreeProps } from './sections/Tree';
import { TreeProvider, TreeProviderProps, useTreeDispatch, useTreeState } from './logic/TreeContext';
import NodeLabelCheckbox from './sections/NodeLabelCheckbox';
import NodeLabel from './sections/NodeLabel';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: 0,
    '& ul': {
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  }
}));

export type AdditionalTreeSelectorProps = {
  type: OrgTreeTypes;
};

type TreeSelectorExtraProps = {
  nodeidloading: number | null;
};

type CheckableProps = {
  nodeTypeWithCheckboxes: keyof typeof NodeTypeIds;
  onCheckedChange: (item: number, isChecked: boolean) => void;
  checkedItems: number[];
};

export type CheckableTreeSelectorProps = Omit<TreeProps, 'renderTreeNode'> & CheckableProps & TreeSelectorExtraProps;

export const CheckableTreeSelector = (props: CheckableTreeSelectorProps): JSX.Element => {
  const { selectNode, expandNode, toggleNodes } = useTreeDispatch();
  const { expandedNodes, selectednode } = useTreeState();

  const { root } = useStyles();

  const renderTreeNode = (item: TreeNode): React.ReactNode => {
    if (item.nodeTypeId === NodeTypeIds[props.nodeTypeWithCheckboxes]) {
      return (
        <NodeLabelCheckbox
          item={item}
          isChecked={props.checkedItems.includes(item.id)}
          onChecked={(isChecked): void => props.onCheckedChange(item.id, isChecked)}
        />
      );
    }

    return (
      <NodeLabel
        isActive={selectednode === item.id}
        isLoading={props.nodeidloading === item.id}
        onClick={selectNode}
        onLoadClick={expandNode}
        item={item}
        overrideNoIndentation={item.children.filter(node => !node.isLeaf).length === 0}
        overrideNoChildren={item.nodeTypeId >= NodeTypeIds[props.maxnodetype]}
      />
    );
  };

  return (
    <TreeView
      className={`${root} id-${props.domid}`}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      onNodeToggle={(event, nodeIds): void => toggleNodes(event, nodeIds)}
      expanded={expandedNodes}
    >
      <Tree {...props} renderTreeNode={renderTreeNode} />
    </TreeView>
  );
};

type CheckableTreeSelectorWithContextProps = CheckableTreeSelectorProps & TreeProviderProps;

const CheckableTreeSelectorWithContext: React.FC<CheckableTreeSelectorWithContextProps> = ({
  onNodeSelected,
  onNodeToggled,
  rootNode,
  defaultExpandedNodes,
  selectednode,
  nodeidloading,
  maxnodetype,
  domid,
  nodeTypeWithCheckboxes,
  onCheckedChange,
  checkedItems
}) => {
  return (
    <TreeProvider
      onNodeSelected={onNodeSelected}
      onNodeToggled={onNodeToggled}
      rootNode={rootNode}
      defaultExpandedNodes={defaultExpandedNodes}
      selectednode={selectednode}
      maxnodetype={maxnodetype}
    >
      <CheckableTreeSelector
        domid={domid}
        selectednode={selectednode}
        maxnodetype={maxnodetype}
        nodeidloading={nodeidloading}
        nodeTypeWithCheckboxes={nodeTypeWithCheckboxes}
        onCheckedChange={onCheckedChange}
        checkedItems={checkedItems}
      />
    </TreeProvider>
  );
};

export default CheckableTreeSelectorWithContext;
