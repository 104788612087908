import { Theme } from '@mui/material';
import React from 'react';
import CloseIcon from '../icons/CloseIcon';

type Props = {
  width?: string | number | undefined;
  height?: string | number | undefined;
};

const ModalCloseIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <CloseIcon
      sx={{
        width: width,
        height: height,
        fill: (theme: Theme) => theme.palette.grey[200],
        transition: 'fill 100ms ease-in-out',
        '&:hover': {
          fill: theme => theme.palette.grey[300],
          cursor: 'pointer'
        },
        '& > path': {
          stroke: theme => theme.palette.grey[600],
          strokeWidth: 0
        }
      }}
    />
  );
};

export default ModalCloseIcon;
