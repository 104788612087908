import { CMIAnalysisViews } from '@clinintell/modules/cmiAnalysisSelections';
import { Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AnalysisTypeButton from './AnalysisTypeButton';

const useStyles = makeStyles(theme => ({
  topButtonBorderHack: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5),
    position: 'relative',
    '&::after': {
      content: '""',
      height: '24px',
      width: '1px',
      borderLeft: `3px solid ${theme.palette.blue.main}`,
      transform: 'rotate(25deg)',
      position: 'absolute',
      top: 'calc(100% - 2px)',
      left: '40%'
    },
    '&::before': {
      content: '""',
      height: '24px',
      width: '1px',
      borderLeft: `3px solid ${theme.palette.blue.main}`,
      transform: 'rotate(-25deg)',
      position: 'absolute',
      top: 'calc(100% - 2px)',
      left: '59%'
    }
  }
}));

type AnalysisNavigationProps = {
  analysisType: CMIAnalysisViews;
  onAnalysisChange: (type: CMIAnalysisViews) => void;
  singleEntity?: boolean;
};

const AnalysisNavigation: React.FC<AnalysisNavigationProps> = ({
  analysisType,
  onAnalysisChange,
  singleEntity = false
}) => {
  const theme = useTheme();
  const { topButtonBorderHack } = useStyles();

  return (
    <>
      <Box
        marginBottom={3}
        fontWeight={500}
        component="span"
        color={theme.palette.blue.main}
        fontSize={theme.typography.subheading.fontSize}
      >
        Select Analysis Type
      </Box>
      <Box display="flex" flexDirection="column" width="19rem">
        <Box className={topButtonBorderHack}>
          <AnalysisTypeButton
            onClick={(): void => onAnalysisChange('cmi')}
            label="CMI"
            isSelected={analysisType === 'cmi'}
          />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <AnalysisTypeButton
            onClick={(): void => onAnalysisChange('drgmix')}
            label="DRG Mix"
            isSelected={analysisType === 'drgmix'}
          />

          <AnalysisTypeButton
            onClick={(): void => onAnalysisChange('severity')}
            label="Severity"
            isSelected={analysisType === 'severity'}
          />
        </Box>
      </Box>
    </>
  );
};

export default AnalysisNavigation;
