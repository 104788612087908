import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button as MuiButton, Typography, useTheme } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { ActionPanel, Button, CIModal, ClinIntellSkeleton, PageTitle, TextInput } from '@clinintell/components/index';
import { ScheduleReportDto } from '@clinintell/api/apiSchemas';
import useScheduleReport from './logic/useScheduleReport';
import {
  ScheduleReportEmailObjType,
  ReportType,
  ScheduleReportRolesType
} from '@clinintell/containers/reports/types/actionTypes';
import SelectClinicalLeader from '@clinintell/containers/common/SelectClinicalLeader';
import ReportEmailTextBox from '../../components/reportEmailTextBox/ReportEmailTextBox';
import { DateCell, Table } from '@clinintell/components/table_v2';
import { DownloadIcon, EditIcon, DeleteWidgetIcon } from '@clinintell/components/index';
import MenuAction, { RowMenu } from '@clinintell/components/menuAction/MenuAction';

const AllScheduledReports: React.FC = () => {
  const theme = useTheme();
  const [openReportEditor, setOpenReportEditor] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [currentReport, setCurrentReport] = useState<ScheduleReportDto | null>(null);
  const [reportScheduleType, setReportScheduleType] = useState<ReportType | null>(null);
  const [clinicalLeader, setClinicalLeader] = useState<number>(-1);
  const [adminEmails, setAdminEmails] = useState<ScheduleReportEmailObjType>({
    emails: [],
    invalidEmails: [],
    validList: false,
    emailRole: ScheduleReportRolesType.admin
  });
  const [providerEmails, setProviderEmails] = useState<ScheduleReportEmailObjType>({
    emails: [],
    invalidEmails: [],
    validList: true,
    emailRole: ScheduleReportRolesType.provider
  });
  const deleteBtnRef = useRef<HTMLButtonElement>(null);

  const {
    reportList,
    isFetchingReportList,
    isRefetching,
    reportExist,
    downloadReport,
    handleSaveUpdatedReport,
    deleteReport
  } = useScheduleReport();

  const updateReport = (id: number): void => {
    const { exists, report } = reportExist(id);
    if (exists) {
      setCurrentReport(report as ScheduleReportDto);
      setOpenReportEditor(true);
    }
  };

  const setReportEmailList = (emailObj: ScheduleReportEmailObjType): void => {
    switch (emailObj.emailRole) {
      case ScheduleReportRolesType.admin:
        setAdminEmails({ ...emailObj });
        break;
      case ScheduleReportRolesType.provider:
      default:
        setProviderEmails({ ...emailObj });
        break;
    }
  };

  const setScheduleEmails = useCallback(
    (currentEmails: string | undefined | null, emailObj: ScheduleReportEmailObjType, isRequired = false): void => {
      if (currentEmails !== null && currentEmails !== undefined && emailObj.emails.length === 0)
        emailObj.emailRole === ScheduleReportRolesType.provider
          ? setProviderEmails({ ...providerEmails, emails: currentEmails.split(','), validList: !isRequired })
          : setAdminEmails({
              ...adminEmails,
              emails: currentEmails.split(','),
              validList: currentEmails.split(',').length > 0 || !isRequired
            });
    },
    [adminEmails, providerEmails]
  );

  const checkValidScheduleReport = useCallback(() => {
    if (reportScheduleType === ReportType.Quarterly && adminEmails.validList && adminEmails.emails.length > 0) {
      setSubmitEnabled(true);
    } else if (reportScheduleType === ReportType.Group && adminEmails.validList && providerEmails.validList) {
      if ((clinicalLeader === 0 && adminEmails.emails.length) || (adminEmails.emails.length && clinicalLeader))
        setSubmitEnabled(true);
      else setSubmitEnabled(false);
    } else setSubmitEnabled(false);
  }, [adminEmails.emails.length, adminEmails.validList, clinicalLeader, providerEmails.validList, reportScheduleType]);

  const handleConfirmDeleteReport = (id: number): void => {
    const { exists, report } = reportExist(id);
    if (exists) {
      setCurrentReport(report as ScheduleReportDto);
      (deleteBtnRef.current as HTMLButtonElement).click();
    }
  };

  const handleDeleteReport = (): void => {
    deleteReport(currentReport?.id as number);
    (deleteBtnRef.current as HTMLButtonElement).click();
  };

  const handleCloseReportEditor = (): void => {
    setOpenReportEditor(false);
    setCurrentReport(null);
    setReportScheduleType(null);
    setClinicalLeader(-1);
    setAdminEmails({
      emails: [],
      invalidEmails: [],
      validList: false,
      emailRole: ScheduleReportRolesType.admin
    });
    setProviderEmails({
      emails: [],
      invalidEmails: [],
      validList: true,
      emailRole: ScheduleReportRolesType.provider
    });
  };

  useEffect(() => {
    if (reportScheduleType && (clinicalLeader || adminEmails.emails.length > 0)) {
      checkValidScheduleReport();
      return;
    }
    if (currentReport !== undefined && currentReport !== null && currentReport) {
      setReportScheduleType(
        (currentReport?.frequency?.frequencyName as string).toLocaleLowerCase() === 'monthly'
          ? ReportType.Group
          : ReportType.Quarterly
      );
      // Get the Clinical Leader
      setClinicalLeader(currentReport.clinicalLeadUser?.id as number);
      setScheduleEmails(currentReport.recipients, adminEmails, clinicalLeader === -1);
      // Set the providers, only Group Reports hold provider emails
      setScheduleEmails(currentReport.providerRecipients, providerEmails);
    }
  }, [
    adminEmails,
    checkValidScheduleReport,
    clinicalLeader,
    currentReport,
    providerEmails,
    reportScheduleType,
    setScheduleEmails
  ]);

  return (
    <Box sx={{ my: 3, mx: 3 }}>
      <Button
        type={`primary`}
        sx={{ marginBottom: 2 }}
        variant={`text`}
        label={`Back to Reports`}
        leftAdornment={<KeyboardArrowLeftIcon style={{ marginTop: 4 }} />}
        onClick={() => {
          setCurrentReport(null);
          window.history.back();
        }}
      />
      <PageTitle title="All Scheduled Reports" />
      {isFetchingReportList || isRefetching || reportList === undefined || reportList.length === 0 ? (
        <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
      ) : (
        <Table<ScheduleReportDto>
          rowData={reportList}
          renderColumns={({ renderColumn, renderCustomColumn }) => {
            return [
              renderColumn('reportName', {
                cellRenderer: props => props.value || '',
                headerAlignment: 'left',
                sortable: true,
                minWidth: 400
              }),
              renderCustomColumn({
                cellRenderer: props => {
                  const rowMenu: RowMenu[] = [
                    {
                      title: 'Edit Recipients',
                      icon: (
                        <Box mr={1} mt={0.5}>
                          <EditIcon sx={{ height: 16 }} />
                        </Box>
                      ),
                      onClick: (): void => updateReport(Number(props.data?.id))
                    },
                    {
                      title: 'Delete Schedule',
                      icon: (
                        <Box mr={1} mt={0.5}>
                          <DeleteWidgetIcon sx={{ height: 18 }} />
                        </Box>
                      ),
                      onClick: (): void => handleConfirmDeleteReport(Number(props.data?.id))
                    }
                  ];

                  if (props.data?.lastDelivery) {
                    rowMenu.unshift({
                      title: 'Download Report',
                      icon: (
                        <Box mr={1} mt={0.5}>
                          <DownloadIcon sx={{ height: 16 }} />
                        </Box>
                      ),
                      onClick: (): Promise<void> => downloadReport(Number(props.data?.id))
                    });
                  }

                  return (
                    <MenuAction
                      entity={props.data?.id}
                      rawValue={props.data ? props.data.id?.toString() : ''} // Talk to Doug about this
                      rowMenu={rowMenu}
                    />
                  );
                },
                cellStyle: {
                  justifyContent: 'center'
                }
              }),
              renderColumn('reportType', {
                cellRenderer: props => props.value?.typeDescription || '',
                valueFormatter: props => props.value?.typeDescription || '',
                sortable: true,
                cellStyle: {
                  justifyContent: 'center'
                },
                minWidth: 180
              }),
              renderColumn('frequency', {
                cellRenderer: props => props.value?.frequencyName || '',
                valueFormatter: props => props.value?.frequencyName || '',
                sortable: true,
                cellStyle: {
                  justifyContent: 'center'
                }
              }),
              renderColumn('recipients', {
                cellRenderer: props => props.value || '',
                sortable: true,
                cellStyle: {
                  justifyContent: 'center'
                }
              }),
              renderColumn('insertBy', {
                cellRenderer: props => props.value || '',
                sortable: true,
                headerName: 'Owner',
                cellStyle: {
                  justifyContent: 'center'
                }
              }),
              renderColumn('lastDelivery', {
                cellRenderer: props => (props.value ? <DateCell date={props.value} /> : '-'),
                sortable: true,
                cellStyle: {
                  justifyContent: 'center'
                }
              })
            ];
          }}
        />
      )}
      <ActionPanel
        open={openReportEditor}
        closeFn={handleCloseReportEditor}
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ color: theme.palette.grey[700], fontWeight: 600 }}>
              Edit Recipients
            </Typography>
            <Button
              type={`primary`}
              variant={`contained`}
              label={`Save Edits`}
              disabled={!submitEnabled}
              onClick={() => {
                if (currentReport !== null) {
                  const resultSuccessful = handleSaveUpdatedReport(currentReport, adminEmails, providerEmails);
                  resultSuccessful.then(result => {
                    if (result) handleCloseReportEditor();
                  });
                }
              }}
            />
          </Box>
        }
      >
        <Box sx={{ m: 3, display: 'grid', gap: 2 }}>
          <Box sx={{ display: 'grid', gap: 1 }}>
            <Typography variant={`body1`}>Report Name</Typography>
            <TextInput value={currentReport?.reportName} sx={{ width: '100%' }} disabled />
          </Box>
          {reportScheduleType === ReportType.Group ? (
            <SelectClinicalLeader
              title="Clinical Leader"
              defaultValue={clinicalLeader}
              width={'100%'}
              disableDropdown={true}
            />
          ) : (
            <></>
          )}
          <ReportEmailTextBox
            textBoxHeader={reportScheduleType === ReportType.Group ? 'Admin Recipients' : 'Recipients'}
            validEmailList={adminEmails.emails}
            invalidEmailList={adminEmails.invalidEmails}
            emailRoleType={adminEmails.emailRole}
            entryRequired={true}
            setValidEmailsFunc={setReportEmailList}
          />
          {reportScheduleType === ReportType.Group ? (
            <ReportEmailTextBox
              textBoxHeader={'Provider Recipients'}
              validEmailList={providerEmails.emails}
              invalidEmailList={providerEmails.invalidEmails}
              emailRoleType={providerEmails.emailRole}
              setValidEmailsFunc={setReportEmailList}
            />
          ) : (
            <></>
          )}
        </Box>
      </ActionPanel>
      <CIModal modalBtn={<MuiButton ref={deleteBtnRef} variant={`text`} sx={{ opacity: 0 }} />}>
        <CIModal.Header showCloseIcon>
          <Typography variant={`h6`}>Are you sure you want to delete this schedule?</Typography>
        </CIModal.Header>
        <CIModal.Content>
          <Typography variant={`body1`}>This action cannot be undone.</Typography>
        </CIModal.Content>
        <CIModal.Footer cancelBtn>
          <Button key={`delete`} type={`primary`} variant={`contained`} label={`Delete`} onClick={handleDeleteReport} />
        </CIModal.Footer>
      </CIModal>
    </Box>
  );
};

export default AllScheduledReports;
