import React, { useEffect, useRef, useState } from 'react';
import { ApplicationAPI } from '@clinintell/utils/api';
import { useAuthenticationStatus } from '@clinintell/modules/store';
import { Box, IconButton, Link, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import WifiIcon from '@mui/icons-material/Wifi';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import CircleLoadingIndicator from '@clinintell/components/CircleLoadingIndicator';
import localForage from 'localforage';

const Support: React.FC = () => {
  const { isAuthenticated } = useAuthenticationStatus();
  const [online, setOnline] = useState(window.navigator.onLine);
  const [appVersion, setAppVersion] = useState('');

  const apiHearBeat = useRef<number | NodeJS.Timeout>(0);
  const [apiResponseTime, setApiResponseTime] = useState(0);
  const [apiResponseMsg, setApiResponseMsg] = useState<'extremely slow' | 'slow' | 'fast'>('fast');
  // const isLoading = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const checkAPIResponse = (): void => {
    if (!isAuthenticated) return;

    const startTime = new Date();
    let endTime = startTime;
    setIsLoading(true);

    ApplicationAPI.get({ endpoint: 'auth/user' })
      .then(response => {
        if (response.data) {
          endTime = new Date();
        } else {
          throw new Error('The API is not responding');
        }
      })
      .catch(() => {
        throw new Error('The API is not responding');
      })
      .finally(() => {
        const responseTime = (endTime.getTime() - startTime.getTime()) / 1000;
        // Response time article: https://www.guru99.com/response-time-testing.html
        if (responseTime < 1) setApiResponseMsg('fast');
        if (responseTime >= 1 && responseTime <= 6) setApiResponseMsg('slow');
        if (responseTime >= 6) setApiResponseMsg('extremely slow');
        setApiResponseTime(responseTime);
        setIsLoading(false);
      });
  };

  const refreshAPIResponse = (): void => {
    clearInterval(apiHearBeat.current as number);
    checkAPIResponse();
    apiHearBeat.current = setInterval((): void => checkAPIResponse(), 60000);
  };

  useEffect(() => {
    localForage.ready(() => {
      localForage.getItem('appVersion').then(version => setAppVersion(version as string));
    });

    window.addEventListener('online', () => setOnline(window.navigator.onLine));
    window.addEventListener('offline', () => setOnline(window.navigator.onLine));

    if (isAuthenticated) {
      checkAPIResponse();
      apiHearBeat.current = setInterval((): void => checkAPIResponse(), 60000);
    }

    return (): void => {
      window.removeEventListener('online', () => setOnline(window.navigator.onLine));
      window.removeEventListener('offline', () => setOnline(window.navigator.onLine));
      clearInterval(apiHearBeat.current as number);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box m={3}>
      <Typography variant="h4" color="primary">
        ClinIntell Support
      </Typography>
      <Box mt={3}>
        <Typography variant="h5">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1}>Network Status</Box>
            {online ? (
              <Box component="span">
                <WifiIcon />
              </Box>
            ) : (
              <Box component="span">
                <SignalWifi0BarIcon />
              </Box>
            )}
          </Box>
        </Typography>
        {online ? <Box>You're currently online</Box> : <Box>There is no network connectivity</Box>}
      </Box>
      {isAuthenticated && (
        <Box mt={3}>
          <Typography variant="h5">
            API Response
            {isLoading ? (
              <Box component="span" m={2}>
                <CircleLoadingIndicator loadingIndicatorSize={16} />
              </Box>
            ) : (
              <IconButton onClick={refreshAPIResponse} title="Refresh API Response" style={{ padding: 4 }} size="large">
                <RefreshIcon color="primary" />
              </IconButton>
            )}
          </Typography>
          <Box>
            {apiResponseTime} seconds (refreshes every minute) - The server response is {apiResponseMsg}.
          </Box>
          <Box>
            <Link href="https://fast.com/" target="_blank" underline="hover">
              Check Internet Speed
            </Link>
          </Box>
        </Box>
      )}
      <Box mt={3}>
        <Typography variant="h5">Version</Typography>
        <Box>{appVersion}</Box>
      </Box>
    </Box>
  );
};

export default Support;
