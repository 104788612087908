import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Select, SelectOptionType } from '@clinintell/components/index';
import { useOrgGetBDSystemOrgs } from '@clinintell/api/apiComponents';
import useErrorHandling from '@clinintell/errors/useErrorHandling';

type Options = SelectOptionType[];

type Props = {
  labelPosition?: 'top' | 'side' | 'none';
  title?: string;
  defaultValue?: number;
  defaultLabel?: string;
  onSystemChangeFn: (value: number | null, display?: string) => void;
  onSelectLoaded?: (value: boolean) => void;
  width?: number | string;
  placeholder?: string;
};

const SelectSystem: React.FC<Props> = ({
  labelPosition = 'top',
  title,
  defaultValue,
  defaultLabel,
  onSystemChangeFn,
  onSelectLoaded,
  width = 400,
  placeholder
}) => {
  const { showErrorMessage } = useErrorHandling();
  const [systemOptions, setSystemOptions] = useState<Options | []>([]);
  const [systemTotalCount, setSystemTotalCount] = useState(0);
  const [optionSelected, setOptionSelected] = useState(false);
  const [selectedSystemOption, setSelectedSystemOption] = useState<SelectOptionType | null>(null);

  const selectionChange = (option: SelectOptionType): void => {
    const { value, label } = option;
    if (systemOptions.findIndex(hospital => Number(hospital.value) === Number(value)) === -1) {
      setSystemOptions([...systemOptions, option]);
      setSystemTotalCount(systemTotalCount + 1);
    }
    setSelectedSystemOption(option);
    onSystemChangeFn(value ? (value as number) : null, label);
  };

  const { data: bdSystems, isLoading } = useOrgGetBDSystemOrgs({
    onError: showErrorMessage
  });

  useEffect(() => {
    if (systemOptions.length === 1 && !optionSelected) {
      onSystemChangeFn(systemOptions[0].value as number, systemOptions[0].label);
      setSelectedSystemOption(systemOptions[0]);
      setOptionSelected(true);
    }
  }, [systemOptions, onSystemChangeFn, optionSelected]);

  useEffect(() => {
    if (!bdSystems || isLoading) return;

    if (bdSystems && bdSystems.length > 0) {
      const dropDownList: Options | [] = bdSystems
        .map(system => {
          return {
            value: system.id,
            label: system.name,
            useLabelForTestId: true
          } as SelectOptionType;
        })
        .sort((a, b) => {
          if (!a || !a.label || !b || !b.label) return 0;
          return a.label.localeCompare(b.label, 'en', { ignorePunctuation: true });
        });

      if (
        bdSystems &&
        defaultValue &&
        defaultLabel &&
        dropDownList.findIndex(system => system.value === defaultValue) === -1
      ) {
        dropDownList.push({ value: defaultValue, label: defaultLabel });
      }

      setSystemOptions(dropDownList);
      setSystemTotalCount(bdSystems.length ?? 0);

      if (
        onSelectLoaded &&
        defaultValue &&
        dropDownList.length > 0 &&
        dropDownList.find(hospital => hospital.value === defaultValue)
      ) {
        onSelectLoaded(true);
      }
    }
  }, [bdSystems, isLoading, onSelectLoaded, defaultValue, onSystemChangeFn, defaultLabel]);

  const defaultOption =
    defaultValue && defaultValue > 1
      ? systemOptions.find(hospital => hospital.value === defaultValue)
      : selectedSystemOption;

  return (
    <Box
      display="flex"
      flexDirection={labelPosition === 'top' ? 'column' : 'row'}
      alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
      width={width}
    >
      {labelPosition !== 'none' && <Typography variant="p2">{title ?? 'System'}</Typography>}
      <Box
        width="100%"
        style={labelPosition === 'none' ? undefined : labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}
      >
        <Select
          width={width}
          isSearchable={true}
          isLoading={isLoading}
          options={systemOptions}
          value={defaultOption ? defaultOption.value : Number(defaultValue)}
          onChange={(value, label): void => {
            selectionChange({ value: Number(value), label });
          }}
          testId="bdSystemSelector"
          placeholder={placeholder}
        />
      </Box>
    </Box>
  );
};

export default React.memo(SelectSystem);
