import React from 'react';
import { useTheme, Box, Typography, useMediaQuery } from '@mui/material';

type InputLabelProps = {
  label: string;
  position: 'top' | 'side' | 'none';
  labelStyles?: React.CSSProperties;
  wrapperStyles?: React.CSSProperties;
  fontVariant?: boolean;
};

const InputLabel: React.FC<InputLabelProps> = ({
  label,
  position,
  children,
  labelStyles,
  wrapperStyles,
  fontVariant
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const wrapperProps =
    position === 'side'
      ? {
          alignItems: 'center'
        }
      : {
          flexDirection: 'column' as React.CSSProperties['flexDirection'],
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          gap: 1
        };

  return (
    <Box display="flex" {...wrapperProps} sx={{ ...wrapperStyles }}>
      {position !== 'none' && (
        <Typography
          style={{
            display: 'inline',
            marginRight: theme.spacing(1),
            ...labelStyles
          }}
          variant={fontVariant ? 'p1' : isMobileView ? 'caption' : 'body2'}
        >
          {label}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default InputLabel;
