import React, { ReactElement, useState } from 'react';
import { formatNumberWithCommas } from '@clinintell/utils/formatting';
import { format } from 'date-fns';
import clsx from 'clsx';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckBox from '@mui/material/Checkbox';
import DRG from '@clinintell/assets/images/DRG.svg';
import HCC from '@clinintell/assets/images/HCC.svg';
import Ex from '@clinintell/assets/images/Ex.svg';
import { IconButton, TableCell, TableCellProps, Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Tooltip from './tooltip/Tooltip';
import PopupMenu from './popupMenu/PopupMenu';
import PopupMenuItem from './popupMenu/PopupMenuItem';

const useStyles = makeStyles(theme => ({
  headerTooltip: {
    marginLeft: 5,
    top: 3,
    position: 'relative'
  },
  overlay: {
    borderRadius: '1rem',
    fontWeight: 700,
    maxWidth: '5rem',
    margin: '10px auto'
  },
  red: {
    backgroundColor: theme.palette.bubble.red.light,
    color: theme.palette.bubble.red.main
  },
  green: {
    backgroundColor: theme.palette.bubble.green.light,
    color: theme.palette.bubble.green.main
  },
  yellow: {
    backgroundColor: theme.palette.bubble.yellow.light,
    color: theme.palette.bubble.yellow.main
  },
  clickable: {
    color: theme.palette.blue.main,
    cursor: 'pointer'
  },
  plain: {
    listStyleType: 'none',
    margin: '0px',
    '& > li': {
      '&:hover': {
        cursor: 'auto'
      }
    }
  },
  tdroot: {
    position: 'relative'
  },
  expanded: {
    display: 'block',
    paddingBottom: '12px',
    paddingLeft: '20px'
  },
  closed: {
    display: 'none'
  },
  verysmall: {
    width: '1rem',
    height: '1rem',
    fontSize: '1rem',
    padding: 0,
    marginTop: '-1px'
  }
}));

export interface StyledTableCellProps {
  classes?: string;
  type: ColumnDataType;
  prefix?: string;
  postfix?: string;
  value: string | number | null | boolean;
  otherChildren?: string[] | [];
  align: TableCellProps['align'];
  overlayStyle?: OverlayBubbles;
  onClick?: () => void;
  maxCharacters?: number;
  isBubble: boolean;
  overrideAlignment?: boolean;
  entity?: number;
  condition?: number;
  conditionName?: string;
  rowMenu?: RowMenu[];
  showTrainingTooltip?: boolean;
  isFooter?: boolean;
  precision?: number;
  styles?: React.CSSProperties;
  prefixIcon?: ReactElement;
}

export type OverlayBubbles = 'yellow' | 'red' | 'green';

export type ColumnDataType = 'number' | 'integer' | 'string' | 'boolean' | 'conditionType' | 'date';

export type RowMenu = {
  title: string;
  onClick: (entity: number, condition?: number, conditionName?: string, isFooter?: boolean) => void;
};

const styleCell = (value: string | number | null, dataType: ColumnDataType, precision: number): string => {
  if (value === null || value === '') return '-';

  switch (dataType) {
    case 'string':
      return value.toString();
    case 'number':
      if (value === 0) return '0.00';
      return formatNumberWithCommas(Number(value).toFixed(precision));
    case 'integer':
      return formatNumberWithCommas(Number(value));
    default:
      return '-';
  }
};

const StyledIconButton = withStyles(theme => ({
  root: {
    height: 28,
    margin: 4,
    width: 20,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))(IconButton);

const StyledTableCell: React.FC<StyledTableCellProps> = ({
  classes,
  type,
  otherChildren,
  prefix,
  postfix,
  value,
  align,
  overlayStyle,
  onClick,
  maxCharacters,
  isBubble,
  overrideAlignment,
  entity,
  condition,
  conditionName,
  rowMenu,
  showTrainingTooltip,
  isFooter = false,
  precision = 2,
  styles,
  prefixIcon
}) => {
  const {
    overlay,
    red,
    green,
    yellow,
    clickable,
    tdroot,
    plain,
    expanded,
    closed,
    verysmall,
    headerTooltip
  } = useStyles();
  const theme = useTheme();

  let styledValue = '';
  if (type !== 'boolean') {
    styledValue = `${prefix || ''}${styleCell(value as string, type, precision)}${postfix || ''}`;
  }

  if (styledValue.startsWith('$-')) {
    styledValue = styledValue.replace('$-', '-$');
  } else if (value === null && (type === 'number' || type === 'integer')) {
    styledValue = '-';
  } else if (value === null && type === 'string') {
    styledValue = '';
  } else if (type === 'date') {
    styledValue = value ? format(new Date(value as string), 'MM/dd/yyyy') : '-';
  }

  const [isExpanded, setIsExpanded] = useState(false);

  function renderCellContent(): JSX.Element | string {
    if (otherChildren && otherChildren.length > 0) {
      const tsCompliantChildren: string[] = [...otherChildren];
      const toggleIsExpanded = function(e: React.MouseEvent<unknown>): void {
        e.stopPropagation();
        if (isExpanded) {
          setIsExpanded(false);
        } else {
          setIsExpanded(true);
        }
      };
      return (
        <Box style={{ textAlign: 'left' }}>
          <Box style={isExpanded ? { marginTop: 10 } : undefined}>
            <IconButton
              size="small"
              classes={{ root: verysmall, sizeSmall: verysmall }}
              color="primary"
              aria-label="expand"
              onClick={toggleIsExpanded}
            >
              {isExpanded ? (
                <RemoveCircleRoundedIcon
                  fontSize="small"
                  classes={{ root: verysmall, fontSizeSmall: verysmall }}
                ></RemoveCircleRoundedIcon>
              ) : (
                <AddCircleRoundedIcon
                  fontSize="small"
                  classes={{ root: verysmall, fontSizeSmall: verysmall }}
                ></AddCircleRoundedIcon>
              )}
            </IconButton>
            <Box className="ellipse" component="span" ml={1} style={{ marginLeft: '5px' }}>
              {styledValue}
            </Box>
          </Box>
          <ul
            className={clsx(classes, {
              [plain]: true,
              [expanded]: isExpanded,
              [closed]: !isExpanded
            })}
          >
            {tsCompliantChildren.map((c, idx) => {
              return (
                <li
                  style={{
                    marginTop: idx === 0 ? theme.spacing(1) : 0
                  }}
                  key={idx}
                >
                  <Typography color="textPrimary" variant="p2">
                    {c}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </Box>
      );
    }

    let textAlign: React.CSSProperties['textAlign'] = 'right';
    if (isBubble || type === 'date') {
      textAlign = 'center';
    } else if (type === 'string') {
      textAlign = align ? align : 'left';
    } else if (type === 'number' && align) {
      textAlign = align;
    }

    const cellProps: React.CSSProperties = {
      textAlign,
      width: '100%',
      ...styles
    };

    if (
      ((type === 'string' || type === 'integer') && styledValue === '-') ||
      (type === 'string' && overrideAlignment) ||
      (type === 'string' && align === 'center')
    ) {
      cellProps.textAlign = 'center';
    }

    if (type !== 'string' && type !== 'date' && !isBubble) {
      cellProps.fontFamily = theme.typography.fontFamilyMonospaced;
      cellProps.width = 'calc(50% + 10px)';
    }

    if (styledValue === '-') {
      return (
        <Box display="flex" justifyContent="center" alignItems="flex-start" height="100%" width="100%">
          <Box style={{ marginTop: 10, width: '100%', textAlign: 'center' }}>{styledValue}</Box>
        </Box>
      );
    }

    if (isBubble || type !== 'string') {
      return (
        <Box
          display="flex"
          justifyContent={
            isBubble || type === 'date' || (type === 'number' && align === 'center') ? 'center' : 'flex-start'
          }
          alignItems="flex-start"
          height="100%"
          width="100%"
        >
          <Box style={{ ...cellProps, marginTop: 10 }}>
            {prefixIcon ? (
              <Box display="flex" alignItems="start">
                {prefixIcon}
                <Box component="span">{styledValue}</Box>
              </Box>
            ) : (
              styledValue
            )}
          </Box>
        </Box>
      );
    }

    return (
      <Box alignSelf="center" justifySelf={align === 'left' ? 'flex-start' : 'center'} style={{ ...cellProps }}>
        {styledValue}
      </Box>
    );
  }

  const [graphAnchorEl, setGraphAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>): void => {
    setGraphAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setGraphAnchorEl(null);
  };

  if (onClick && entity && rowMenu && rowMenu.length > 0) {
    return (
      <>
        <TableCell align={align} valign="top" className={tdroot}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box onClick={onClick} className={clickable} flexGrow={1}>
              {renderCellContent()}
            </Box>
            <Box alignSelf="flex-start">
              <StyledIconButton onClick={handleMenuOpen} disableRipple disableTouchRipple>
                <MoreVertIcon />
              </StyledIconButton>
              <PopupMenu
                anchorEl={graphAnchorEl}
                isOpen={Boolean(graphAnchorEl)}
                sx={{ marginTop: 10, marginLeft: 30 }}
                onClose={handleMenuClose}
              >
                {rowMenu.map((menu: RowMenu, index: number) => (
                  <PopupMenuItem
                    key={`menuItem-${type}-${index}`}
                    label={menu.title}
                    onClick={(): void => {
                      menu.onClick(entity, condition, conditionName, isFooter);
                      handleMenuClose();
                    }}
                    data-cy={'menuGraph'}
                  />
                ))}
              </PopupMenu>
            </Box>
          </Box>
        </TableCell>
      </>
    );
  } else if (entity && rowMenu && rowMenu.length > 0) {
    return (
      <>
        <TableCell style={{ verticalAlign: 'top' }}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box flexGrow={1}>{renderCellContent()}</Box>
            <Box alignSelf="flex-start">
              <StyledIconButton onClick={handleMenuOpen} disableRipple disableTouchRipple>
                <MoreVertIcon />
              </StyledIconButton>
              <PopupMenu
                anchorEl={graphAnchorEl}
                isOpen={Boolean(graphAnchorEl)}
                sx={{ marginTop: 10, marginLeft: 30 }}
                onClose={handleMenuClose}
              >
                {rowMenu.map((menu: RowMenu, index: number) => (
                  <PopupMenuItem
                    key={`menuItem-${type}-${index}`}
                    label={menu.title}
                    data-cy={'menuGraph' + index}
                    onClick={(): void => {
                      menu.onClick(entity, condition, conditionName, isFooter);
                      handleMenuClose();
                    }}
                  />
                ))}
              </PopupMenu>
            </Box>
          </Box>
        </TableCell>
      </>
    );
  } else if (type === 'conditionType') {
    return (
      <TableCell>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <img
            src={value === 1 ? DRG : value === 2 ? HCC : Ex}
            alt={value === 1 ? 'DRG' : value === 2 ? 'HCC' : 'Ex'}
          />
        </Box>
      </TableCell>
    );
  } else if (showTrainingTooltip !== undefined && showTrainingTooltip) {
    return (
      <TableCell
        style={{
          verticalAlign: 'top'
        }}
        className="notApproved"
      >
        <Box className="ellipse">
          {styledValue}
          <Tooltip content="Clinical content currently unavailable">
            <span className={headerTooltip}>
              <HelpOutlineIcon component="svg" style={{ fontSize: '1.2rem' }} />
            </span>
          </Tooltip>
        </Box>
      </TableCell>
    );
  } else if (type === 'boolean') {
    return (
      <TableCell align="center">
        <CheckBox checked={value === true} color="primary" disabled />
      </TableCell>
    );
  } else {
    return (
      <TableCell
        align={align}
        onClick={onClick}
        className={clsx(classes, {
          [clickable]: onClick !== undefined,
          [tdroot]: true
        })}
      >
        {overlayStyle ? (
          <Box display="flex" alignSelf="flex-start" height="100%">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ fontFamily: theme.typography.fontFamilyMonospaced, height: 18, width: 72 }}
              className={clsx({
                [overlay]: overlayStyle !== undefined,
                [red]: overlayStyle === 'red',
                [green]: overlayStyle === 'green',
                [yellow]: overlayStyle === 'yellow'
              })}
              component="div"
            >
              {styledValue}
            </Box>
          </Box>
        ) : (
          <Box display="flex" height="100%" style={{ verticalAlign: 'middle' }}>
            {renderCellContent()}
          </Box>
        )}
      </TableCell>
    );
  }
};

export default StyledTableCell;
