export type CmiTrendType = 'positive' | 'negative' | 'neutral';

export const calculateImpactOnCMI = (cmi: number, value: number, comparisonValue: number): number => {
  let cmiTrend: CmiTrendType = 'neutral';
  if (cmi > 0) {
    cmiTrend = 'positive';
  } else if (cmi < 0) {
    cmiTrend = 'negative';
  }

  if (cmiTrend === 'neutral') {
    return 0;
  }

  // If trend is positive, value is positive and comparison value is negative, the impact for the value is 100%
  if (cmiTrend === 'positive' && value > 0 && comparisonValue < 0) {
    return 1;
  }

  if (cmiTrend === 'positive' && value < 0) {
    return 0;
  }

  // Reverse is true if negative trend
  if (cmiTrend === 'negative' && value < 0 && comparisonValue > 0) {
    return 1;
  }

  if (cmiTrend === 'negative' && value > 0) {
    return 0;
  }

  const totalImpact = Math.abs(value) + Math.abs(comparisonValue);
  return Math.abs(value) / totalImpact;
};
