export const Labels = {
  cmi: { title: 'CMI', axisLabel: 'CMI' },
  docScore: { title: 'CMI Documentation Score', axisLabel: 'CC/MCC value O/CE' },
  severityCmi: { title: 'Severity CMI', axisLabel: 'Severity CMI' },
  los: { title: 'LOS Severity Gap', axisLabel: 'LOS Severity Gap' },
  elixhauserMortality: { title: 'AHRQ Mortality RA', axisLabel: 'RA O/CE' },
  elixhauserReadmission: { title: 'AHRQ Readmission RA', axisLabel: 'RA O/CE' },
  psi: { title: 'AHRQ PSI RA', axisLabel: 'RA O/CE' },
  raf: { title: 'Inpatient RAF', axisLabel: 'IP RAF O/CE' },
  targetedConditions: { title: 'Targeted Conditions', axisLabel: 'Targeted Conditions' },
  allConditions: { title: 'All Conditions', axisLabel: 'All Conditions' },
  condition: { title: 'Clinical Condition', axisLabel: 'Clinical Condition' },
  hcupcmi: { title: 'HCUP CMI', axisLabel: 'HCUP CMI' }
};

export const Resources = {
  Months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  FullMonths: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  ThreeMonths: 'Previous 3 Months',
  SixMonths: 'Previous 6 Months',
  TwelveMonths: 'Previous 12 Months',
  TwentyFourMonths: 'Previous 24 Months',
  PrePostIntervention: 'Pre-Post Intervention',
  Apply: 'Apply',
  Cancel: 'Cancel',
  GraphTitles: {
    cmi: `${Labels.cmi.title} {0}`,
    docScore: `${Labels.docScore.title} {0}`,
    severityCmi: `${Labels.severityCmi.title} {0}`,
    los: `${Labels.los.title} {0}`,
    elixhauserMortality: `${Labels.elixhauserMortality.title} {0}`,
    elixhauserReadmission: `${Labels.elixhauserReadmission.title} {0}`,
    psi: `${Labels.psi.title} {0}`,
    raf: `${Labels.raf.title} {0}`,
    condition: 'Coded Rate for {0}'
  },
  Performance: 'Performance',
  DataTable: 'Data Table',
  TableColumns: {
    name: 'Name *',
    action: 'Action',
    current: 'Current *',
    historical: 'Historical *',
    clinicallyExpected: 'Clinically Expected',
    gapVsCe: 'Gap',
    errorGap: 'Error, Gap',
    cases: 'Cases',
    opportunityRW: 'Opportunity RW',
    minOpportunityRW: 'Min Opportunity RW',
    maxOpportunityRW: 'Max Opportunity RW',
    opportunityDollar: 'Opportunity $',
    minOpportunityDollar: 'Min Opportunity $',
    maxOpportunityDollar: 'Max Opportunity $',
    improvementRW: 'Improvement RW',
    minImprovementRW: 'Min Improvement RW',
    maxImprovementRW: 'Max Improvement RW',
    improvementDollar: 'Improvement $',
    minImprovementDollar: 'Min Improvement $',
    maxImprovementDollar: 'Max Improvement $',
    change: 'Change',
    errorChange: 'Error Change',
    hcupWgtAvg: 'HCUP Avg',
    ceHcupWgtAvg: 'CE HCUP Avg',
    opportunity: 'Opportunity',
    improvement: 'Improvement',
    category: 'Category',
    group: 'Group',
    raf: 'RAF',
    mdcId: 'MDC',
    mdcName: 'MDC',
    conditionTypeId: 'Condition Type',
    conditionId: 'Condition',
    isTargetCondition: 'Target Condition',
    hasApprovedContent: 'Approved Content',
    docScore: 'Doc Score',
    otoE: 'Current *',
    otoEChange: 'Change',
    otoEErrorChange: 'Error Change',
    otoEErrorChangePositive: 'Error, Change +',
    otoEErrorChangeNegative: 'Error, Change -',
    otoEErrorGap: 'Error, Gap',
    otoEErrorGapPositive: 'Error, Gap +',
    otoEErrorGapNegative: 'Error, Gap -',
    otoEGapVsCe: 'Gap',
    otoEce: 'Clinically Expected',
    historicalOtoE: 'Historical *',
    historicalOtoEce: 'Clinically Expected',
    historicalOtoEErrorGap: 'Error, Gap'
  }
};

// Change this number to see search vs select options on reports pages
// on dev user sees select, but on prod with root entity high in the tree, user will see search
// reason: limit drop down select to sensible number of items
export const HOSPITAL_SELECT_LIMIT = 50;

export const ReorderList = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getResourceString = (query: string, strReplacement?: string, condition?: string): string => {
  let result = '';
  if (condition) {
    result = '{0}';
    strReplacement = condition + ' ' + strReplacement;
  } else {
    // Find the first entry that matches or set to query
    // eslint-disable-next-line array-callback-return
    Object.entries(Resources.GraphTitles).find(([key, value]) => {
      if (key === query) {
        result = value;
      }
    });
  }

  if (strReplacement && strReplacement.length > 0) {
    result = result.replace('{0}', strReplacement);
  }

  return result;
};
