import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ClinIntellSkeleton } from '@clinintell/components/index';
import { useDashboard2, useDashboard2Dispatch } from '@clinintell/modules/store';
import { getTargetedConditionsModalGraphData, TargetedConditionsTableType } from '@clinintell/modules/dashboard2';
import {
  ChartDataSetAverageTypes,
  ChartDataSetJSON
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import ViewPerformanceGraph from '@clinintell/containers/autogenStrategy/components/ViewPerformanceGraph';
import TargetedConditionsWidgetTable from './TargetedConditionsWidgetTable';
import { containerBorderRadius } from '@clinintell/theme/theme';

const TargetedConditionsModal: React.FC = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const dashboard2Dispatch = useDashboard2Dispatch();
  const { targetedConditionsWidget, entity, groupId, groupName } = useDashboard2();
  const {
    isModalLoading,
    isModalGraphLoading,
    modalTableData,
    modalGraphData,
    modalRecordName,
    modalRecordId
  } = targetedConditionsWidget;
  const { historicalStart, historicalEnd, currentStart, currentEnd } = modalTableData as TargetedConditionsTableType;

  const getOrgId = useMemo(() => {
    if (groupId && groupId !== entity?.id) return groupId;
    if (entity) return entity.id;
    return -1;
  }, [entity, groupId]);

  const [conditionName, setConditionName] = useState('');

  const handleGraphClick = (conditionId: number, conditionDescription: string): void => {
    if (conditionId === modalRecordId) return;
    dashboard2Dispatch(
      getTargetedConditionsModalGraphData({
        entity: getOrgId,
        conditionId,
        conditionDescription,
        currentStart,
        currentEnd,
        historicalStart,
        historicalEnd
      })
    );
    setConditionName(conditionDescription as string);
  };

  useEffect(() => {
    if (isModalLoading) return;
    setConditionName(modalRecordName);
  }, [isModalLoading, modalTableData, modalRecordName, modalGraphData]);

  return (
    <Box p={isMobileView ? 0 : 4} pt={0}>
      {isModalLoading ||
      !(modalTableData as TargetedConditionsTableType).table ||
      !(modalGraphData as ChartDataSetJSON).data ? (
        <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
      ) : (
        <>
          <Typography variant="h5" style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
            {conditionName} O/CE Graph
          </Typography>

          <Box
            sx={{
              border: `1px solid grey[200]`,
              borderRadius: `${containerBorderRadius}px`,
              padding: theme.spacing(2),
              display: 'flex',
              marginTop: theme.spacing(1.5),
              marginBottom: theme.spacing(4),
              overflow: 'scroll',
              [theme.breakpoints.down(1350)]: {
                overflowX: 'scroll',
                justifyContent: 'flex-start'
              }
            }}
          >
            {isModalGraphLoading ? (
              <ClinIntellSkeleton variant="rectangular" width="100%" height="400px" />
            ) : (
              <ViewPerformanceGraph
                graphData={modalGraphData as ChartDataSetJSON}
                isCondition={true}
                viewOption={
                  // If we the groupId is 1 or groupName is ALL then we are not a group provider
                  groupId !== 1 && groupName.localeCompare('All')
                    ? ChartDataSetAverageTypes.Month
                    : ChartDataSetAverageTypes.TwoMonth
                }
                filteredChart={new Set(['OtoERatio', 'CurrentAverageOtoCE', 'HistoricalAverageOtoCE'])}
                forOCE={true}
                useErrorBands={false}
              />
            )}
          </Box>
          <Box>
            <TargetedConditionsWidgetTable onGraphClick={handleGraphClick} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TargetedConditionsModal;
