enum TableActions {
  INITIALIZE = 'INITIALIZE',
  SET_TABLE_COLUMN_SIZES = 'SET_TABLE_COLUMN_SIZES'
  // Some features for future implementation
  // These can change based on the needs of the clients
  // SET_TABLE_FILTERS = 'SET_TABLE_FILTERS',
  // SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS',
  // SET_TABLE_COLUMN_ORDER = 'SET_TABLE_COLUMN_ORDER'
}

export type columnSize = {
  id: string;
  width: number;
};

export interface TableAction {
  type?: keyof typeof TableActions;
  payload?: unknown;
}

export interface TableState {
  // initialized: boolean;
  tableColumnSizes: columnSize[];
}

export const setTableColumnSize = ({ id, width }: columnSize): TableAction => ({
  type: 'SET_TABLE_COLUMN_SIZES',
  payload: { id, width }
});

// interface InitProps {
//   tableColumnSizes: columnSize[];
// }

// export const initializeTableState = (): TableAction => ({
//   type: 'INITIALIZE'
// });

export const initialState: TableState = {
  // initialized: false,
  tableColumnSizes: []
};

const TableReducer = (state: TableState = initialState, action: TableAction): TableState => {
  switch (action.type) {
    // case 'INITIALIZE': {
    //   return {
    //     ...state,
    //     initialized: true,
    //     tableColumnSizes: []
    //   };
    // }
    case 'SET_TABLE_COLUMN_SIZES': {
      const { id, width } = action.payload as columnSize;
      const { tableColumnSizes } = state;

      const cloneData = [...tableColumnSizes];
      const col = cloneData.find(c => c.id === id);
      if (col) {
        col.width = width;
      } else {
        cloneData.push({ id, width });
      }

      return {
        ...state,
        tableColumnSizes: cloneData
      };
    }
    default: {
      return state;
    }
  }
};

export default TableReducer;
