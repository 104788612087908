import { Box } from '@mui/material';
import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type TooltipContainerProps = {
  title?: string;
  content: string | JSX.Element;
  showIcon?: boolean;
};

const TooltipContainer: React.FC<TooltipContainerProps> = ({ title, content, showIcon = false }) => {
  return (
    <Box sx={{ padding: title !== undefined ? 1 : 0.5 }}>
      {title ? (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {showIcon && <ErrorOutlineIcon />}
          <Box sx={{ typography: 'p3', mb: 1, ml: showIcon ? 1 : 0, fontWeight: 'semibold', color: 'shade.white' }}>
            {title}
          </Box>
        </Box>
      ) : null}
      <Box sx={{ backgroundColor: 'dark.700', color: 'grey.200', typography: 'p2' }}>{content}</Box>
    </Box>
  );
};

export default TooltipContainer;
