import { SvgIcon } from '@mui/material';
import StrategyIcon from '@clinintell/components/icons/StrategyIcon';
import AnalyticsIcon from '@clinintell/components/icons/Analytics';
import { Feature } from '@clinintell/containers/authentication/rules';
import ReportIcon from '@clinintell/components/icons/Report';
import TrainingBookIcon from '@clinintell/components/icons/TrainingBook';
import DashboardIcon from '@clinintell/components/icons/DashboardIcon';
import Metrics from '@clinintell/components/icons/Metrics';
import { LiveHelpIcon } from '@clinintell/components/index';
import WrenchIcon from '@clinintell/components/icons/WrenchIcon';
import SignOutIcon from '@clinintell/components/icons/SignOut';

export type MenuNames =
  | 'dashboard'
  | 'metrics'
  | 'smartGoals'
  | 'overviewLanding'
  | 'buildStrategy'
  | 'trackPerformance'
  | 'analytics'
  | 'cmiAnalysis'
  | 'cmiComparison'
  | 'reports'
  | 'providerReport'
  | 'groupReport'
  | 'systemReport'
  | 'trainingProgress'
  | 'training'
  | 'assignTraining'
  | 'previewTraining'
  | 'clinicalContent'
  | 'resources'
  | 'medicareHistograms'
  | 'tools'
  | 'providerManagement'
  | 'userManagement'
  | 'helpCenter'
  | 'contactSupport'
  | 'signout';

export type ChildMenuItem = {
  label: string;
  link: string;
  isExternalLink?: boolean;
  isActive: boolean;
  parent: MenuItem;
  name: MenuNames;
  permissions: Feature[];
};

export type MenuItem = {
  label: string;
  name: MenuNames;
  IconComponent: typeof SvgIcon;
  link: string;
  children: ChildMenuItem[];
  childrenAreExpanded?: boolean;
  permissions: Feature[];
  isActive: boolean;
  hideIfDesktopView?: boolean;
  isExternalLink?: boolean;
  isZendeskTenant?: boolean;
};

export type MenuNavigationState = {
  menuItems: MenuItem[];
  menuIsExpanded: boolean;
  menuIsTemporarilyExpanded: boolean;
};

/* Actions */
const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';

type SetActiveMenuItemPayload = {
  name: MenuNames;
};

interface SetActiveMenuItem {
  type: typeof SET_ACTIVE_MENU_ITEM;
  payload: SetActiveMenuItemPayload;
}

const SET_MENU_EXPANDED = 'SET_MENU_EXPANDED';

type SetMenuExpandedPayload = {
  isExpanded: boolean;
};

interface SetMenuExpanded {
  type: typeof SET_MENU_EXPANDED;
  payload: SetMenuExpandedPayload;
}

const SET_MENU_ITEM_CHILDREN_EXPANDED = 'SET_MENU_ITEM_CHILDREN_EXPANDED';

type SetMenuItemChildrenExpandedPayload = {
  name: MenuNames;
  isExpanded: boolean;
};

interface SetMenuItemChildrenExpanded {
  type: typeof SET_MENU_ITEM_CHILDREN_EXPANDED;
  payload: SetMenuItemChildrenExpandedPayload;
}

const SET_MENU_TEMPORARILY_EXPANDED = 'SET_MENU_TEMPORARILY_EXPANDED';

type SetMenuTemporarilyExpandedPayload = {
  isTemporarilyExpanded: boolean;
};

interface SetMenuTemporarilyExpanded {
  type: typeof SET_MENU_TEMPORARILY_EXPANDED;
  payload: SetMenuTemporarilyExpandedPayload;
}

export type MenuNavigationActionTypes =
  | SetActiveMenuItem
  | SetMenuExpanded
  | SetMenuItemChildrenExpanded
  | SetMenuTemporarilyExpanded;

export const setActiveMenuItem = (args: SetActiveMenuItemPayload): MenuNavigationActionTypes => ({
  type: 'SET_ACTIVE_MENU_ITEM',
  payload: args
});

export const setMenuExpanded = (args: SetMenuExpandedPayload): MenuNavigationActionTypes => ({
  type: 'SET_MENU_EXPANDED',
  payload: args
});

export const setMenuTemporarilyExpanded = (args: SetMenuTemporarilyExpandedPayload): MenuNavigationActionTypes => ({
  type: 'SET_MENU_TEMPORARILY_EXPANDED',
  payload: args
});

export const setMenuItemChildrenExpanded = (args: SetMenuItemChildrenExpandedPayload): MenuNavigationActionTypes => ({
  type: 'SET_MENU_ITEM_CHILDREN_EXPANDED',
  payload: args
});

const smartGoalsMenuItem: MenuItem = {
  label: 'SMART Goals',
  IconComponent: StrategyIcon,
  link: '/goals',
  children: [],
  name: 'smartGoals',
  permissions: ['docScoreGoalView', 'docScoreGoalEdit', 'docScoreGoalOverride'],
  isActive: false
};

const analyticsMenuItem: MenuItem = {
  label: 'Analytics',
  IconComponent: AnalyticsIcon,
  link: '/analytics',
  children: [],
  name: 'analytics',
  permissions: [
    'reportCmiAnalysisView',
    'reportCmiComparisonView',
    'reportCmiAnalysisViewProviders',
    'reportCmiComparisonOfProvidersView',
    'reportMedicareComparisonView',
    'reportMedicareComparisonOfProvidersView',
    'safHistogramView'
  ],
  isActive: false
};

export const reportGeneralPermissions: Feature[] = [
  'reportProviderReportView',
  'reportGroupReportAdminView',
  'reportGroupReportProviderView',
  'reportSystemReport',
  'reportTrainingReportProgressView'
];

const reportsMenuItem: MenuItem = {
  label: 'Reports',
  link: '/reports',
  children: [],
  name: 'reports',
  permissions: reportGeneralPermissions,
  IconComponent: ReportIcon,
  isActive: false
};

const trainingMenuItem: MenuItem = {
  label: 'Provider Training',
  link: '/training',
  children: [],
  name: 'training',
  IconComponent: TrainingBookIcon,
  permissions: ['trainingEdit', 'trainingContentView', 'trainingPreview', 'trainingContentApproval'],
  isActive: false
};

const toolsMenuItem: MenuItem = {
  label: 'Tools',
  link: '/tools',
  children: [],
  name: 'tools',
  IconComponent: WrenchIcon,
  permissions: ['usersEdit', 'manageProvider'],
  isActive: false
};

const resourcesMenuItem: MenuItem = {
  label: 'Resources',
  IconComponent: LiveHelpIcon,
  link: HELP_CENTER_URL,
  children: [],
  name: 'resources',
  permissions: [],
  isActive: false,
  hideIfDesktopView: true
};

const menuItems: MenuItem[] = [
  {
    label: 'Dashboard',
    IconComponent: DashboardIcon,
    link: '/',
    children: [],
    name: 'dashboard',
    permissions: [],
    isActive: false
  },
  {
    label: 'Metrics',
    IconComponent: Metrics,
    link: '/metrics',
    children: [],
    name: 'metrics',
    permissions: ['metricsAll', 'metricsDocScore'],
    isActive: false
  },
  {
    ...smartGoalsMenuItem,
    children: [
      {
        label: 'Overview',
        link: '/overview',
        isActive: false,
        parent: smartGoalsMenuItem,
        name: 'overviewLanding',
        permissions: ['docScoreGoalView', 'docScoreGoalEdit', 'docScoreGoalOverride']
      },
      {
        label: 'Build Strategy',
        link: '/build',
        isActive: false,
        parent: smartGoalsMenuItem,
        name: 'buildStrategy',
        permissions: ['docScoreGoalEdit', 'docScoreGoalOverride']
      },
      {
        label: 'Track Performance',
        link: '/track',
        isActive: false,
        parent: smartGoalsMenuItem,
        name: 'trackPerformance',
        permissions: ['docScoreGoalView', 'docScoreGoalEdit', 'docScoreGoalOverride']
      }
    ]
  },
  {
    ...analyticsMenuItem,
    children: [
      {
        label: 'CMI Analysis',
        link: '/cmianalysis',
        isActive: false,
        parent: analyticsMenuItem,
        name: 'cmiAnalysis',
        permissions: [
          'reportCmiAnalysisView',
          'reportCmiComparisonView',
          'reportCmiAnalysisViewProviders',
          'reportCmiComparisonOfProvidersView',
          'reportMedicareComparisonView',
          'reportMedicareComparisonOfProvidersView'
        ]
      },
      {
        label: 'Medicare Histograms',
        link: '/medicarehistograms',
        isActive: false,
        parent: analyticsMenuItem,
        name: 'medicareHistograms',
        permissions: ['safHistogramView']
      }
    ]
  },
  {
    ...reportsMenuItem
  },
  {
    ...trainingMenuItem,
    children: [
      {
        label: 'Assign',
        link: '/assign',
        isActive: false,
        parent: trainingMenuItem,
        name: 'assignTraining',
        permissions: ['trainingEdit', 'trainingContentView']
      },
      {
        label: 'Clinical Content',
        link: '/clinicalcontent',
        isActive: false,
        parent: trainingMenuItem,
        name: 'clinicalContent',
        permissions: ['trainingContentApproval', 'trainingEdit', 'trainingPreview']
      }
    ]
  },
  {
    ...toolsMenuItem,
    children: [
      {
        label: 'Manage Providers',
        link: '/manageprovider',
        isActive: false,
        parent: toolsMenuItem,
        name: 'providerManagement',
        permissions: ['usersEdit', 'usersView', 'manageProvider']
      },
      {
        label: 'Manage Users',
        link: '/manageusers',
        isActive: false,
        parent: toolsMenuItem,
        name: 'userManagement',
        permissions: ['usersEdit', 'usersView']
      }
    ]
  },
  {
    ...resourcesMenuItem,
    children: [
      {
        label: 'Help Center',
        link: HELP_CENTER_URL,
        parent: resourcesMenuItem,
        name: 'helpCenter',
        permissions: [],
        isActive: false,
        isExternalLink: true
      },
      {
        label: 'Contact Support',
        link: 'https://help.clinintell.com/hc/en-us/requests/new',
        parent: resourcesMenuItem,
        name: 'contactSupport',
        permissions: [],
        isActive: false,
        isExternalLink: true
      }
    ]
  },
  {
    label: 'Sign Out',
    name: 'signout',
    IconComponent: SignOutIcon,
    link: '/signout',
    permissions: [],
    isActive: false,
    hideIfDesktopView: true,
    children: []
  }
];

export const initialState: MenuNavigationState = {
  menuItems,
  menuIsExpanded: true,
  menuIsTemporarilyExpanded: false
};

const isMatchedMenuItem = (menuItem: MenuItem, name: MenuNames): boolean => {
  if (menuItem.name === name) {
    return true;
  }

  if (menuItem.children.length === 0) {
    return false;
  }

  const findMatchedChildMenuItem = menuItem.children.find(child => child.name === name);
  return findMatchedChildMenuItem ? true : false;
};

// Reducer
const reducer = (state = initialState, action: MenuNavigationActionTypes): MenuNavigationState => {
  switch (action.type) {
    case 'SET_ACTIVE_MENU_ITEM': {
      state.menuItems.forEach(menuItem => {
        const isMatched = isMatchedMenuItem(menuItem, action.payload.name);
        if (isMatched) {
          menuItem.isActive = !menuItem.isExternalLink;

          if (menuItem.children.length > 0) {
            menuItem.childrenAreExpanded = true;

            menuItem.children.forEach(childMenuItem => {
              childMenuItem.isActive = childMenuItem.name === action.payload.name;
            });
          }
        } else {
          menuItem.isActive = false;

          if (menuItem.children.length > 0) {
            menuItem.childrenAreExpanded = false;
            menuItem.children.forEach(child => (child.isActive = false));
          }
        }
      });

      return {
        ...state,
        // Close any temporary expanded state
        menuIsTemporarilyExpanded: false
      };
    }
    case 'SET_MENU_EXPANDED': {
      return {
        ...state,
        menuIsExpanded: action.payload.isExpanded,
        // Override any temporary expansion state
        menuIsTemporarilyExpanded: false
      };
    }
    case 'SET_MENU_TEMPORARILY_EXPANDED': {
      return {
        ...state,
        menuIsTemporarilyExpanded: action.payload.isTemporarilyExpanded
      };
    }
    case 'SET_MENU_ITEM_CHILDREN_EXPANDED': {
      const selectedMenuItemIndex = state.menuItems.findIndex(menuItem => menuItem.name === action.payload.name);
      if (selectedMenuItemIndex === -1) {
        throw new Error(`Cannot find menu item with name ${action.payload.name}`);
      }

      state.menuItems[selectedMenuItemIndex].childrenAreExpanded = action.payload.isExpanded;
      return {
        ...state,
        menuItems: [...state.menuItems],
        menuIsExpanded: true
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
