/* Adds label to top of bars of bar chart */
import { ClinIntellChart } from '@clinintell/components/Chart/types';
import { Easing } from 'chart.js';
import {
  SliderBox,
  DRAG_CONTROL_WIDTH,
  SliderPluginClass
} from '@clinintell/components/Chart/plugins/sliderPluginClass';

export type SliderRange = {
  id: string;
  startIndex: number;
  span: number;
  color: string;
};

export type SliderOptions = {
  onSliderStartChange: (id: string, startLabel: string) => void;
  onSliderEndChange: (id: string, endLabel: string) => void;
  sliderEnabled: boolean;
  sliders: SliderRange[];
  canvasId: string;
};

export const shouldNotRender = (alreadyRendered: boolean | undefined, easingValue: number): boolean =>
  alreadyRendered || easingValue === 1;

type SliderPlugin = {
  id: string;
  rendered: boolean;
  options: SliderOptions;
  afterLayout(chart: ClinIntellChart, options: SliderOptions): void;
  afterDatasetsDraw(chart: ClinIntellChart, easingValue: Easing, options: SliderOptions): void;
  initialEasingValue: number;
};

const sliderPlugin: SliderPlugin = {
  id: 'sliderPlugin',
  rendered: false,
  initialEasingValue: 0,
  options: {
    onSliderStartChange: (): void => {
      /* empty object */
    },
    onSliderEndChange: (): void => {
      /* empty object */
    },
    sliderEnabled: false,
    sliders: [],
    canvasId: ''
  },
  afterLayout(chart: ClinIntellChart, options: SliderOptions): void {
    if (!options.sliderEnabled || Object(chart).hasOwnProperty('sliderPlugin')) {
      return;
    }

    const canvas = document.getElementById(options.canvasId);
    if (!canvas) {
      return;
    }

    this.options = {
      ...this.options,
      ...options
    };

    // Attach the slider object to the chart object, this way we can attach event handlers here
    chart.sliderPlugin = new SliderPluginClass(options.canvasId, chart.chartArea, canvas.offsetLeft);
    chart.sliderPlugin.onBoxStartChange = this.options.onSliderStartChange;
    chart.sliderPlugin.onBoxEndChange = this.options.onSliderEndChange;

    const canvasParent = canvas?.parentElement;
    canvasParent?.appendChild(chart.sliderPlugin.canvas);
  },
  afterDatasetsDraw(chart: ClinIntellChart, easingValue: Easing, options: SliderOptions): void {
    if (!options.sliderEnabled || !chart.sliderPlugin || !chart.scales) {
      return;
    }

    const verticalScales = chart.scales['x-axis-0'];
    // If only 2 data periods, don't bother rendering sliders. We check for 4 as two blank datasets are manually added to the front and back.
    if (verticalScales.ticks.length <= 4) {
      return;
    }

    // Slider colors could change, in which case we want to redraw with new colors
    // Slider periods could have changed due to outside circumstances, in which case we want to redraw with the new period dimensions
    let colorsHaveChanged = false;
    let resetSliders = false;

    if (!chart.initialEasingValue) {
      chart.initialEasingValue = Number(easingValue);
    }

    // The methods wrapped in this if statement are expensive and cause UI glitches while attempting to move the sliders while the easing value is constantly updated to a value of
    // 1. Run this once on the initial draw to speed up the re-rendering of the sliders (if necessary).
    if (Number(easingValue) === chart.initialEasingValue) {
      chart.sliderPlugin.boxes.forEach((box, index) => {
        if (box.color !== options.sliders[index].color) {
          colorsHaveChanged = true;
        }
      });

      if (chart.sliderPlugin.boxes.length > 0) {
        chart.sliderPlugin.boxes.forEach((box, index) => {
          if (!chart.sliderPlugin) {
            return;
          }

          const xStartIndex = Math.round(box.x / chart.sliderPlugin.widthBetweenPoints);
          if (xStartIndex !== options.sliders[index].startIndex) {
            resetSliders = true;
          }

          const xEndIndex = Math.round((box.x + box.width) / chart.sliderPlugin.widthBetweenPoints);
          if (xEndIndex !== options.sliders[index].startIndex + options.sliders[index].span) {
            resetSliders = true;
          }
        });
      }
    }

    // This method is called many times, wait until it's been rendered and only run this method
    // one time. Only apply this plugin to bar charts.
    chart.renderedOnce = shouldNotRender(chart.renderedOnce, Number(easingValue));
    // TODO -- for some reason the onchange handler is causing this afterDatasetsDraw even to re-render many times.
    // This is a quick fix to only have this fire once
    if (!chart.renderedOnce || (chart.sliderPluginConfigured === true && !colorsHaveChanged && !resetSliders)) {
      return;
    }

    this.rendered = true;
    this.options = {
      ...this.options,
      ...options
    };

    const { datasets } = chart.data;
    if (datasets === undefined) {
      return;
    }

    chart.sliderPlugin.setWidthBetweenPoints(chart);
    chart.sliderPlugin.setXValues(
      verticalScales.ticks.map(tick => {
        if (tick === null) {
          return tick;
        }

        return tick.toString();
      })
    );

    const overlayCanvas = document.getElementById(`${this.options.canvasId}_overlay`) as HTMLCanvasElement;
    const ctx = overlayCanvas.getContext('2d');

    function draw(): void {
      if (!ctx || !chart.sliderPlugin) {
        return;
      }

      ctx.clearRect(0, 0, overlayCanvas?.clientWidth, overlayCanvas?.clientHeight);

      chart.sliderPlugin.boxes.forEach(box => {
        ctx.globalAlpha = 0.05;
        ctx.fillStyle = box.color;
        ctx.fillRect(box.x, box.y, box.width, box.height);

        // Draw the right dragger control
        ctx.globalAlpha = 0.15;
        ctx.beginPath();
        ctx.fillRect(box.rightDragCenterX, 0, DRAG_CONTROL_WIDTH, box.height);
        ctx.closePath();
        ctx.fill();

        ctx.globalAlpha = 0.7;
        ctx.strokeStyle = box.color;
        ctx.beginPath();
        ctx.moveTo(box.rightDragCenterX + DRAG_CONTROL_WIDTH / 2 - 2, box.height / 2);
        ctx.lineTo(box.rightDragCenterX + DRAG_CONTROL_WIDTH / 2 - 2, box.height / 2 + 7.5);
        ctx.lineTo(box.rightDragCenterX + DRAG_CONTROL_WIDTH / 2 - 2, box.height / 2 - 7.5);
        ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(box.rightDragCenterX + DRAG_CONTROL_WIDTH / 2 + 2, box.height / 2);
        ctx.lineTo(box.rightDragCenterX + DRAG_CONTROL_WIDTH / 2 + 2, box.height / 2 + 7.5);
        ctx.lineTo(box.rightDragCenterX + DRAG_CONTROL_WIDTH / 2 + 2, box.height / 2 - 7.5);
        ctx.closePath();
        ctx.stroke();

        // Draw the left dragger control
        ctx.globalAlpha = 0.15;
        ctx.beginPath();
        ctx.fillRect(box.leftDragCenterX, 0, DRAG_CONTROL_WIDTH, box.height);
        ctx.closePath();
        ctx.fill();

        ctx.globalAlpha = 0.7;
        ctx.strokeStyle = box.color;
        ctx.beginPath();
        ctx.moveTo(box.leftDragCenterX + DRAG_CONTROL_WIDTH / 2 + 2, box.height / 2);
        ctx.lineTo(box.leftDragCenterX + DRAG_CONTROL_WIDTH / 2 + 2, box.height / 2 + 7.5);
        ctx.lineTo(box.leftDragCenterX + DRAG_CONTROL_WIDTH / 2 + 2, box.height / 2 - 7.5);
        ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(box.leftDragCenterX + DRAG_CONTROL_WIDTH / 2 - 2, box.height / 2);
        ctx.lineTo(box.leftDragCenterX + DRAG_CONTROL_WIDTH / 2 - 2, box.height / 2 + 7.5);
        ctx.lineTo(box.leftDragCenterX + DRAG_CONTROL_WIDTH / 2 - 2, box.height / 2 - 7.5);
        ctx.closePath();
        ctx.stroke();
      });

      requestAnimationFrame(draw);
    }

    draw();

    // The datasets don't matter, just need the x axes values from a random one
    const metaData = chart.getDatasetMeta(0).data;

    if (ctx === null) {
      return;
    }

    chart.sliderPlugin.boxes = [];
    this.options.sliders.forEach(slider => {
      if (!chart.sliderPlugin) {
        return;
      }

      const startPoint = metaData[slider.startIndex - 1];

      const sliderBox = new SliderBox(
        startPoint._model.x - chart.sliderPlugin.sliderAdjustment - 6.5,
        0,
        chart.sliderPlugin.widthBetweenPoints * slider.span,
        chart.chartArea.bottom - chart.chartArea.top,
        slider.id,
        slider.color
      );

      chart.sliderPlugin.boxes.push(sliderBox);
    });

    chart.sliderPluginConfigured = true;
    chart.initialEasingValue = 0;
  }
};

export default sliderPlugin;
