import React, { useEffect, useState } from 'react';
import { useHospitalsGetById, HospitalsGetByIdQueryParams } from '@clinintell/api/apiComponents';
import { Box, Typography } from '@mui/material';
import { Select, SelectOptionType } from '../../components/index';
import useErrorHandling from '@clinintell/errors/useErrorHandling';

export type EntityReturnType = {
  id: number;
  hospitalName: string;
  physicianGroups: {
    id: number;
    physicianGroupName: string;
    trainingUsers: boolean;
  }[];
};

type SelectOptionTrainingType = SelectOptionType & { trainingUsers: boolean };
type Options = SelectOptionTrainingType[];

type Props = {
  labelPosition?: 'top' | 'side' | 'none';
  title?: string;
  hospitalId?: number;
  defaultValue?: number;
  onGroupChangeFn: (value: number | null, display?: string) => void;
  onSelectLoaded?: (value: boolean) => void;
  includeAllGroups?: boolean;
  addAllGroupsToSelection?: boolean;
  excludeOtherGroups?: boolean;
  excludeOtherMDs?: boolean;
  excludeHiddenProviders?: boolean;
  excludeHiddenSpecialtyGroups?: boolean;
  trainingOnly?: boolean;
  width?: number | string;
  labelWidth?: number | string;
};

const SelectGroup: React.FC<Props> = ({
  labelPosition = 'top',
  title,
  hospitalId,
  defaultValue,
  onGroupChangeFn,
  onSelectLoaded,
  includeAllGroups = true,
  addAllGroupsToSelection = false,
  excludeOtherGroups = false,
  excludeOtherMDs = false,
  excludeHiddenProviders = false,
  excludeHiddenSpecialtyGroups = false,
  trainingOnly = false,
  width = 400,
  labelWidth
}) => {
  const { showErrorMessage } = useErrorHandling();
  const [groupOptions, setGroupOptions] = useState<Options | []>([]);

  const groupParams: HospitalsGetByIdQueryParams = {
    includeAllGroups: includeAllGroups ? 1 : 0,
    excludeOtherGroups: excludeOtherGroups ? 1 : 0,
    excludeOtherMDs: excludeOtherMDs ? 1 : 0,
    excludeHiddenProviders: excludeHiddenProviders ? 1 : 0,
    excludeHiddenSpecialtyGroups: excludeHiddenSpecialtyGroups ? 1 : 0
  };

  const { data: groupData, isLoading } = useHospitalsGetById(
    {
      pathParams: {
        id: Number(hospitalId)
      },
      queryParams: {
        ...groupParams
      },
      onError: showErrorMessage
    },
    {
      enabled: hospitalId !== undefined && hospitalId !== -1
    }
  );

  useEffect(() => {
    if (!groupData || isLoading) return;

    if (groupData && groupData.physicianGroups) {
      let dropDownList: Options | [] = groupData.physicianGroups
        .filter(group =>
          addAllGroupsToSelection ? group.physicianGroupName : group.physicianGroupName !== 'All Groups'
        )
        .map(group => {
          return {
            value: group.id,
            label: group.physicianGroupName === 'All Groups' ? 'All' : group.physicianGroupName,
            trainingUsers: group.trainingUsers,
            useLabelForTestId: true
          } as SelectOptionTrainingType;
        })
        .sort((a, b) => {
          if (a.label === 'All') return -1;
          return a.label.localeCompare(b.label, 'en', { ignorePunctuation: true });
        });

      if (trainingOnly) {
        dropDownList = dropDownList.filter(group => group.trainingUsers);
      }

      setGroupOptions(dropDownList);
      if (dropDownList.length === 1 || (dropDownList.length > 0 && addAllGroupsToSelection && defaultValue === -1)) {
        onGroupChangeFn(dropDownList[0].value as number, dropDownList[0].label);
        return;
      }

      if (
        onSelectLoaded &&
        defaultValue &&
        dropDownList.length > 1 &&
        dropDownList.find(group => group.value === defaultValue)
      ) {
        onSelectLoaded(true);
      }
    }
  }, [groupData, onGroupChangeFn, isLoading, onSelectLoaded, defaultValue, trainingOnly, addAllGroupsToSelection]);

  return (
    <Box
      display="flex"
      flexDirection={labelPosition === 'top' ? 'column' : 'row'}
      alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
    >
      {labelPosition !== 'none' && (
        <Typography variant="p2" width={labelWidth !== undefined || labelWidth !== null ? labelWidth : 'auto'}>
          {title ?? 'Physician Group'}
        </Typography>
      )}
      <Box
        width="100%"
        style={labelPosition === 'none' ? undefined : labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}
      >
        <Select
          isDisabled={hospitalId === undefined || hospitalId === -1 || isLoading}
          isSearchable={true}
          isLoading={hospitalId !== undefined && hospitalId !== -1 ? isLoading : undefined}
          options={groupOptions}
          value={Number(defaultValue)}
          onChange={(value, label): void => {
            onGroupChangeFn(Number(value), label);
          }}
          width={width}
          testId="groupSelector"
        />
      </Box>
    </Box>
  );
};

export default React.memo(SelectGroup);
