import React from 'react';
import { isIE, browserVersion } from 'react-device-detect';
import { Box, useTheme } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { ChartDatasetBase } from '@clinintell/components/Chart/types';

interface PieChartProps {
  title?: string;
  chartData: ChartDatasetBase;
  enableTooltip?: boolean;
  labels?: string[];
  enableBorder?: boolean;
  borderColor?: string;
  styles?: React.CSSProperties;
}

const PieChart: React.FC<PieChartProps> = ({
  title = 'pieChart',
  chartData,
  enableTooltip = false,
  labels = ['Gap Closure'],
  enableBorder = false,
  borderColor,
  styles
}) => {
  const theme = useTheme();
  const isIE11 = isIE && browserVersion === '11';

  return (
    <>
      <Box id={title} role="img" aria-label="PieChart" aria-describedby={`${title}-graphDescription`} style={styles}>
        <Pie
          data={{
            datasets: [
              {
                data: chartData.data,
                backgroundColor: chartData.backgroundColor,
                borderWidth: enableBorder ? 1 : 2,
                borderColor: borderColor
                  ? borderColor
                  : enableBorder
                  ? theme.palette.grey[300]
                  : theme.palette.shade.white
              }
            ],
            labels: labels
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            tooltips: {
              enabled: enableTooltip,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              custom: function(tooltipModel: Chart.ChartTooltipModel): any {
                if (enableTooltip) return;

                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (tooltipModel.dataPoints && tooltipModel.dataPoints[0].index === 0) {
                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const dataValue = this._data.datasets[0].data[0];
                    if (labels[0].includes('%d')) {
                      tooltipEl.innerText = labels[0].replace('%d', dataValue);
                    } else {
                      tooltipEl.innerText = `${labels[0]}: ${dataValue}%`;
                    }
                    document.body.appendChild(tooltipEl);
                  }

                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = '0';
                    return;
                  }

                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const position = this._chart.canvas.getBoundingClientRect();
                  tooltipEl.style.opacity = '1';
                  tooltipEl.style.position = 'absolute';
                  const tooltipLeftPosition =
                    position.left > window.innerWidth - window.innerWidth / 4
                      ? position.left - 125
                      : position.left + window.pageXOffset + tooltipModel.caretX;
                  tooltipEl.style.left = tooltipLeftPosition + 'px';
                  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                  tooltipEl.style.padding = '8px';
                  tooltipEl.style.backgroundColor = theme.palette.dark[700];
                  tooltipEl.style.color = theme.palette.grey[200];
                  tooltipEl.style.borderRadius = '5px';
                  tooltipEl.style.fontWeight = 'bold';
                  tooltipEl.style.zIndex = '999999';
                  tooltipEl.style.fontSize = theme.typography.caption.fontSize as string;
                } else {
                  // remove the tooltip
                  tooltipEl && tooltipEl.remove();
                }
              }
            }
          }}
          width={isIE11 ? 200 : 300}
        />
      </Box>
      <Box id={`${title}-graphDescription`} display="none">
        {JSON.stringify(chartData)}
      </Box>
    </>
  );
};

export default PieChart;
