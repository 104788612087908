import React from 'react';
import { AppBar, Box } from '@mui/material';

type MobileHeaderProps = {
  children: React.ReactNode;
};

export const MOBILE_HEADER_HEIGHT = 80;

const MobileHeader = React.forwardRef<HTMLDivElement | null, MobileHeaderProps>(({ children }, ref) => {
  return (
    <AppBar
      ref={ref}
      sx={{
        position: 'fixed',
        top: 0,
        backgroundColor: 'shade.white',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
        color: 'grey.500',
        width: '100%',
        zIndex: 'header'
      }}
    >
      <Box
        sx={{
          height: `${MOBILE_HEADER_HEIGHT}px`,
          display: 'flex',
          alignItems: 'center',
          paddingX: 2,
          position: 'relative'
        }}
      >
        {children}
      </Box>
    </AppBar>
  );
});

export default MobileHeader;
