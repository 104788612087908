import React from 'react';
import { Box } from '@mui/material';
import DataLoaderLottie from './DataLoaderLottie';

type LoadingScreenProps = {
  backgroundHeight?: string | number;
  loadingIndicatorSize?: number;
};

const LoadingScreen: React.FC<LoadingScreenProps> = ({ loadingIndicatorSize = 250, backgroundHeight }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'transparent',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        ...(backgroundHeight !== undefined ? { height: backgroundHeight } : null)
      }}
    >
      <DataLoaderLottie size={loadingIndicatorSize} />
    </Box>
  );
};

export default LoadingScreen;
