import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';

const OverviewLanding = lazy(() =>
  import(
    /* webpackChunkName: 'OverviewLanding' */ '@clinintell/containers/autogenStrategy/sections/overview/OverviewLanding'
  )
);
const DocScoreGoalController = lazy(() =>
  import(
    /* webpackChunkName: 'DocScoreGoalController' */ '@clinintell/containers/autogenStrategy/sections/buildStrategy/DocScoreGoalStrategy'
  )
);
const TrackPerformance = lazy(() =>
  import(/* webpackChunkName: 'TrackPerformance' */ '@clinintell/containers/autogenStrategy/TrackPerformance')
);

const GoalRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route
        index
        path="overview"
        element={
          <Can
            permissions={['docScoreGoalEdit', 'docScoreGoalView', 'docScoreGoalOverride']}
            yes={(): JSX.Element => <OverviewLanding />}
            no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
          />
        }
      />
      <Route
        path="build"
        element={
          <Can
            permissions={['docScoreGoalEdit', 'docScoreGoalView', 'docScoreGoalOverride']}
            yes={(): JSX.Element => <DocScoreGoalController />}
            no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
          />
        }
      />
      <Route
        path="track"
        element={
          <Can
            permissions={['docScoreGoalEdit', 'docScoreGoalView', 'docScoreGoalOverride']}
            yes={(): JSX.Element => <TrackPerformance />}
            no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
          />
        }
      />
      <Route path="*" element={<RouteErrorPage error={new Error('401')} />} />
    </Routes>
  );
};

export default GoalRoutes;
