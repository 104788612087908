export const downloadFile = (data: ArrayBuffer, dataType: string, filename: string) => {
  const blob = new Blob([data as ArrayBuffer], {
    type: dataType
  });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }

  const link = document.createElement('a');
  link.download = filename;
  link.href = URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
