import React from 'react';
import { Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  skeleton: {
    background: theme.palette.blue.light5,
    borderRadius: '5px'
  }
}));

interface Props {
  variant: 'circular' | 'rectangular' | 'text' | undefined;
  height: string;
  width: string;
}

const ClinIntellSkeleton: React.FC<Props> = ({ variant, height, width }) => {
  const { skeleton } = useStyles();

  return <Skeleton variant={variant} height={height} width={width} className={skeleton} />;
};

export default ClinIntellSkeleton;
