import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const Metrics = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{ width: 20, height: 18, fill: 'none', ...props.sx }}
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3334 9H15L12.5 16.5L7.50002 1.5L5.00002 9H1.66669"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
Metrics.muiName = SvgIcon.muiName;

export default Metrics;
