import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TrainingBook = (props: SvgIconProps) => (
  <SvgIcon
    sx={{
      width: '20px',
      height: '20px',
      fill: 'none',
      ...props.sx
    }}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3333 2.50024H13.3333C12.4493 2.50024 11.6014 2.85143 10.9763 3.47655C10.3512 4.10168 10 4.94952 10 5.83358V17.5002C10 16.8372 10.2634 16.2013 10.7322 15.7325C11.2011 15.2636 11.837 15.0002 12.5 15.0002H18.3333V2.50024Z"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66669 2.50024H6.66669C7.55074 2.50024 8.39859 2.85143 9.02371 3.47655C9.64883 4.10168 10 4.94952 10 5.83358V17.5002C10 16.8372 9.73663 16.2013 9.26779 15.7325C8.79895 15.2636 8.16306 15.0002 7.50002 15.0002H1.66669V2.50024Z"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
TrainingBook.muiName = SvgIcon.muiName;

export default TrainingBook;
