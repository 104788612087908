import React, { useCallback, useEffect, useState, useRef } from 'react';
import { makeMonthYearList } from '@clinintell/utils/dates';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useRootDefaultDates from '@clinintell/containers/systemReport/logic/useRootDefaultDates';
import { SelectOptionType } from '@clinintell/types/common';
import DropDown from '@clinintell/components/dropdown/DropDown';
import AsyncSelect from 'react-select/async';
import useAlert from '@clinintell/components/alert/logic/useAlert';
//
import useExecutiveSummaryReport from '@clinintell/containers/systemReport/logic/useExecutiveSummaryReport';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '0.9rem',
    paddingBottom: theme.spacing(1)
  }
}));

type SystemReportType = {
  dateChngTracker: (dates: string) => void;
};

const SystemReport: React.FC<SystemReportType> = ({ dateChngTracker }) => {
  const firstUpdate = useRef(true);
  const [reportDate, setReportDate] = useState<string>('');
  const [dateOptions, setDateOptions] = useState<SelectOptionType[]>([]);
  const defaultDates = useRootDefaultDates();
  const { label } = useStyles();

  useEffect(() => {
    if (!defaultDates || !defaultDates.currentMinMonth || !defaultDates.currentMaxMonth) {
      return;
    }

    const monthYearList = makeMonthYearList(defaultDates.currentMinMonth, defaultDates.currentMaxMonth);
    setDateOptions(monthYearList);
    dateChngTracker(reportDate);

    if (firstUpdate.current) {
      firstUpdate.current = false;
      setReportDate(monthYearList[0].value as string);
      return;
    }
  }, [defaultDates, reportDate, dateChngTracker]);

  const defaultOption = dateOptions.find(option => option.value === reportDate);

  return (
    <>
      <Box display="block" padding={'8px'}>
        <Box alignItems="baseline" alignContent="center" flexDirection="column">
          {dateOptions.length > 0 ? (
            <>
              <Typography className={label}>Report Date</Typography>
              <DropDown
                options={dateOptions}
                value={defaultOption}
                onChange={(value): void => {
                  dateChngTracker(value as string);
                  setReportDate(value as string);
                }}
                testId="reportDateSelector"
              />
            </>
          ) : (
            <EmptyDropdDown dropdownLabel="Report Date" />
          )}
        </Box>
      </Box>
    </>
  );
};

type ExecutiveReportType = {
  dateChngTracker: (dates: string) => void;
};

export const ExecutiveReport: React.FC<ExecutiveReportType> = ({ dateChngTracker }) => {
  const firstUpdate = useRef(true);
  const defaultDates = useRootDefaultDates();
  const { label } = useStyles();
  const [reportDate, setReportDate] = useState<string>('');
  const [dateOptions, setDateOptions] = useState<SelectOptionType[]>([]);
  const { pushAlert } = useAlert();
  const { hasError } = useExecutiveSummaryReport();

  // Might not need to kee this in the callback but leaving it for now
  const setDateInfo = useCallback(() => {
    if (!defaultDates || !defaultDates.absoluteMinMonth || !defaultDates.currentMaxMonth) {
      return;
    }
    const monthYearList = makeMonthYearList(defaultDates.absoluteMinMonth, defaultDates.currentMaxMonth);
    setDateOptions(monthYearList);
    dateChngTracker(reportDate);
    if (firstUpdate.current) {
      firstUpdate.current = false;
      setReportDate(monthYearList[0].value as string);
      return;
    }
  }, [defaultDates, reportDate, dateChngTracker]);

  useEffect(() => {
    setDateInfo();
  }, [setDateInfo]);

  useEffect(() => {
    if (hasError) {
      pushAlert({ message: 'There was an error with the report', variant: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  return (
    <>
      <Box display="block" padding={'8px'}>
        <Box alignItems="baseline" alignContent="center" flexDirection="column">
          {dateOptions.length > 0 ? (
            <>
              <Typography className={label}>Report Date</Typography>
              <DropDown
                options={dateOptions}
                value={reportDate}
                onChange={(value): void => {
                  dateChngTracker(value as string);
                  setReportDate(value as string);
                }}
                testId="reportDateSelector"
              />
            </>
          ) : (
            <EmptyDropdDown dropdownLabel="Report Date" />
          )}
        </Box>
      </Box>
    </>
  );
};

type EmptyDropDownType = {
  dropdownLabel?: string;
};

const EmptyDropdDown: React.FC<EmptyDropDownType> = ({ dropdownLabel }) => {
  const theme = useTheme();
  const width = '400px';

  const fontStyles = {
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    color: theme.palette.grey[900]
  };
  const optionStyles = {
    paddingX: 2,
    paddingY: 1.5,
    color: theme.palette.grey[900],
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start">
      <Typography width="100%" variant="p2">
        {dropdownLabel}
      </Typography>
      <Box width="100%" style={{ marginTop: 8 }}>
        <AsyncSelect
          isDisabled={true}
          placeholder="Select"
          styles={{
            container: (provided, _state) => ({
              ...provided,
              minWidth: width
            }),
            indicatorsContainer: (provided, _state) => ({
              ...provided,
              '&:hover': {
                backgroundColor: theme.palette.grey[50],
                borderRadius: 25,
                width: 32,
                height: 32,
                margin: 2,
                '& div': {
                  padding: 6
                }
              },
              '& span': {
                display: 'none'
              }
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              '& svg': {
                width: '1em',
                height: '1em',
                fontSize: '1.5em',
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 250ms',
                strokeWidth: '0.25px'
              }
            }),
            control: (provided, _state) => ({
              ...provided,
              ...fontStyles,
              borderRadius: 10,
              textAlign: 'left',
              borderColor: theme.palette.grey[200],
              boxShadow: `0 0 0 0 ${theme.palette.grey[200]}`,
              '&:hover': {
                borderColor: theme.palette.grey[200]
              }
            }),
            menu: (provided, _state) => ({
              ...provided,
              ...fontStyles,
              width: '100%',
              borderRadius: 10,
              overflow: 'hidden',
              zIndex: theme.zIndex.modal + 3
            }),
            menuList: (provided, _state) => ({
              MaxHeight: '400px',
              ...provided
            }),
            menuPortal: (provided, _state) => ({
              ...provided,
              zIndex: theme.zIndex.modal + 3
            }),
            option: (provided, state) => ({
              ...provided,
              ...optionStyles,
              fontWeight: state.isSelected ? 600 : 'normal',
              borderBottom: `0 solid ${theme.palette.grey[200]}`
            })
          }}
          testId="reportDateSelector"
        />
      </Box>
    </Box>
  );
};

export default React.memo(SystemReport);
