import {
  ColumnResizedEvent,
  ColumnState,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  RowSelectedEvent,
  SortChangedEvent
} from 'ag-grid-community';
import { ColumnApi, GridApi } from 'ag-grid-community';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import {
  deletePersistedFilter,
  deletePersistedQuickFilter,
  getPersistedColumnState,
  getPersistedFilter,
  getPersistedQuickFilter,
  setPersistedColumnState,
  setPersistedFilter,
  setPersistedQuickFilter,
  setPersistedRowsSelection
} from '../utils/persistence';

type TableApiState = {
  quickFilterText: string;
};

type TableApiActions = {
  initializeTableApis: (readyGridApi: GridApi, readyColumnApi: ColumnApi) => void;
  onSortingChanged: (event: SortChangedEvent) => void;
  onColumnResized: (event: ColumnResizedEvent) => void;
  onQuickFilterChanged: (filterText: string) => void;
  onFilterChanged: (event: FilterChangedEvent) => void;
  onFirstDataRendered: (event: FirstDataRenderedEvent) => void;
  onRowsSelectedChanged: (rows: unknown[], event: RowSelectedEvent) => void;
  getPersistedColumnState: (id: string, key: string) => ColumnState[] | undefined;
  getSortedColumn: () => [string, 'asc' | 'desc' | null | undefined] | undefined;
};

const TableApiActionsContext = createContext<TableApiActions>({
  initializeTableApis: () => {
    /* empty function */
  },
  onSortingChanged: () => {
    /* empty function */
  },
  onColumnResized: () => {
    /* empty function */
  },
  onQuickFilterChanged: () => {
    /* empty function */
  },
  onFilterChanged: () => {
    /* empty function */
  },
  onFirstDataRendered: () => {
    /* empty function */
  },
  onRowsSelectedChanged: () => {
    /* empty function */
  },
  getPersistedColumnState: () => undefined,
  getSortedColumn: () => undefined
});
const TableApiStateContext = createContext<TableApiState>({
  quickFilterText: ''
});

type TableApiProviderProps = {
  persistTableId?: string;
  persistTableKey?: string;
  persistFilterId?: string;
  persistFilterKey?: string;
  persistRowSelectionId?: string;
  persistRowSelectionKey?: string;
};

export const TableApiProvider: React.FC<TableApiProviderProps> = ({
  children,
  persistTableKey,
  persistTableId,
  persistFilterKey,
  persistFilterId,
  persistRowSelectionId,
  persistRowSelectionKey
}) => {
  const [quickFilterText, setQuickFilterText] = useState('');

  useEffect(() => {
    // If the persist ID or key change, then apply any saved column state that matches that signature.
    // If one doesn't exist, reset to the default column state.
    if (persistTableId && persistTableKey) {
      const persistedColumnState = getPersistedColumnState(persistTableId, persistTableKey);
      if (persistedColumnState) {
        columnApi.current?.applyColumnState({ state: persistedColumnState });
      } else {
        columnApi.current?.resetColumnState();
      }
    }
  }, [persistTableId, persistTableKey]);

  const gridApi = useRef<GridApi>();
  const columnApi = useRef<ColumnApi>();

  const initializeTableApis = (readyGridApi: GridApi, readyColumnApi: ColumnApi) => {
    gridApi.current = readyGridApi;
    columnApi.current = readyColumnApi;
  };

  const verifyApiInitialized = (
    callback: ({ gridApi, columnApi }: { gridApi: GridApi; columnApi: ColumnApi }) => void
  ) => {
    if (!gridApi.current || !columnApi.current) {
      throw new Error('Grid API and Column API must be initialized first. See initializeTableApis function.');
    }

    return callback({ gridApi: gridApi.current, columnApi: columnApi.current });
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    if (persistFilterId && persistFilterKey) {
      // Either set the quick filter text if persisted, or keep clear with a blank string.
      const persistedFilterText = getPersistedQuickFilter(persistFilterId, persistFilterKey) || '';

      event.api.setQuickFilter(persistedFilterText);
      setQuickFilterText(persistedFilterText);

      const persistedFilterModel = getPersistedFilter(persistFilterId, persistFilterKey);
      if (persistedFilterModel) {
        event.api.setFilterModel(persistedFilterModel);
      }
    }

    gridApi.current = event.api;
    columnApi.current = event.columnApi;
  };

  const onQuickFilterChanged = (filterText: string) => {
    setQuickFilterText(filterText);

    verifyApiInitialized(({ gridApi }) => {
      gridApi.setQuickFilter(filterText);

      if (persistFilterId && persistFilterKey) {
        if (filterText) {
          setPersistedQuickFilter(persistFilterId, persistFilterKey, filterText);
        } else {
          deletePersistedQuickFilter(persistFilterId, persistFilterKey);
        }
      }
    });
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    if (persistFilterId && persistFilterKey) {
      const filterModel = event.api.getFilterModel();
      if (Object.keys(filterModel).length > 0) {
        setPersistedFilter(persistFilterId, persistFilterKey, filterModel);
      } else {
        deletePersistedFilter(persistFilterId, persistFilterKey);
      }
    }

    gridApi.current = event.api;
    columnApi.current = event.columnApi;
  };

  const onSortingChanged = (event: SortChangedEvent) => {
    if (persistTableKey && persistTableId) {
      // event source uiColumnSorted means there was manual interaction trigger the sorting change. We
      // only want to persist manual sorting change events.
      if (event.source === 'uiColumnSorted') {
        setPersistedColumnState(persistTableId, persistTableKey, event.columnApi.getColumnState());
      }
    }

    gridApi.current = event.api;
    columnApi.current = event.columnApi;
  };

  const onColumnResized = (event: ColumnResizedEvent) => {
    if (persistTableKey && persistTableId) {
      // event source uiColumnResized means there was manual interaction trigger the column sizing. We
      // only want to persist manual column sizing events.
      if (event.source === 'uiColumnResized' && event.finished) {
        setPersistedColumnState(persistTableId, persistTableKey, event.columnApi.getColumnState());
      }
    }

    gridApi.current = event.api;
    columnApi.current = event.columnApi;
  };

  const onRowsSelectedChanged = (rows: unknown[], event: RowSelectedEvent) => {
    if (persistRowSelectionId && persistRowSelectionKey) {
      setPersistedRowsSelection(persistRowSelectionId, persistRowSelectionKey, rows);
    }

    gridApi.current = event.api;
    columnApi.current = event.columnApi;
  };

  const getSortedColumn = (): [string, 'asc' | 'desc' | undefined | null] | undefined => {
    if (columnApi.current) {
      const columnState = columnApi.current.getColumnState();
      const sortedColumn = columnState.find(col => col.sort !== null);
      return sortedColumn ? [sortedColumn.colId, sortedColumn.sort] : undefined;
    }

    return undefined;
  };

  return (
    <TableApiStateContext.Provider value={{ quickFilterText }}>
      <TableApiActionsContext.Provider
        value={{
          initializeTableApis,
          onSortingChanged,
          onColumnResized,
          onQuickFilterChanged,
          onFirstDataRendered,
          getPersistedColumnState,
          getSortedColumn,
          onFilterChanged,
          onRowsSelectedChanged
        }}
      >
        {children}
      </TableApiActionsContext.Provider>
    </TableApiStateContext.Provider>
  );
};

export const useTableApiState = (): TableApiState => {
  const context = useContext(TableApiStateContext);
  if (context === undefined) {
    throw new Error('useTableApiState hook must be used within a TableApiProvider');
  }

  return context;
};

export const useTableApiActions = (): TableApiActions => {
  const context = useContext(TableApiActionsContext);
  if (context === undefined) {
    throw new Error('useTableActionsState hook must be used within a TableApiProvider');
  }

  return context;
};
