import React from 'react';
import { Box, Typography } from '@mui/material';
import { Select, SelectOptionType } from '../../components/index';

export type EntityReturnType = {
  id: number;
  hospitalName: string;
  physicianGroups: {
    id: number;
    physicianGroupName: string;
    trainingUsers: boolean;
  }[];
};

type Props = {
  labelPosition?: 'top' | 'side' | 'none';
  title?: string;
  hospitalId?: number;
  defaultValue?: number;
  onGroupChangeFn: (value: number | null, display?: string) => void;
  physicianGroupData: SelectOptionType[];
  width?: number | string;
  labelWidth?: number | string;
};

const SelectReportGroup: React.FC<Props> = ({
  labelPosition = 'top',
  title,
  hospitalId,
  defaultValue,
  onGroupChangeFn,
  physicianGroupData,
  width = 400,
  labelWidth
}) => {
  return (
    <Box
      display="flex"
      flexDirection={labelPosition === 'top' ? 'column' : 'row'}
      alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
    >
      {labelPosition !== 'none' && (
        <Typography variant="p2" width={labelWidth !== undefined || labelWidth !== null ? labelWidth : 'auto'}>
          {title ?? 'Physician Group'}
        </Typography>
      )}
      <Box
        width="100%"
        style={labelPosition === 'none' ? undefined : labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}
      >
        <Select
          isDisabled={hospitalId === undefined || hospitalId === -1}
          isSearchable={true}
          options={physicianGroupData}
          value={Number(defaultValue)}
          onChange={(value, label): void => {
            onGroupChangeFn(Number(value), label);
          }}
          width={width}
          testId="groupSelector"
        />
      </Box>
    </Box>
  );
};

export default React.memo(SelectReportGroup);
