import React, { useEffect, useState } from 'react';
import { ApplicationAPI } from '@clinintell/utils/api';
import { Box, Grid, Typography, useTheme, SpeedDialAction, SpeedDial, SpeedDialIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import WidgetJSON from '@clinintell/containers/dashboard/widgetTypes';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { ReorderList } from '@clinintell/utils/resources';
import {
  useUser,
  useMetricsNavigation,
  useMetricsNavigationDispatch,
  useDashboard,
  useDashboardDispatch,
  useHospitals,
  useHospitalsDispatch,
  useDashboard2,
  useDashboard2Dispatch
} from '@clinintell/modules/store';
import { startOfMonth, sub, format } from 'date-fns';
import {
  initializeDashboard,
  fetchDashboardList,
  clearWidgetDefinition,
  resetUserDashboard,
  setDashboardList,
  WidgetDefinition
} from '@clinintell/modules/dashboard';
import { initializeDashboard2 } from '@clinintell/modules/dashboard2';
import { Metrics as MetricTypes, metricLabels, setDefaultDates } from '@clinintell/modules/metricsNavigation';
import Widget from './Widget';
import { isIE, browserVersion } from 'react-device-detect';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { TreeJSON } from '@clinintell/modules/orgTree';
import { DefaultDatesJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import {
  getDefaultMetricTableColumns,
  getDefaultViewForMetric
} from '@clinintell/containers/metrics/typings/tableSchemas';
import ActionPanel from '@clinintell/components/ActionPanel';
import { ChartDataSetAverageTypes } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import AddWidget from './AddWidget';
import EditWidget from './EditWidget';
import { ModalWindow } from '@clinintell/components/index';
import Button from '@clinintell/components/button/Button';
import useAlert from '@clinintell/components/alert/logic/useAlert';
import { containerBorderRadius } from '@clinintell/theme/theme';
import DashboardUpdate from '@clinintell/containers/dashboard/DashboardUpdate';
const hash = require('object-hash');

interface NoDataProps {
  message?: string;
}

export const NoDataMessage: React.FC<NoDataProps> = ({ message = 'No data to display' }) => {
  return (
    <Box padding={5}>
      <Typography variant="subheading">{message}</Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    '& .box': {
      overflow: 'auto',
      '& @media screen and (-webkit-min-device-pixel-ratio:0)': {
        overflow: 'scroll'
      },
      '& .MuiTableContainer-root': {
        overflow: 'inherit'
      }
    },
    '& table td:not(:last-child)': {
      color: theme.palette.common.black,
      cursor: 'auto'
    },
    '& tr[class*="footerRowClass"] td': {
      color: theme.palette.common.black
    }
  },
  gridItem: {
    padding: theme.spacing(1),
    '& > div': {
      border: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: 'none',
      borderRadius: containerBorderRadius
    }
  },
  gridItemMove: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    border: `dashed 1px`,
    borderColor: `${theme.palette.grey[300]}`
  },
  closeIcon: {
    float: 'right',
    color: theme.palette.common.black,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  addWidgetButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.blue.main,
    '&:hover': {
      color: theme.palette.blue.main,
      backgroundColor: theme.palette.grey[50]
    }
  },
  cancelButton: {
    textDecoration: 'underline',
    marginRight: theme.spacing(1),
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  popupMd: {
    maxWidth: '880px'
  }
}));

const ALLOWED_MAX_WIDGETS = 10;

const EmptyWidgetDefinition: WidgetJSON = {
  name: '',
  endpoint: '',
  size: 'm',
  widgetType: 'graph',
  entityName: ''
};

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const { pushAlert } = useAlert();
  const { id: userId, rootId, role } = useUser();
  const { roleName } = role;

  const { widgetList, isLoading, newWidget, hasError, errorMessage } = useDashboard();

  if (hasError) {
    throw new Error(errorMessage);
  }

  const [widgetListLoading, setWidgetListLoading] = useState<boolean>(isLoading);
  const [activeWidgetList, setActiveWidgetList] = useState<WidgetJSON[]>(widgetList);
  const dashboardDispatch = useDashboardDispatch();
  const { absoluteMinPeriod, defaultPeriodEnd } = useMetricsNavigation();

  const { container, gridItem } = useStyles();

  const [widgetOpen, setWidgetOpen] = useState(false);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const [alertWindowOpen, setAlertWindowOpen] = useState(false);

  const [actionPanelTitle, setActionPanelTitle] = useState('');
  const [actionPanelType, setActionPanelType] = useState<'new' | 'update'>('new');
  const editWidgetDefinition = React.useRef<WidgetJSON>(EmptyWidgetDefinition);
  const [isDragDropDisabled, setIsDragDropDisabled] = useState(false);

  const [updateDashboard, setUpdateDashboard] = useState(false);

  useEffect(() => {
    if (updateDashboard) {
      // Use a timeout to give the server enough time to update
      window.setTimeout(() => {
        dashboardDispatch(fetchDashboardList(userId));
        setUpdateDashboard(false);
        setWidgetListLoading(false);
      }, 1000);
    }
  }, [dashboardDispatch, updateDashboard, userId]);

  const actions = [
    { icon: <LibraryAddIcon />, name: 'Add Widget', fn: (): void => handleDashboardActionOpen() },
    { icon: <RotateLeftIcon />, name: 'Reset Dashboard', fn: (): void => setAlertWindowOpen(true) }
  ];

  if (activeWidgetList && activeWidgetList.length >= ALLOWED_MAX_WIDGETS) {
    actions.splice(0, 1);
  }

  const buildWidgetDefinition = (definition: WidgetDefinition): WidgetJSON => {
    let title = definition.entityName ?? '';
    title += ` ${metricLabels[definition.metric]}`;
    if (definition.condition) {
      title += ` ${definition.conditionName}`;
    }
    title += ` ${definition.widgetType === 'table' ? 'Performance' : 'Trend'}`;

    const endpointEntity = definition.entity ?? rootId;

    const endpointMetric =
      definition.metric === 'condition' || definition.metric === 'targetedConditions'
        ? 'conditions'
        : definition.metric;
    const endpoint = `${definition.widgetType === 'table' ? 'metrics' : 'graph'}/${endpointMetric}/${endpointEntity}`;

    const minDate = startOfMonth(sub(defaultPeriodEnd, { months: 13 }));
    const maxDate = startOfMonth(sub(defaultPeriodEnd, { months: 1 }));

    const sortedIds = activeWidgetList.map((widget: WidgetJSON) => widget.id).sort();
    const id = sortedIds.length > 0 ? Number(sortedIds[sortedIds.length - 1]) + 1 : 1;

    const newWidget: WidgetJSON = {
      id,
      name: `widget${Math.random() * 10000}`,
      title: title,
      endpoint,
      queryString: {
        startDate: '{Last13MonthsStart}T00:00:00',
        endDate: '{Last13MonthsEnd}T00:00:00',
        currentStart: '{DefaultDate.CurrentMinMonth}T00:00:00',
        currentEnd: '{DefaultDate.CurrentMaxMont}T00:00:00',
        historicalStart: '{DefaultDate.HistoricalMinMonth}T00:00:00',
        historicalEnd: '{DefaultDate.HistoricalMaxMonth}T00:00:00',
        conditionName: ''
      },
      metric: definition.metric,
      view: definition.metric !== 'condition' ? getDefaultViewForMetric(definition.metric) : null,
      widgetType: definition.widgetType,
      size: 'm',
      entity: definition.entity ?? undefined,
      entityName: definition.entityName ?? '',
      customSettings: {
        startDate: format(minDate, "yyyy-MM-dd'T'HH:mm:ss"),
        endDate: format(maxDate, "yyyy-MM-dd'T'HH:mm:ss"),
        currentStart: minDate,
        currentEnd: maxDate,
        historicalStart: sub(minDate, { years: 1 }),
        historicalEnd: sub(maxDate, { years: 1 }),
        conditionName: ''
      }
    };

    if (definition.metric !== 'targetedConditions' && definition.condition && newWidget.queryString) {
      newWidget.queryString.conditionId = definition.condition;
    }
    if (definition.widgetType === 'table') {
      const tableConfig = getDefaultMetricTableColumns(definition.metric);
      newWidget.tableInfo = {
        columns: tableConfig.columns,
        defaultSort: tableConfig.defaultSort,
        sortDirection: 'desc',
        limit: 10,
        tableType: 'metric',
        view: getDefaultViewForMetric(definition.metric)
      };
      newWidget.graphInfo = undefined;
      if (definition.metric === 'condition' && newWidget.queryString) {
        newWidget.queryString.conditionId = String(definition.condition);
      }
    } else {
      newWidget.size = 'xl';
      newWidget.graphInfo = {
        graphType: 'line',
        dataSetAverageTypes:
          definition.metric === 'condition' && definition.widgetType === 'graph'
            ? ChartDataSetAverageTypes.ThreeMonth
            : ChartDataSetAverageTypes.Month,
        filteredDatasets:
          definition.metric === 'condition'
            ? ['AvgImpactDual', 'AvgImpactSingle', 'SecondaryDual', 'SecondarySingle', 'Total']
            : []
      };
      if (newWidget.metric === 'condition' && newWidget.queryString) {
        newWidget.queryString.startDate = '{DefaultDate.AbsoluteMinMonth}T00:00:00';
        newWidget.queryString.endDate = '{DefaultDate.CurrentMaxMont}T00:00:00';
      }
      newWidget.tableInfo = undefined;
      if (definition.condition && newWidget && newWidget.queryString) {
        newWidget.queryString.conditionId = String(definition.condition);
      }
    }

    return newWidget;
  };

  const handleWidgetDelete = (name: string): void => {
    const cloneData = [...activeWidgetList];
    const ix = cloneData.findIndex((x: WidgetJSON) => x.name === name);
    cloneData.splice(ix, 1);
    ApplicationAPI.put({
      endpoint: `dashboard/${userId}`,
      data: JSON.stringify(cloneData)
    }).then(res => {
      if (res.status === 200) {
        pushAlert({ message: 'Dashboard updated successfully', variant: 'success' });
        setActiveWidgetList(cloneData);
        dashboardDispatch(setDashboardList(res.data as WidgetJSON[]));
      } else {
        pushAlert({ message: 'Dashboard not updated', variant: 'error' });
      }
    });
  };

  const handleOnDragEnd = (result: DropResult): void => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const cloneWidgetList = [...activeWidgetList];
    const newList = ReorderList(cloneWidgetList, source.index, destination.index);
    setActiveWidgetList(newList);
    ApplicationAPI.put({
      endpoint: `dashboard/${userId}`,
      data: JSON.stringify(newList)
    }).then(res => {
      if (res.status === 200) {
        pushAlert({ message: 'Dashboard updated successfully', variant: 'success' });
        dashboardDispatch(setDashboardList(res.data as WidgetJSON[]));
      } else {
        pushAlert({ message: 'Dashboard not updated', variant: 'error' });
        setActiveWidgetList(cloneWidgetList);
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDashboardMenuOpen = (event: any): void => {
    setSpeedDialOpen(true);
  };

  const handleDashboardMenuClose = (): void => {
    setSpeedDialOpen(false);
  };

  const handleDashboardActionOpen = (): void => {
    setActionPanelTitle('Add New Widget');
    setActionPanelType('new');
    setWidgetOpen(true);
    setIsDragDropDisabled(true);
    handleDashboardMenuClose();
  };

  const handleDashboardActionClose = (): void => {
    dashboardDispatch(clearWidgetDefinition());
    setWidgetOpen(false);
    setIsDragDropDisabled(false);
  };

  const handleAddWidgets = (): void => {
    if (newWidget.metric === 'condition' && !newWidget.condition) {
      pushAlert({ message: 'Please select a Clinical Condition', variant: 'warning' });
      return;
    }
    handleDashboardMenuClose();
    const widget = buildWidgetDefinition(newWidget);
    ApplicationAPI.put({
      endpoint: `dashboard/${userId}`,
      data: JSON.stringify([...activeWidgetList, widget])
    })
      .then(res => {
        if (res.status === 200) {
          pushAlert({ message: 'Dashboard updated successfully', variant: 'success' });
          const responseWidgetList = res.data as WidgetJSON[];
          const ix = responseWidgetList.findIndex(w => w.name === widget.name);
          if (ix !== -1) {
            widget.queryString = responseWidgetList[ix].queryString;
            setActiveWidgetList([...activeWidgetList, widget]);
            dashboardDispatch(setDashboardList(res.data as WidgetJSON[]));
          }
        } else {
          pushAlert({ message: 'Dashboard not updated successfully', variant: 'error' });
        }
      })
      .catch(err => {
        console.log('error: ', err);
      })
      .finally(() => {
        handleDashboardActionClose();
        setTimeout(() => {
          const gridContainer = document.querySelector('.MuiGrid-container') as HTMLElement;
          if (gridContainer && gridContainer.lastElementChild) {
            gridContainer.lastElementChild.scrollIntoView({ behavior: 'smooth' });
          }
        }, 2000);
      });
  };

  const updateEditWidgetDefinition = (updatedWidget: WidgetJSON): void => {
    editWidgetDefinition.current = updatedWidget;
  };

  const handleOpenWidgetSettings = (name: string): void => {
    const widget = activeWidgetList.find(x => x.name === name);
    if (widget) {
      // JSON parse/stringify is an efficient deep clone
      editWidgetDefinition.current = JSON.parse(JSON.stringify(widget));
      setActionPanelTitle(`${widget.title} Widget`);
      setActionPanelType('update');
      setWidgetOpen(true);
      setIsDragDropDisabled(true);
    }
  };

  const shouldEndpointUpdate = (updatedWidget: WidgetJSON, originalWidget: WidgetJSON): boolean => {
    if (updatedWidget.entity !== originalWidget.entity && originalWidget.entity !== -1) {
      return true;
    }
    if (updatedWidget.metric !== originalWidget.metric) {
      return true;
    }
    if (updatedWidget.queryString && originalWidget.queryString) {
      if (updatedWidget.queryString.conditionId !== originalWidget.queryString.conditionId) {
        return true;
      }
    }

    return false;
  };

  const handleUpdateWidget = (): void => {
    if (!editWidgetDefinition.current) return;

    if (
      editWidgetDefinition.current.metric === 'condition' &&
      editWidgetDefinition.current.queryString &&
      !editWidgetDefinition.current.queryString.conditionId
    ) {
      pushAlert({ message: 'Please select a Clinical Condition', variant: 'warning' });
      return;
    }

    const extractedWidget = editWidgetDefinition.current;
    const ix = activeWidgetList.findIndex((widget: WidgetJSON) => widget.name === editWidgetDefinition.current.name);
    const updateEndpoint = shouldEndpointUpdate(extractedWidget, activeWidgetList[ix]);
    extractedWidget.id = activeWidgetList[ix].id;
    const cloneWidgetList = [...activeWidgetList];
    cloneWidgetList.splice(ix, 1, extractedWidget);
    if (updateEndpoint) {
      // build the endpoint
      const endpointEntity = editWidgetDefinition.current.entity ?? rootId;

      const endpointMetric =
        editWidgetDefinition.current.metric === 'condition' ||
        editWidgetDefinition.current.metric === 'targetedConditions' ||
        editWidgetDefinition.current.metric === 'allConditions'
          ? 'conditions'
          : editWidgetDefinition.current.metric;
      const endpoint = `${
        editWidgetDefinition.current.widgetType === 'table' ? 'metrics' : 'graph'
      }/${endpointMetric}/${endpointEntity}`;

      extractedWidget.endpoint = endpoint;

      let title = editWidgetDefinition.current.entityName ?? '';

      if (editWidgetDefinition.current.metric !== 'condition' && editWidgetDefinition.current.metric) {
        title += ` ${metricLabels[editWidgetDefinition.current.metric]}`;
      }
      if (editWidgetDefinition.current.queryString && editWidgetDefinition.current.queryString.conditionId) {
        title += ` ${editWidgetDefinition.current.queryString.conditionName}`;
      }
      title += ` ${editWidgetDefinition.current.widgetType === 'table' ? 'Performance' : 'Trend'}`;

      extractedWidget.title = title;
    }

    if (extractedWidget.queryString) {
      extractedWidget.queryString.startDate =
        extractedWidget.widgetType === 'graph' &&
        (extractedWidget.metric === 'condition' ||
          extractedWidget.metric === 'targetedConditions' ||
          extractedWidget.metric === 'allConditions')
          ? absoluteMinPeriod
          : '{Last13MonthsStart}T00:00:00';
      extractedWidget.queryString.endDate =
        extractedWidget.widgetType === 'graph' &&
        (extractedWidget.metric === 'condition' ||
          extractedWidget.metric === 'targetedConditions' ||
          extractedWidget.metric === 'allConditions')
          ? '{DefaultDate.CurrentMaxMont}T00:00:00'
          : '{Last13MonthsEnd}T00:00:00';

      if (
        extractedWidget.graphInfo &&
        extractedWidget.metric === 'condition' &&
        extractedWidget.widgetType === 'graph'
      ) {
        extractedWidget.graphInfo.dataSetAverageTypes = ChartDataSetAverageTypes.ThreeMonth;
        extractedWidget.queryString.startDate = '{DefaultDate.AbsoluteMinMonth}T00:00:00';
        extractedWidget.queryString.endDate = '{DefaultDate.CurrentMaxMont}T00:00:00';
      }
      extractedWidget.queryString.currentStart = '{DefaultDate.CurrentMinMonth}T00:00:00';
      extractedWidget.queryString.currentEnd = '{DefaultDate.CurrentMaxMont}T00:00:00';
      extractedWidget.queryString.historicalStart = '{DefaultDate.HistoricalMinMonth}T00:00:00';
      extractedWidget.queryString.historicalEnd = '{DefaultDate.HistoricalMaxMonth}T00:00:00';
    }

    ApplicationAPI.put({
      endpoint: `dashboard/${userId}`,
      data: JSON.stringify(cloneWidgetList)
    })
      .then(res => {
        if (res.status === 200) {
          pushAlert({ message: 'Dashboard updated successfully', variant: 'success' });
          const responseWidgetList = res.data as WidgetJSON[];
          const responseWidgetFound = responseWidgetList.find(w => w.name === extractedWidget.name);
          if (responseWidgetFound) {
            cloneWidgetList.splice(ix, 1, responseWidgetFound);
          }
          setActiveWidgetList(cloneWidgetList);
          dashboardDispatch(setDashboardList(res.data as WidgetJSON[]));
        } else {
          pushAlert({ message: 'Dashboard not updated successfully', variant: 'error' });
        }
      })
      .catch(err => {
        console.log('error: ', err);
      })
      .finally(() => {
        handleDashboardActionClose();
      });
  };

  const handleEditTitle = (name: string, title: string): void => {
    const cloneWidgetList = [...activeWidgetList];
    const ix = cloneWidgetList.findIndex((widget: WidgetJSON) => widget.name === name);
    if (ix !== -1) {
      cloneWidgetList[ix].title = title;
      ApplicationAPI.put({
        endpoint: `dashboard/${userId}`,
        data: JSON.stringify(cloneWidgetList)
      }).then(res => {
        if (res.status === 200) {
          pushAlert({ message: 'Dashboard updated successfully', variant: 'success' });
          setActiveWidgetList(cloneWidgetList);
          dashboardDispatch(setDashboardList(res.data as WidgetJSON[]));
        } else {
          pushAlert({ message: 'Dashboard not updated', variant: 'error' });
        }
      });
    }
  };

  const handleResetDashboard = (): void => {
    dashboardDispatch(resetUserDashboard(userId));
    setWidgetListLoading(true);
    setUpdateDashboard(true);
    setAlertWindowOpen(false);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    window.scrollTo(0, 0);
    setActiveWidgetList(widgetList);
  }, [isLoading, widgetList]);

  // Need to disable this code until we find a better solution
  // this code causes the table widget to flicker due to the rerender
  // const handleColumnResizeEvent = (e: MouseEvent): void => {
  //   // Reference to the widgetOpen is not available, so query the DOM for the actionpanel
  //   const actionPanelDiv = document.querySelector('.actionpanel');
  //   if (actionPanelDiv) return;

  //   const target = e.target as HTMLElement;
  //   if (target.closest('.ag-header-row') && !isDragDropDisabled) {
  //     setIsDragDropDisabled(true);
  //   } else {
  //     setIsDragDropDisabled(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousemove', handleColumnResizeEvent);

  //   return (): void => {
  //     document.removeEventListener('mousemove', handleColumnResizeEvent);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (!activeWidgetList || widgetListLoading) {
    return <ClinIntellSkeleton variant="rectangular" height="30rem" width="100%" />;
  }

  const isIE11 = isIE && browserVersion === '11';
  const providerWidgets = activeWidgetList.filter(
    widget => widget.name === 'progress' || widget.name === 'd3speedometer'
  );
  const remainingWidgets = activeWidgetList.filter(
    widget => widget.name !== 'progress' && widget.name !== 'd3speedometer'
  );

  return (
    <>
      {providerWidgets.length > 0 ? (
        <Grid container className={container} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {providerWidgets.map((widget: WidgetJSON, index: number) => {
            const hashId = hash(widget);
            return (
              <Grid
                item
                key={`grid-${hashId}`}
                md={widget.size === 'xs' ? 1 : 2}
                xs={widget.name === 'd3speedometer' ? 12 : 6}
                sx={{
                  padding: 1,
                  minWidth: widget.name === 'd3speedometer' ? 350 : 200,
                  backgroundColor: 'grey.100'
                }}
                className={gridItem}
              >
                <Widget
                  index={index}
                  hashId={hashId}
                  {...widget}
                  deleteFn={handleWidgetDelete}
                  editTitleFn={handleEditTitle}
                  updateWidgetFn={handleUpdateWidget}
                  openSettings={handleOpenWidgetSettings}
                  actionPanelOpen={widgetOpen}
                  isDragDropDisabled={true}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      {remainingWidgets.length > 0 ? (
        <>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="list">
              {(provided): JSX.Element => (
                <Grid
                  container
                  className={container}
                  style={{ margin: '0 auto' }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {remainingWidgets &&
                    remainingWidgets.map((widget: WidgetJSON, index: number) => {
                      const { size } = widget;
                      const hashId = hash(widget);
                      return (
                        <Grid
                          item
                          key={`grid-${hashId}`}
                          md={
                            size === 'xl' || size === 'xlarge' || size === 'l' || size === 'large'
                              ? 12
                              : size === 'm' || size === 'medium'
                              ? 6
                              : size === 's' || size === 'small'
                              ? 2
                              : 1
                          }
                          sm
                          xs={12}
                          sx={{
                            padding: 1,
                            backgroundColor: 'grey.100'
                          }}
                          className={gridItem}
                        >
                          <Widget
                            index={index}
                            hashId={hashId}
                            metric={widget.metric ?? MetricTypes.cmi}
                            {...widget}
                            deleteFn={handleWidgetDelete}
                            editTitleFn={handleEditTitle}
                            updateWidgetFn={handleUpdateWidget}
                            openSettings={handleOpenWidgetSettings}
                            actionPanelOpen={widgetOpen}
                            isDragDropDisabled={
                              roleName === 'provider' || roleName.toLowerCase() === 'providerlead'
                                ? true
                                : isDragDropDisabled
                            }
                          />
                        </Grid>
                      );
                    })}
                  {isIE && browserVersion === '11' ? (
                    <Box minHeight={theme.spacing(0.5)} mb={theme.spacing(0.5)}>
                      &nbsp;
                    </Box>
                  ) : null}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : null}
      {roleName !== 'provider' && (
        <>
          <SpeedDial
            ariaLabel="Customize Dashboard"
            icon={<SpeedDialIcon />}
            onClose={handleDashboardMenuClose}
            onOpen={handleDashboardMenuOpen}
            open={speedDialOpen}
            direction="up"
            sx={{
              position: 'fixed',
              bottom: theme.spacing(6),
              right: theme.spacing(2),
              '& button[aria-label="Customize Dashboard"]': {
                backgroundColor: theme.palette.teal.main
              }
            }}
          >
            {actions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.fn}
                onTouchEnd={action.fn}
              />
            ))}
          </SpeedDial>
          <ActionPanel
            open={widgetOpen}
            closeFn={handleDashboardActionClose}
            title={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>{actionPanelTitle}</Box>
                <Button
                  onClick={(): void => {
                    if (actionPanelType === 'new') {
                      handleAddWidgets();
                    } else {
                      handleUpdateWidget();
                    }
                  }}
                  label="Save"
                />
              </Box>
            }
          >
            {actionPanelType === 'new' ? (
              <AddWidget />
            ) : (
              <EditWidget widget={editWidgetDefinition.current} onUpdateWidget={updateEditWidgetDefinition} />
            )}
          </ActionPanel>
        </>
      )}
      <ModalWindow
        openAnchor={alertWindowOpen}
        onClose={(): void => setAlertWindowOpen(false)}
        title="Reset to Default Dashboard?"
        content={
          <Box>
            <Box sx={{ mb: 2, typography: 'p2' }}>You will lose any changes to your dashboard. Are you sure?</Box>
            <Button label="Yes" onClick={handleResetDashboard} sx={{ marginRight: 2, marginBottom: 2 }} />
            <Button
              label="No"
              onClick={(): void => setAlertWindowOpen(false)}
              variant="outlined"
              sx={{ marginBottom: 2 }}
            />
          </Box>
        }
        width="sm"
      />
      {isIE11 ? (
        <Box minHeight={theme.spacing(1)} mb={theme.spacing(1)}>
          &nbsp;
        </Box>
      ) : null}
    </>
  );
};

const DashboardController: React.FC = () => {
  const user = useUser();
  const { role } = useUser();
  const { roleName } = role;
  const { totalCount: hospitalCount, isLoading: isHospitalLoading } = useHospitals();
  const hospitalDispatch = useHospitalsDispatch();
  const dashboardDispatch = useDashboardDispatch();
  const dashboard2Dispatch = useDashboard2Dispatch();
  const metricsNavDispatch = useMetricsNavigationDispatch();
  const { isInitialized } = useMetricsNavigation();
  const { isInitialized: isDashboardInitialized, isLoading: isDashboardLoading } = useDashboard();
  const { isDashboard2Initialized, isHospitalsWithSystemInitialized, isLoading: isDashboard2Loading } = useDashboard2();

  // Default dates API call should only be made when entity changes - there could possibly be new dates and/or intervention period
  const { output: defaultDates } = useGetAPICAll<DefaultDatesJSON>({
    endpoint: `DefaultDates/${user.rootId}`,
    isWaiting: false
  });

  const { output: orgOutput } = useGetAPICAll<TreeJSON>({
    endpoint: `org/${user.rootId}`,
    isWaiting: false
  });

  if (defaultDates && defaultDates.error) {
    throw new Error(defaultDates.error);
  }

  if (orgOutput && orgOutput.error) throw new Error(orgOutput.error);
  const isSaf = orgOutput?.data?.name.toLowerCase().includes('saf');

  const useOldDashboard = ['provider', 'clinicalleader'].includes(roleName.toLowerCase()) || isSaf;

  useEffect(() => {
    if (useOldDashboard === undefined) return; // prevents the dashboard dispatches until we know which one to call
    if (useOldDashboard) {
      if (!isDashboardInitialized && !isDashboardLoading) {
        dashboardDispatch(initializeDashboard({ id: user.id, rootId: user.rootId }));
      }
    } else {
      if (!isDashboard2Initialized && !isHospitalsWithSystemInitialized && !isDashboard2Loading) {
        dashboard2Dispatch(initializeDashboard2());
      }
    }

    if (!defaultDates || isInitialized) {
      return;
    }

    const {
      historicalMinMonth,
      historicalMaxMonth,
      currentMinMonth,
      currentMaxMonth,
      absoluteMinMonth,
      interventionMonth
    } = defaultDates.data as DefaultDatesJSON;

    metricsNavDispatch(
      setDefaultDates({
        defaultPeriodStart: currentMinMonth,
        defaultPeriodEnd: currentMaxMonth,
        defaultComparisonPeriodStart: historicalMinMonth,
        defaultComparisonPeriodEnd: historicalMaxMonth,
        absoluteMinPeriod: absoluteMinMonth,
        interventionPeriod: interventionMonth
      })
    );

    // prevent multiple calls to the same endpoint by checking the loading state
    if (hospitalCount === 0 && !isHospitalLoading) {
      hospitalDispatch({ type: 'HOSPITAL_FETCH_ALL_REQUESTED', payload: { itemCount: 50 } });
    }
  }, [
    useOldDashboard,
    defaultDates,
    metricsNavDispatch,
    dashboardDispatch,
    user,
    isInitialized,
    isDashboardInitialized,
    hospitalCount,
    isHospitalLoading,
    hospitalDispatch,
    isDashboardLoading,
    roleName,
    isDashboard2Initialized,
    isHospitalsWithSystemInitialized,
    dashboard2Dispatch,
    isSaf,
    isDashboard2Loading
  ]);

  // prevent prematurely loading a dashboard
  if (useOldDashboard === undefined && (!isDashboardInitialized || !isDashboard2Initialized)) return null;

  return useOldDashboard ? <Dashboard /> : <DashboardUpdate />;
};

export default DashboardController;
