import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const SignOut = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6665 15.5834L19.2498 11.0001L14.6665 6.41675"
        stroke="#64748B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19.25 11H8.25" stroke="#64748B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.25 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V4.58333C2.75 4.0971 2.94315 3.63079 3.28697 3.28697C3.63079 2.94315 4.0971 2.75 4.58333 2.75H8.25"
        stroke="#64748B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
SignOut.muiName = SvgIcon.muiName;

export default SignOut;
