import React, { useEffect, useRef, useState } from 'react';
import { Box, Button as MuiButton, SxProps, Theme } from '@mui/material';
import CircleLoadingIndicator from '../../components/CircleLoadingIndicator';

export const buttonStyles: SxProps<Theme> = {
  borderRadius: '8px',
  lineHeight: '17px',
  px: '18px',
  fontSize: '13px',
  fontWeight: 600,
  letterSpacing: '0.5px',
  height: '2.5rem',
  boxShadow: 'none',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  '&:hover': {
    boxShadow: 'none'
  },
  '&.MuiButton-containedPrimary': {
    color: 'shade.white',
    backgroundColor: 'blue.main',
    '&:hover': {
      backgroundColor: 'blue.dark2'
    }
  },
  '&.MuiButton-containedSecondary': {
    color: 'shade.white',
    backgroundColor: 'teal.main',
    '&:hover': {
      backgroundColor: 'teal.dark2'
    }
  },
  '&.MuiButton-outlinedPrimary': {
    color: 'blue.main',
    backgroundColor: 'shade.white',
    borderColor: 'blue.main',
    '&:hover': {
      color: 'blue.dark2',
      borderColor: 'blue.dark2'
    }
  },
  '&.MuiButton-outlinedSecondary': {
    color: 'grey.500',
    backgroundColor: 'shade.white',
    borderColor: 'grey.500',
    '&:hover': {
      color: 'blue.light1',
      borderColor: 'blue.light1'
    }
  },
  '&.Mui-disabled': {
    backgroundColor: 'grey.200',
    color: 'grey.400',
    border: 'none'
  }
};

export interface ButtonProps {
  /**
   * Text or JSX element that will be displayed on the button
   */
  label: string | Element | JSX.Element;
  /**
   * Defines the main color of the button - if not outlined it is for the background color, if outlined it is for the text and border color
   */
  type?: 'primary' | 'secondary';
  /**
   * If the button is contained, outlined, or just text
   */
  variant?: 'contained' | 'outlined' | 'text';
  /**
   * Event that triggers when you click the button
   */
  onClick?: (event: React.MouseEvent) => void;
  /**
   * If the button is busy - it shows the loading indicator and is disabled
   */
  isBusy?: boolean;
  /**
   * React element that appears to the left of the button label. This is usually an icon.
   */
  leftAdornment?: JSX.Element;
  /**
   * React element that appears to the right of the button label. This is usually an icon.
   */
  rightAdornment?: JSX.Element;
  /**
   * If button status is disabled
   */
  disabled?: boolean;
  /**
   * Extra styles that will be added to the sx property
   */
  sx?: SxProps<Theme>;
  /**
   * Used to reference in front end tests
   */
  testId?: string;
}

const Button: React.FC<ButtonProps> = ({
  label,
  type = 'primary',
  variant = 'contained',
  disabled,
  sx,
  onClick,
  leftAdornment,
  rightAdornment,
  testId,
  isBusy
}) => {
  const [buttonWidth, setButtonWidth] = useState<number>();
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Keep track of the button's width, as there are certain styles (such as is busy loading circle) that would change the
  // width otherwise
  useEffect(() => {
    if (buttonRef.current && buttonRef.current.offsetWidth > 0) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  const buttonLabelComponent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: variant === 'text' ? 500 : 600
      }}
    >
      {leftAdornment && !isBusy ? <Box sx={{ mr: 1 }}>{leftAdornment}</Box> : null}
      {isBusy ? <CircleLoadingIndicator loadingIndicatorSize={20} /> : label}
      {rightAdornment && !isBusy ? <Box sx={{ ml: 1 }}>{rightAdornment}</Box> : null}
    </Box>
  );

  return (
    <MuiButton
      ref={buttonRef}
      data-cy={testId}
      sx={{
        ...buttonStyles,
        ...(buttonWidth !== undefined ? { width: buttonWidth } : null),
        ...(variant === 'text'
          ? {
              padding: '0',
              height: '20px',
              borderRadius: '8px',
              position: 'relative',
              backgroundColor: 'transparent !important',
              // This fixes issue where the before element is rendering on top of the parent, without messing with zindexing
              transformStyle: 'preserve-3d',
              ...(type === 'secondary' ? { color: 'grey.500' } : null),
              '&:hover': {
                backgroundColor: 'transparent !important',
                '&::before': {
                  backgroundColor: 'blue.light5'
                }
              },
              '&::before': {
                position: 'absolute',
                minWidth: buttonWidth,
                backgroundColor: 'transparent',
                borderRadius: '8px',
                height: '20px',
                px: '5px',
                content: '""',
                transition: 'background-color 100ms linear',
                // This fixes issue where the before element is rendering on top of the parent, without messing with zindexing
                transform: 'translateZ(-1px)'
              }
            }
          : null),
        ...sx
      }}
      disabled={disabled || isBusy}
      variant={variant}
      size="medium"
      color={type}
      onClick={onClick}
    >
      {buttonLabelComponent}
    </MuiButton>
  );
};

export default Button;
