import React, { useEffect } from 'react';
import { useConditions, useConditionsDispatch } from '@clinintell/modules/store';
import { Box } from '@mui/material';
import Select, { StylesConfig } from 'react-select';
import CircleLoadingIndicator from './CircleLoadingIndicator';
import { ConditionTypes, fetchConditions, IndexedCondition } from '@clinintell/modules/conditions';
import makeStyles from '@mui/styles/makeStyles';
import { SelectOptionType } from '@clinintell/types/common';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  dropdown: {
    minWidth: 150,
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'left',
    zIndex: 999
  },
  optionWrapper: {
    padding: theme.spacing(1),
    cursor: 'pointer'
  },
  optionItem: {
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  selecthover: {
    backgroundColor: theme.palette.blue.light1,
    color: '#000'
  },
  conditionTypeHover: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.75rem',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.6)'
    }
  }
}));

interface ConditionSelectOptionType extends SelectOptionType {
  conditionType?: string;
}

interface SelectConditionProps {
  id?: string;
  orgId?: number;
  onChange: (id: number, name: string) => void;
  selectedValue?: SelectOptionType | undefined;
  loadFirstOption?: boolean;
  filterConditionTypes?: ConditionTypes[];
}

const SelectCondition: React.FC<SelectConditionProps> = ({
  id = 'selectCondition',
  orgId,
  onChange,
  selectedValue,
  loadFirstOption = false,
  filterConditionTypes
}) => {
  const { dropdown } = useStyles();
  const conditionDispatch = useConditionsDispatch();
  const { byId: conditions, isLoading, isInitialized: isConditionsInitialized } = useConditions();

  const conditionSelectOptions: SelectOptionType[] = [];

  useEffect(() => {
    if (isLoading) return;

    if (!isConditionsInitialized) {
      conditionDispatch(fetchConditions({ orgId }));
    }
  }, [conditionDispatch, isConditionsInitialized, isLoading, orgId]);

  useEffect(() => {
    const filteredConditions = filterConditionTypes
      ? Object.entries(conditions as IndexedCondition).filter(condition =>
          filterConditionTypes.includes(condition[1].conditionType as ConditionTypes)
        )
      : Object.entries(conditions as IndexedCondition);
    filteredConditions
      .sort((a, b) => {
        const aDTO = a[1];
        const bDTO = b[1];
        if (aDTO.conditionDescription.toLowerCase() > bDTO.conditionDescription.toLowerCase()) return 1;
        if (aDTO.conditionDescription.toLowerCase() < bDTO.conditionDescription.toLowerCase()) return -1;
        return 0;
      })
      .forEach(condition => {
        const { id, conditionDescription, conditionType } = condition[1];
        conditionSelectOptions.push({
          value: Number(id),
          label: `${conditionDescription}, ${conditionType}`,
          useLabelForTestId: true
        });
      });

    // This is for the Metrics > Graph > Conditions
    if (isConditionsInitialized && !isLoading && loadFirstOption && conditionSelectOptions.length) {
      const { value, label } = conditionSelectOptions[0];
      onChange(value as number, label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionSelectOptions, conditions]);

  let selectedOption: ConditionSelectOptionType | undefined = undefined;
  if (isConditionsInitialized && !isLoading && loadFirstOption) {
    const condition = Object.values(conditions as IndexedCondition)[0];
    selectedOption = {
      label: condition?.conditionDescription,
      value: condition?.id,
      conditionType: condition?.conditionType
    } as ConditionSelectOptionType;
  } else if (
    conditions &&
    !isLoading &&
    selectedValue &&
    (selectedValue?.label === undefined || selectedValue?.label === '')
  ) {
    const condition = Object.values(conditions as IndexedCondition).find(c => c.id === selectedValue?.value);
    selectedOption = {
      label: condition?.conditionDescription,
      value: condition?.id,
      conditionType: condition?.conditionType
    } as ConditionSelectOptionType;
  } else if (selectedValue && selectedValue.label !== undefined && selectedValue.label !== '') {
    selectedOption = selectedValue;
  }

  const showConditionStyle: StylesConfig = {
    menu: (provided, _state) => ({
      ...provided,
      width: isMobile ? '100%' : 500
    }),
    menuList: (provided, _state) => ({
      ...provided,
      width: '100%'
    })
  };

  return (
    <Select
      id={id}
      closeMenuOnSelect={true}
      value={selectedOption}
      options={conditionSelectOptions}
      isSearchable={true}
      className={dropdown}
      onChange={(value): void => {
        const condition = (value as ConditionSelectOptionType).value as number;
        onChange(condition, (value as ConditionSelectOptionType).label);
      }}
      components={{
        LoadingIndicator: (): JSX.Element => (
          <Box mr={2}>
            <CircleLoadingIndicator loadingIndicatorSize={18} />
          </Box>
        )
      }}
      styles={showConditionStyle}
      testId="conditionSelector"
    />
  );
};

export default SelectCondition;
