import React from 'react';
import { Feature } from '../containers/authentication/rules';
import { useUser } from '@clinintell/modules/store';

type CanProps = {
  permissions: Feature[];
  yes: () => JSX.Element | null;
  no: () => JSX.Element | null;
};
const Can: React.FC<CanProps> = (props: CanProps): JSX.Element | null => {
  const { features } = useUser();
  if (!props.permissions || props.permissions.length === 0) {
    return props.yes();
  }
  if (features.some(v => props.permissions.includes(v.featureName))) {
    return props.yes();
  }
  return props.no();
};

Can.defaultProps = {
  yes: (): null => null,
  no: (): null => null
};

export default Can;
