import useDebounce from '@clinintell/utils/useDebounce';
import { AutocompleteRenderOptionState, Box, Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';
import { useState } from 'react';
import { AutoCompleteChoiceType, ReasonType } from '../AutoComplete';

type UseAutoCompleteInputHandlerProps = {
  baseSearchTerm: string;
  isMultipleSearch?: boolean;
  onTextClear: () => void;
  onTextInput: (value: string) => void;
  onSelected?: (selection: AutoCompleteChoiceType) => void;
  onMultipleSelected?: (selection: AutoCompleteChoiceType[]) => void;
  minCharactersToSearch: number;
  maxCharactersToSearch: number;
};

type UseAutoCompleteInputHandlerOutput = {
  debouncedBaseSearchTerm: string;
  handleTextChange: (e: React.SyntheticEvent | null, value: string, reason: ReasonType) => void;
  handleInputChange: (e: React.SyntheticEvent, value: AutoCompleteChoiceType[] | AutoCompleteChoiceType | null) => void;
  getOptionLabel: (opt: AutoCompleteChoiceType) => string;
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    opt: AutoCompleteChoiceType,
    state: AutocompleteRenderOptionState
  ) => JSX.Element;
};

const useAutoCompleteInputHandler = ({
  baseSearchTerm,
  isMultipleSearch,
  onTextClear,
  onTextInput,
  onSelected,
  onMultipleSelected,
  minCharactersToSearch,
  maxCharactersToSearch
}: UseAutoCompleteInputHandlerProps): UseAutoCompleteInputHandlerOutput => {
  const [debouncedBaseSearchTerm, setDebouncedBaseSearchTerm] = useState('');
  const [selectedSearchValue, setSelectedSearchValue] = useState('');

  useDebounce(
    () => {
      if (
        baseSearchTerm === selectedSearchValue ||
        baseSearchTerm.length < minCharactersToSearch ||
        baseSearchTerm.length > maxCharactersToSearch
      ) {
        return;
      }

      setDebouncedBaseSearchTerm(baseSearchTerm);
    },
    500,
    [baseSearchTerm]
  );

  const handleTextChange = (_event: React.SyntheticEvent | null, value: string, reason: ReasonType): void => {
    switch (reason) {
      case 'clear':
        setDebouncedBaseSearchTerm('');
        onTextClear();
        setSelectedSearchValue('');
        break;
      case 'input': {
        onTextInput(value);
        break;
      }
      case 'reset':
      default:
        break;
    }
  };

  const handleInputChange = (
    _event: React.SyntheticEvent,
    value: AutoCompleteChoiceType[] | AutoCompleteChoiceType | null
  ): void => {
    setDebouncedBaseSearchTerm('');

    if (!value) {
      return;
    }

    // Autocomplete component handles multi-select functionality differently than a single select.
    // The value will be an array rather than a single auto complete choice type
    if (isMultipleSearch) {
      const hospitals = value as AutoCompleteChoiceType[];

      if (!onMultipleSelected) {
        throw new Error('If isMultipleSearch prop is true, the onMultipleSelected prop must be a valid function');
      }

      // undefined values are a result of enter key press where search term returns 0 records
      onMultipleSelected(hospitals.filter(hospital => hospital !== undefined));

      onTextClear();
      return;
    }

    const hospital = value as AutoCompleteChoiceType;

    if (!onSelected) {
      throw new Error('If isMultipleSearch prop is false, the onSelected prop must be a valid function');
    }

    const nameToSelect = hospital.childName || hospital.parentName;

    onSelected(hospital);
    setSelectedSearchValue(nameToSelect);
    onTextInput(nameToSelect);
  };

  const getOptionLabel = (opt: AutoCompleteChoiceType): string => {
    if (!opt || !opt.parentName) {
      return 'No option selected';
    }

    if (opt.parentName) {
      return opt.parentName;
    }
    return 'No option selected';
  };

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    opt: AutoCompleteChoiceType,
    _state: AutocompleteRenderOptionState
  ): JSX.Element => {
    if (typeof opt === 'number') return <Box />;
    if (typeof opt !== 'string') {
      if (!opt.childName) {
        return (
          <Box component="li" {...props} display="flex" flexWrap="wrap" alignItems="center">
            <Box display="flex" alignContent="center">
              <Typography variant="p2" color="textPrimary">
                {opt.parentName}
              </Typography>
            </Box>
          </Box>
        );
      }

      return (
        <Box component="li" {...props} display="flex" flexWrap="wrap" alignItems="center">
          <Box display="flex" alignContent="center">
            <Typography variant="p2" color="textPrimary">
              {opt.childName};
            </Typography>
          </Box>
          <Box ml={1} display="flex" alignContent="center">
            <Typography variant="caption" color="textSecondary">
              {opt.parentName}
            </Typography>
          </Box>
        </Box>
      );
    }

    return <Box />;
  };

  return {
    debouncedBaseSearchTerm,
    handleTextChange,
    handleInputChange,
    getOptionLabel,
    renderOption
  };
};

export default useAutoCompleteInputHandler;
