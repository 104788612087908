import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TrainingRetake = (props: SvgIconProps): JSX.Element => (
  <SvgIcon style={{ fontSize: '2.5rem' }} viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 5.25V0.25L3.75 6.5L10 12.75V7.75C14.1375 7.75 17.5 11.1125 17.5 15.25C17.5 19.3875 14.1375 22.75 10 22.75C5.8625 22.75 2.5 19.3875 2.5 15.25H0C0 20.775 4.475 25.25 10 25.25C15.525 25.25 20 20.775 20 15.25C20 9.725 15.525 5.25 10 5.25Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default TrainingRetake;
