import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const ProviderTraining = (props: SvgIconPropType): JSX.Element => {
  const colorOverride = props.sx?.color ?? 'inherit';
  return (
    <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19" cy="19.0002" r="19" fill="#FFC0C7" />
      <path
        d="M17.7175 12.8953L11.365 23.5003C11.234 23.7271 11.1647 23.9842 11.164 24.2462C11.1633 24.5081 11.2311 24.7656 11.3608 24.9932C11.4905 25.2207 11.6776 25.4103 11.9033 25.5432C12.129 25.676 12.3856 25.7474 12.6475 25.7503H25.3525C25.6144 25.7474 25.871 25.676 26.0967 25.5432C26.3225 25.4103 26.5095 25.2207 26.6392 24.9932C26.7689 24.7656 26.8368 24.5081 26.836 24.2462C26.8353 23.9842 26.766 23.7271 26.635 23.5003L20.2825 12.8953C20.1488 12.6749 19.9606 12.4926 19.7359 12.3661C19.5113 12.2397 19.2578 12.1732 19 12.1732C18.7422 12.1732 18.4888 12.2397 18.2641 12.3661C18.0395 12.4926 17.8512 12.6749 17.7175 12.8953V12.8953Z"
        stroke={colorOverride}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 16.7502V19.7502"
        stroke={colorOverride}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 22.7502H19.0075"
        stroke={colorOverride}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ProviderTraining;
