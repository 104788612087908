import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { useDashboard, useDashboardDispatch, useHospitals, useUser } from '@clinintell/modules/store';
import { metricLabels, Metrics as MetricTypes } from '@clinintell/modules/metricsNavigation';
import { InputLabel, Select } from '@clinintell/components/index';
import { SelectCondition, SelectMetric } from '@clinintell/containers/common/index';
import EntityNavigation from '../entityNavigation/EntityNavigation';
import { NodeDetails } from '../treeSelector/logic/TreeContext';
import { ConditionTypes } from '@clinintell/modules/conditions';

type WidgetTypeOption = {
  value: 'graph' | 'table';
  label: string;
};

const widgetTypes: WidgetTypeOption[] = [
  { label: 'Graph', value: 'graph' },
  { label: 'Table', value: 'table' }
];

const AddWidget: React.FC = () => {
  const [isTreeVisible, setIsTreeVisible] = useState(true);

  const dashboardDispatch = useDashboardDispatch();
  const { originId, newWidget } = useDashboard();

  const user = useUser();
  const hospitals = useHospitals();

  const [selectednodeId, setselectednodeId] = useState(newWidget.entity ?? originId);

  const handleSelectEntity = useCallback(
    (id: number, nodeDetails: NodeDetails): void => {
      const { name } = nodeDetails;

      setselectednodeId(id);
      dashboardDispatch({ type: 'SET_WIDGET_ENTITY', payload: { value: id, text: name || '' } });
    },
    [dashboardDispatch]
  );

  const treeSearchComponent = (
    <>
      <Box sx={{ mt: 1, mb: 2, typography: 'p2' }}>
        Select Entity:
        {newWidget.entityName && ` (${newWidget.entityName} selected)`}
      </Box>
      <EntityNavigation
        defaultExpandedNodes={[user.rootId.toString()]}
        entity={selectednodeId}
        onEntitySelected={handleSelectEntity}
        isTreeVisible={isTreeVisible}
        onToggleTreeVisibility={(): void => setIsTreeVisible(!isTreeVisible)}
        maxnodetype="Provider"
        withSearch={hospitals.totalCount > 1}
        onEntitySearchComplete={handleSelectEntity}
        minCharactersToSearch={3}
        maxCharactersToSearch={50}
        hideButtonColor="primary"
        id="dashboardEntitySelector"
        type="client"
      />
    </>
  );

  const excludeMetrics = [MetricTypes.allConditions, MetricTypes.hcupcmi];
  if (newWidget.widgetType !== 'table') {
    excludeMetrics.push(MetricTypes.targetedConditions);
  }

  return (
    <Box p={2}>
      <Box mx={1} mt={1} mb={2}>
        {treeSearchComponent}
      </Box>
      <Box m={1}>
        <InputLabel label="Select Widget Type" position="top">
          <Select
            id="selectWidgetType"
            options={widgetTypes}
            value={newWidget.widgetType as string}
            onChange={(value): void => {
              dashboardDispatch({ type: 'SET_WIDGET_TYPE', payload: value });
            }}
            testId="widgetTypeSelector"
          />
        </InputLabel>
      </Box>
      <Box m={1}>
        <InputLabel label="Select Metric" position="top">
          <SelectMetric
            onChange={(metric): void => {
              dashboardDispatch({ type: 'SET_WIDGET_METRIC', payload: metric });
            }}
            selectedValue={metricLabels[newWidget.metric]}
            excludeMetrics={excludeMetrics}
            showConditionsOptions={false}
            testId="widgetMetricSelector"
          />
        </InputLabel>
      </Box>
      {newWidget.metric === 'condition' ? (
        <Box m={1}>
          <InputLabel label="Select Condition" position="top">
            <SelectCondition
              onChange={(id: number, name: string): void => {
                dashboardDispatch({ type: 'SET_WIDGET_CONDITION', payload: { value: id, text: name } });
              }}
              selectedValue={{ value: newWidget.condition ?? -1, label: newWidget.conditionName ?? '' } ?? undefined}
              filterConditionTypes={[ConditionTypes.DRG]}
              testId="widgetConditionSelector"
            />
          </InputLabel>
        </Box>
      ) : null}
    </Box>
  );
};

export default AddWidget;
