import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ClinIntellSkeleton } from '@clinintell/components/index';
import { useDashboard2, useDashboard2Dispatch } from '@clinintell/modules/store';
import {
  ChartDataSetAverageTypes,
  ChartDataSetJSON
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import ViewPerformanceGraph from '@clinintell/containers/autogenStrategy/components/ViewPerformanceGraph';
import {
  DashboardDefaultDates,
  getTopDrgConditionModalData,
  DrgConditionOpportunityData
} from '@clinintell/modules/dashboard2';
import DrgConditionsWidgetTable from './DrgConditionsOpportunityTable';
import { containerBorderRadius } from '@clinintell/theme/theme';
import { sub } from 'date-fns';

type Props = {
  data: DrgConditionOpportunityData;
};

const DrgConditionOpportunityModal: React.FC<Props> = ({ data }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const dashboardDispatch = useDashboard2Dispatch();
  const { drgConditionsByOpportunityWidget, dashboardDefaultDates, entity, groupId } = useDashboard2();
  const { isModalLoading, isModalGraphLoading, modalGraphData } = drgConditionsByOpportunityWidget;
  const { currentStart, currentEnd } = dashboardDefaultDates as DashboardDefaultDates;

  const orgId = useMemo(() => {
    if (groupId && groupId !== entity?.id) return groupId;
    if (entity) return entity.id;
    return -1;
  }, [entity, groupId]);

  const [selectedConditionId, setSelectedConditionId] = useState(data.conditionId);
  const [selectedConditionName, setSelectedConditionName] = useState(data.name);

  const handleTopDRGClickHandler = (conditionId: number, conditionName: string): void => {
    if (conditionId === selectedConditionId) return;
    setSelectedConditionName(conditionName);
    setSelectedConditionId(conditionId);
  };

  useEffect(() => {
    if (isModalLoading) return;
    dashboardDispatch(
      getTopDrgConditionModalData({
        orgId: orgId,
        startDate: sub(new Date(currentEnd), { months: 23 }).toDateString(),
        endDate: currentEnd,
        conditionId: selectedConditionId
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConditionId]);

  const graphHasData = modalGraphData && (modalGraphData as ChartDataSetJSON).data;

  return (
    <Box p={isMobileView ? 0 : 4} pt={0}>
      {isModalLoading ? (
        <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
      ) : (
        <>
          <Typography variant="h5" style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
            {selectedConditionName} Graph
          </Typography>
          <Box
            sx={{
              border: `1px solid grey[200]`,
              borderRadius: `${containerBorderRadius}px`,
              padding: theme.spacing(2),
              display: 'flex',
              marginTop: theme.spacing(1.5),
              marginBottom: theme.spacing(4),
              overflow: 'scroll',
              [theme.breakpoints.down(1350)]: {
                overflowX: 'scroll',
                justifyContent: 'flex-start'
              }
            }}
          >
            {isModalGraphLoading || !graphHasData ? (
              <ClinIntellSkeleton variant="rectangular" width="100%" height="400px" />
            ) : (
              <ViewPerformanceGraph
                graphData={modalGraphData as ChartDataSetJSON}
                isCondition={true}
                viewOption={ChartDataSetAverageTypes.TwoMonth}
                filteredChart={new Set(['OtoECE', 'OtoERatio'])}
                forOCE={true}
              />
            )}
          </Box>
          <Box>
            <DrgConditionsWidgetTable
              onGraphClick={handleTopDRGClickHandler}
              startDate={currentStart}
              endDate={currentEnd}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default DrgConditionOpportunityModal;
