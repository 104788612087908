import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import ClinIntellLogo from '@clinintell/assets/images/InitialsLogo.svg';

const useStyles = makeStyles(theme => ({
  logoContainer: {
    position: 'absolute',
    top: '20px',
    left: '50%',
    display: 'block',
    transform: 'translateX(-50%)',
    borderRadius: '50%',
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      top: '0',
      left: '50%'
    }
  },
  logo: {
    height: '60px',
    width: '60px',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4)
    }
  }
}));

const useAppendClinIntellLogo = (element: Element | null, auth0Lock: Auth0LockStatic | null): void => {
  const { logoContainer, logo } = useStyles();

  useEffect(() => {
    if (auth0Lock && element) {
      auth0Lock.on('show', () => {
        const logoContainerElement = document.createElement('div');
        logoContainerElement.className = logoContainer;

        const imageElement = document.createElement('img');
        imageElement.className = logo;
        imageElement.src = ClinIntellLogo;

        logoContainerElement.appendChild(imageElement);
        element.appendChild(logoContainerElement);
      });
    }
  }, [auth0Lock, element, logo, logoContainer]);
};

export default useAppendClinIntellLogo;
