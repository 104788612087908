/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { NoDataMessage } from '@clinintell/containers/dashboard/Dashboard';
import { LegendItemProps, Legend } from '@clinintell/components/Chart/Legend';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import getChartAndTableConfiguration from '@clinintell/containers/metricsTimeSeries/logic/getChartAndTableConfiguration';
import useChartStyles from '@clinintell/containers/metricsTimeSeries/logic/useChartStyles';
import Chart from '@clinintell/components/Chart/Chart';
import {
  ChartDataSetJSON,
  ChartDataSetAverageTypes
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import GenerateChartProps from '@clinintell/containers/metricsTimeSeries/logic/generateChartProps';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { QueryParams, GraphInfo } from '@clinintell/containers/dashboard/widgetTypes';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';

interface Props {
  name?: string;
  hashId: string;
  endpoint: string;
  queryString?: QueryParams;
  graphInfo: GraphInfo;
  metric: string;
}

const GraphWidget: React.FC<Props> = ({ name = 'graph', hashId, endpoint, queryString, graphInfo, metric }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { filteredDatasets, dataSetAverageTypes } = graphInfo;
  const queryStringValues: QueryStringParam[] = [];

  const config = getChartAndTableConfiguration(metric as keyof typeof Metrics);
  const chartStyles = useChartStyles(metric as keyof typeof Metrics);

  // Remove COVID line
  if (metric !== 'cmi' && metric !== 'docScore' && metric !== 'severityCmi') {
    config.chartDataSets = config.chartDataSets.filter(ds => ds.id !== 'COVID');
  }
  config.dataTableDataSets = config.dataTableDataSets.filter(ds => ds.id !== 'COVID');

  queryStringValues.push({ key: 'startDate', value: formatDateForAPI(new Date(queryString!.startDate as string)) });
  queryStringValues.push({ key: 'endDate', value: formatDateForAPI(new Date(queryString!.endDate as string)) });

  if (queryString) {
    for (const [key, value] of Object.entries(queryString)) {
      if (key !== 'startDate' && key !== 'endDate' && value !== null && value !== undefined) {
        queryStringValues.push({ key, value });
      }
    }
  }

  const widgetEndpoint = createUrlWithQueryString(endpoint, queryStringValues);

  const { output, isLoading } = useGetAPICAll<ChartDataSetJSON>({
    endpoint: widgetEndpoint,
    isWaiting: false
  });

  if (output && output.error) {
    // throw new Error(output.error);
    return <NoDataMessage />;
  }

  if (!output || !output.data || isLoading) {
    return <ClinIntellSkeleton variant="rectangular" height="10rem" width="100%" />;
  }

  const chartDataSetStyles = config.chartDataSets
    .filter(dataset => {
      // Don't return any datasets that are expliclity filtered out
      if (filteredDatasets && filteredDatasets.filter(ds => ds !== null).includes(dataset.id)) {
        return false;
      }

      return true;
    })
    .map(dataset => chartStyles[dataset.id]);

  const legendItems: LegendItemProps[] = chartDataSetStyles
    .filter(dataset => dataset.id !== 'ComparisonEntity')
    .map(dataset => ({
      color: dataset.color,
      type: dataset.lineType,
      label: dataset.label
    }));

  const { xAxis, yAxes, chartDatasets, hasErrorBand } = GenerateChartProps({
    chartDatasets: output.data.data.filter(set => {
      // Don't return any datasets that are expliclity filtered out
      if (filteredDatasets && filteredDatasets.filter(ds => ds !== null).includes(set.dataSetType)) {
        return false;
      }

      if (config.chartDataSets.find(dataset => dataset.id === set.dataSetType)) {
        return true;
      }

      return false;
    }),
    chartStyles: chartDataSetStyles,
    chartPeriodType: dataSetAverageTypes ?? ChartDataSetAverageTypes.Month,
    datasetConfigurations: config.chartDataSets,
    yAxes: config.yAxes
  });

  if (hasErrorBand) {
    legendItems.push({
      color: theme.palette.chart.grey.light,
      type: 'Solid',
      label: 'Error Band'
    });
  }

  // Remove Target Doc Score if there's null data
  const targetDocScoreData = chartDatasets.find(c => c.label.toLowerCase() === 'target');
  const targetDocScoreLegend = legendItems.find(l => l.label.toLowerCase() === 'target');
  if (targetDocScoreData && targetDocScoreData.data.every(value => value === null) && targetDocScoreLegend) {
    chartDatasets.splice(chartDatasets.indexOf(targetDocScoreData), 1);
    legendItems.splice(legendItems.indexOf(targetDocScoreLegend), 1);
  }

  const isChartDataMissing =
    chartDatasets.filter(chartData => chartData.data.every(value => value === null)).length > 0;

  return (
    <>
      {isChartDataMissing ? (
        <NoDataMessage />
      ) : (
        <Box key={`graph-${hashId}`} style={{ overflowX: 'auto' }}>
          <Box display="flex" flexDirection={isMobileView ? 'column' : 'row'} width={isMobileView ? '40rem' : '75rem'}>
            <Legend
              styles={{ marginLeft: '3rem', marginRight: '2rem', marginBottom: isMobileView ? '0.5rem' : '2.5rem' }}
              items={legendItems}
            />
          </Box>
          <Chart title={name} datasets={chartDatasets} yAxes={yAxes} xAxis={xAxis}>
            {yAxes.length > 1 ? Bar : Line}
          </Chart>
        </Box>
      )}
    </>
  );
};

export default GraphWidget;
