import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloseIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon sx={{ width: 36, height: 36, ...props.sx }} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.500244" width="30" height="30" rx="15" fill="inherit" />
      <path
        d="M15 14.3893L18.8891 10.5002L20 11.6112L16.1109 15.5002L20 19.3893L18.8891 20.5002L15 16.6112L11.1109 20.5002L10 19.3893L13.8891 15.5002L10 11.6112L11.1109 10.5002L15 14.3893Z"
        fill="#475569"
      />
    </SvgIcon>
  );
};
CloseIcon.muiName = SvgIcon.muiName;

export default CloseIcon;
