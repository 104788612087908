import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApplicationAPI, HttpResponseException } from '@clinintell/utils/api';
// import { addDefaultDatesToPhysicianGroup } from './physicianGroups';

// GET /api/reports/individual/log/specialtyGroup/{id} where org is specialty group
// Back end will filter out otherMDs
export type ProvRptLogDTOProvider = {
  id: number;
  providerName: string;
  email: string | null;
  docScore: number;
  sentTo: string | null; // email
  sentDate: string | null; // ISO I hope??
  hasTraining?: boolean; // future feature as of 20200721
};

export type ProvRptLogDTOProviders = ProvRptLogDTOProvider[];

export type ProvRptLogDTO = {
  physicianGroupId: number;
  providers: ProvRptLogDTOProviders;
};

export type ProviderReportState = {
  id: string; // provider Id
  docScore: number;
  specialtyGroupId: string;
  providerName: string;
  email: string;
  sentTo: string; // last email used to send report
  sentDate: string; // ISO compliant datetime
  hasTraining?: boolean | null;
};

export type ProviderReportsById = {
  [key: string]: ProviderReportState; // key on Provider_SpecialtyGroup
};

export type ProviderReports = {
  data: ProviderReportsById;
  error: string;
  isLoading: boolean;
};

// concat returns new array

export enum ProviderReportsActions {
  ADD_PROVIDER_REPORTS_BY_PHYS_GRP = 'ADD_PROVIDER_REPORTS_BY_PHYS_GRP',
  PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_REQUESTED = 'PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_REQUESTED',
  PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_FAILED = 'PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_FAILED',
  PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_SUCCESSFUL = 'PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_SUCCESSFUL'
}

export type ProviderReportsAction<T> = {
  type: keyof typeof ProviderReportsActions;
  payload: T;
};

// Normalize State
// replace null values with empty strings
export const convertDTOtoById = (dtoData: ProvRptLogDTO): ProviderReportsById | unknown => {
  const group = dtoData.physicianGroupId.toString();
  return dtoData.providers.reduce((acc, val) => {
    const key = `${val.id.toString()}_${group}`;
    return {
      ...acc,
      [key]: {
        ...val,
        id: val.id.toString(),
        email: val.email ? val.email : '',
        sentTo: val.sentTo ? val.sentTo : '',
        sentDate: val.sentDate ? val.sentDate : '',
        specialtyGroupId: group
      }
    };
  }, {});
};

// Initial State

export const initialProviderReports: ProviderReports = {
  isLoading: false,
  error: '',
  data: {}
};

// Actions

// Reducer

function reducer(
  state: ProviderReports = initialProviderReports,
  action: ProviderReportsAction<ProviderReports>
): ProviderReports {
  switch (action.type) {
    case 'ADD_PROVIDER_REPORTS_BY_PHYS_GRP': {
      return { ...state, data: { ...action.payload.data } };
    }
    case 'PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_FAILED': {
      return { ...state, error: action.payload.error, isLoading: false };
    }
    case 'PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_REQUESTED': {
      return { data: state.data, isLoading: true, error: '' };
    }
    case 'PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_SUCCESSFUL': {
      return { ...state, isLoading: false, error: '' };
    }
    default: {
      return { ...state };
    }
  }
}

export default reducer;

// Sagas
export type ProviderReportForSpecialtyGroupSaga = {
  type: keyof typeof ProviderReportsActions;
  payload: string;
};

export function* fetchProviderReportLogForSpecialtyGroup(action: ProviderReportForSpecialtyGroupSaga): SagaIterator {
  try {
    const response = yield call(ApplicationAPI.get, {
      endpoint: `reports/provider/log/physiciangroup/${action.payload}`
    });
    if (response.hasOwnProperty('error')) {
      throw response.error;
    }
    const providerRecordsById = convertDTOtoById(response.data) as ProviderReportsById;
    yield put({ type: 'ADD_PROVIDER_REPORTS_BY_PHYS_GRP', payload: { data: { ...providerRecordsById } } });
    yield put({ type: 'PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_SUCCESSFUL' });
  } catch (exception) {
    const errorText = (exception as HttpResponseException).message
      ? (exception as HttpResponseException).message
      : exception;
    yield put({ type: 'PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_FAILED', payload: { error: errorText } });
  }
}

export function* providerReportLogForSpecialtyGroupSaga(): SagaIterator {
  yield takeLatest('PROVIDER_REPORTS_BY_PHYS_GRP_FETCH_REQUESTED', fetchProviderReportLogForSpecialtyGroup);
}
