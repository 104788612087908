import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { useCMIAnalysisState } from '@clinintell/modules/store';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import useTimeSeriesData from '../logic/useTimeSeriesData';
import AnalysisChart from './AnalysisChart';
import DRGImpactTables from '@clinintell/components/analysisSharedComponents/DRGImpactTables';
import { DRGMixTableRow } from '@clinintell/containers/cmiComparison/typings/cmiComparisonDtos';
import { DRGGroupShare } from '../types/DRGGroup';

const DRGMixAnalysisDetails: React.FC = () => {
  const {
    historicalMinDate,
    historicalMaxDate,
    currentMinDate,
    currentMaxDate,
    chartMinDate,
    chartMaxDate,
    drgMixSummary,
    drgAnalysis,
    isLoadingSummaryRecords,
    entityId,
    noSummaryData
  } = useCMIAnalysisState();

  const { data: chartData, isLoading } = useTimeSeriesData({
    minChartPeriod: chartMinDate,
    maxChartPeriod: chartMaxDate,
    orgId: entityId,
    metric: 'hcupcmi'
  });

  let historicalColumnLabel = '';
  if (historicalMinDate && historicalMaxDate) {
    const min = parseDateIntoPeriod(historicalMinDate);
    const max = parseDateIntoPeriod(historicalMaxDate);

    historicalColumnLabel = min;
    if (min !== max) {
      historicalColumnLabel += `-${max}`;
    }
  }

  let currentColumnLabel = '';
  if (currentMinDate && currentMaxDate) {
    const min = parseDateIntoPeriod(currentMinDate);
    const max = parseDateIntoPeriod(currentMaxDate);

    currentColumnLabel = min;
    if (min !== max) {
      currentColumnLabel += `-${max}`;
    }
  }

  const hasPositiveCMIDelta =
    drgMixSummary && drgMixSummary.current && drgMixSummary.historical
      ? drgMixSummary.current - drgMixSummary.historical >= 0
      : true;

  let filteredChartData;
  if (chartData) {
    filteredChartData = chartData.data.filter(record => record.dataSetType === 'Actual');
  }

  const chartElement =
    chartData && filteredChartData && !isLoading ? (
      <AnalysisChart
        metric="cmi"
        yAxisLabelOverride="HCUP CMI"
        dataset={chartData}
        chartDataSets={filteredChartData}
        chartPeriodType="Month"
        currentDateIsBetterTimes={hasPositiveCMIDelta}
        chartTitle="DRG Mix"
      />
    ) : (
      <ClinIntellSkeleton variant="rectangular" height="26rem" width="100%" />
    );

  const convertedTableData = useMemo(() => {
    if (!drgAnalysis) {
      return [];
    }

    return drgAnalysis.table.map(
      (row: DRGGroupShare): DRGMixTableRow => {
        return {
          ...row,
          entity1: {
            id: 1,
            share: row.historicalShare
          },
          entity2: {
            id: 2,
            share: row.currentShare
          }
        };
      }
    );
  }, [drgAnalysis]);

  let tableElement = null;
  if (drgAnalysis) {
    if (!noSummaryData) {
      tableElement = (
        <DRGImpactTables
          isLoading={isLoadingSummaryRecords}
          hasPositiveDelta={hasPositiveCMIDelta}
          tableRows={convertedTableData}
          referenceLabel={historicalColumnLabel}
          comparerLabel={currentColumnLabel}
          changeLabel="Change, Share"
        />
      );
    }
  }

  return (
    <Box>
      {chartElement}
      {tableElement}
    </Box>
  );
};

export default DRGMixAnalysisDetails;
