import { BreadcrumbItem } from '@clinintell/modules/breadcrumbNavigation';
import { Box } from '@mui/material';
import React from 'react';

export type BreadcrumbProps = BreadcrumbItem & {
  isCurrent: boolean;
  onClick: (value: string | number) => void;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ label, isDisabled, onClick, isCurrent, value }) => {
  const handleClick = () => {
    if (!isDisabled && !isCurrent) {
      onClick(value);
    }
  };

  let color = 'blue.main';
  if (isDisabled) {
    color = 'grey.400';
  } else if (isCurrent) {
    color = 'grey.600';
  }

  return (
    <Box
      role="button"
      sx={{
        cursor: !isDisabled && !isCurrent ? 'pointer' : 'initial',
        typography: 'p1',
        color,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
      onClick={handleClick}
    >
      {label}
    </Box>
  );
};

export default Breadcrumb;
