export type BreadcrumbItem = {
  label: string;
  value: number | string;
  isDisabled: boolean;
};

export type BreadcrumbNavigation = {
  breadcrumbs: BreadcrumbItem[];
  navigationAction: (value: number | string) => void;
};

/* Actions */
const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
const SET_NAVIGATION_ACTION = 'SET_NAVIGATION_ACTION';

type SetBreadcrumbsPayload = {
  breadcrumbs: BreadcrumbItem[];
};

interface SetBreadcrumbs {
  type: typeof SET_BREADCRUMBS;
  payload: SetBreadcrumbsPayload;
}

type SetNavigationActionPayload = {
  navigationAction: (value: string | number) => void;
};

interface SetNavigationAction {
  type: typeof SET_NAVIGATION_ACTION;
  payload: SetNavigationActionPayload;
}

export type BreadcrumbNavigationActionTypes = SetBreadcrumbs | SetNavigationAction;

export const setBreadcrumbs = (args: SetBreadcrumbsPayload): BreadcrumbNavigationActionTypes => ({
  type: 'SET_BREADCRUMBS',
  payload: args
});

export const setNavigationAction = (args: SetNavigationActionPayload): BreadcrumbNavigationActionTypes => ({
  type: 'SET_NAVIGATION_ACTION',
  payload: args
});

export const initialState: BreadcrumbNavigation = {
  breadcrumbs: [],
  navigationAction: () => {
    throw new Error('navigationAction has not been set');
  }
};

// Reducer
const reducer = (state = initialState, action: BreadcrumbNavigationActionTypes): BreadcrumbNavigation => {
  switch (action.type) {
    case 'SET_BREADCRUMBS': {
      return {
        ...state,
        breadcrumbs: [...action.payload.breadcrumbs]
      };
    }
    case 'SET_NAVIGATION_ACTION': {
      return {
        ...state,
        navigationAction: action.payload.navigationAction
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
