import React from 'react';
import { Box, TextField, Typography, useTheme } from '@mui/material';

type ReportNameInputProps = {
  reportName: string;
  showError?: boolean;
  showRequired?: boolean;
  inputChangeHandler?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

const ReportNameInput: React.FC<ReportNameInputProps> = ({
  reportName,
  showError = false,
  showRequired = false,
  inputChangeHandler
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography
        style={{
          marginBottom: 8
        }}
        variant="p2"
      >
        Report Name
      </Typography>
      <TextField
        type="text"
        inputProps={{
          padding: '0',
          maxLength: 120
        }}
        disabled={true}
        size="small"
        onChange={inputChangeHandler}
        error={showError}
        value={reportName}
        sx={{
          backgroundColor: 'shade.white',
          borderRadius: `8px`,
          width: '100%',
          '& fieldset': {
            borderColor: showError ? theme.palette.red[300] : theme.palette.grey[300], //reportName.length > 0 && firstAttempt
            borderRadius: `8px`
          },
          '&&:hover fieldset': {
            borderColor: 'blue.light2'
          },
          '&&.Mui-focused fieldset': {
            borderColor: 'blue.light2'
          },
          '& fieldset > legend': {
            width: '0px' // hiding the legend
          }
        }}
      ></TextField>
      {showRequired && (
        <Box padding="8px">
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginBottom: '8px 8px 0 8px',
                color: theme.palette.red[500],
                fontWeight: 'bold'
              }}
              variant="p2"
            >
              <>Required Field</>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReportNameInput;
