import CMIAnalysis from '@clinintell/containers/cmiAnalysis/CMIAnalysis';
import { setCMIAnalysisBestFit } from '@clinintell/modules/cmiAnalysis';
import { useCMIAnalysisDispatch, useCMIAnalysisSelectionsState, useCMIAnalysisState } from '@clinintell/modules/store';
import React, { useEffect } from 'react';

const CMIAnalysisController = () => {
  const { entityId, entityName } = useCMIAnalysisSelectionsState();
  const { entityId: currentEntityId } = useCMIAnalysisState();
  const cmiAnalysisDispatch = useCMIAnalysisDispatch();

  useEffect(() => {
    // If CMI Analysis single entity has already been initialized with entity, no need to set intial records
    if (entityId === undefined || currentEntityId !== undefined) {
      return;
    }

    cmiAnalysisDispatch(
      setCMIAnalysisBestFit({
        orgId: entityId,
        metric: 'cmi',
        orgName: entityName
      })
    );
  }, [cmiAnalysisDispatch, currentEntityId, entityId, entityName]);

  return <CMIAnalysis />;
};

export default CMIAnalysisController;
