import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconPropType } from '@clinintell/types/common';

const TimeIcon = (props: SvgIconPropType): JSX.Element => {
  const colorOverride = props.sx.color ?? 'white';
  return (
    <SvgIcon sx={{ fill: 'none', ...props.sx }} viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 17C13.1421 17 16.5 13.6421 16.5 9.5C16.5 5.35786 13.1421 2 9 2C4.85786 2 1.5 5.35786 1.5 9.5C1.5 13.6421 4.85786 17 9 17Z"
        stroke={colorOverride}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 5V9.5L12 11" stroke={colorOverride} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default TimeIcon;
