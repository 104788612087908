import { ReportTypeDto, ReportTypes, ScheduleFrequencyDto } from '@clinintell/api/apiSchemas';
import { useEffect, useState } from 'react';
import useAlert from '@clinintell/components/alert/logic/useAlert';
import useErrorHandling from '@clinintell/errors/useErrorHandling';
import {
  fetchSchedulePostReports,
  useScheduleGetReportsReportTypes,
  useScheduleGetReports
} from '@clinintell/api/apiComponents';
import {
  ReportType,
  ScheduleReportEmailObjType,
  ScheduleReportRolesType
} from '@clinintell/containers/reports/types/actionTypes';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { EntityDataType } from './useReportSchedulerEntitySelector';
import { ApiRequestError } from '@clinintell/errors/customErrors';

export interface QuarterlyReportSubmissionType {
  schedulingReportType: ReportType;
  groupData: EntityDataType;
  reportName: string;
  adminEmails: ScheduleReportEmailObjType;
  selectedFrequency: ScheduleFrequencyDto;
}

export interface GroupReportSubmissionType extends QuarterlyReportSubmissionType {
  clinicalLeader: number;
  providerEmails: ScheduleReportEmailObjType;
  reportStartDate: string | Date;
}

const useReportSchedulerSave = () => {
  const { pushAlert } = useAlert();
  const { showErrorMessage } = useErrorHandling();
  const [reportTypes, setReportTypes] = useState<ReportTypeDto[]>([]);

  const { data: reportTypesResults, isLoading: isFetchingReportTypes } = useScheduleGetReportsReportTypes({
    onError: showErrorMessage
  });

  const { refetch: fetchSchedules } = useScheduleGetReports(
    {
      onError: showErrorMessage
    },
    {
      enabled: false
    }
  );

  /*
    Retrives the report schedules types for the dropdown
  */
  useEffect(() => {
    if (isFetchingReportTypes) return;
    const { reportTypeList } = reportTypesResults as ReportTypes;
    setReportTypes(reportTypeList as ReportTypeDto[]);
  }, [isFetchingReportTypes, reportTypesResults]);

  const scheduleQuarterlyReportClickHandler = async (requestData: QuarterlyReportSubmissionType) => {
    return scheduleReportClickHandler({
      ...requestData,
      clinicalLeader: -1,
      providerEmails: { emails: [], invalidEmails: [], validList: true, emailRole: ScheduleReportRolesType.provider },
      reportStartDate: ''
    });
  };

  const scheduleReportClickHandler = async (requestData: GroupReportSubmissionType) => {
    const {
      schedulingReportType,
      groupData,
      reportName,
      adminEmails,
      providerEmails,
      clinicalLeader,
      reportStartDate,
      selectedFrequency
    } = requestData;
    let successfulPost;
    const currentReportType = schedulingReportType === ReportType.Group ? 'Monthly' : 'Quarterly';
    const dateAPIFormatted = () => {
      if (schedulingReportType === ReportType.Quarterly) return formatDateForAPI(new Date());
      else
        return `${formatDateForAPI(typeof reportStartDate === 'string' ? new Date(reportStartDate) : reportStartDate)}`;
    };

    await fetchSchedulePostReports({
      body: {
        frequency: selectedFrequency,
        groupOrgHierarchyId: groupData.id,
        id: -1,
        startDate: dateAPIFormatted(),
        reportType: reportTypes.find(elem => elem.typeDescription?.includes(currentReportType)),
        recipients: adminEmails.emails.join(),
        providerRecipients: providerEmails.emails.join(),
        reportName: reportName,
        clinicalLeadUserId: reportStartDate === ReportType.Group ? null : clinicalLeader
      },
      onError: (error: unknown) => {
        successfulPost = (error as ApiRequestError).statusCode;
        if (successfulPost !== 409) showErrorMessage(error, `Failed to save ${schedulingReportType} Report Schedule`);
      }
    });
    if (typeof successfulPost === 'undefined') {
      fetchSchedules();
      pushAlert({ message: 'Successfully scheduled report', variant: 'success' });
      successfulPost = 200;
    }
    return successfulPost;
  };

  return {
    scheduleReportClickHandler,
    scheduleQuarterlyReportClickHandler
  };
};

export default useReportSchedulerSave;
