import {
  clearTableDirty,
  setOpportunityPreferences,
  setTargetedConditions,
  setUseTargetConditions
} from '@clinintell/modules/metricsNavigation';
import { useMetricsNavigation, useMetricsNavigationDispatch, useUser } from '@clinintell/modules/store';
import { ApplicationAPI } from '@clinintell/utils/api';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import useSessionOpportunityPreferences from './useSessionOpportunityPreferences';

type SaveResult = {
  message: string;
  variant: 'success' | 'error';
};

type TargetedConditionsState = {
  isSaving: boolean;
  exmSessionWt: number;
  exrSessionWt: number;
  hccSessionWt: number;
  psiSessionWt: number;
  psi90SessionWt: number;
  psi04SessionWt: number;
  psi02SessionWt: number;
  psi07SessionWt: number;
  selectedTargetedConditions: number[];
  saveDisabled: boolean;
  saveResult: SaveResult | undefined;
  recalculateDisabled: boolean;
};

type TargetedConditionsDispatch = {
  onSelection: (rows: number[]) => void;
  onReset: () => void;
  onRecalculate: () => void;
  onSave: () => Promise<void>;
  setExmSessionWt: Dispatch<SetStateAction<number>>;
  setExrSessionWt: Dispatch<SetStateAction<number>>;
  setHccSessionWt: Dispatch<SetStateAction<number>>;
  setPSISessionWt: Dispatch<SetStateAction<number>>;
  setPSI90SessionWt: Dispatch<SetStateAction<number>>;
  setPSI04SessionWt: Dispatch<SetStateAction<number>>;
  setPSI02SessionWt: Dispatch<SetStateAction<number>>;
  setPSI07SessionWt: Dispatch<SetStateAction<number>>;
  setRecalculateDisabled: Dispatch<SetStateAction<boolean>>;
};

const TargetedConditionsStateContext = createContext<TargetedConditionsState>({
  isSaving: false,
  exmSessionWt: 0,
  exrSessionWt: 0,
  hccSessionWt: 0,
  psiSessionWt: 0,
  psi90SessionWt: 0,
  psi04SessionWt: 0,
  psi02SessionWt: 0,
  psi07SessionWt: 0,
  selectedTargetedConditions: [],
  saveDisabled: true,
  saveResult: undefined,
  recalculateDisabled: true
});

const TargetedConditionsDispatchContext = createContext<TargetedConditionsDispatch>({
  onSelection: () => {
    /* empty function */
  },
  onReset: () => {
    /* empty function */
  },
  onRecalculate: () => {
    /* empty function */
  },
  onSave: () => Promise.resolve(),
  setExmSessionWt: () => {
    /* empty function */
  },
  setExrSessionWt: () => {
    /* empty function */
  },
  setHccSessionWt: () => {
    /* empty function */
  },
  setPSISessionWt: () => {
    /* empty function */
  },
  setPSI90SessionWt: () => {
    /* empty function */
  },
  setPSI04SessionWt: () => {
    /* empty function */
  },
  setPSI02SessionWt: () => {
    /* empty function */
  },
  setPSI07SessionWt: () => {
    /* empty function */
  },
  setRecalculateDisabled: () => {
    /* empty function */
  }
});

export const TargetedConditionsProvider: React.FC = ({ children }) => {
  const { entity, metric, targetedConditions } = useMetricsNavigation();

  // TODO -- how does targeted conditions work within metrics data??
  // Reset target conditions whenever the entity changes
  useEffect(() => {
    setSelectedTargetConditions(targetedConditions);
  }, [targetedConditions]);

  const [selectedTargetConditions, setSelectedTargetConditions] = useState<number[]>(targetedConditions);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [recalculateDisabled, setRecalculateDisabled] = useState(true);
  const [saveResult, setSaveResult] = useState<SaveResult>();
  const [isSaving, setIsSaving] = useState(false);

  const prevConditions = useRef<number[]>(selectedTargetConditions);
  const metricsNavDispatch = useMetricsNavigationDispatch();
  const { features } = useUser();
  const {
    exmSessionWt,
    exrSessionWt,
    hccSessionWt,
    psiSessionWt,
    psi90SessionWt,
    psi04SessionWt,
    psi02SessionWt,
    psi07SessionWt,
    setExrSessionWt,
    setExmSessionWt,
    setHccSessionWt,
    setPSISessionWt,
    setPSI90SessionWt,
    setPSI04SessionWt,
    setPSI02SessionWt,
    setPSI07SessionWt,
    originalPSI90Wt,
    originalPSI04Wt,
    originalPSI02Wt,
    originalPSI07Wt
  } = useSessionOpportunityPreferences();

  const onSelection = useCallback((rows: number[]): void => {
    setSelectedTargetConditions(rows);
    setSaveDisabled(rows === prevConditions.current);
  }, []);

  const onReset = (): void => {
    setPSI90SessionWt(originalPSI90Wt);
    setPSI04SessionWt(originalPSI04Wt);
    setPSI02SessionWt(originalPSI02Wt);
    setPSI07SessionWt(originalPSI07Wt);
  };

  const onRecalculate = (): void => {
    metricsNavDispatch(
      setOpportunityPreferences({
        hccWeight: hccSessionWt,
        exmWeight: exmSessionWt,
        exrWeight: exrSessionWt,
        psiWeight: psiSessionWt,
        psi02Weight: psi02SessionWt,
        psi04Weight: psi04SessionWt,
        psi07Weight: psi07SessionWt,
        psi90Weight: psi90SessionWt
      })
    );
    // Set useTargetConditions to false to support recalculating the pies
    metricsNavDispatch(setUseTargetConditions(false));
    metricsNavDispatch(setTargetedConditions(selectedTargetConditions));
  };

  const onSaveTargetedConditions = async (): Promise<void> => {
    if (entity < 1) throw new Error('Entity must be greater than zero');

    try {
      const response = await ApplicationAPI.put({
        endpoint: `metrics/conditions/shares`,
        data: JSON.stringify({
          orgId: Number(entity),
          drg: 5,
          hcc: hccSessionWt,
          elixhauserMortality: exmSessionWt,
          elixhauserReadmission: exrSessionWt,
          psi: psiSessionWt,
          psi02: psi02SessionWt,
          psi04: psi04SessionWt,
          psi07: psi07SessionWt,
          psi90: psi90SessionWt
        })
      });

      if (response.status === 200) {
        setSaveResult({ message: 'Opportunity Preferences Saved Successfully', variant: 'success' });
      } else if (response.status === 403) {
        setSaveResult({ message: 'An Error Occurred While Saving, Try Again Later', variant: 'error' });
      }
    } catch (exception) {
      setSaveResult({ message: 'An Error Occurred While Saving, Try Again Later', variant: 'error' });
    }
  };

  const onSaveForAllConditionsMetric = async (): Promise<void> => {
    try {
      const endpoint = `metrics/conditions/${entity}`;

      const response = await ApplicationAPI.submit({
        endpoint,
        data: JSON.stringify({
          targetConditions: selectedTargetConditions
        }),
        method: 'POST',
        fileType: 'json'
      });

      if (response.status === 204) {
        setSaveResult({ message: 'Targeted Conditions Saved Successfully', variant: 'success' });
        prevConditions.current = selectedTargetConditions;
      } else if (response.status === 409) {
        setSaveResult({ message: 'Targeted Conditions Must be Saved at The Group Level', variant: 'error' });
      }
    } catch (exception) {
      setSaveDisabled(true);
      setSaveResult({ message: 'Targeted Conditions Must be Saved at The Group Level', variant: 'error' });
    } finally {
      setSaveDisabled(true);
      metricsNavDispatch(setUseTargetConditions(true));
    }
  };

  const onSave = async (): Promise<void> => {
    if (features.some(f => f.featureName === 'metricsConditionsSaveTargetConditions')) {
      setIsSaving(true);
      await onSaveTargetedConditions();
      if (metric === 'allConditions') {
        await onSaveForAllConditionsMetric();
      }
      setIsSaving(false);
    } else if (
      metric === 'allConditions' &&
      features.some(f => f.featureName === 'metricsConditionsViewAllConditions')
    ) {
      metricsNavDispatch(setUseTargetConditions(false));
    }

    metricsNavDispatch(clearTableDirty());
  };

  return (
    <TargetedConditionsStateContext.Provider
      value={{
        exmSessionWt,
        exrSessionWt,
        hccSessionWt,
        psiSessionWt,
        psi90SessionWt,
        psi04SessionWt,
        psi02SessionWt,
        psi07SessionWt,
        saveDisabled,
        recalculateDisabled,
        saveResult,
        isSaving,
        selectedTargetedConditions: selectedTargetConditions
      }}
    >
      <TargetedConditionsDispatchContext.Provider
        value={{
          setExmSessionWt,
          setExrSessionWt,
          setHccSessionWt,
          setPSISessionWt,
          setPSI90SessionWt,
          setPSI04SessionWt,
          setPSI02SessionWt,
          setPSI07SessionWt,
          onSelection,
          onSave,
          onReset,
          onRecalculate,
          setRecalculateDisabled
        }}
      >
        {children}
      </TargetedConditionsDispatchContext.Provider>
    </TargetedConditionsStateContext.Provider>
  );
};

export const useTargetedConditionsState = (): TargetedConditionsState => {
  const context = useContext(TargetedConditionsStateContext);
  if (!context) {
    throw new Error('useTargetedConditionsState hook must be used within a TargetedConditionsProvider');
  }

  return context;
};

export const useTargetedConditionsDispatch = (): TargetedConditionsDispatch => {
  const context = useContext(TargetedConditionsDispatchContext);
  if (!context) {
    throw new Error('useTargetedConditionsDispatch hook must be used within a TargetedConditionsProvider');
  }

  return context;
};
