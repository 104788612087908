import { ColumnDataType } from '@clinintell/components/StyledTableCell';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { ColumnName } from './tableSchemas';

export type ColumnMetatdata = {
  title: string;
  type: ColumnDataType;
  prefix?: string;
  postfix?: string;
};

export const isPercentageBased = (metric: keyof typeof Metrics): boolean => {
  return (
    metric === 'docScore' ||
    metric === 'elixhauserMortality' ||
    metric === 'elixhauserReadmission' ||
    metric === 'psi' ||
    metric === 'raf' ||
    metric === 'targetedConditions' ||
    metric === 'allConditions' ||
    metric === 'condition'
  );
};

export const getDateBasedColumnMetadata = (
  startDate: Date,
  endDate: Date,
  metric: keyof typeof Metrics
): ColumnMetatdata => {
  const isPercentageBasedData = isPercentageBased(metric);

  return {
    title: startDate && endDate ? `${parseDateIntoPeriod(startDate)}-${parseDateIntoPeriod(endDate)}` : '',
    type: isPercentageBasedData ? 'integer' : 'number',
    postfix: isPercentageBasedData ? '%' : undefined
  };
};

export const getColumnMetadata = (column: ColumnName, metric: keyof typeof Metrics): ColumnMetatdata => {
  const isPercentageBasedData = isPercentageBased(metric);

  switch (column) {
    case 'name':
      return {
        title: '',
        type: 'string'
      };
    case 'action':
      return {
        title: 'Action',
        type: 'string'
      };
    case 'clinicallyExpected':
      return {
        title: 'Clinically Expected',
        type: isPercentageBasedData ? 'integer' : 'number',
        postfix: isPercentageBasedData ? '%' : undefined
      };
    case 'cases':
      return {
        title: 'Cases',
        type: 'integer'
      };
    case 'opportunity':
      return {
        title: 'Opportunity',
        type: 'integer'
      };
    case 'opportunityRW':
      return {
        title: 'Opportunity',
        type: 'integer'
      };
    case 'minOpportunityRW':
      return {
        title: 'Min Opportunity',
        type: 'integer'
      };
    case 'maxOpportunityRW':
      return {
        title: 'Max Opportunity',
        type: 'integer'
      };
    case 'opportunityDollar':
      return {
        title: 'Opportunity',
        type: 'integer',
        prefix: '$'
      };
    case 'minOpportunityDollar':
      return {
        title: 'Min Opportunity',
        type: 'integer',
        prefix: '$'
      };
    case 'maxOpportunityDollar':
      return {
        title: 'Max Opportunity',
        type: 'integer',
        prefix: '$'
      };
    case 'improvement':
      return {
        title: 'Improvement',
        type: 'integer'
      };
    case 'minImprovementRW':
      return {
        title: 'Min Improvement',
        type: 'integer'
      };
    case 'minImprovementDollar':
      return {
        title: 'Min Improvement',
        type: 'integer',
        prefix: '$'
      };
    case 'maxImprovementRW':
      return {
        title: 'Max Improvement',
        type: 'integer'
      };
    case 'maxImprovementDollar':
      return {
        title: 'Max Improvement',
        type: 'integer',
        prefix: '$'
      };
    case 'improvementDollar':
      return {
        title: 'Improvement',
        type: 'integer',
        prefix: '$'
      };
    case 'improvementRW':
      return {
        title: 'Improvement',
        type: 'integer'
      };
    case 'mdcName':
      return {
        title: 'MDC',
        type: 'string'
      };
    case 'conditionTypeId':
      return {
        title: 'Type',
        type: 'integer'
      };
    case 'gapVsCe':
    case 'otoEGapVsCe':
      return {
        title: 'Gap',
        type: isPercentageBasedData ? 'integer' : 'number',
        postfix: isPercentageBasedData ? '%' : undefined
      };
    case 'errorChange':
      return {
        title: 'Error, Change',
        type: isPercentageBasedData ? 'integer' : 'number',
        postfix: isPercentageBasedData ? '%' : undefined,
        prefix: '±'
      };
    case 'errorGap':
      return {
        title: 'Error, Gap',
        type: isPercentageBasedData ? 'integer' : 'number',
        postfix: isPercentageBasedData ? '%' : undefined,
        prefix: '±'
      };
    case 'change':
      return {
        title: 'Change',
        type: isPercentageBasedData ? 'integer' : 'number',
        postfix: isPercentageBasedData ? '%' : undefined
      };
    case 'hcupWgtAvg':
      return {
        title: 'HCUP Avg',
        type: 'number'
      };
    case 'ceHcupWgtAvg':
      return {
        title: 'CE HCUP Avg',
        type: 'number'
      };
    case 'otoEChange':
      return {
        title: 'Change',
        postfix: '%',
        type: 'integer'
      };
    case 'historicalOtoEce':
    case 'otoEce':
      return {
        title: 'Clinically Expected',
        postfix: '%',
        type: 'integer'
      };
    default:
      return {
        title: '',
        type: 'string'
      };
  }
};
