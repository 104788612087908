import React, { useEffect, useState } from 'react';
import { ApplicationAPI } from '@clinintell/utils/api';
import { Button, ButtonProps } from '@clinintell/components/index';
import { getContentType } from '@clinintell/utils/api';

interface Props extends ButtonProps {
  endpoint: string;
  filename: string;
  extension: string;
}

const DownloadButton: React.FC<Props> = props => {
  const { endpoint, filename, extension } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (!isDownloading) {
      return;
    }

    ApplicationAPI.download(endpoint, extension)
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          throw new Error(response.error);
        }
      })
      .then(buffer => {
        const blob = new Blob([buffer], { type: getContentType(extension) });
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
          return;
        }
        const link = document.createElement('a');
        link.download = filename;
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        throw new Error(`Error with the download request: ${error}`);
        // Update global error boundary state to display error boundary
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, [endpoint, extension, filename, isDownloading]);

  const handleDownload = () => {
    setIsDownloading(true);
  };

  return <Button isBusy={isDownloading} type={'primary'} onClick={handleDownload} {...props} />;
};

export default DownloadButton;
