import * as Sentry from '@sentry/react';
import { ApiRequestError, CustomError, isCustomError } from './customErrors';
import { ScopeContext } from '@sentry/types';
import renderErrorMessage from './renderErrorMessage';

export type ReportToSentryContext = Partial<Pick<ScopeContext, 'extra' | 'tags' | 'user'>>;

const reportToSentry = (error: Error | unknown, context: ReportToSentryContext = {}) => {
  const sentryContext = isCustomError(error) ? (error as CustomError).sentryContext : context;

  // NOTE: ErrorBoundary injects this property and it adds a ton of noise to Sentry
  // if there is a purpose to it in the future delete these lines
  if ((error as unknown & { componentStack: unknown }).componentStack) {
    // eslint-disable-next-line no-param-reassign
    delete (error as unknown & { componentStack: unknown }).componentStack;
  }

  if (error instanceof ApiRequestError) {
    // NOTE: before sending to sentry help visibility and grouping of API errors by setting their message to the endpoint (method + path)
    // eslint-disable-next-line no-param-reassign
    error.message = error.endpoint;
  }

  Sentry.captureException(error, scope => {
    const scopedUser = scope.getUser();
    const user = scopedUser ? { ...scopedUser, ...sentryContext.user } : sentryContext.user;

    scope.setUser(user || null);
    scope.setTags(sentryContext.tags || {});
    // NOTE: use key application context so it will show at the top of the alphabetized list of contexts
    // NOTE: use setContext not "extra" https://docs.sentry.io/platforms/javascript/enriching-events/context/#additional-data
    if (sentryContext.extra) scope.setContext('application context', sentryContext.extra);

    return scope;
  });
};

/**
 * Callback for onError of react-error-boundary
 * @param error
 * @param info
 */
export const handleErrorBoundaryError = (error: Error | unknown) => {
  reportToSentry(error);
};

const defaultErrorMessageConfig = {
  message: 'An unexpected error occurred! Our team has been notified and will work on a resolution',
  displayTimeMs: 4000
};

/**
 * NOTE: should be used for recoverable errors (something the user can fix) or issues they should be aware of
 *
 * Displays an error message to the user using @see {@link renderErrorMessage}
 *
 * behavior:
 * - if error is a {@link CustomError} is provided its properties are used to configure the alert
 * - otherwise the default error message config ( {@link defaultErrorMessageConfig}) is used
 *
 * @param error a {@link CustomError} subclass or any other error
 * @param overrideDefaultMessage [optional] set a custom message to display to the user
 *
 * @example
 * // simple
 * doSomethingAsync().catch(showErrorMessage)
 *
 * // with custom error wrapper (see errors/customErrors)
 * try {
 *  doSomethingAsync();
 * } catch(error) {
 *   showErrorMessage(new CustomError(error))
 * }
 *
 * // with a custom message
 * doSomethingAsync.catch(error => showErrorMessage(error, 'Custom message to show user'))
 */
export const showErrorMessage = (error: Error | CustomError | unknown, overrideDefaultMessage?: string) => {
  const showErrorConfig = { ...defaultErrorMessageConfig };

  if (isCustomError(error)) {
    const { message, details, displayTimeMs } = error as CustomError;

    showErrorConfig.message = `${message}. ${details}`;
    showErrorConfig.displayTimeMs = displayTimeMs;
  }

  const displayMessage = overrideDefaultMessage || showErrorConfig.message;

  renderErrorMessage(displayMessage, showErrorConfig.displayTimeMs);

  reportToSentry(error);
};

/**
 * Sends the error to Sentry without throwing or displaying an error message
 * @param error
 * @returns
 */
export const captureErrorSilently = (error: Error | CustomError | unknown) =>
  reportToSentry(error, { tags: { silent: true } });
