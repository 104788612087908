import {
  MetricsGetConditionsByOrgIdQueryParams,
  MetricsGetPsiPiesByOrgIdResponse,
  useMetricsGetConditionsByOrgId,
  useMetricsGetPsiPiesByOrgId
} from '@clinintell/api/apiComponents';

import { useEffect, useMemo, useState } from 'react';
import { useMetricsNavigation } from '@clinintell/modules/store';
import { CACHE_TIMES } from '../logic/MetricsDataContext';
import { ConditionMetric } from '@clinintell/api/apiSchemas';
import { ConditionData } from '../typings/metricTypes';
import useErrorHandling from '@clinintell/errors/useErrorHandling';

type UseAllConditionsDataProps = {
  entity: number;
  isEnabled: boolean;
  conditionParams: MetricsGetConditionsByOrgIdQueryParams;
};

type UseAllConditionsDataOutput = {
  metrics: ConditionMetric[];
  conditionData: ConditionData | undefined;
  psiPieShares: MetricsGetPsiPiesByOrgIdResponse | undefined;
  targetedConditions: number[];
  isLoading: boolean;
};

const useAllConditionsData = ({
  entity,
  isEnabled,
  conditionParams
}: UseAllConditionsDataProps): UseAllConditionsDataOutput => {
  const { showErrorMessage } = useErrorHandling();
  const [previousEntity, setPreviousEntity] = useState(entity);

  const {
    condition,
    useTargetConditions,
    psi02Weight,
    psi04Weight,
    psi07Weight,
    psi90Weight,
    targetedConditions,
    metric
  } = useMetricsNavigation();

  const [defaultTargetedConditions, setDefaultTargetedConditions] = useState(targetedConditions);

  const { data: allConditionsData, isFetching: isFetchingAllConditionsData } = useMetricsGetConditionsByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...conditionParams,
        ...(condition !== undefined && { ConditionId: condition }),
        includeAllConditions: 1,
        useSavedTargetConds: useTargetConditions ? 1 : 0,
        shareHcc: conditionParams.shareHcc || 0,
        shareMortality: conditionParams.shareMortality || 0,
        shareReadmission: conditionParams.shareReadmission || 0,
        sharePsi: conditionParams.sharePsi || 0,
        adjPsi2Weight: psi02Weight || 0,
        adjPsi4Weight: psi04Weight || 0,
        adjPsi7Weight: psi07Weight || 0,
        adjPsi90Weight: psi90Weight || 0,
        ...(targetedConditions && targetedConditions.length > 0 && { targetConditionIds: targetedConditions.join(',') })
      },
      onError: showErrorMessage
    },
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: psiPieData, isFetching: isFetchingPsiPieData } = useMetricsGetPsiPiesByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...conditionParams
      },
      onError: showErrorMessage
    },
    { enabled: isEnabled, refetchOnWindowFocus: false, ...CACHE_TIMES }
  );

  // Only update default targeted conditions if the entity has changed. Otherwise, regardless of other params changing (dates, etc.), keep
  // the existing targeted conditions in state
  useEffect(() => {
    if (metric !== 'allConditions' || isFetchingAllConditionsData || !allConditionsData || entity === previousEntity) {
      return;
    }

    setPreviousEntity(entity);
    setDefaultTargetedConditions(
      (allConditionsData.metrics as ConditionMetric[])
        .filter(metric => metric.isTargetCondition)
        .map(metric => Number(metric.conditionId))
    );
  }, [allConditionsData, entity, isFetchingAllConditionsData, metric, previousEntity]);

  const outputData: Omit<UseAllConditionsDataOutput, 'targetedConditions'> = useMemo(() => {
    const data: ConditionMetric[] = [];
    let conditionData: ConditionData | undefined = undefined;
    let psiPieShares: MetricsGetPsiPiesByOrgIdResponse | undefined = undefined;

    if (allConditionsData) {
      const { metrics, ...conditionDataVals } = allConditionsData;

      if (metrics) {
        data.push(...metrics);
      }

      if (psiPieData) {
        psiPieShares = psiPieData;
      }

      conditionData = conditionDataVals;
    }

    return {
      metrics: data,
      conditionData,
      isLoading: isFetchingAllConditionsData || isFetchingPsiPieData,
      psiPieShares
    };
  }, [allConditionsData, isFetchingAllConditionsData, isFetchingPsiPieData, psiPieData]);

  return {
    ...outputData,
    targetedConditions: defaultTargetedConditions
  };
};

export default useAllConditionsData;
