import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Select, SelectOptionType } from '@clinintell/components/index';
import { useHospitalsGetBDSystemHospitalsByOrgId } from '@clinintell/api/apiComponents';
import useErrorHandling from '@clinintell/errors/useErrorHandling';

type Options = SelectOptionType[];

type Props = {
  labelPosition?: 'top' | 'side' | 'none';
  title?: string;
  systemId?: number;
  defaultValue?: number;
  onHospitalChangeFn: (value: number | null, display?: string) => void;
  onSelectLoaded?: (value: boolean) => void;
  width?: number | string;
  placeholder?: string;
};

const SelectHospital: React.FC<Props> = ({
  labelPosition = 'top',
  title,
  systemId = -1,
  defaultValue,
  onHospitalChangeFn,
  onSelectLoaded,
  width = 400,
  placeholder
}) => {
  const { showErrorMessage } = useErrorHandling();
  const [hospitalOptions, setHospitalOptions] = useState<Options | []>([]);

  const { data: hospitalData, isLoading } = useHospitalsGetBDSystemHospitalsByOrgId(
    {
      pathParams: {
        orgId: systemId
      },
      onError: showErrorMessage
    },
    {
      enabled: systemId !== undefined && systemId !== -1
    }
  );

  useEffect(() => {
    if (!hospitalData || isLoading) return;

    if (hospitalData && hospitalData.hospitals) {
      const dropDownList: Options | [] = hospitalData.hospitals
        .map(hospital => {
          return {
            value: hospital.id,
            label: hospital.hospitalName,
            useLabelForTestId: true
          } as SelectOptionType;
        })
        .sort((a, b) => {
          if (!a || !a.label || !b || !b.label) return 0;
          return a.label.localeCompare(b.label, 'en', { ignorePunctuation: true });
        });

      setHospitalOptions(dropDownList);
      if (dropDownList.length === 1) {
        onHospitalChangeFn(dropDownList[0].value as number, dropDownList[0].label);
        return;
      }

      if (
        onSelectLoaded &&
        defaultValue &&
        dropDownList.length > 0 &&
        dropDownList.find(hospital => hospital.value === defaultValue)
      ) {
        onSelectLoaded(true);
      }
    }
  }, [hospitalData, isLoading, onSelectLoaded, defaultValue, onHospitalChangeFn]);

  return (
    <Box
      display="flex"
      flexDirection={labelPosition === 'top' ? 'column' : 'row'}
      alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
      width={width}
    >
      {labelPosition !== 'none' && <Typography variant="p2">{title ?? 'Hospital'}</Typography>}
      <Box
        width="100%"
        style={labelPosition === 'none' ? undefined : labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}
      >
        <Select
          width={width}
          isDisabled={systemId === undefined || isLoading}
          isSearchable={true}
          isLoading={systemId !== undefined && systemId !== -1 ? isLoading : undefined}
          options={hospitalOptions}
          value={Number(defaultValue)}
          onChange={(value, label): void => {
            onHospitalChangeFn(Number(value), label);
          }}
          testId="bdHospitalSelector"
          placeholder={placeholder}
        />
      </Box>
    </Box>
  );
};

export default React.memo(SelectHospital);
