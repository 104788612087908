import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { useDefaultDates, useDefaultDatesDispatch } from '@clinintell/modules/store';
import { ProviderTrainingAssignmentState } from '@clinintell/modules/trainingAssignment';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { Box, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useReportDownloadStatus } from '../context/ReportDownloadContext';
import { OrgTableProps } from '../TrainingStatusReport';
import {
  providerHasAssignedTraining,
  providerHasCompletedTraining
} from '@clinintell/containers/trainingConfiguration/logic/providerTrainingStatuses';
import ProgressBar from '@clinintell/components/ProgressBar';
import { Table, DateCell, IntegerCell, useTableApiActions } from '@clinintell/components/table_v2';

const GroupGeneratedReport: React.FC<OrgTableProps> = ({ orgId }) => {
  const { isLoading, output } = useGetAPICAll<ProviderTrainingAssignmentState[]>({
    endpoint: `reports/training/physicianGroup/${orgId}`,
    isWaiting: false
  });

  const defaultDates = useDefaultDates();
  const defaultDatesDispatch = useDefaultDatesDispatch();

  const { enableDownload, disableDownload } = useReportDownloadStatus();
  const { initializeTableApis } = useTableApiActions();

  useEffect(() => {
    if (defaultDates.byId[orgId]) {
      return;
    }

    defaultDatesDispatch({ type: 'DEFAULT_DATES_FETCH_REQUESTED', payload: orgId.toString() });
  }, [defaultDates.byId, defaultDatesDispatch, orgId]);

  const groupDates = defaultDates.byId[orgId];

  const headerTooltipText = groupDates
    ? `The percentage of CC/MCC severity value reported versus expected.  Current period = ${parseDateIntoPeriod(
        new Date(groupDates.currentMinMonth as string)
      )} - ${parseDateIntoPeriod(new Date(groupDates.currentMaxMonth as string))}`
    : '';

  const theme = useTheme();

  if (isLoading) {
    return <ClinIntellSkeleton variant="rectangular" height="25rem" width="100%" />;
  }

  if (output?.status === 204) {
    disableDownload();
    return (
      <Box mt={5} ml={1} component="span" color={theme.palette.red[500]}>
        None of the providers in this group are enrolled in ClinIntell's online training.
      </Box>
    );
  } else {
    enableDownload();
  }

  return (
    <Table<ProviderTrainingAssignmentState>
      onGridReady={initializeTableApis}
      rowData={output?.data || []}
      renderColumns={({ renderColumn }) => {
        return [
          renderColumn('orgName', {
            cellRenderer: props => {
              return <Box>{props.value}</Box>;
            },
            headerName: 'Provider',
            suppressMenu: false,
            headerAlignment: 'left',
            sortable: true,
            width: 350,
            suppressSizeToFit: true
          }),
          renderColumn('currentDocumentationScore', {
            headerName: 'Documentation Score',
            cellRenderer: props =>
              props.value !== undefined && props.value !== null ? <IntegerCell postfix="%" value={props.value} /> : '-',
            cellStyle: {
              justifyContent: 'center'
            },
            sortable: true,
            headerTooltip: headerTooltipText
          }),
          renderColumn('assignedDate', {
            cellRenderer: props => {
              return props.value && props.data && providerHasAssignedTraining(props.data) ? (
                <DateCell date={props.value} />
              ) : (
                '-'
              );
            },
            cellStyle: {
              justifyContent: 'center'
            },
            sortable: true
          }),
          renderColumn('percentComplete', {
            cellRenderer: props => (
              <Box width="100%" display="flex" flexDirection="column" py={1}>
                <ProgressBar top={-7} size="small" value={(props.value || 0) / 100} width="100%" />
              </Box>
            ),
            sortable: true
          }),
          renderColumn('completedDate', {
            cellRenderer: props =>
              props.value && props.data && providerHasCompletedTraining(props.data) ? (
                <DateCell date={props.value} />
              ) : (
                <Box>{'-'}</Box>
              ),
            sortable: true,
            cellStyle: {
              justifyContent: 'center'
            }
          }),
          renderColumn('percentScore', {
            valueFormatter: props =>
              props.data && providerHasCompletedTraining(props.data) && props.value ? props.value : -1,
            cellRenderer: props =>
              props.data && props.value && providerHasCompletedTraining(props.data) ? (
                <IntegerCell value={props.value} postfix="%" />
              ) : (
                <Box>-</Box>
              ),

            sortable: true,
            sort: 'desc',
            cellStyle: {
              justifyContent: 'center'
            }
          })
        ];
      }}
    />
  );
};

export default GroupGeneratedReport;
