import React from 'react';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import Alert from '@clinintell/components/alert/Alert';
import { Box } from '@mui/material';

const renderErrorMessage = (message: string, displayTimeMs: number): void => {
  enqueueSnackbar(message, {
    variant: 'error',
    autoHideDuration: displayTimeMs,
    content: key => {
      return (
        <Box id={`${key}`}>
          <Alert message={message} variant={'error'} onClose={() => closeSnackbar(key)} />
        </Box>
      );
    }
  });
};

export default renderErrorMessage;
