import React, { useContext } from 'react';
import { ModalContext, ModalContextProvider } from './CIModalContext';
import Dialog from '@mui/material/Dialog';
import { Box, DialogTitle, DialogContent, DialogActions, useTheme, IconButton } from '@mui/material';
import Button from '@clinintell/components/button/Button';
import { isMobile } from 'react-device-detect';
import ModalCloseIcon from '@clinintell/components/icons/ModalCloseIcon';
import makeStyles from '@mui/styles/makeStyles';
import { containerBorderRadius } from '@clinintell/theme/theme';

const useStyles = makeStyles(theme => ({
  dialogPaperContainer: {
    height: 'auto',
    minWidth: '515px',
    width: '30vw',
    borderRadius: `${containerBorderRadius}px`
  },
  dialogPaperContainerMobile: {
    height: '100%',
    width: '100%'
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 18px',
    '& .dialogChildContainer': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center'
    },
    '& .dialogExtBtn': {
      marginLeft: 'auto',
      cursor: 'pointer'
    }
  },
  dialogContent: {
    height: 'auto'
  },
  dialogFooter: {
    display: 'flex',
    backgroundColor: theme.palette.grey[50],
    justifyContent: 'flex-end',
    padding: 16
  }
}));

type DialogProps = {
  modalBtn?: JSX.Element;
  onClose?: () => void;
  hideBackdrop?: boolean;
  openModal?: boolean;
};

// Define the component's composition properties
// Header - Content - Footer
type CIModalComposition = {
  Header: React.FC<ModalHeader>;
  Content: React.FC<ModalContent>;
  Footer: React.FC<ModalFooter>;
};

const CIModal: React.FC<DialogProps> & CIModalComposition = props => {
  return (
    <ModalContextProvider>
      <CIModalContainer {...props}>{props.children}</CIModalContainer>
    </ModalContextProvider>
  );
};

const CIModalContainer: React.FC<DialogProps> = ({
  modalBtn = <></>,
  onClose,
  children,
  hideBackdrop = false,
  openModal = false
}) => {
  const classes = useStyles();
  const { modalVisible, toggleVisible } = useContext(ModalContext);
  const handleModalToggle = () => {
    if (modalVisible && onClose !== undefined) onClose();
    if (toggleVisible !== undefined) toggleVisible();
  };

  return (
    <>
      <CIModalButtonWrapper disabled={modalBtn.props['disabled']}>{modalBtn}</CIModalButtonWrapper>
      <Dialog
        classes={{ paper: isMobile ? classes.dialogPaperContainerMobile : classes.dialogPaperContainer }}
        onClose={handleModalToggle}
        onBackdropClick={handleModalToggle}
        open={modalVisible || openModal}
        disableEscapeKeyDown={true}
        fullScreen={isMobile}
        hideBackdrop={hideBackdrop}
      >
        {children}
      </Dialog>
    </>
  );
};

type ModalHeader = {
  id?: string;
  showCloseIcon?: boolean;
};

const CIDialogHeader: React.FC<ModalHeader> = ({ id = 'CIDialogHeaderID', showCloseIcon = false, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { toggleVisible } = useContext(ModalContext);
  return (
    <DialogTitle id={id} className={classes.dialogHeader}>
      <Box className="dialogChildContainer">{children}</Box>
      {showCloseIcon ? (
        <IconButton onClick={toggleVisible} disableRipple data-testid="cimodal-closeIcon">
          <ModalCloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type ModalContent = {
  id?: string;
};

const CIDialogContent: React.FC<ModalContent> = ({ id = 'CIDialogContentID', children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <DialogContent id={id} className={classes.dialogContent}>
      {children}
    </DialogContent>
  );
};

type ModalFooter = {
  id?: string;
  cancelBtn?: boolean;
  cancelBtnLabel?: string;
  cancelBtnEventHandler?: () => void;
  width?: number | string;
};

const CIDialogFooter: React.FC<ModalFooter> = ({
  id = 'CIDialogFooterID',
  cancelBtn = false,
  cancelBtnLabel = 'Cancel',
  cancelBtnEventHandler = null,
  width = 'auto',
  children
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { toggleVisible } = useContext(ModalContext);
  const cancelBtnClickHanlder = () => {
    if (cancelBtnEventHandler != null) cancelBtnEventHandler();
    toggleVisible();
  };

  if (!children) return null;
  return (
    <DialogActions id={id} className={classes.dialogFooter}>
      {cancelBtn ? (
        <Button
          label={cancelBtnLabel}
          type="primary"
          variant="outlined"
          onClick={cancelBtnClickHanlder}
          sx={{ width: width }}
        />
      ) : null}
      {children}
    </DialogActions>
  );
};

type CIModalButtonWrapperProps = {
  clickBtnHandler?: () => void;
  disabled?: boolean;
};

export const CIModalButtonWrapper: React.FC<CIModalButtonWrapperProps> = ({ clickBtnHandler, disabled, children }) => {
  const { toggleVisible } = useContext(ModalContext);
  const clickHandler = () => {
    if (disabled) return;
    if (clickBtnHandler != null) clickBtnHandler();
    toggleVisible();
  };

  if (!children) return null;
  return (
    <Box
      sx={{
        height: 'wrap-content',
        width: 'wrap-content',
        margin: 0,
        padding: 0
      }}
      onClick={clickHandler}
    >
      {children}
    </Box>
  );
};

export const CIModalCloseModal = () => {
  // This isn't the best solution, but it works and saves time modifying the CIModal
  const footer = document.getElementById('CIDialogFooterID') as HTMLElement;
  if (footer && footer.childNodes.length > 0) {
    const cancelBtn = footer.firstChild as HTMLButtonElement;
    cancelBtn.click();
  }
};

CIModal.Header = CIDialogHeader;
CIModal.Content = CIDialogContent;
CIModal.Footer = CIDialogFooter;

export default CIModal;
