import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Box } from '@mui/material';
import { appBarHeight } from '@clinintell/theme/theme';

type HeaderProps = {
  children: React.ReactNode;
};

const Header = React.forwardRef<HTMLDivElement | null, HeaderProps>(({ children }, ref) => {
  const location = useLocation();
  if (!location.pathname.includes('metrics')) return null;
  return (
    <AppBar
      ref={ref}
      sx={{
        backgroundColor: 'shade.white',
        boxShadow: 'none',
        color: 'grey.500',
        width: '100%',
        zIndex: 'header',
        // this is to appear as the header spans higher than the viewable area
        height: 125,
        marginTop: '-59px',
        paddingTop: '59px'
      }}
      position="sticky"
    >
      <Box
        sx={{
          height: appBarHeight,
          display: 'flex',
          alignItems: 'center',
          paddingX: 2
        }}
      >
        {children}
      </Box>
    </AppBar>
  );
});

export default Header;
