import { useState } from 'react';
import { ScheduleReportEmailObjType, ScheduleReportRolesType } from '@clinintell/containers/reports/types';

type ScheduledEmailProps = {
  adminEmails: ScheduleReportEmailObjType;
  providerEmails: ScheduleReportEmailObjType;
  resetEmailLists: () => void;
  setReportEmailList: (emailObj: ScheduleReportEmailObjType) => void;
};

const useReportSchedulerEmails = (): ScheduledEmailProps => {
  const [adminEmails, setAdminEmails] = useState<ScheduleReportEmailObjType>({
    emails: [],
    invalidEmails: [],
    validList: false,
    emailRole: ScheduleReportRolesType.admin
  });
  const [providerEmails, setProviderEmails] = useState<ScheduleReportEmailObjType>({
    emails: [],
    invalidEmails: [],
    validList: true,
    emailRole: ScheduleReportRolesType.provider
  });

  const resetEmailLists = (): void => {
    setAdminEmails({ emails: [], invalidEmails: [], validList: false, emailRole: ScheduleReportRolesType.admin });
    setProviderEmails({ emails: [], invalidEmails: [], validList: true, emailRole: ScheduleReportRolesType.provider });
  };

  const setReportEmailList = (emailObj: ScheduleReportEmailObjType): void => {
    switch (emailObj.emailRole) {
      case ScheduleReportRolesType.admin:
        setAdminEmails({ ...emailObj });
        break;
      case ScheduleReportRolesType.provider:
      default:
        setProviderEmails({ ...emailObj });
        break;
    }
  };

  return { adminEmails, providerEmails, resetEmailLists, setReportEmailList };
};

export default useReportSchedulerEmails;
