import ModalWindow from '@clinintell/components/modal/ModalWindow';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import EntityComparison from './sections/entityComparison/EntityComparison';
import SingleEntity from './sections/singleEntity/SingleEntity';
import { CMIAnalysisTypes, EntitySelection, setAnalysisType } from '@clinintell/modules/cmiAnalysisSelections';
import { useCMIAnalysisSelectionsDispatch, useCMIAnalysisSelectionsState } from '@clinintell/modules/store';
import CMIComparisonController from './sections/cmiComparisonController/CMIComparisonController';
import CMIAnalysisController from './sections/cmiAnalysisController/CMIAnalysisController';
import GoBackToLandingPage from './sections/goBackToLandingPage/GoBackToLandingPage';
import useCMIAnalysisPermissions from './logic/useCMIAnalysisPermissions';
import useIsMobileView from '@clinintell/utils/useIsMobileView';
import PageTitle from '@clinintell/components/typography/pageTitle/PageTitle';
import CardSelector from '@clinintell/components/cardSelector/CardSelector';
import Button from '@clinintell/components/button/Button';
import { isMobile } from 'react-device-detect';

export const CARD_WIDTH = isMobile ? '23.15rem' : '25.15rem';

export const CardSelectorLayout: React.FC = ({ children }) => {
  const isMobileView = useIsMobileView();

  return isMobileView ? (
    <>{children}</>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '25px' }}>{children}</Box>
  );
};

const CMIAnalysisLanding = () => {
  const [analysisTypeSelectors, setAnalysisTypeSelectors] = useState<CMIAnalysisTypes>('landing');
  const { analysisType } = useCMIAnalysisSelectionsState();
  const cmiAnalysisSelectionDispatch = useCMIAnalysisSelectionsDispatch();
  const [showEntitySelectors, setShowEntitySelectors] = useState(false);

  const {
    canViewSingleEntity,
    canViewEntityComparison,
    canViewNationalMedicareComparison
  } = useCMIAnalysisPermissions();

  switch (analysisType) {
    case 'single':
      return (
        <GoBackToLandingPage>
          <CMIAnalysisController />
        </GoBackToLandingPage>
      );
    case 'comparison':
    case 'nationalcomparison':
      return (
        <GoBackToLandingPage>
          <CMIComparisonController />
        </GoBackToLandingPage>
      );
    default:
      break;
  }

  const handleModalClose = () => {
    setShowEntitySelectors(false);
  };

  const handleAnalysisTypeSelected = (
    type: CMIAnalysisTypes,
    entity?: EntitySelection,
    peerEntity?: EntitySelection
  ) => {
    cmiAnalysisSelectionDispatch(setAnalysisType({ analysisType: type, entity, peerEntity }));
    setShowEntitySelectors(false);
  };

  const handleComparisonTypeCardSelected = (type: CMIAnalysisTypes) => {
    setAnalysisTypeSelectors(type);
    setShowEntitySelectors(true);
  };

  let modalComponent;
  switch (analysisTypeSelectors) {
    case 'comparison':
      modalComponent = (
        <EntityComparison orgTreeType="client" onAnalyze={handleAnalysisTypeSelected} onGoBack={handleModalClose} />
      );
      break;
    case 'single':
      modalComponent = (
        <SingleEntity orgTreeType="client" onAnalyze={handleAnalysisTypeSelected} onGoBack={handleModalClose} />
      );
      break;
    case 'nationalcomparison':
      modalComponent = (
        <EntityComparison orgTreeType="saf" onAnalyze={handleAnalysisTypeSelected} onGoBack={handleModalClose} />
      );
      break;
    default:
      modalComponent = <RouteErrorPage />;
      break;
  }

  return (
    <>
      <Box sx={{ my: 3, mx: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <PageTitle
            title="CMI Analysis"
            subTitle="Choose the analysis you would like to view."
            tooltip="Estimates the underlying severity capture and patient mix drivers of CMI variance."
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
          {canViewSingleEntity ? (
            <Box sx={{ width: CARD_WIDTH }}>
              <CardSelector onClick={() => handleComparisonTypeCardSelected('single')}>
                <CardSelector.Details
                  title="Single Entity"
                  explanation="Identifies drivers of CMI changes over time for your health system, a hospital or a physician group using your all payer data."
                />
                <CardSelector.Actions>
                  <Button
                    variant="text"
                    onClick={() => handleComparisonTypeCardSelected('single')}
                    label="Get Started"
                    sx={{ width: '5.5rem' }}
                  />
                </CardSelector.Actions>
              </CardSelector>
            </Box>
          ) : null}
          <CardSelectorLayout>
            {canViewEntityComparison ? (
              <Box sx={{ width: CARD_WIDTH }}>
                <CardSelector onClick={() => handleComparisonTypeCardSelected('comparison')}>
                  <CardSelector.Details
                    title="Entity Comparison"
                    explanation="Identifies drivers of CMI differences between two of your hospitals or physician groups internally using your all payer data."
                  />
                  <CardSelector.Actions>
                    <Button
                      variant="text"
                      onClick={() => handleComparisonTypeCardSelected('comparison')}
                      label="Get Started"
                      sx={{ width: '5.5rem' }}
                    />
                  </CardSelector.Actions>
                </CardSelector>
              </Box>
            ) : null}
            {canViewNationalMedicareComparison ? (
              <Box sx={{ width: CARD_WIDTH }}>
                <CardSelector onClick={() => handleComparisonTypeCardSelected('nationalcomparison')}>
                  <CardSelector.Details
                    title="National Medicare Comparison"
                    explanation="Identifies drivers of CMI differences between any two health systems or hospitals using Medicare claims-level data."
                  />
                  <CardSelector.Actions>
                    <Button
                      variant="text"
                      onClick={() => handleComparisonTypeCardSelected('nationalcomparison')}
                      label="Get Started"
                      sx={{ width: '5.5rem' }}
                    />
                  </CardSelector.Actions>
                </CardSelector>
              </Box>
            ) : null}
          </CardSelectorLayout>
        </Box>
      </Box>
      <ModalWindow
        width={analysisTypeSelectors === 'single' ? 'md' : 'xl'}
        content={modalComponent}
        openAnchor={showEntitySelectors}
      />
    </>
  );
};

export default CMIAnalysisLanding;
