import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import LoadingScreen from '@clinintell/components/LoadingScreen';

const ManageUsersController = lazy(() =>
  import(
    /* webpackChunkName: 'usermanagement/ManageUsersController' */ '@clinintell/containers/usermanagement/ManageUsers'
  )
);

const ManageProviderController = lazy(() =>
  import(
    /* webpackChunkName: 'providerManagement/ProviderManagement' */ '@clinintell/containers/providerManagement/ProviderManagement'
  )
);

const ManageProvider = lazy(() =>
  import(
    /* webpackChunkName: 'providerManagement/EditProviderScreen' */ '@clinintell/containers/providerManagement/EditProviderScreen'
  )
);

const ToolRoutes = (): JSX.Element => {
  return (
    <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
      <Routes>
        <Route
          path="manageprovider"
          element={
            <Can
              permissions={['usersEdit', 'usersView', 'manageProvider']}
              yes={(): JSX.Element => <ManageProviderController />}
              no={(): JSX.Element => <RouteErrorPage />}
            />
          }
        />
        <Route
          path="manageprovider/manage"
          element={
            <Can
              permissions={['usersEdit', 'usersView']}
              yes={(): JSX.Element => <ManageProvider />}
              no={(): JSX.Element => <RouteErrorPage />}
            />
          }
        />
        <Route
          path="manageusers"
          element={
            <Can
              permissions={['usersEdit', 'usersView']}
              yes={(): JSX.Element => <ManageUsersController />}
              no={(): JSX.Element => <RouteErrorPage />}
            />
          }
        />
        <Route path="*" element={<RouteErrorPage error={new Error('404')} />} />
      </Routes>
    </Suspense>
  );
};

export default ToolRoutes;
