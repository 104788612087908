import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type VideoPlayerProps = {
  link: string;
  onPlayHandler?: () => void;
  playing?: boolean;
  width: string | number;
  height: string | number;
};

const useStyles = makeStyles(() => ({
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    maxHeight: '90vh'
  }
}));

const VideoPlayer: React.FC<VideoPlayerProps> = ({ link, playing, onPlayHandler, width, height }) => {
  const { player } = useStyles();

  return (
    <Box position="relative" paddingTop="56.25%">
      <ReactPlayer
        className={player}
        url={link}
        playing={playing}
        onPlay={(): void => {
          if (onPlayHandler) {
            onPlayHandler();
          }
        }}
        controls
        width={width}
        height={height}
      />
    </Box>
  );
};

export default VideoPlayer;
